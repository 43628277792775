import React from 'react';
import { getPropertyById } from "../../../Services/Property";
import { getBannerImage } from "../../../helpers/Utils"
import PropertyAllDropDown from '../../../components/Properties/PropertyAllDropDown';
import { withRouter } from '../../../helpers/withRouter';
import { Link} from 'react-router-dom';

class PropertyDetails extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            propertydetails: [],
            toggleProperty : false
        };
    }

    getQueryStringParams = query => {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params, param) => {
                        let [key, value] = param.split('=');
                        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                        return params;
                    }, {}
                )
            : {}
    };

    componentDidMount = async () =>{
        let query = this.getQueryStringParams(window.location.search);
        let propRes = await getPropertyById(query.id);
        if(!propRes.data.data){
            this.props.navigate('/properties');
        }
        this.setState({propertydetails : propRes.data.data});
    }

    redirect = () => {
        console.log(this.state.propertydetails)
        this.props.navigate('/edit-property?id=' + this.state.propertydetails.id);
    }
    
    render(){
        return(
            <>
            <div className="content-wrapper">
                <div className="page-header">
                    <div className="left-cell">
                        <h3 className="page-title">{this.state.propertydetails.property_name}</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to = "/properties">Properties List</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{this.state.propertydetails.property_name}</li>
                            </ol>
                        </nav>
                    </div>

                </div>
                <section className="property-details-sec">
                    <div className="inner-property-details-sec">
                        <div className="row">
                            <div className="col-md-3 grid-margin stretch-card left-main-col">
                            <div className="quick-option-box mb-4 row">
                                <div  className="inner-quick-option-box">
                                    <h3>
                                        Quick option
                                    </h3>
                                    <div className="row-cell">
                                        {/* <Link to={''}> */}
                                        <span className="col-cell" onClick={()=>{this.redirect()}}>
                                        <div className="img-box">
                                            <img src="assets/admin/images/request-services-icon3.png" alt="bell-icon"/>
                                        </div>
                                        <h4>
                                            Update Property
                                        </h4>
                                        </span>
                                        {/* </Link> */}
                                        <span className="col-cell" >
                                        <div className="img-box">
                                            <img src="assets/admin/images/person-icon.png" alt="person-icon"/>
                                        </div>
                                        <h4>
                                            Add Reminders
                                        </h4>
                                        </span>
                                        <span className="col-cell" >
                                        <div className="img-box">
                                            <img src="assets/admin/images/hand-icon.png" alt="hand-icon"/>
                                        </div>
                                        <h4>
                                            New Maintenance request
                                        </h4>
                                        </span>
                                        <span className="col-cell">
                                        <div className="img-box">
                                            <img src="assets/admin/images/home-icon.png" alt="home-icon"/>
                                        </div>
                                        <h4>
                                            List Property
                                        </h4>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row w-100 mb-4" >
                                <div className="col-xl-12">
                                    <div className="card banking-information-card">
                                        <div className="card-body">
                                        <div className="clearfix">
                                            <h3 className="card-title float-left">banking information</h3>
                                        </div>
                                        <div className="heading-update-title d-flex justify-content-between flex-wrap">
                                            <h3>
                                                your wallet
                                            </h3>
                                            <h3>
                                                this month <span className="progress-title up"><i className="mdi mdi-chevron-up"></i> 1309</span>
                                            </h3>
                                            <h2 className="w-100 d-block currency-title">
                                                <span className="currency">$</span> 45000.00
                                            </h2>
                                        </div>
                                        <content className="mb-4 pb-4">
                                            <div className="wrapper">
                                                <div style={{height:"20%"}}></div>
                                                <div style={{height:"40%"}}></div>
                                                <div style={{height:"70%"}}></div>
                                                <div style={{height:"80%"}}></div>
                                                <div style={{height:"40%"}}></div>
                                                <div style={{height:"95%"}}></div>
                                                <div style={{height:"60%"}}></div>
                                                <div style={{height:"00%"}}></div>
                                                <div style={{height:"60%"}}></div>
                                                <div style={{height:"30%"}}></div>
                                                <div style={{height:"80%"}}></div>
                                                <div style={{height:"40%"}}></div>
                                                <div style={{height:"20%"}}></div>
                                                <div style={{height:"58%"}}></div>
                                                <div style={{height:"40%"}}></div>
                                                <div style={{height:"50%"}}></div>
                                            </div>
                                        </content>
                                        <div className="expense-banking-cont w-100 d-block mt-4">
                                            <div className="two-row-cell d-flex justify-content-between w-100">
                                                <div className="two-col-cell left-cell">
                                                    <div className="inner-two-col-cell">
                                                    <h3 className="orange-color">
                                                        <span className="currency-sign">$</span> 850
                                                    </h3>
                                                    <h4 className="label-text gray-color">
                                                        Expenses
                                                    </h4>
                                                    <div className="progress mt-4  mr-3">
                                                        <div className="progress-bar bg-orange-color" style={{width:"70%"}}></div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="two-col-cell right-cell">
                                                    <div className="inner-two-col-cell">
                                                    <h3 className="light-green-color">
                                                        125
                                                    </h3>
                                                    <h4 className="label-text gray-color">
                                                        Income
                                                    </h4>
                                                    <div className="progress mt-4 mr-3">
                                                        <div className="progress-bar bg-light-green" style={{width:"70%"}}></div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row w-100 mb-4 billing-information-box" >
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                        <div className="clearfix d-flex align-items-center justify-content-between mb-4">
                                            <h3 className="card-title float-left">Property Financials</h3>
                                        </div>
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>Purchase Price</td>
                                                    <td><span>:</span>53275531</td>
                                                </tr>
                                                <tr>
                                                    <td>Current Loan Balance</td>
                                                    <td><span>:</span>53275532</td>
                                                </tr>
                                                <tr>
                                                    <td>John</td>
                                                    <td><span>:</span>53275533</td>
                                                </tr>
                                                <tr>
                                                    <td>Peter</td>
                                                    <td><span>:</span>53275534</td>
                                                </tr>
                                                <tr>
                                                    <td>Dave</td>
                                                    <td><span>:</span>53275535</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-md-9 grid-margin stretch-card right-main-col">
                            <div className="row w-100 mb-4">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                        <div className="property-view-box">
                                            <div className="inner-property-view-box">
                                                <div className="img-box mb-4">
                                                    {
                                                        this.state.propertydetails.propertyfiles ? getBannerImage(this.state.propertydetails.propertyfiles) :''
                                                    }
                                                    {/* <img src="assets/admin/images/property-feature1.jpg" alt="property-feature1"/> */}
                                                </div>
                                                <div className="card-heading-cell mb-5">
                                                    <div className="left-cell">
                                                    <h3 className="card-title">{this.state.propertydetails.street_address}</h3>
                                                    <ul className="card-text">
                                                        <li>
                                                            <img className="location-icon" src="assets/admin/images/location-icon.png" alt="location-icon"/> 
                                                            {this.state.propertydetails.city}
                                                            {this.state.propertydetails.state +" "+ this.state.propertydetails.country +", "+this.state.propertydetails.zip}
                                                        </li>
                                                    </ul>
                                                    </div>
                                                    <div className="right-cell">
                                                    <div className="inner-right-cell">
                                                        <ul className="property-detail-list">
                                                            <li>
                                                                <span> year built</span>
                                                                <h3 className="orange-color">{this.state.propertydetails.year_built}</h3>
                                                            </li>
                                                            <li>
                                                                <span> purchase date</span>
                                                                <h3 className="orange-color">8/2022</h3>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="mb-5 properties-feature-list mt-3">
                                                    <div className="inner-properties-feature-list">
                                                    <ul>
                                                        <li>
                                                            <a href="/#">
                                                            <span className="img-box">
                                                            <img src="assets/admin/images/double-bed.png" alt="double-bed"/>
                                                            </span> <span className="number">4</span>
                                                            bedroom
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/#">
                                                            <span className="img-box">
                                                            <img src="assets/admin/images/toilet.png" alt="toilet"/>
                                                            </span>
                                                            <span className="number">4</span> bathroom
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/#">
                                                            <span className="img-box">
                                                            <img src="assets/admin/images/squre_feet.png" alt="squre-feet"/>
                                                            </span>
                                                            <span className="number">2800</span> sq. ft
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div className="property-cate-box">
                                                    <div className="inner-property-cate-box">
                                                    <ul>
                                                        <li>
                                                            <span className="cate-title">property manager
                                                            :</span>
                                                            <h3>samuel rodrigues</h3>
                                                        </li>
                                                        <li>
                                                            <span className="cate-title">property type
                                                            :</span>
                                                            <h3>{this.state.propertydetails.property_type}</h3>
                                                        </li>
                                                        <li>
                                                            <span className="cate-title">unit :</span>
                                                            <h3><span>2</span> units</h3>
                                                        </li>
                                                        <li>
                                                            <span className="cate-title">HOA
                                                            :</span>
                                                            <h3>yes</h3>
                                                        </li>
                                                        <li>
                                                            <span className="cate-title">monthly rent
                                                            :</span>
                                                            <h3>2400</h3>
                                                        </li>
                                                        <li>
                                                            <span className="cate-title">deposit :</span>
                                                            <h3>2400</h3>
                                                        </li>
                                                        <li>
                                                            <span className="cate-title">property status
                                                            :</span>
                                                            <h3>active</h3>
                                                        </li>
                                                        <li>
                                                            <span className="cate-title">HOA amount
                                                            :</span>
                                                            <h3>120</h3>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row w-100">
                                <div className="col-xl-12">
                                    <div id="main-property">
                                        <div className="accordion" id="property">
                                            {
                                               this.state.propertydetails && this.state.propertydetails.property_utilities ? <PropertyAllDropDown data={this.state.propertydetails.property_utilities} field="utilities_name" type="utilities" /> : ""
                                            }
                                            {
                                                this.state.propertydetails && this.state.propertydetails.property_appliance ? <PropertyAllDropDown data={this.state.propertydetails.property_appliance} field="appliances_name" type="appliance" /> : ""
                                            }
                                            {
                                                this.state.propertydetails && this.state.propertydetails.property_features ? <PropertyAllDropDown data={this.state.propertydetails.property_features} field="feature_name" type="features" /> : ""
                                            }
                                            {
                                                this.state.propertydetails && this.state.propertydetails.property_amenities ? <PropertyAllDropDown data={this.state.propertydetails.property_amenities } field="amenities_name" type="amenity" /> : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>        
            </>
        )
    }
}
export default withRouter(PropertyDetails);