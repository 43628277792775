import React from "react";
import SimpleReactValidator from 'simple-react-validator';
class AddNotes extends React.Component{
    constructor(props){
        super(props)
            this.state = {
                note : '',
            };
       this.validator = new SimpleReactValidator();
         this.submitForm =async ()=> {

            if (this.validator.allValid()) {
                return { status : true , data:this.state };
            } else {
                this.validator.showMessages();
                this.forceUpdate();
                return { status : false , data:[] };
            }
        }  
    }
    setValue = (e, field)=>{
        this.setState({...this.state, [field]:e.target.value});
    }

    componentDidMount = async () => {   
        if(this.props.data){
            this.populateDetails(this.props.data);
        }
    }

    populateDetails = (data) => {
        this.setState({
            note:data
        });

    }
    render(){
        return(
            <>
                <div className="card card-accordion-theme mb-4">
                    <div className="card-header arrow-add-right d-flex align-items-start">
                        <span className="btn-header-link collapsed" data-bs-toggle="collapse" onClick={(e) => {this.setState({toggleAddNotes : !this.state.toggleAddNotes})}}
                            role="button" aria-expanded="false" aria-controls="collapseExample4">
                            <h3 className="d-inline-block align-middle"><i className="mdi mdi-plus-circle-outline"></i>
                                <span>Add Notes</span>
                            </h3>
                        </span>
                        </div>
                        <div className={"collapse" + (this.state.toggleAddNotes ? "show" : "")} id="collapseExample4">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea className="form-control" rows="7" name="note" placeholder="type..." value={this.state.note} onChange={(e) => this.setValue(e, 'note')}></textarea>
                                    </div>
                                    {this.validator.message('note', this.state.note, 'required')}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default AddNotes;