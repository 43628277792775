import { authenticatedPost, authenticatedGet,authenticatedParamGet } from "./core";

export const uploadFile = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'upload-file');
}

export const deleteFile = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'delete-file');
}

export const addProperty = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'properties/add-property');
}

export const getAllProperty = async (request) => {
   return await authenticatedParamGet(request, process.env.REACT_APP_BASE_URL+'properties/properties-by-user-id');
}

export const getPropertyById = async (id) => {
   return await authenticatedGet( process.env.REACT_APP_BASE_URL+'properties/properties-by-property-id/'+id);
}

export const updateProperty = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'properties/update-property');
}

export const updateUserAddress = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'maintenance-upload');
}

export const applyForProperty = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'application-request');
}

export const showInterest = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'property-interest');
}

export const getlikedAndAppliedProperties = async () => {
   return await authenticatedGet( process.env.REACT_APP_BASE_URL+'get-liked-applied-properties');
}

export const getPropertyByUnit = async(id) => {
   return await authenticatedGet( process.env.REACT_APP_BASE_URL+'properties/get-property-by-unit/'+id);
}

export const getAllApplications = async() => {
   return await authenticatedGet( process.env.REACT_APP_BASE_URL+'properties/get-all-applications');
}

export const getApplicationDetails = async(id) => {
   return await authenticatedGet( process.env.REACT_APP_BASE_URL+'properties/get-application-details/'+id);
}








