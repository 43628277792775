import React from 'react';
import { withRouter } from "../../../helpers/withRouter";
import { getQueryStringParams } from "../../../helpers/Utils";
import { Link } from 'react-router-dom';
import {LandlordProfile} from './Services';
class LandlordDetails extends React.Component {
   constructor (props) {
        super(props);
        this.state = {
            id:'',
            details : [],
            activeTab : '',
            details:[],
            addressDetails:{},
            isLoad:false
        };
   }

    componentDidMount = async () => {
        this.setState({isLoad:true})
        let response = await LandlordProfile(this.props.params);
        this.setState({isLoad:false});
        this.setState({details:response.data.data});
        this.setState({addressDetails:response.data.data.user_address[0]});
    }

    componentDidUpdate = async (prevProps) => {
        if( prevProps.params.id !== this.props.params.id ){
            this.setState({isLoad:true})
            let response = await LandlordProfile(this.props.params);
            this.setState({isLoad:false});
            this.setState({details:response.data.data});
            console.log('www', response.data.data.user_address[0] )
            this.setState({addressDetails:response.data.data.user_address[0]});
        }
    }


    render() {
        return (
            <div className="content-wrapper">
            {
                this.state.isLoad?
                    <div className='loading'></div>
                :''
            }
            <div className="page-header">
               <div className="left-cell">
                  <h3 className="page-title">Landlord Details</h3>
                  <nav aria-label="breadcrumb">
                     <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            Landlord
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Landlord Details
                        </li>
                     </ol>
                  </nav>
               </div>
            </div>
            <div className="row mt-4">
               <div className="col-md-3">
                  <div className="stretch-card mb-4">
                     <div className="card left-tenant-detail">
                        <div className="card-body pt-0 text-center">
                           <div className="tenant-profile-img mb-4">
                              <div className="online tp-img"><img className="img-fluid" src="assets/admin/images/profile-img.jpg" alt="tenanat-1"/></div>
                              <h4 className="heading-h4"> {this.state.details.first_name || ''} {this.state.details.last_name || ''} </h4>
                              <p> { this.state.addressDetails.address ? this.state.addressDetails.address + ',' : ""  }
                                  { this.state.addressDetails.city ? this.state.addressDetails.city + ',' : ""  }
                                  { this.state.addressDetails.state ? this.state.addressDetails.state + ',' : ""  }
                                  { this.state.addressDetails.country ? this.state.addressDetails.country + ',' : ""  }
                                  { this.state.addressDetails.postal_code ? this.state.addressDetails.postal_code : ""  }
                              </p>
                           </div>
                           <div className="text-left">

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-9">
                  <div className="stretch-card flex-column flp-section-two">
                     <div className="card mb-4">
                        <ul className="nav nav-pills nav-pills-theme mb-5" id="pills-tab" role="tablist">
                           <li className={ "nav-item" }  role="presentation" >
                              <button className="nav-link " id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="false">Landlord Profile</button>
                           </li>
                        </ul>
                        <div className="tab-content tab-content-theme" id="pills-tabContent">
                           <div className="tab-pane fade active show"  id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                              <div className="card-header d-flex align-items-start">
                                 <h3 className="d-inline-block align-middle">General information</h3>
                              </div>
                              <div className="card-body">
                                 <div className="row row-theme">
                                    <div className="col-md-12">
                                       <ul className="list-md mb-1">
                                          <li><strong>First Name</strong><span> : &nbsp; {this.state.details.first_name || 'N/A'}  </span></li>
                                          <li><strong>Last Name </strong><span> : &nbsp; {this.state.details.last_name || 'N/A'}  </span></li>
                                          <li><strong>Email</strong><span> : &nbsp; {this.state.details.email || 'N/A'} </span></li>
                                          <li><strong>Gender</strong><span> : &nbsp; { (this.state.details.gender == 'm' )?'Male':'Female'}  </span></li>
                                          <li><strong>Date of birth</strong><span> : &nbsp; {this.state.details.birth_date || 'N/A'} </span></li>
                                          <li><strong>Phone Number</strong><span> : &nbsp;  {this.state.details.phone || 'N/A'} </span></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
            );
        }
}
export default withRouter(LandlordDetails);
