import React from "react";
import SimpleReactValidator from 'simple-react-validator';
class AdditionalOccupant extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            count : 1,
        };

        this.validator = new SimpleReactValidator();
        this.validateForm = ()=> {
             if(this.validator.allValid()) {
                 return {status : true, data : this.state};                
             }else{
                 this.validator.showMessages();
                 this.forceUpdate();
                 console.log('inValid', this.state);
                 return {status : false, data : []};
             }
            
        } 
    }

    setValue = (e, field) => {
        this.setState({[field] : e.target.value})
    }

    componentDidMount = async() => {
       // console.log(this.props   , 'sdf')
        if(this.props?.occupantIndex){
            this.setState({count : this.props.occupantIndex + 1});
        }

        if(this.props?.oldData){
            this.setState({
                ...this.props.oldData
            })
        }
    }


 
    render(){
        return (
            <>
                <div className="ai-Occupants-info">
                    <h5 className="as-tittle-h5">Occupant { this.state.count } <span onClick={()=>this.props.deleteAdditionalOccupant(this.props.occupantIndex)}>Delete</span></h5>
                    <div className="row row-theme">
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputName">First Name* </label>
                            <input type="text" name= "first_name" className="form-control" id="exampleInputName" placeholder=""  value={this.state.first_name} onChange={(e)=>this.setValue(e,'first_name')}/>
                            </div>
                            {this.validator.message('first_name', this.state.first_name, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputName">Last Name* </label>
                            <input type="text" name= "last_name" className="form-control" id="exampleInputLastName" placeholder=""  value={this.state.last_name} onChange={(e)=>this.setValue(e,'last_name')}/>
                            </div>
                            {this.validator.message('last_name', this.state.last_name, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputName">Email* </label>
                            <input type="email" name="email" className="form-control" id="exampleInputName" placeholder=""  value={this.state.email} onChange={(e)=>this.setValue(e,'email')}/>
                            </div>
                            {this.validator.message('email', this.state.email, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputPriority">Invite to apply </label>
                            <section id="model-53">
                                <div className="card-toggle-yes">
                                    <div className="toggle-switch">
                                        <input type="checkbox" id="check-53" />
                                        <label htmlFor="check-53"></label>
                                    </div>
                                </div>
                            </section>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default AdditionalOccupant;