import React from "react";
import SimpleReactValidator from 'simple-react-validator';
import Loader from "../../../common/Misc/Loader";
import { getErrorMessage } from "../../../helpers/Utils";
import Autocomplete from "react-google-autocomplete";
import { Link } from 'react-router-dom';
import { uploadFile, deleteFile, addProperty } from "../../../Services/Property";
import {
    parking_options,
    laundry_options ,
    air_conditioning_options,
    property_amenities,
    property_features,
    property_utilities,
    property_appliances

} from "../../../helpers/Constants"
import SingleUnitDetails from "../../../components/Properties/SingleUnitDetails";
import MultipleUnitDetails from "../../../components/Properties/MultipleUnitDetails";
import CustomField from "../../../components/Properties/CustomField";
import { getallCurrency, getAllCountry } from "../../../Services/Miscellaneous";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import {withRouter } from "../../../helpers/withRouter"
import showNotification from "../../../helpers/Notify";

class AddProperty extends  React.Component{
    constructor(props){
        super(props);
         this.state = {
              property_name: '',
              year_built: '',
              mis : '',
              street_address: '',
              city: '',
              state : '',
              country: '',
              currency: '',
              parking: '',
              laundry: '',
              lease_duration: '',
              security_deposite: '',
              payment_type: '',
              cat: '',
              small_dog: '',
              large_dog: '',
              pets_deposit: '',
              pets_fee: '',
              description: '',
              have_pets: 'yes',
              lease_note : '',
              pet_policy_note : '',
              market_rent: '',
              unit_type:'single_unit_type',
              air_conditioning: '',
              all_countries: [],
              multiple_unit_details : [{'index' : 0, ref : 'instance0' }],
              requestInprogress : false,
              propertyAmenities : [],
              propertyFeatures : [],
              propertyUtilities : [],
              propertyAppliances : [],
              addCustomAmenity : false,
              addCustomFeature : false,
              addCustomUtility : false,
              addCustomAppliances : false,
              property_amenities : property_amenities,
              property_features : property_features,
              property_appliances : property_appliances,
              property_utilities : property_utilities,
              uploadedFileList : [],
              profile_pic_path : '',
              allCurrency : [],
              single_unit_property_details : [],
              multiple_unit_property_details :[],
              statusKey:0,
              propertyStatus:[
                    { value: 'active', label: 'Active',key:0 },
                    { value: 'owner_managed', label: 'Owner managed',key:1 },
                    { value: 'not_active', label: 'Not active',key:2 }
              ]
         };
         this.yourFunctionHere=this.yourFunctionHere.bind(this);
        this.validator = new SimpleReactValidator();
        this.submitForm =async ()=> {
            let subComponentHasError = false;
            let property_type_data = [];
            if(this.state.unit_type === 'single_unit_type'){
                let response = await this.child.validateForm();
                subComponentHasError = !response.status ? true : false;
                property_type_data.push(response.data);
            }else{
                let response = this.state.multiple_unit_details.map((row) => {
                    return this[row.ref].validateForm();
                });
                response.map((row) => {
                    property_type_data.push(row.data);
                    if(!row.status)
                        subComponentHasError = true;
                    return false;
                })
            }
            if (this.validator.allValid() && !subComponentHasError) {
                let allFileIds = [];
                this.state.uploadedFileList.map((row) => {
                    allFileIds.push(row.id);
                    return false;
                });



                this.setState({requestInprogress : true});
                let request = {
                    ...this.state,
                    currency : this.state.currency.value,
                    property_type_data,
                    amenities_data : this.state.propertyAmenities,
                    features_data : this.state.propertyFeatures,
                    utilities_data : this.state.propertyUtilities,
                    appliances_data : this.state.propertyAppliances,
                    file_ids:allFileIds,
                    status: this.state.propertyStatus[this.state.statusKey].value
                };


                let response = await addProperty(request);
                this.setState({requestInprogress : false});
                if(response.data.success){
                    showNotification(getErrorMessage(response.data.message), 'success');
                    this.yourFunctionHere()
                } else {
                    showNotification(getErrorMessage(response.data.message), 'error');
                }
            } else {
            this.validator.showMessages();
            this.forceUpdate();
            }
        }
    }

    yourFunctionHere()
    {
        this.props.navigate('/properties')
    }
    getErrorMEssage = (myObj) =>{
        if(typeof myObj == 'object'){
            for(const row in myObj){
                return myObj[row][0];
            }
        }else{
            return myObj;
        }
    }
    setValue = (e, field)=>{
        if(field === 'terms_policy'){
            this.setState({...this.state, [field]:e.target.checked});
        }else{
            this.setState({...this.state, [field]:e.target.value});
        }
    }
    componentDidMount = async() => {
        let resCountry = await getAllCountry();
        this.setState({all_countries: resCountry.data.data});

        let response = await getallCurrency();
        this.setState({allCurrency : response.data.data})
    }

    addMultipleUnit = () => {
        let allunits = this.state.multiple_unit_details;
        let last_index = 0;
        let new_index = ++last_index;
        allunits.map((row) => ( last_index = row.index ));
        allunits.push({ index : new_index , ref : 'instance' + new_index });
        this.setState({ ...this.state,
            multiple_unit_details : allunits
        });
    }

    getMultipleUnitsForms = () => {
        let form = [];
        this.state.multiple_unit_details.map((row,i) => {
            return form.push(<MultipleUnitDetails ref={instance => {this[row.ref] = instance }} key={i} />);
        });
      return form;
    }

    selectPropertyFeature = (e) => {
        let feature = e.target.value;
        let allFeature = this.state.propertyFeatures;
        let ameIndex = allFeature.indexOf(feature);
        if(ameIndex === -1){
            allFeature.push(feature);
        }else {
            allFeature.splice(ameIndex, 1);
        }
        this.setState({propertyFeatures : allFeature});
    }

    selectPropertyAmenities = (e) => {
        let amenities = e.target.value;
        let allAmenities = this.state.propertyAmenities;
        let ameIndex = allAmenities.indexOf(amenities);
        if(ameIndex === -1){
            allAmenities.push(amenities);
        }else {
            allAmenities.splice(ameIndex, 1);
        }
        this.setState({propertyAmenities  : allAmenities});
    }


    selectPropertyUtilities = (e) => {
        let utilities = e.target.value;
        let allUtilities = this.state.propertyUtilities;
        let ameIndex = allUtilities.indexOf(utilities);
        if(ameIndex === -1){
            allUtilities.push(utilities);
        }else {
            allUtilities.splice(ameIndex, 1);
        }
        this.setState({propertyUtilities : allUtilities});

    }

    selectPropertyAppliances = (e) => {
        let appliances = e.target.value;
        let allAppliances = this.state.propertyAppliances;
        let ameIndex = allAppliances.indexOf(appliances);
        if(ameIndex === -1){
            allAppliances.push(appliances);
        }else {
            allAppliances.splice(ameIndex, 1);
        }
        this.setState({propertyAppliances : allAppliances});
    }

    addCustomAmenityFun = (e, type) => {
        if(type === 'amenity'){
            this.setState({addCustomAmenity : true});
        }
        else if(type === 'feature'){
            this.setState({addCustomFeature : true});
        }
        else if(type === 'utilities'){
            this.setState({addCustomUtility : true});
        }
        else
            this.setState({addCustomAppliances : true});
    }

    removeCustomInput = (type) => {
        if(type === 'amenity'){
            this.setState({addCustomAmenity : false});
        }else if(type === 'utilities'){
            this.setState({addCustomUtility : false});
        }else if(type === 'appliances'){
            this.setState({addCustomAppliances : false});
        }
        else
            this.setState({addCustomFeature : false});

    }

    addCustomInput = (value, type) => {
        let obj = {
            label : value,
            value : value.replace(/ /g,"_")
        }

        if(type === 'amenity'){
            let array = this.state.property_amenities;
            array.push(obj);
            this.setState({property_amenities : array});

            let allAmenity = this.state.propertyAmenities;
            allAmenity.push(value);
            this.setState({propertyAmenities : allAmenity});
        }
        else if(type === 'utilities'){
            let array = this.state.property_utilities;
            array.push(obj);
            this.setState({property_utilities : array});

            let allUtilities = this.state.propertyUtilities;
            allUtilities.push(value);
            this.setState({propertyUtilities : allUtilities});
        }
         else if(type === 'appliances'){
            let array = this.state.property_appliances;
            array.push(obj);
            this.setState({property_appliances : array});

            let allAppliances = this.state.propertyAppliances;
            allAppliances.push(value);
            this.setState({propertyAppliances : allAppliances});
        } else {
            let array = this.state.property_features;
            array.push(obj);
            this.setState({property_features : array});

            let allFeature = this.state.propertyFeatures;
            allFeature.push(value);
            this.setState({propertyFeatures : allFeature});
        }

    }

    doFileUpload = async(e, type) => {
        let files = e.target.files;
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            console.log(files[i], '321');
            formData.append(`file`, files[i]);
            formData.append(`type`, type);
            let response = await uploadFile(formData);
            if(response.data.success){
                let new_obj = {
                    id : response.data.data.file_id,
                    file_name : files[i]['name'],
                    file_path : response.data.data.file_path,
                    file_type : files[i]['type'],
                    type:type
                };
                if(type === 'banner'){
                    this.setState({'profile_pic_path' : response.data.data.file_path});
                }
                let imageList =  this.state.uploadedFileList;
                imageList.push(new_obj);
                this.setState({
                    uploadedFileList : imageList
                });

            }else{
                let error_message = getErrorMessage(response.data.message);
                toast.warning(error_message, {autoClose:10000});
            }

        }
    }

    deleteFile = async(e, row) => {
        let request = {
            file_id : row.id
        };
        let response = await deleteFile(request);

        if(response.data.success){
            var delete_index = -1;
            let imageList =  this.state.uploadedFileList;
            imageList.map((all_row, index) => {
                if(row.id === all_row.id)
                 delete_index = index;
                 return delete_index;
            });
            if(delete_index !== -1){
                imageList.splice(delete_index, 1);
                this.setState({
                    uploadedFileList : imageList
                });
            }
        } else {
            let error_message = getErrorMessage(response.data.message);
            toast.warning(error_message, {autoClose:10000});
        }
    }

    showMessage = (message, type) => {
        toast.warning(message, {autoClose:10000});
    }

    getFileElements = (display_type) => {
       return  this.state.uploadedFileList.map((row, index) => {
            if(row.type === display_type && row.type === 'gallery'){
                return (
                    <div key={index} className="gallery" style={{ }}>
                        <img src={ process.env.REACT_APP_SITE_URL + row.file_path} alt={row.file_id}></img>
                        <span className="delete" onClick={(e) => { this.deleteFile(e, row)}}>
                        <i className="mdi mdi-delete"></i>
                        </span>
                    </div>
                )
            }else if(row.type === display_type && row.type === 'attachment'){
                return <div key={index} className="gallery"> { row.file_name } <span className="delete" onClick={(e) => { this.deleteFile(e, row)}}><i className="mdi mdi-delete"></i></span></div>
            }
        })
    }

    setOptionValue = (e, field) => {
        if( field == 'statusKey' ){
            e = e.key;
        }else{
            e = e;
        }
        this.setState({[field] : e})
    }

    getAddressDetails = (address) => {
        this.setState({
            street_address : address.formatted_address,
            lat : address.geometry.location.lat(),
            long : address.geometry.location.lng(),
            place_id : address.place_id
        });
        this.extractDetailAddress(address.address_components);
    }
    extractDetailAddress = (address_component) =>{
        console.log(address_component, 'address_component')
        address_component.map((row)=> {
            if(row.types.indexOf('country') != -1){
                this.setState({country : row.short_name});
            }else if(row.types.indexOf("postal_code") != -1){
                this.setState({zip : row.short_name});
            }else if(row.types.indexOf("administrative_area_level_1") != -1){
                this.setState({state : row.long_name});
            }else if(row.types.indexOf("locality") != -1){
                this.setState({city : row.long_name});
            }
        });
    }

    render(){
        return(
            <>
            <ToastContainer/>
            <div className="content-wrapper">
                <div className="page-header">
                    <div className="left-cell">
                        <h3 className="page-title">Add Property </h3>
                    </div>
                </div>
                <div className="detail-cont">
                    <div className="row">
                        <div className="col-md-8 grid-margin stretch-card flex-column">
                            <div className="card mb-4">
                            <div className="card-header d-flex align-items-start">
                                <h3 className="d-inline-block align-middle">General information</h3>
                            </div>
                            <div className="card-body">
                                <form className="forms-sample">
                                    <div className="row">
                                        <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPropertyName">Property name* </label>
                                            <input type="text" className="form-control" name = "property_name" id="exampleInputPropertyName" placeholder="Enter property name*" value={this.state.property_name} onChange={(e)=>this.setValue(e,'property_name')}/>
                                        </div>
                                        {this.validator.message('property_name', this.state.property_name, 'required')}
                                        </div>
                                        <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputYearbuilt">Year built</label>
                                            <input type="number" className="form-control" name = "year_built" id="exampleInputYearbuilt" placeholder="Enter year built" value={this.state.year_built} onChange={(e)=>this.setValue(e,'year_built')}/>
                                        </div>
                                        {this.validator.message('year_built', this.state.year_built, 'required|numeric')}
                                        </div>
                                        <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputMls">Mls #</label>
                                            <input type="text" className="form-control" name = "mis" id="exampleInputMls" placeholder="Enter mls #" value={this.state.mis} onChange={(e)=>this.setValue(e,'mis')}/>
                                        </div>
                                        {this.validator.message('mis', this.state.mis, 'required')}
                                        </div>
                                        <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEnterAddress">Street address</label>
                                            <Autocomplete className="form-control"
                                                apiKey= { process.env.REACT_APP_GOOGLE_API_KEY }
                                                onPlaceSelected={(place) => {
                                                    this.getAddressDetails(place)
                                                }}
                                                googleMapsScriptBaseUrl = "https://maps.googleapis.com/maps/api/js"
                                            />
                                        </div>
                                        {this.validator.message('street_address', this.state.street_address, 'required')}
                                        </div>
                                        <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputCity">City*</label>
                                            <input type="text" className="form-control" name = "city" id="exampleInputCity" placeholder="Enter city" value={this.state.city} onChange={(e)=>this.setValue(e,'city')}/>
                                        </div>
                                        {this.validator.message('city', this.state.city, 'required')}
                                        </div>
                                        <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputStateRegion">State / Region*</label>
                                            <input type="text" className="form-control"  name = "state" id="exampleInputStateRegion" placeholder="Enter state / Region" value={this.state.state} onChange={(e)=>this.setValue(e,'state')}/>
                                        </div>
                                        {this.validator.message('state', this.state.state, 'required')}
                                        </div>
                                        <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputZip">Zip*</label>
                                            <input type="text" className="form-control" name = "zip" id="exampleInputZip" placeholder="Enter zip" value={this.state.zip} onChange={(e)=>this.setValue(e,'zip')}/>
                                        </div>
                                        {this.validator.message('zip', this.state.zip, 'required')}
                                        </div>
                                        <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputCountry">Country*</label>
                                            <select className="form-control tex-select select_btn" name = "country" id="exampleInputCountry" value={this.state.country} onChange={(e)=> this.setValue(e,'country')}>
                                                <option className="unselect">Enter country</option>
                                                {
                                                    this.state.all_countries.map((option,i) => (
                                                      <option value={option.country_code} key={"enterCountry"+i}>{option.country_name}</option>
                                                ))
                                                }
                                            </select>
                                        </div>
                                        {this.validator.message('country', this.state.country, 'required')}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                            <div className="card mb-4">
                            <div className="card-header d-flex align-items-start">
                                <h3 className="d-inline-block align-middle">Property type</h3>
                            </div>
                            <div className="card-body">
                                <form className="forms-sample">
                                    <div className="row row-propertytype mb-3">
                                        <div className="col-md-6">
                                        <blockquote className="blockquote">
                                            <h4 className="mb-3">Single Unit type</h4>
                                            <p>Single family rentals (often abbreviated as SFR) are rentals in which there is only one rental associated to a specific address. This type of rental is usually used for a house, single mobile home, or a single condo. <b>This type of property does not allow to add any units/rooms</b></p>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                <input type="radio" className="form-check-input" name="unit_type" value="single_unit_type" id="optionsRadios2" defaultChecked   onChange={(e)=>this.setValue(e,'unit_type')}/> Single Unit type
                                                <i className="input-helper"></i>
                                                </label>

                                            </div>
                                        </blockquote>
                                        </div>
                                        <div className="col-md-6">
                                        <blockquote className="blockquote">
                                            <h4 className="mb-3">Multi Unit type</h4>
                                            <p>Multi-unit property are for rentals in which there are multiple rental units per a single address. This type of property is typically used for renting out rooms of a house, apartment units, office units, condos, garages, storage units, mobile home park and etc.</p>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                <input type="radio" className="form-check-input" name="unit_type" value="multi_unit_type" id="optionsRadios1" onChange={(e)=>this.setValue(e,'unit_type')}/> Multi Unit type
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </blockquote>
                                        </div>
                                    </div>
                                    {this.validator.message('unit_type', this.state.unit_type, 'required')}
                                    {
                                        this.state.unit_type === 'single_unit_type' ? <SingleUnitDetails ref={instance => { this.child = instance; }} key={'p'}/> : this.getMultipleUnitsForms()
                                    }

                                    {
                                        this.state.unit_type !== 'single_unit_type' ? <button className="btn btn-link btn-link-lg p-0" type="button" onClick={(e) => this.addMultipleUnit(e)}> <i className="mdi mdi-plus-box"></i> Add Unit </button> :''
                                    }

                                </form>
                            </div>
                            </div>

                            <div className="card mb-4">
                            <div className="card-header d-flex align-items-start">
                                <h3 className="d-inline-block align-middle">Basic amenities</h3>
                            </div>
                            <div className="card-body">
                                <form className="forms-sample">
                                    <div className="row">
                                        <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleParking">Parking </label>
                                            <select  className="form-control tex-select select_btn" name = "parking" id="exampleParking" value={this.state.parking} onChange={(e)=>this.setValue(e,'parking')}>
                                                {parking_options.map((option,i) => (
                                                      <option value={option.value} key={"Parking"+i}>{option.label}</option>
                                                ))}

                                            </select>
                                        </div>
                                        {this.validator.message('parking', this.state.parking, 'required')}

                                        </div>
                                        <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleLaundry">Laundry </label>
                                            <select className="form-control tex-select select_btn" name = "laundry" id="exampleLaundry" value={this.state.laundry} onChange={(e)=>this.setValue(e,'laundry')}>
                                               {laundry_options.map((option,i) => (
                                                      <option value={option.value} key={"Laundry"+i}>{option.label}</option>
                                                ))}

                                            </select>
                                        </div>
                                        {this.validator.message('laundry', this.state.laundry, 'required')}

                                        </div>
                                        <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleAirconditioning">Air conditioning</label>
                                            <select  className="form-control tex-select select_btn" name = "air_conditioning" id="exampleAirconditioning"  value={this.state.air_conditioning} onChange={(e)=>this.setValue(e,'air_conditioning')}>
                                                 {air_conditioning_options.map((option,i) => (
                                                      <option value={option.value} key={"AirCond"+i}>{option.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {this.validator.message('air_conditioning', this.state.air_conditioning, 'required')}

                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                            <div className="card mb-4">
                            <div className="card-header d-flex align-items-start">
                                <h3 className="d-inline-block align-middle">Lease Term</h3>
                            </div>
                            <div className="card-body">
                                <form className="forms-sample">
                                    <div className="row">
                                        <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleParking">Lease Duration( In Months )</label>
                                            <input type="number"   className="form-control" name = "lease_duration" id="lease_duration" placeholder="Enter lease duration*" value={this.state.lease_duration} onChange={(e)=>this.setValue(e,'lease_duration')}/>

                                        </div>
                                        {this.validator.message('lease_duration', this.state.lease_duration, 'required')}

                                        </div>
                                        <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleLaundry">Security Deposit</label>
                                            <input type="text" className="form-control" name = "security_deposite" id="security_deposite" placeholder="Enter security deposit*" value={this.state.security_deposite} onChange={(e)=>this.setValue(e,'security_deposite')}/>
                                        </div>
                                        {this.validator.message('security_deposite', this.state.security_deposite, 'required')}

                                        </div>
                                        <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleAirconditioning">Payment Type</label>
                                            <select className="form-control" name = "payment_type" id="payment_type"   placeholder="Enter month to month*"value={this.state.payment_type} onChange={(e)=>this.setValue(e,'payment_type')}>
                                                    <option value="monthly"> Monthhly</option>
                                                    <option value="quaterly"> Quaterly</option>
                                                    <option value="halfyearly"> Halfyearly</option>
                                                    <option value="yearly"> Yearly</option>
                                                    <option value="full"> Full</option>
                                            </select>
                                        </div>
                                        {this.validator.message('payment_type', this.state.payment_type, 'required')}
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="exampleLaundry">Any note?</label>
                                                <textarea type="text"cols="12" rows="6" className="form-control" name = "lease_note" id="lease_note" placeholder="Enter any specific note if required" value={this.state.lease_note} onChange={(e)=>this.setValue(e,'lease_note')}/>
                                            </div>

                                        </div>

                                    </div>
                                </form>
                            </div>
                            </div>
                            <div className="card badge-anchors mb-4">
                            <div className="card-header d-flex align-items-start">
                                <h3 className="d-inline-block align-middle">Allow Pets?</h3>
                            </div>
                            <div className="card-body">
                                <div className="mb-4">
                                <select  className="form-control tex-select select_btn" name = "have_pets" id="have_pets"  value={this.state.have_pets} onChange={(e)=>this.setValue(e,'have_pets')}>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>

                                </div>

                            </div>
                            </div>

                            {
                                this.state.have_pets === 'yes' ? (
                                    <div className="card mb-4">
                                <div className="card-header d-flex align-items-start">
                                    <h3 className="d-inline-block align-middle">Pet Policy</h3>
                                </div>
                                <div className="card-body">
                                    <form className="forms-sample">
                                        <div className="row">
                                            <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleParking">Cat </label>
                                                <input type="text"   className="form-control" name = "cat" id="cat" value={this.state.cat} onChange={(e)=>this.setValue(e,'cat')}/>

                                            </div>
                                            {this.validator.message('cat', this.state.cat, 'required')}

                                            </div>
                                            <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleLaundry">Small Dog</label>
                                                <input type="text" className="form-control" name = "small_dog" id="small_dog"  value={this.state.small_dog} onChange={(e)=>this.setValue(e,'small_dog')}/>
                                            </div>
                                            {this.validator.message('small_dog', this.state.small_dog, 'required')}

                                            </div>
                                            <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleAirconditioning">Large Dog</label>
                                                <input type="text"  className="form-control" name = "large_dog" id="large_dog" value={this.state.large_dog} onChange={(e)=>this.setValue(e,'large_dog')}/>

                                            </div>
                                            {this.validator.message('large_dog', this.state.large_dog, 'required')}
                                            </div>
                                            <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleAirconditioning">Pets Deposit</label>
                                                <input type ="number"  className="form-control" name = "pets_deposit" id="pets_deposit"   value={this.state.pets_deposit} onChange={(e)=>this.setValue(e,'pets_deposit')}/>

                                            </div>
                                            {this.validator.message('pets_deposit', this.state.pets_deposit, 'required')}

                                            </div>

                                            <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleAirconditioning">Pets Fee</label>
                                                <input type ="number"  className="form-control" name = "pets_fee" id="pets_fee" value={this.state.pets_fee} onChange={(e)=>this.setValue(e,'pets_fee')}/>

                                            </div>
                                            {this.validator.message('pets_fee', this.state.pets_fee, 'required')}

                                            </div>


                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleLaundry">Any note?</label>
                                                    <textarea type="text"cols="12" rows="6" className="form-control" name = "pet_policy_note" id="pet_policy_note" placeholder="Enter any specific note if required" value={this.state.pet_policy_note} onChange={(e)=>this.setValue(e,'pet_policy_note')}/>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>

                                ) : ''
                            }
                            <div className="card badge-anchors mb-4">
                            <div className="card-header d-flex align-items-start">
                                <h3 className="d-inline-block align-middle">Property amenities</h3>
                            </div>
                            <div className="card-body">
                                <div className="mb-4">
                                {this.state.property_amenities.map((option,i) => (
                                    <option className={"badge badge-big rounded-pill bg-outline-secondary" + (this.state.propertyAmenities.indexOf(option.value) !== -1 ? " active" : '') } key={"Propertyamenities"+i} value={option.value} onClick={(e) => { this.selectPropertyAmenities(e)}}>{option.label}</option>
                                ))}

                                </div>
                                <div>

                                    {
                                        this.state.addCustomAmenity ? '' : (
                                            <button className="btn btn-link btn-link-lg p-0 show" type="button"  onClick={(e) => this.addCustomAmenityFun(e, 'amenity')}> <i className="mdi mdi-plus-box"></i> Add custom Amenity </button>
                                        )

                                    }

                                    {
                                        this.state.addCustomAmenity ? <CustomField label="Add custom amenities" type="amenity" showMessage={this.showMessage} addCustomInput={this.addCustomInput} removeCustomInput={this.removeCustomInput} oldData={this.state.propertyAmenities}  /> : ''
                                    }

                                </div>
                            </div>
                            </div>
                            <div className="card badge-anchors mb-4">
                            <div className="card-header d-flex align-items-start">
                                <h3 className="d-inline-block align-middle">Property features</h3>
                            </div>
                            <div className="card-body">
                                <div className="mb-4">
                                    {this.state.property_features.map((option,i) => (
                                        <option className={"badge badge-big rounded-pill bg-outline-secondary" + (this.state.propertyFeatures.indexOf(option.value) !== -1 ? " active" : '') } value={option.value} key={"Propertyfeatures"+i} onClick={(e) => { this.selectPropertyFeature(e)}}>{option.label}</option>
                                ))}

                                </div>
                                <div>
                                    {
                                        this.state.addCustomFeature ? '' : (
                                            <button className="btn btn-link btn-link-lg p-0" type="button" onClick={(e) => this.addCustomAmenityFun(e, 'feature')}> <i className="mdi mdi-plus-box"></i> Add custom feature </button>
                                        )
                                    }

                                    {
                                        this.state.addCustomFeature ? <CustomField label="Add custom feature" type="feature" showMessage={ this.showMessage } addCustomInput={ this.addCustomInput } removeCustomInput={ this.removeCustomInput } oldData={ this.state.propertyAmenities}/> : ''
                                    }
                                </div>
                            </div>
                            </div>
                            <div className="card badge-anchors mb-4">
                            <div className="card-header d-flex align-items-start">
                                <h3 className="d-inline-block align-middle">Property Utilities</h3>
                            </div>
                            <div className="card-body">
                                <div className="mb-4">
                                    {
                                        this.state.property_utilities.map((option,i) => (
                                            <option className={"badge badge-big rounded-pill bg-outline-secondary" + (this.state.propertyUtilities.indexOf(option.value) !== -1 ? " active" : '') } value={option.value} key={"PropertyUtilities"+i} onClick={(e) => { this.selectPropertyUtilities(e)}}>{option.label}</option>
                                        ))
                                    }

                                </div>
                                <div>
                                    {
                                        this.state.addCustomUtility ? '' : (
                                            <button className="btn btn-link btn-link-lg p-0" type="button" onClick={(e) => this.addCustomAmenityFun(e, 'utilities')}> <i className="mdi mdi-plus-box"></i> Add custom Utilities </button>
                                        )
                                    }

                                    {
                                        this.state.addCustomUtility ? <CustomField label="Add custom utility" type="utilities" showMessage={ this.showMessage } addCustomInput={ this.addCustomInput } removeCustomInput={ this.removeCustomInput } oldData={ this.state.propertyUtilities}/> : ''
                                    }
                                </div>
                            </div>
                            </div>
                             <div className="card badge-anchors mb-4">
                            <div className="card-header d-flex align-items-start">
                                <h3 className="d-inline-block align-middle">Property Appliances</h3>
                            </div>
                            <div className="card-body">
                                <div className="mb-4">
                                    {
                                        this.state.property_appliances.map((option,i) => (
                                            <option className={"badge badge-big rounded-pill bg-outline-secondary" + (this.state.propertyAppliances.indexOf(option.value) !== -1 ? " active" : '') } value={option.value} key={"PropertyAppliances"+i} onClick={(e) => { this.selectPropertyAppliances(e)}}>{option.label}</option>
                                        ))
                                    }
                                </div>
                                <div>
                                    {
                                        this.state.addCustomAppliances ? '' : (
                                            <button className="btn btn-link btn-link-lg p-0" type="button" onClick={(e) => this.addCustomAmenityFun(e, 'appliances')}> <i className="mdi mdi-plus-box"></i> Add custom Appliances </button>
                                        )
                                    }
                                    {
                                        this.state.addCustomAppliances ? <CustomField type="appliances" showMessage={ this.showMessage } addCustomInput={ this.addCustomInput } label="Add custom appliance" removeCustomInput={ this.removeCustomInput } oldData={ this.state.propertyAppliances}/> : ''
                                    }
                                </div>
                            </div>
                            </div>
                            <div className="card badge-anchors mb-4">
                            <div className="card-header d-flex align-items-start">
                                <h3 className="d-inline-block align-middle">Property attachments</h3>
                            </div>
                            <div className="card-body">
                                <div className="mb-4">
                                </div>
                                <div className="">
                                    <div className="drop_box">
                                        <h4>Select File here</h4>
                                        <p>store documents and templates</p>
                                        <div className="upload-btn-wrapper">
                                        <button className="btn btn-dark">Upload a file</button>
                                        <input type="file" name="myfile" multiple onChange={(e) => { this.doFileUpload(e, 'attachment') }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="file-upload-names">
                                    <div className="row row-theme">
                                        {
                                            this.getFileElements('attachment')
                                        }
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEnterDT">Description </label>
                                    <textarea className="form-control" rows="7" value={this.state.description} onChange={(e)=>this.setValue(e,'description')}></textarea>
                                </div>
                                {this.validator.message('description', this.state.description, 'required')}
                            </div>

                            <div className="card  mb-4">
                            <div className="card-body p-0">
                                <div className="d-flex justify-content-between">
                                    <Link to = "/properties" type="button" className="btn btn-outline-dark">Cancel</Link>
                                    <button className="btn bg-orange btn-cus mr-2 text-white" disabled={ this.state.requestInprogress}  onClick={this.submitForm}>
                                        { this.state.requestInprogress ? (<Loader className="btn theme-btn-orange" msg= "Adding Property" /> ) : 'Create' }
                                    </button>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-4 grid-margin stretch-card right-side-profile-cell">
                            <div className="row">
                            <div className="col-sm-12">
                                <div className="card mb-4">
                                    <div className="card-header d-flex align-items-start">
                                        <h3 className="d-inline-block align-middle">Currency
                                        </h3>
                                    </div>
                                    <form className="forms-sample mt-2">
                                        <div className="form-group">
                                         <Select options={this.state.allCurrency}  value={this.state.currency}   onChange={(e)=> this.setOptionValue(e,'currency')}/>
                                        </div>
                                        {this.validator.message('currency', this.state.currency, 'required')}
                                    </form>
                                    <div className="card-header d-flex align-items-start">
                                        <h3 className="d-inline-block align-middle">Status
                                        </h3>
                                    </div>
                                    <form className="forms-sample mt-2">
                                        <div className="form-group">
                                         <Select options={this.state.propertyStatus}  value={this.state.propertyStatus[this.state.statusKey]}   onChange={(e)=> this.setOptionValue(e,'statusKey')}/>
                                        </div>
                                    </form>
                                </div>
                               <div className="card mb-4">
                                    <div className="card-header d-flex align-items-start">
                                        <h3 className="d-inline-block align-middle">Property photo
                                        </h3></div>
                                    <div className="profile-pic-wrapper mt-4">
                                        <div className="pic-holder mb-0">
                                        {
                                            this.state.profile_pic_path === '' ?  <img id="profilePic" className="pic" src="assets/admin/images/profile-img.jpg" alt="pic"/> :  <img id="profilePic" className="pic" src={ process.env.REACT_APP_SITE_URL + this.state.profile_pic_path} alt="pic"/>
                                        }
                                        <input className="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto" accept="image/*" style={{opacity: "0"}} onChange={(e) => { this.doFileUpload(e, 'banner') }} allow="jpg,png,jpeg"/>
                                        <label htmlFor="newProfilePhoto" className="upload-file-block">
                                            <div className="text-center">
                                                <div className="mb-2">
                                                    <i className="fa fa-camera fa-2x"></i>
                                                </div>
                                                <div className="text-uppercase">
                                                    Property <br></br> photo
                                                </div>
                                            </div>
                                        </label>
                                        </div>
                                        <div className="fs-cus-20">
                                        Property photo
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between">
                                        <h3 className="d-inline-block align-middle">Gallery
                                        </h3>
                                        <div className="heading-upload">
                                        <div className="file-upload btn btn-link p-0">
                                            <i className="mdi mdi-cloud-upload"></i> Upload photos
                                            <input type="file" name="file"  multiple onChange={(e) => { this.doFileUpload(e, 'gallery') }} accept="image/*"/>
                                        </div>

                                        </div>
                                    </div>
                                    <div className="card-body card-gm-body">
                                    {
                                        this.getFileElements('gallery')
                                        }
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <div className="container-fluid d-flex justify-content-center">
                        <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright ©
                        <b>DigitalTenant</b> 2022. All Rights Reserved.</span>
                    </div>
                </footer>
                </div>
            </>
        )
    }
}
export default withRouter(AddProperty);
