import { useEffect, useState } from "react";
import { withRouter } from "../../helpers/withRouter";
import {NotificationView} from "./Services"
const View = (props) => {

    const [detail,setDetail] = useState({});
    const [isLoad,setIsLoad] = useState(false);

    useEffect(() => {
        getNotificationView();
    },[]);

    const getNotificationView = async () => {
        setIsLoad(true);
        let response = await NotificationView({ id: props.params.id });
        setDetail(response.data.data);
        setIsLoad(false);
    }

    return (
        <div className="content-wrapper">
            {isLoad?<div className="loading"></div>:''}
            <div className="page-header">
                <div className="left-cell">
                    <h3 className="page-title">Notification Details</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Notification</li>
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                Notification Details
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="stretch-card flex-column flp-section-two">
                        <div className="card mb-4">
                            <ul
                                className="nav nav-pills nav-pills-theme mb-5"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li className={"nav-item"} role="presentation">
                                    <button
                                        className="nav-link "
                                        id="pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="false"
                                    >
                                        Notification
                                    </button>
                                </li>
                            </ul>
                            <div
                                className="tab-content tab-content-theme"
                                id="pills-tabContent"
                            >
                                <div
                                    className="tab-pane fade active show"
                                    id="pills-home"
                                    role="tabpanel"
                                    aria-labelledby="pills-home-tab"
                                    tabIndex="0"
                                >
                                    <div className="card-header d-flex align-items-start"></div>
                                    <div className="card-body">
                                        <div className="row row-theme">
                                            <div className="col-md-12">
                                                <ul className="list-md mb-1">
                                                    <li>
                                                        <strong>Title</strong>
                                                        <span> : &nbsp; {detail.title} </span>
                                                    </li>
                                                    <li>
                                                        <strong>
                                                            Description
                                                        </strong>
                                                        <span> : &nbsp; {detail.description} </span>
                                                    </li>
                                                    <li>
                                                        <strong>Send By</strong>
                                                        <span> : &nbsp; {detail?.user_details?.first_name} {detail?.user_details?.last_name} </span>
                                                    </li>
                                                    <li>
                                                        <strong>
                                                            Send date
                                                        </strong>
                                                        <span> : &nbsp; {detail.created_at} </span>
                                                    </li>
                                                    <li>
                                                        <strong>Send To</strong>
                                                        <span> : &nbsp; {detail.send_to} </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(View);
