import { authenticatedPost, authenticatedGet } from "../../../Services/core";

export const composeList = async (request) => {
    return await authenticatedGet(
        process.env.REACT_APP_BASE_URL + "mail/compose-list"
    );
};

export const sendToEmail = async (request) => {
    return await authenticatedPost(
        request,
        process.env.REACT_APP_BASE_URL + "mail/sendmail"
    );
};

export const List = async (request) => {
    return await authenticatedPost(
        request,
        process.env.REACT_APP_BASE_URL + "mail/list"
    );
};

export const SendList = async (request) => {
    return await authenticatedPost(
        request,
        process.env.REACT_APP_BASE_URL + "mail/send-list"
    );
};

export const View = async (request) => {
    return await authenticatedPost(
        request,
        process.env.REACT_APP_BASE_URL + "mail/view-compose"
    );
};

export const Important = async (request) => {
    return await authenticatedPost(
        request,
        process.env.REACT_APP_BASE_URL + "mail/important"
    );
};

export const ImportantView = async (request) => {
    return await authenticatedPost(
        request,
        process.env.REACT_APP_BASE_URL + "mail/important-view"
    );
};

export const SelectedAction = async (request) => {
    return await authenticatedPost(
        request,
        process.env.REACT_APP_BASE_URL + "mail/selected-action"
    );
};
