import React from "react";
import SimpleReactValidator from 'simple-react-validator';
class EmergencyContact extends React.Component{
    constructor(props){
        super(props)
            this.state = {
                first_name : '',
                last_name : '',
                email : '',
                phone: '',
                relationship : '',
                description: '',
 
            };
            this.validator = new SimpleReactValidator();
            this.submitForm =async ()=> {
                console.log(this.state, 'Contact Form Data');
                if (this.validator.allValid()) {
                    return { status : true , data: this.state };
                } else {
                    this.validator.showMessages();
                    this.forceUpdate();
                    return { status : false , data:[] };
                }
            }  
    }
    setValue = (e, field)=>{
        this.setState({...this.state, [field]:e.target.value});
    }
    
    componentDidMount = async () => {   
        if(this.props.data){
            this.populateDetails(this.props.data);
        }
    }

    populateDetails = (details) => {
        this.setState({
            id:details.id,
            first_name : details.first_name,
            last_name : details.last_name,
            email : details.email,
            phone: details.phone,
            relationship : details.relationship,
            description: details.detail,
        });

    }
    render(){
        return(
            <>
            <form className="forms-sample">
                <div className="row">
                    <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="exampleInputFirstName">First Name</label>
                        <input type="text" className="form-control" name="first_name" id="exampleInputFirstName" placeholder="" value={this.state.first_name} onChange={(e) =>this.setValue(e,'first_name')}/>
                    </div>
                    {this.validator.message('first_name', this.state.first_name, 'required')}
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="exampleInputLastName">Last Name</label>
                        <input type="text" className="form-control" name="last_name" id="exampleInputLastName" placeholder="" value={this.state.last_name} onChange={(e) =>this.setValue(e,'last_name')}/>
                    </div>
                    {this.validator.message('last_name', this.state.last_name, 'required')}
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail">Email</label>
                        <input type="email" className="form-control" name="email" id="exampleInputEmail" placeholder="" value={this.state.email} onChange={(e) =>this.setValue(e,'email')}/>
                    </div>
                    {this.validator.message('email', this.state.email, 'required')}
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="exampleInputPhone">Phone</label>
                        <input type="text" className="form-control" name="phone" id="exampleInputPhone" placeholder="" value={this.state.phone} onChange={(e) =>this.setValue(e,'phone')}/>
                    </div>
                    {this.validator.message('phone', this.state.phone, 'required')}
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="exampleInputRelationship">Relationship</label>
                        <input type="text" className="form-control" name="relationship" id="exampleInputRelationship" placeholder="" value={this.state.relationship} onChange={(e) =>this.setValue(e,'relationship')}/>
                    </div>
                    {this.validator.message('relationship', this.state.relationship, 'required')}
                    </div>
                    <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="exampleInputCountry">Description</label>
                        <textarea className="form-control" name="description" rows="7" value={this.state.description} onChange={(e) =>this.setValue(e,'description')}></textarea>
                    </div>
                    {this.validator.message('description', this.state.description, 'required')}
                    </div>
                </div>
            </form>
            </>
        )
    }
}
export default EmergencyContact;