import React from 'react';

class StatsCount extends React.Component {
    constructor (props) {
        super(props);
        this.state = {}
    }
      
    render() {
        return (
            <>
            <div className="col-md-3 stretch-card grid-margin">
                <div className="card  card-img-holder text-white">
                <div className="card-body">
                    <img src={this.props.icon} className="card-img-absolute" alt="total_maintenance"/>
                    <h4 className="font-weight-normal mb-3 color-gray">{ this.props.title}
                    </h4>
                    <h2 className="color-dark">{this.props.value}</h2>
                </div>
                </div>
            </div>
            </>
        );
    }
}
export default StatsCount;