const all_links = [
    // "assets/css/fonts.css",
    // "assets/css/bootstrap.min.css",
    // "assets/css/reset.css",
    // "assets/css/variable.css",
    // "assets/css/common.css",
    // "assets/css/style.css",
    // "assets/css/responsive.css",

    

    "assets/admin/vendors/mdi/css/materialdesignicons.min.css",
    "assets/admin/vendors/css/vendor.bundle.base.css",
    "assets/admin/admin/css/jqvmap.min.css",
    "assets/admin/css/style.css",
    "assets/admin/css/login.css",
    "assets/admin/css/comman.css",
    "assets/admin/css/custom.css",
    "assets/admin/css/responsive.css",
    "https://fonts.googleapis.com/css2?family=Anton&amp;family=Poppins&amp;family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap",
    //"https://cdn.rawgit.com/mdehoog/Semantic-UI/6e6d051d47b598ebab05857545f242caf2b4b48c/dist/semantic.min.css"
]

export default function getLRStyleSheet(){

    let body = document.body;
    body.classList= "login-register-body";
    // var  head = document.head;
    // all_links.map((linkl) => {        
    //     let  link = document.createElement("link");
    //     link.type = "text/css";
    //     link.rel = "stylesheet";
    //     link.href = linkl;
    //     head.appendChild(link);
    //     return false;
    // });
}


