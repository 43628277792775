import React from "react";
import SimpleReactValidator from 'simple-react-validator';
class AdditionalVehicle extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            vehicle_type: '',
            make: '',
            model: '',
            year_made: '',
            color: '',
            license_plate: '',
            count : 1,
        };

        this.validator = new SimpleReactValidator();
        this.validateForm =async()=> {
            // this.props.changeStepVar(3);
            if(this.validator.allValid()) {
                console.log('Valid', this.state);
                return {status : true, data : this.state};                
            }else{
                this.validator.showMessages();
                this.forceUpdate();
                console.log('inValid', this.state);
                return {status : false, data : []};
            }
           
        } 
    }

    setValue = (e, field) => {
        this.setState({[field] : e.target.value})
    }

    componentDidMount = async() => {
        if(this.props.vehicleIndex){
            this.setState({count : this.props.vehicleIndex + 1});
        }

        if(this.props?.oldData){
            this.setState({
                ...this.props.oldData
            })
        }
    }

    render(){
        return(
            <>
                <div className="ai-vehicles-info">
                    <h5 className="as-tittle-h5">Vehicle {this.state.count} <span onClick={()=>this.props.deleteAdditionalVehicle(this.props.vehicleIndex)}>Delete</span></h5>
                    <div className="row row-theme">
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputName">Type* </label>
                            <select className="form-control tex-select select_btn" name= "vehicle_type" id="exampleInputName"  value={this.state.vehicle_type} onChange={(e)=>this.setValue(e,'vehicle_type')}>
                                <option className="unselect" key="1">Select Vehicle Type</option>
                                <option value="motor_cycle" key="2">Motor Cycle</option>
                                <option value="car" key="3">Car</option>
                                <option value="suv" key="4">SUV</option>
                            </select>
                            </div>
                            {this.validator.message('vehicle_type', this.state.vehicle_type, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputName">Make </label>
                            <input type="text" name= "make" className="form-control" id="exampleInputLastName" placeholder="Enter Make"  value={this.state.make} onChange={(e)=>this.setValue(e,'make')}/>
                            </div>
                            {this.validator.message('make', this.state.make, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputName">Model </label>
                            <input type="text" name= "model" className="form-control" id="exampleInputLastName" placeholder="Enter Model"  value={this.state.model} onChange={(e)=>this.setValue(e,'model')}/>
                            </div>
                            {this.validator.message('model', this.state.model, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputName">Year made</label>
                            <input type="text" name= "year_made" className="form-control" id="exampleInputLastName" placeholder="Enter Year Made"  value={this.state.year_made} onChange={(e)=>this.setValue(e,'year_made')}/>
                            </div>
                            {this.validator.message('year_made', this.state.year_made, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputName">Color</label>
                            <input type="text" name= "color" className="form-control" id="exampleInputLastName" placeholder="Enter Color"  value={this.state.color} onChange={(e)=>this.setValue(e,'color')}/>
                            </div>
                            {this.validator.message('color', this.state.color, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputName">License Plate #</label>
                            <input type="text" name= "license_plate" className="form-control" id="exampleInputLastName" placeholder="Enter License Plate"  value={this.state.license_plate} onChange={(e)=>this.setValue(e,'license_plate')}/>
                            </div>
                            {this.validator.message('license_plate', this.state.license_plate, 'required')}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default AdditionalVehicle;