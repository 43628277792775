import { useEffect,useState,createContext  } from "react";
import { db,doc,onSnapshot } from '../../firebase';
import { useNavigate } from "react-router-dom";
import {connect} from 'react-redux';
import { ActiveChatUser } from '../../ReduxServices/Action';

import {getFirstAlphaLetter, capitalize, limitMsgShow} from '../../helpers/Utils'

const MessageList = (props) => {

    const navigate = useNavigate()
    const [uid,setUid] = useState('');
    const [userDetail,setUserDetail] = useState({});
    const [data, setData] = useState([]);
    const [msgCount, setMsgCount] = useState(false);
    useEffect(() => {
        var adminUid = localStorage.getItem('firebase_uid');
        var id = adminUid > props.usersId?adminUid + props.usersId:props.usersId + adminUid
        let unsub = onSnapshot(doc(db,'lastMsg',id),( doc ) => {
            let lastMsg = doc.data();
            if( typeof lastMsg != 'undefined' && 'timestamp' in lastMsg ){
                if(  adminUid ==  lastMsg.reciver_id &&  lastMsg.unread ){
                    let msgdate = doc.data().timestamp.toDate();
                    lastMsg.timeage = timeSince(msgdate);
                    lastMsg.uid = doc.id;
                    setMsgCount(true);
                    setData(lastMsg);
                }
            }
        })
        return () => unsub();
    },[props])


    const timeSince = (date) => {
        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = seconds / 31536000;
        if (interval > 1) {
            return Math.floor(interval) + " years ago";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months ago";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days ago";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " min ago";
        }
        return " just now";
    }

    const handleMessageActiveUid = (data) => {
        setUid(data.uid);
        let userData = {uid:data.uid,displayName:capitalize(data.details.displayName),nickName:getFirstAlphaLetter(data.details.displayName) }
        setUserDetail(userData)
        props.ActiveChatUser(userData)
        navigate('/messages')
    }



    return(
        <>
            {
                ( data.reciver_id != props.row.uid && data.unread  )?
                    <>
                        <li class="chat-unread" onClick={(e) => handleMessageActiveUid(props.row)} >
                            <a selectedid={props.row.uid} selectedname={props.row.details.displayName} selectnickname={getFirstAlphaLetter(props?.row?.details?.displayName)}  ></a>
                            <div class="d-flex justify-content-between p-2">
                                <div class="d-flex flex-row">
                                    <div class="avatar-img me-3">{getFirstAlphaLetter(props?.row?.details?.displayName)}</div>
                                        <div class="pt-1 avatar-name">
                                            <p class="fw-bold mb-0">{capitalize(props.row.details.displayName)}</p>
                                            <p class="small text-muted m-0 messageNotification-p">{ limitMsgShow(data.messages) }</p>
                                        </div>
                                    </div>
                                    <div class="pt-1 avatar-time">
                                        <p class="small text-muted mb-1">{data.timeage}</p>
                                    </div>
                                </div>
                        </li>
                    </>:''
            }

        </>


    )


}



const MapDispachToProps = dispatch => ({
    ActiveChatUser:(userDetail)  => dispatch(ActiveChatUser(userDetail))
});
const mapStateToProps = state => ({
    UserUidCode:state.UserUidCode
})
export default connect(mapStateToProps,MapDispachToProps)(MessageList);
