import React from "react";
import StatsCount from "../../../components/Maintainance/StatsCount";
import { Link} from 'react-router-dom';
import { getAllMaintenance, filterResult } from '../../../Services/Maintenance';
class Maintenance extends React.Component{
    constructor (props){
        super (props);
        this.state = {
         allMaintenance : [],
         total :0,
         new:0,
         inProgress : 0,
         resolved : 0,
         status_coilor : {new : 'blue-text', in_progress : 'orange-text', resolved : 'green-text' },
         searchText : '',
         searchStatus : 'all'
        };
    }
    componentDidMount = async() => {

      let allPropertyList = await getAllMaintenance();
      this.setState(
         {
            allMaintenance : allPropertyList.data.data.all,
            total : allPropertyList.data.data.all.length,
            new : allPropertyList.data.data.new,
            resolved : allPropertyList.data.data.resolved,
            inProgress : allPropertyList.data.data.inProgress,
         });
     }

   updateSearchField = async (e) => {
      await this.setState({...this.state, [e.target.name] : e.target.value});
      this.filterResult();
     };

     filterResult = async () => {
      let filter = {
         text : this.state.searchText,
         status : this.state.searchStatus
      };
      let result = await filterResult(filter);
      this.setState(
         {
            allMaintenance : result.data.data,
         });
   }
    render(){
        return (
            <>
            <div className="content-wrapper">
               <div className="page-header">
                  <div className="left-cell">
                     <h3 className="page-title">Maintenance</h3>
                     <nav aria-label="breadcrumb">
                        {/* <ol className="breadcrumb">
                           <li className="breadcrumb-item"><a href="/#">Maintenance </a></li>
                           <li className="breadcrumb-item active" aria-current="page">Request</li>
                        </ol> */}
                     </nav>
                  </div>
                  <div className="right-cell">
                  </div>
               </div>
               <div className="row top-grid-view maintenance">
                    <StatsCount title="Total Maintenance" icon="assets/admin/images/total_maintenance.png" value={this.state.total}/>
                    <StatsCount title="In Progress" icon="assets/admin/images/inprogress.png" value={this.state.inProgress}/>
                    <StatsCount title="New Maintenance" icon="assets/admin/images/hold_maintenance.png" value={this.state.new }/>
                    <StatsCount title="Complete Maintenance" icon="assets/admin/images/complete_maintenance.png" value={this.state.resolved}/>
               </div>
               <div className="page-header-two">
                  <div className="inner-page-header-two">
                     <div className="cell-row ">
                        <div className="left-cell">
                        </div>
                        <div className="right-cell">
                           <div className="inner-right-cell">
                              <ul className="navbar-nav navbar-nav-right">
                                 <li className="search-field d-none d-md-block">
                                    <form className="d-flex align-items-center h-100" action="#">
                                       <div className="input-group">
                                          <input type="text" className="form-control bg-transparent border-0" name="searchText" placeholder="Search Maintenance" onKeyUp={(e) => { this.updateSearchField(e) }}/>
                                          <div className="input-group-prepend bg-transparent">
                                             <img src="assets/images/dashboard/search-icon.png" alt=""/>
                                          </div>
                                       </div>
                                    </form>
                                 </li>
                                 {/* <li className="account-item">
                                    <select id="all" className="account_selection select_btn">
                                       <option>all</option>
                                       <option>1</option>
                                    </select>
                                 </li> */}
                                 <li className="category-item">
                                    <select id="category" className="category_selection select_btn" name="searchStatus" value={this.state.searchStatus} onChange={(e) => { this.updateSearchField(e) }}>
                                       <option value=''>Status</option>
                                       <option value=''>All</option>
                                       <option value='new'>New</option>
                                       <option value='in_progress'>In Progress</option>
                                       <option value='resolved'>Resolved</option>
                                    </select>
                                 </li>
                                 {
                                    (JSON.parse(localStorage.getItem('userDetalis')).user_type != 'admin')?
                                        <li className="add-transaction cus-btn">
                                            <Link to="/new-maintenance" className="bg-light-green"><i className="mdi mdi-plus"></i> Add Maintenance</Link>
                                        </li>
                                    :''
                                }

                                 <li className="download-item cus-btn">
                                    <a className="bg-orange">download  <i className="fa fa-download mr-3"></i></a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row mt-4 data-table-box maintenance-data-table-box" >
                  <div className="col-lg-12 grid-margin stretch-card">
                     <div className="card">
                        <div className="card-body pt-0">
                            <div className="table-responsive">
                           <table className="table  table-striped">
                              <thead>
                                 <tr>
                                    <th> # </th>
                                    <th>Description</th>
                                    <th>Property</th>
                                    <th>Assigned To</th>
                                    <th>Priority</th>
                                    <th>  Status  </th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>

                                 {
                                    this.state.allMaintenance.length > 0 ?
                                    this.state.allMaintenance.map((row) => {
                                       return (
                                          <tr key={row.id}>
                                             <td>
                                                { row.mo }
                                             </td>
                                             <td>{ row.description }</td>
                                             <td> { row.property ? row.property : 'N/A' }  </td>
                                             <td>{ row.maintenanceassigned ? row.maintenanceassigned.assigned_to : 'Not Assigned' }  </td>
                                             <td style={{ textTransform: 'capitalize'}}> { row.priority } </td>
                                             <td className={this.state.status_coilor[row.status]} style={{ textTransform: 'capitalize'}}>  {row.status.replace("_", " ")} </td>
                                             <td> <Link to= { "/edit-maintenance?id=" + row.id  } className="vd-btn">   view details</Link></td>
                                          </tr>

                                       )
                                    })
                                     : <tr>
                                     <td colSpan={7}><div className="no-data-table">No Data Found</div></td>
                                   </tr>
                                 }
                              </tbody>
                           </table>
                           </div>

                           {/* <div className="pagination">
                              <a href="/#" className="back-page page-btn"><i className="mdi mdi-chevron-left"></i></a>
                              <a href="/#"  className="active">1</a>
                              <a href="/#">2</a>
                              <a href="/#">3</a>
                              <a href="/#">4</a>
                              <a href="/#">5</a>
                              <a href="/#">6</a>
                              <a href="/#" className="next-page page-btn"><i className="mdi mdi-chevron-right"></i></a>
                            </div> */}
                        </div>
                     </div>
                  </div>
               </div>

            </div>
            </>
        )
    }
}
export default Maintenance;
