import { authenticatedPost, authenticatedGet } from "../../Services/core";

export const usersList = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'users/list');
}

export const NotificationSend = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'notification/send');
}

export const NotificationList = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'notification/list');
}

export const NotificationView = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'notification/view');
}
