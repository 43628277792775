
import React from "react";
import { Link } from 'react-router-dom';
import { ViewMileage,deleteMileage } from "../../../Services/Mileage";
import { withRouter } from "../../../helpers/withRouter";
import Swal from 'sweetalert2'
import showNotification from "../../../helpers/Notify";


class View extends React.Component{
    constructor (props){
        super (props);
        this.state = {
            row:[],
            property:[],
            deleteId:null,
        }
    }

    componentDidMount = async () => {
        let response = await ViewMileage({'id':this.props.params.id});
        this.setState({row:response.data.data});
        this.setState({property:
            {"from_properties":response.data.data.from_properties.street_address,
              "to_properties":response.data.data.to_properties.street_address  }
        });
    }

    componentDidUpdate = async (prevProps) => {
        if( prevProps.params.id !== this.props.params.id ){
            let response = await ViewMileage({'id':this.props.params.id});
            this.setState({row:response.data.data});
            this.setState({property:
                {"from_properties":response.data.data.from_properties.street_address,
                "to_properties":response.data.data.to_properties.street_address  }
            });
        }
    }

    deleteThisGoal(id) {
        this.setState({deleteId : id});
        Swal.fire({
         title: 'Are you sure?',
         text: "You won't be able to revert this!",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes, delete it!'
       }).then((result) => {
         if (result.isConfirmed) {
             this.hideAlert();
         }
       });

      }

      hideAlert = async () => {
        var id = this.state.deleteId;
        let deleteResponse = await deleteMileage({'id':id});
        if(deleteResponse.data.success){
            showNotification(deleteResponse.data.message, 'success');

         } else {
            showNotification(deleteResponse.data.message, 'warning');
         }
         this.props.navigate('/mileage');
      }



    render(){
        var id = this.props.params.id;
        return (
            <>
            <div className="content-wrapper">
               <div className="page-header">
                  <div className="left-cell">
                     <h3 className="page-title">Mileage Deatils</h3>
                     <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                           <li className="breadcrumb-item"><a href="/#">Mileage </a></li>
                           <li className="breadcrumb-item active" aria-current="page"> details</li>
                        </ol>
                     </nav>
                  </div>
                  <div className="right-cell">
                     <form action="">
                        <div className="breadcrumb date_picker">
                           <div className="breadcrumb-item active calender" aria-current="page">
                              <div className="ui calendar" id="example2">
                                 <div className="ui input left icon">
                                    <i className="calendar icon"></i>
                                    <input type="text" placeholder="calendar" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
               <div className="all_transaction_record_box row mb-4">
                  <div className="inner_all_transaction_record_box d-flex">
                     <div className="action-btns-group ml-auto">
                        <div className="innner-action-btns-group">
                           <Link to={ `../edit-details/${id}`}  className="btn btn-dark btn-fw">Edit</Link>
                           <button type="button" className="btn btn-dark btn-fw">Print</button>
                           <button onClick={() => this.deleteThisGoal(id)} type="button" className="btn btn-dark btn-fw">Delete</button>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="row row-theme mt-4">

               <div className="col-md-12">
                  <div className="stretch-card flex-column flp-section-two">
                     <div className="card id-card-summary mb-4">
                        <div className="card-header d-flex align-items-start">
                           <h3 className="d-inline-block align-middle">Summary</h3>
                       </div>
                        <div className="card-body">
                           <div className="row row-theme">
                              <div className="col-md-12">
                         <ul className="list-md mb-4">
                           <li><strong>Trip Date</strong><span> : &nbsp; {this.state.row['trip_date'] || 'NA'} </span></li>
                           <li><strong>Business Name </strong><span> : &nbsp; {this.state.row['business_name'] || 'NA' } </span></li>
                           <li><strong>From Property</strong><span> : &nbsp; {this.state.property.from_properties || 'NA' } </span></li>
                           <li><strong>To Property</strong><span> : &nbsp; {this.state.property.to_properties || 'NA' } </span></li>
                           <li><strong>Purpose</strong><span> : &nbsp; {this.state.row['purpose'] || 'NA' } </span></li>
                           <li><strong>Miles</strong><span> : &nbsp; {this.state.row['miles'] || 'NA' } </span></li>
                           <li><strong>Amount</strong><span> : &nbsp; ${this.state.row['amount'] || 'NA' } </span></li>
                           </ul>
                        </div>
                        </div>
                        </div>
                     </div>
                  </div>

               </div>
            </div>
               <footer className="footer">
                  <div className="container-fluid d-flex justify-content-center">
                     <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright ©
                     <b>DigitalTenant</b> 2022. All Rights Reserved.</span>
                  </div>
               </footer>
            </div>
            </>
        )
    }
}
export default withRouter(View) ;
