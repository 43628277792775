import React from "react";
import SimpleReactValidator from 'simple-react-validator';
class AddVehicles extends React.Component{
    constructor(props){
        super(props)
            this.state = {
                vehicles_type : '',
                make : '', 
                model : '',
                year : '', 
                color : '',
                license : '',
            };
       this.validator = new SimpleReactValidator();
         this.submitForm =async ()=> {
            if (this.validator.allValid()) {
                return { status : true , data: this.state };
            } else {
                this.validator.showMessages();
                this.forceUpdate();
                return { status : false , data:[] };
            }
        }  
    }
    setValue = (e, field)=>{
        this.setState({...this.state, [field]:e.target.value});
    }

    componentDidMount = async () => {   
        if(this.props.data){
            this.populateDetails(this.props.data);
        }
    }

    populateDetails = (details) => {
        this.setState({
            id:details.id,
            vehicles_type : details.vehicle_type,
            make : details.make, 
            model : details.model,
            year : details.year, 
            color : details.color,
            license : details.license,
        });

    }

    render(){
        return(
            <>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="exampleInputFirstName">Vehicles type</label>
                                <select className="form-control tex-select select_btn" name="vehicles_type" id="exampleInputCountry" value={this.state.vehicles_type} onChange={(e) =>
                                this.setValue(e,'vehicles_type')}>
                                <option className="unselect">Automobile</option>
                                <option>Motorrcycle</option>
                                <option>Moped</option>
                                <option>Truck</option>
                                <option>Van</option>
                                </select>
                            </div>
                            {this.validator.message('vehicles_type', this.state.vehicles_type, 'required')}
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="exampleInputMake">Make</label>
                                <input type="text" className="form-control" name="make" id="exampleInputMake" placeholder="" value={this.state.make} onChange={(e) =>this.setValue(e, 'make')}/>
                            </div>
                            {this.validator.message('make', this.state.make, 'required')}
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="exampleInputModel">Model</label>
                                <input type="text" className="form-control" name="model" id="exampleInputModel" placeholder="" value={this.state.model} onChange={(e) => this.setValue(e, 'model')}/>
                            </div>
                            {this.validator.message('model', this.state.model, 'required')}
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="exampleInputYear">Year</label>
                                <input type="text" className="form-control" name="year" id="exampleInputYear" placeholder="" value={this.state.year} onChange={(e) => this.setValue(e, 'year')}/>
                            </div>
                            {this.validator.message('year', this.state.year, 'required')}
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="exampleInputColor">Color</label>
                                <input type="text" className="form-control" name="color" id="exampleInputColor" placeholder="" value={this.state.color} onChange={(e) => this.setValue(e, 'color')}/>
                            </div>
                            {this.validator.message('color', this.state.year, 'required')}
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="exampleInputLicense">License</label>
                                <input type="text" className="form-control" name="license" id="exampleInputLicense"  placeholder="" value={this.state.license} onChange={(e) => this.setValue(e, 'license')}/>
                            </div>
                            {this.validator.message('license', this.state.license, 'required')}
                        </div>
                        <div className="col-md-12">
                           
                    </div>
                    </div>
                    
            </>
        )
    }
}
export default AddVehicles;