const Pay = () => {

    //var requirejs = require('Converge');

    return(
        <>
            <form action="request_session_token_demo.php" name="test1" method="post">
                <table border="1" bordercolorlight="#C0C0C0" cellspacing="5" bordercolordark="#FFFFFF">
                    <tr>
                        <td>Trans Amount</td>
                        <td><input type="text" name="ssl_amount" size="6" value="1.00" /></td>
                    </tr>
                    <tr>
                        <td>Transaction Type</td>
                        <td>
                            <select size="1" name="ssl_transaction_type">
                                <option value="CCSALE">Credit Card Sale</option>
                                <option value="CCAUTHONLY">Credit Card Authorization Only</option>
                                <option value="CCVerify">Credit Card Verification </option>
                                <option value="CCGETTOKEN">Credit Card Get Token</option>
                                <option value="SALE">Generic Sale</option>
                                <option value="AUTHONLY">Generic Authorization Only</option>
                                <option value="ecspurchase">ACH</option>
                                <option value="EGCSALE">Gift Card</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <p align="center">Redirect to NG HPP</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" align="center">
                            <input type="submit" value="Pay Now" name="Submit" />
                        </td>
                    </tr>
                </table>
            </form>
        </>

    )

}

export default Pay;
