import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {addTemplate} from './Services'
import { useNavigate  } from "react-router-dom";
import showNotification from "../../../helpers/Notify";
import { getErrorMessage } from "../../../helpers/Utils";
import Card from 'react-bootstrap/Card';
import Dropzone from 'react-dropzone'
import SimpleReactValidator from 'simple-react-validator';


const Create = () => {


    const [title,setTitle] = useState('');
    const [acceptedFiles,setAcceptedFiles] = useState({});
    const [documentType,setDocumentType] = useState('copyPaste');
    let navigate = useNavigate();

    const simpleValidator = useRef(new SimpleReactValidator())
    const [, forceUpdate] = useState();

    const handleSelectedType = (type) => {
        setDocumentType(type)
    }



    const handleSubmitForm = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.fieldValid('title')

        if (!formValid) {
            simpleValidator.current.showMessageFor('title')
            forceUpdate(1)
        }else{
            if( documentType == 'upload' && acceptedFiles.length == 0 ){
                simpleValidator.current.showMessageFor('File')
                forceUpdate(1)
            }else{
                const formData = new FormData();
                formData.append('files',acceptedFiles);
                formData.append('title',title);
                formData.append('documentType',documentType);
                let data = await addTemplate(formData);
                if( typeof data.data.data != 'undefined' || data.data.data != '' ){
                    showNotification(getErrorMessage('Template Create successfully. Create Document By SignNow'), 'success');
                    navigate('/document-template');
                }else{
                    showNotification(getErrorMessage('Something wrong please ry again'), 'error');
                    setTitle('');
                }
            }
        }

    }

    return(
        <>
        <div className="content-wrapper">
            <div className="page-header">
                <div className="left-cell">
                    <h3 className="page-title">Create Template </h3>
                </div>
            </div>
            <div className="detail-cont">
                <form onSubmit={handleSubmitForm} >
                    <div className="row">
                        <div className="col-md-12 grid-margin stretch-card flex-column">
                                <div className="card mb-4">
                                    <div className="card-header d-flex align-items-start">
                                        <h3 className="d-inline-block align-middle">Document Template</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPropertyName">Template Title* </label>
                                            <input type="text" className="form-control" name = "title" id="exampleInputPropertyName" placeholder="Enter the title *" value={title || ''} onChange={ (e) => { setTitle(e.target.value) }  } />


                                        </div>
                                        {simpleValidator.current.message('title', title, 'required')}
                                        <div className="form-group templateCreateOption">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <Card style={{ width: '18rem',margin: 'auto' }} onClick={(e) => handleSelectedType('copyPaste') } className={documentType == 'copyPaste'?'active':''}>
                                                        <Card.Body>
                                                            <Card.Title><i class="mdi mdi-file" style={{ fontSize: '52px' }} ></i></Card.Title>
                                                            <Card.Title>Copy & Paste</Card.Title>
                                                            <Card.Text>
                                                                Create your own customizable template and use it during the move-in or send as a notice.
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div class="col-md-6">
                                                    <Card style={{ width: '18rem' }} onClick={(e) => handleSelectedType('upload') } className={documentType == 'upload'?'active':''} >
                                                        <Card.Body>
                                                            <Card.Title><i class="mdi mdi-cloud-upload" style={{ fontSize: '52px' }} ></i></Card.Title>
                                                            <Card.Title>Upload PDF</Card.Title>
                                                            <Card.Text>
                                                                Upload and edit PDFs with the dynamic PDF editor. You can add textbox, signature and other auto-fill elements.
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            documentType == 'upload'?
                                                <>
                                                <div className="form-group templateUploadPdf" style={{     paddingLeft:"11em",paddingRight:"23em" }}>
                                                    <Dropzone onDrop={acceptedFiles => setAcceptedFiles( acceptedFiles[0] ) }>
                                                        {({getRootProps, getInputProps}) => (
                                                            <section className='dropDown' style={{ border:"2px #d7d4d4 dotted" }}>
                                                            <div {...getRootProps()} style={{ textAlign:"center" }}>
                                                                <input {...getInputProps()} />
                                                                <p><i class="mdi mdi-cloud-upload" style={{ fontSize: '52px' }} ></i></p>
                                                                <h4>PDF File</h4>
                                                                <p>Drag & drop the file</p>
                                                            </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                                {simpleValidator.current.message('File', acceptedFiles, 'required')}
                                                </>
                                                :''
                                        }

                                    </div>
                                </div>
                                <div class="card  mb-4">
                                    <div class="card-body p-0">
                                        <div class="d-flex justify-content-between">
                                            <Link to="/document-template" type="button" class="btn btn-outline-dark" >Cancal</Link>
                                            <button type="submit" class="btn bg-orange btn-cus mr-2 text-white">Save</button>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </>
    )
}

export default Create;
