import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Footer from "../../../common/Footer";
import {Button,Card} from 'react-bootstrap';
import {listTemplate} from './Services'
import Dropdown from 'react-bootstrap/Dropdown';

const List = () => {

const [templateData,setTemplateData] = useState([]);

useEffect(() => {
        getAllTemplate();
},[])

const getAllTemplate = async () => {
    let response = await listTemplate({});
    if( typeof response.data.data.data != 'undefined' && response.data.data.data.length ){
        setTemplateData(response.data.data.data);
    }
}

return(
    <div className="content-wrapper">
        <div className="page-header">
            <div className="left-cell">
                <h3 className="page-title">Properties</h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard" >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Properties</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div className="page-header-two">
            <div className="inner-page-header-two">
                <div className="cell-row ">
                    <div className="left-cell">
                    <h4>
                        0<span>Total</span>
                    </h4>
                    </div>
                    <div className="right-cell">
                    <div className="inner-right-cell">
                        <ul className="navbar-nav navbar-nav-right">
                            <li className="search-field d-none d-md-block">
                                <form className="d-flex align-items-center h-100" action="#">
                                <div className="input-group">
                                    <input type="text" className="form-control bg-transparent border-0" placeholder="Search"/>
                                    <div className="input-group-prepend bg-transparent">
                                        <img src="assets/admin/images/dashboard/search-icon.png" alt=""/>
                                    </div>
                                </div>
                                </form>
                            </li>
                            <li className="grid-item">
                                <a className="nav-link">
                                <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                                </a>
                            </li>
                            <li className="new-property-item">
                                <Link to="/document-create" >Create Template</Link>

                            </li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <section className="document-list-view-sec ">
            <div className="inner-document-list-view-sec">
                <div className="row row-cus templateList" style={{ marginTop:'20px' }} >
                    {
                        templateData.length > 0?
                            templateData.map((row) =>
                                    <div className="col-md-2">
                                        <Link to='../document-view' >
                                        <Card style={{ width: '16rem' }}>
                                            <Card.Body className="templateEditHover" >
                                                <Card.Title><i class="mdi mdi-file" style={{ fontSize: '52px' }} ></i></Card.Title>
                                                <Card.Subtitle className="mb-2">{row.title}</Card.Subtitle>
                                            </Card.Body>
                                        </Card>
                                        </Link>
                                    </div>
                            )
                        :<div className="col-md-12">No Templates</div>
                    }

                </div>
            </div>
        </section>
        <Footer/>
    </div>

)


}

export default List;
