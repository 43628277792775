import { getAuth } from "firebase/auth";
import { auth,db } from '../firebase';
import { collection, addDoc, getDoc,updateDoc,Timestamp } from "firebase/firestore";
import { useEffect,useState } from "react";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { authenticatedPost } from "../Services/core";


import PushNotification from './PushNoification';

const SendNotification = async (data) => {
        /*  example data pass
            {'landlord':[{value:36}],title:'text','description':'text description','send_type':1,'goto' => application-details?id=1} */
        var response = await authenticatedPost(data,`${process.env.REACT_APP_BASE_URL}notification/send`);
        PushNotification(response.data.data)
}

export default SendNotification;
