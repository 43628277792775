import React from 'react';
class Loading extends React.Component {    
    constructor (props) {
        super(props);
        this.state = { };      
       
    }
  render() {
    return (
       <><span>{this.props.msg}</span></>
    );
  }
}
export default Loading;