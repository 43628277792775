import React from 'react';
import { beds, baths} from "../../helpers/Constants";
import SimpleReactValidator from 'simple-react-validator';
class SingleUnitDetails extends React.Component {
    constructor(props){
        super(props);
           this.state = {
            beds : '',
            baths :'',
            size : '',
            rent:'',
            deposit:''
           };
           this.validator = new SimpleReactValidator();
    }

    validateForm = () => {
        if(this.validator.allValid()) {
            return {status : true, data : this.state};
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            return {status : false, data : []};
        }
    }

    setValue = (e, field)=>{
         this.setState({...this.state, [field]:e.target.value});
    }

    componentDidMount = async() => {
        if(this.props.data){
            let data = this.props.data[0]
            this.setState({beds:data.beds})
            this.setState({baths:data.baths})
            this.setState({size:data.size})
            this.setState({rent:data.rent})
            this.setState({deposit:data.deposite})
        }
    }
    render() {
        return (
            <div className="row" >
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputCountry">Beds</label>
                        <select className="form-control tex-select select_btn" name = "beds"  id="exampleInputCountry" value={this.state.beds} onChange={(e)=>this.setValue(e,'beds')}>
                            <option className="unselect" >Select Beds</option>
                            {beds.map((option,i) => (
                            <option value={option.value} key={i} >{option.label}</option>
                            ))}
                        </select>
                    </div>
                    {this.validator.message('beds', this.state.beds, 'required')}
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputCountry">Baths</label>
                        <select className="form-control tex-select select_btn" name = "baths" id="exampleInputCountry" value={this.state.baths} onChange={(e)=>
                            this.setValue(e,'baths')}>
                            <option className="unselect">Select Baths</option>
                            {baths.map((option,i) => (
                            <option value={option.value} key={i} >{option.label}</option>
                            ))}
                        </select>
                    </div>
                    {this.validator.message('baths', this.state.baths, 'required')}
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputUsername2">Size, sq.ft </label>
                        <input type="number" className="form-control"  name = "size"  id="exampleInputUsername2" placeholder="0.00" value={this.state.size} onChange={(e)=>this.setValue(e,'size')}/>
                    </div>
                    {this.validator.message('size', this.state.size, 'required|numeric')}
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputUsername2">Market rent*</label>
                        <input type="number" className="form-control" name = "market_rent" id="exampleInputUsername2" placeholder="0.00" value={this.state.rent} onChange={(e)=>this.setValue(e,'rent')}/>
                    </div>
                    {this.validator.message('rent', this.state.rent, 'required|numeric')}
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputUsername2">Deposit</label>
                        <input type="number" className="form-control" name = "deposite" id="exampleInputUsername2" placeholder="0.00" value={this.state.deposit} onChange={(e)=>this.setValue(e,'deposit')}/>
                    </div>
                    {this.validator.message('deposit', this.state.deposit, 'required|numeric')}
                </div>
            </div>

         );
    }
}

export default SingleUnitDetails;
