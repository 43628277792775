import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Footer from "../../../common/Footer";
import {Button,Card} from 'react-bootstrap';
import {listTemplate} from './Services'
import Dropdown from 'react-bootstrap/Dropdown';

const View = () => {

return(
    <div className="content-wrapper">
        <div className="page-header">
            <div className="left-cell">
                <h3 className="page-title">Document Template</h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard" >Dashboard</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Document Template View</li>
                    </ol>
                </nav>
            </div>
        </div>
        <section className="document-list-view-sec ">
            <div className="inner-document-list-view-sec">
                <div className="row row-cus templateList" style={{ marginTop:'20px' }} >

                </div>
            </div>
        </section>
        <Footer/>
    </div>

)


}

export default View;
