import {authenticatedGet , authenticatedPost} from "./core.js"

export const getallCountries = async () => {
    return await authenticatedGet( process.env.REACT_APP_BASE_URL+'all-countries')
}

export const getallCurrency = async () => {
    return await authenticatedGet( process.env.REACT_APP_BASE_URL+'currency')
}

export const getAllCountry = async () => {
    return await authenticatedGet( process.env.REACT_APP_BASE_URL + 'all-countries')
}

export const getFilteredResult = async (req) => {
    return await authenticatedPost( req, process.env.REACT_APP_BASE_URL + 'search-filter')
}

export const getAllActivityLog = async () => {
    return await authenticatedGet( process.env.REACT_APP_BASE_URL + 'all-activity-log')
}

export const getBundles = async () => {
    return await authenticatedGet( process.env.REACT_APP_BASE_URL + 'bundles')
}

export const approveApplication = async (req) => {
    return await authenticatedPost( req, process.env.REACT_APP_BASE_URL + 'approve-application')
}

export const getLeaseDetails = async (id) => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL + 'lease-details/'+id)
}





