import React from 'react';
class Footer extends React.Component {
  render() {
    return (
        <>
            <footer className="footer">
                <div className="container-fluid d-flex justify-content-center">
                    <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright ©
                    <b>DigitalTenant</b> 2022. All Rights Reserved.</span>
                </div>
            </footer>
        </>
    )
  }
}
export default Footer;