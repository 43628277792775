import React from "react";
import { getAllTenantTransactions } from "../../../Services/Tenants"; 
import { Link } from "react-router-dom";
import  Paginate from "../../admin/Comman/Paginate";
class List extends React.Component{
    constructor(props){
        super(props);
           this.state = {
            allTransactions: [],
            total :0,
            search:null,
            page:0,
            limit:10,
            toggleFilterMenu : false,
            transaction_status : '',
            duration : '',
            total_sum : 0
        };
    }

    componentDidMount = () => {
        let parameter = {
            page: this.state.page,
            limit: this.state.limit
        };
        this.getResult(parameter);
    }

    handlePageCallback =  (event) => {
        let parameter = {
            search: this.state.search,
            page:parseInt(event.selected),
            limit:this.state.limit
        }
        this.getResult(parameter);
    }

    searchTransaction = (value) => {
        let parameter = {
            search: value,
            page:this.state.page,
            limit:this.state.limit
        }
        this.setState({search:value});
        this.getResult(parameter);
    }

    getResult = async(params) => {
        let res = await getAllTenantTransactions(params);
        this.setState({ 
            allTransactions : res.data.data.result,
            total : res.data.data.total,
            total_sum : res.data.data.total_sum
        });
    }

    toggleFilterMenu = () => {
        let val = this.state.toggleFilterMenu;
        this.setState({
            toggleFilterMenu : !val
        });
    }

    setValue = (e, val) =>{
        this.setState({[val] : e.target.value});
    }

    applyFilter = () =>{
        console.log(this.state);
        let parameter = {
            search: this.state.search,
            page: this.state.page,
            limit: this.state.limit,
            duration: this.state.duration,
            transaction_status: this.state.transaction_status

        }
        this.setState({ toggleFilterMenu : false });
        this.getResult(parameter);
    }

    render(){
        return(
          <>
            <div className="content-wrapper">
                <div className="page-header">
                    <div className="left-cell">
                        <h3 className="page-title">My Transactions</h3>
                    </div>
                    <div className="right-cell">
                    </div>
                </div>
                {/* <div className="all_transaction_record_box row mb-4">
                    <div className="inner_all_transaction_record_box d-flex">
                        <div className="action-btns-group action-btns-disabled ml-auto">
                        <div className="innner-action-btns-group">
                            <button type="button" className="btn btn-dark btn-fw">edit</button>
                            <button type="button" className="btn btn-dark btn-fw">duplicate</button>
                            <button type="button" className="btn btn-dark btn-fw">split</button>
                            <button type="button" className="btn btn-dark btn-fw">delete</button>
                        </div>
                        </div>
                    </div>
                </div> */}
                <div className="page-header-two rent-header-two">
                    <div className="inner-page-header-two">
                        <div className="cell-row ">
                        <div className="left-cell">
                            <h4 className="poppins-font">
                                Outstanding <strong className="mx-2">${this.state.total_sum }</strong>
                            </h4>
                        </div>
                        <div className="right-cell">
                            <div className="inner-right-cell">
                                <ul className="navbar-nav navbar-nav-right">
                                    <li className="search-field d-none d-md-block">
                                    <form className="d-flex align-items-center h-100" action="#">
                                        <div className="input-group">
                                            <input type="text" className="form-control bg-transparent border-0"
                                                placeholder="Search Transactions" value={this.state.search || ''} onChange={(input) => this.searchTransaction(input.target.value)} />
                                            <div className="input-group-prepend bg-transparent">
                                                <img src="assets/images/dashboard/search-icon.png" alt="" />
                                            </div>
                                        </div>
                                    </form>
                                    </li>

                                    <li className="dropdown custom-dropdown filter-item filter-item-pp">
                                    <span className="btn dropdown-toggle show" href="#" role="button" data-bs-toggle="dropdown"
                                        aria-expanded="false" onClick={() => { this.toggleFilterMenu()}}> 
                                        <i className="mdi mdi-filter-outline"></i> filter
                                    </span>

                                    <div className={"dropdown-menu "+ (this.state.toggleFilterMenu ? "show" : "") } style={{position: "absolute", inset:" 0px 0px auto auto", margin: "0px", transform: "translate(0px, 44px)"}} >
                                        <form>
                                            
                                            <div className="form-group">
                                                <h5>Date </h5>
                                                <select className="form-select" aria-label="Default select example" onChange={(e)=>this.setValue(e, 'duration')}>
                                                    <option selected value='current_month'>Current month</option>
                                                    <option value="last_30_days">Last 30 days</option>
                                                    <option value="last_month">Previous month</option>
                                                    <option value="last_3_month">Last 3 months</option>
                                                    <option value="previous_quarter">Previous quarter</option>
                                                    <option value="last_12_months">Last 12 months</option>
                                                    <option value="last_year">Previous year</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <h5>Transaction Status</h5>
                                                <select className="form-select" aria-label="Default select example" onChange={(e)=>this.setValue(e, 'transaction_status')}>
                                                <option selected value=''>open</option>
                                                <option value="pending">Pending</option>
                                                <option value="done">Done</option>
                                                <option value="cancel">Canceled</option>
                                                <option value="failed">Failed</option>
                                                <option value="requested">Requested</option>
                                                <option value="refund">Refunded</option>
                                                <option value="partial_refund">Partially Refunded</option>
                                                </select>
                                            </div>

                                            <div className="custom-dropdown-footer">
                                                <button type="button" className="btn btn-secondary"
                                                data-bs-dismiss="modal">Reset</button>
                                                <button type="button" className="btn btn-primary" onClick={() => this.applyFilter()}>Show result</button>
                                            </div>
                                        </form>

                                    </div>
                                    </li>

                                    <li className="download-item cus-btn">
                                    <a href="javascript:void(0)" className="bg-orange">download <i
                                            className="fa fa-download mr-3"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4 data-table-box">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                        <div className="card-body pt-0">
                            <div className="table-reponsive">
                                <table className="table  table-striped">
                                    <thead>
                                        <tr>
                                            {/* <th>
                                                <span className="form-check form-check-flat form-check-primary">
                                                    <label className="form-check-label">
                                                    <input type="checkbox" className="form-check-input" /><i
                                                        className="input-helper"></i></label>
                                                </span>
                                            </th> */}
                                            <th>Due Date</th>
                                            <th>Property/Unit</th>
                                            <th>Person </th>
                                            <th>Total/Paid</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            this.state.allTransactions?.length > 0 ? (
                                                this.state.allTransactions.map((row) =>{
                                                    return (
                                                        <tr>
                                                            {/* <td>
                                                                <span className="form-check form-check-flat form-check-primary">
                                                                    <label className="form-check-label">
                                                                    <input type="checkbox" className="form-check-input" /><i
                                                                        className="input-helper"></i></label>
                                                                </span>
                                                            </td> */}
                                                            <td>{ row.transaction_date } </td>
                                                            <td>{ row.property.property_name }</td>
                                                            <td>{ row.paid_to.first_name +' '+row.paid_to.last_name }</td>
                                                            <td>${ row.amount }</td>
                                                            <td className="text-success">{ row.status  === 'done'? 'Completed' : row.status }</td>
                                                            <td><Link to={"/transaction-details?id="+row.id} className="vd-btn">view details</Link></td>
                                                        </tr>

                                                    )
                                                })
                                            ): "No Data Found"
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>

                            <Paginate pageCount={this.state.total}  limit={this.state.limit} handlePageCallback = {this.handlePageCallback} />
                        </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <div className="container-fluid d-flex justify-content-center">
                        <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright ©
                        <b>DigitalTenant</b> 2022. All Rights Reserved.</span>
                    </div>
                </footer>
            </div>
          </>
        )
    }
}
export default List;

