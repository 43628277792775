import React from 'react';
import { Link} from 'react-router-dom';
class AdminNav extends React.Component {
    constructor(props){
        super(props);
           this.state = {
            user_role : '',
            activeLink : 'tenant-dashboard'
        };
    }

    componentDidMount = () => {
        let user_details = localStorage.getItem('userDetalis');
        user_details  = JSON.parse(user_details);
        this.setState({user_role : user_details.user_type});
    }
    goToPage = async (path) => {
        await this.setState({
            activeLink : path
        });
    }

    logoutUser = (e) => {
        localStorage.clear();
        window.location.href = '/';
    }
  render() {
    return (
        <>
            <li className={ (this.state.activeLink === 'tenant-dashboard') ? 'nav-item  active' : 'nav-item '} onClick={(e) => { this.goToPage('tenant-dashboard')}}>
                <Link className="nav-link " to="/tenant-dashboard">
                    <img src="assets/admin/images/dashboard/dashboard-icon.png" className="dashboard-icon" alt="dashboard"/>
                    <img src="assets/admin/images/dashboard/dashboard-active-icon.png" className="dashboard-icon-hover" alt="dashboard-icon"/>
                    <span className="menu-title">Dashboard</span>
                </Link>
            </li>
            <li className={ (this.state.activeLink === 'rent') ? 'nav-item  active' : 'nav-item '} onClick={(e) => { this.goToPage('rent')}}>
                <Link className="nav-link " to="/rent">
                <img src="assets/admin/images/dashboard/td-rent-icon.png" className="dashboard-icon" alt="message"/>
                  <img src="assets/admin/images/dashboard/td-rent-active-icon.png" alt="message-active"
                     className="dashboard-icon-hover"/>
                    <span className="menu-title">All Transactions</span>
                </Link>
            </li>
            <li className={ (this.state.activeLink === 'my-applications') ? 'nav-item  active' : 'nav-item '} onClick={(e) => { this.goToPage('my-applications')}}>
                <Link className="nav-link " to="/my-applications">
                <img src="assets/admin/images/dashboard/td-application-icon.png" className="dashboard-icon" alt="message"/>
                  <img src="assets/admin/images/dashboard/td-application-active-icon.png" alt="message-active"
                     className="dashboard-icon-hover"/>
                    <span className="menu-title">My Applications</span>
                </Link>
            </li>
            <li className={ (this.state.activeLink === 'property-search') ? 'nav-item  active' : 'nav-item '} onClick={(e) => { this.goToPage('property-search')}}>
                <Link className="nav-link" data-bs-toggle="collapse" to="/property-search" aria-expanded="false" aria-controls="menu-properties">
                    <img src="assets/admin/images/dashboard/property-icon.png" className="dashboard-icon" alt="property"/>
                    <img src="assets/admin/images/dashboard/property-active-icoon.png" alt="property-active" className="dashboard-icon-hover"/>
                    <span className="menu-title">Properties</span>
                </Link>
            </li>
            <li className={ (this.state.activeLink === 'messages') ? 'nav-item  active' : 'nav-item '} onClick={(e) => { this.goToPage('messages')}}>
                <Link className="nav-link" data-bs-toggle="collapse" to="/messages" aria-expanded="false" aria-controls="menu-properties">
                <img src="assets/admin/images/dashboard/message-icon.png" className="dashboard-icon" alt="message"/>
                <img src="assets/admin/images/dashboard/message-active-icon.png" alt="message-active"
                    className="dashboard-icon-hover"/>
                    <span className="menu-title">Messages</span>
                </Link>
            </li>
            <li className={ (this.state.activeLink === 'support') ? 'nav-item  active' : 'nav-item '} onClick={(e) => { this.goToPage('support')}}>
                <Link className="nav-link" data-bs-toggle="collapse" to="/support" aria-expanded="false" aria-controls="menu-properties">
                <img src="assets/admin/images/dashboard/td-customer-icon.png" alt="reports" className="dashboard-icon"/>
                <img src="assets/admin/images/dashboard/td-customer-active-icon.png" alt="reports-active"
                     className="dashboard-icon-hover"/>
                    <span className="menu-title">Support</span>
                </Link>
            </li>
        </>
    );
  }
}

export default AdminNav;
