import React from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Loader from "../../../common/Misc/Loader"

import { uploadFile, deleteFile, getMaintenanceById, editMaintenance } from "../../../Services/Maintenance";
import { getAllProperty } from '../../../Services/Property';
import { getErrorMessage } from "../../../helpers/Utils";
import { getAllTenant } from '../../../Services/Maintenance';
import showNotification from "../../../helpers/Notify";
import { withRouter } from "../../../helpers/withRouter";
import { Link } from 'react-router-dom';

class EditMaintenance extends React.Component {
   constructor (props) {
        super(props);
         this.state = {
            id:'',
            priority:null,
            property:null,
            status:'new',
            tenant:null,
            pet:null,
            description_title:null,
            description:null,
            video:null,
            images:[],
            service_pro_allowed : false,
            availability : '',
            notify_tenants : '',
            assigned_to : '',
            requested_by : '',
            availabilityDetails : false,
            requestInprogress:false,
            video_image_list :[],
            image_list : [],
            allPropertyList : [],
            allTenants : []
         };
        this.validator = new SimpleReactValidator();
        this.submitForm =async()=> {
            if (this.validator.allValid()) {
               this.setState({requestInprogress : true});
               let allFileIds = [];
               this.state.video_image_list.map((row) => {
                  allFileIds.push(row.id);
                  return false;
               });
               let request = {
                  ...this.state,
                  file_ids:allFileIds,
               };
               let response = await editMaintenance(request);
               this.setState({requestInprogress : false});
                  if(response.data.success){
                     showNotification(getErrorMessage(response.data.message), 'success');
                     this.props.navigate('/maintenance')
                  } else {
                     showNotification(getErrorMessage(response.data.message), 'warning');
                  }
            } else {
               this.validator.showMessages();
               this.forceUpdate();
            }
        }
   }

   setValue = (e, field)=>{
      this.setState({...this.state, [field]:e.target.value});
   }

   toggleAvailability = (e) => {
      console.log(e.target.checked);
      this.setState({availabilityDetails : e.target.checked})
   }

   doFileUpload = async(e, type) => {
      let files = e.target.files;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
          formData.append(`file`, files[i]);
          formData.append(`type`, type);
          let response = await uploadFile(formData);
          if(response.data.success){
               let new_obj = {
                  id : response.data.data.file_id,
                  file_name : files[i]['name'],
                  file_path : response.data.data.file_path,
                  file_type : files[i]['type'],
                  type:type
               };
               let video_image_list =  this.state.video_image_list;
               video_image_list.push(new_obj);
               this.setState({
                  video_image_list : video_image_list
               });
          }else{
              let error_message = getErrorMessage(response.data.message);
              showNotification(error_message,'warnig');
          }
      }
  }

  getFileElements = (display_type) => {
      return  this.state.video_image_list.map((row, index) => {
         if(row.type === display_type && row.type === 'file'){
               return (
                  <div key={index} className="gallery" style={{ }}>
                     <img src={ process.env.REACT_APP_SITE_URL + (row.file_path ?  row.file_path : row.path)} alt={row.file_id}></img>
                     <span className="delete" onClick={(e) => { this.deleteFile(e, row)}}>
                     <i className="mdi mdi-delete"></i>
                     </span>
                  </div>
               )
         }else if(row.type === display_type && row.type === 'video'){
               return <div key={index} className="gallery"> { row.file_name ? row.file_name : row.path } <span className="delete" onClick={(e) => { this.deleteFile(e, row)}}><i className="mdi mdi-delete"></i></span></div>
         }
      })
   }

   deleteFile = async(e, row) => {
      let request = {
          file_id : row.id
      };
      let response = await deleteFile(request);

      if(response.data.success){
          var delete_index = -1;
          let video_image_list =  this.state.video_image_list;
          video_image_list.map((all_row, index) => {
               if(row.id === all_row.id)
                  delete_index = index;
               return delete_index;
          });
          if(delete_index !== -1){
               video_image_list.splice(delete_index, 1);
               this.setState({
                  video_image_list : video_image_list
               });
          }
      } else {
          let error_message = getErrorMessage(response.data.message);
          showNotification(error_message,'warnig');
      }
  }

  getQueryStringParams = query => {
   return query
       ? (/^[?#]/.test(query) ? query.slice(1) : query)
           .split('&')
           .reduce((params, param) => {
                   let [key, value] = param.split('=');
                   params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                   return params;
               }, {}
           )
       : {}
};

  componentDidMount = async() => {
      let query = this.getQueryStringParams(window.location.search);
      let maintenanceDetails = await getMaintenanceById(query.id);
      this.populateData(maintenanceDetails.data.data);
      let allPropertyList = await getAllProperty();
      this.setState({allPropertyList : allPropertyList.data.data });
      let allTenantRes = await getAllTenant();
      this.setState({allTenants : allTenantRes.data.data });
  }

    componentWillReceiveProps = async () => {
        let query = this.getQueryStringParams(window.location.search);
        let maintenanceDetails = await getMaintenanceById(query.id);
        this.populateData(maintenanceDetails.data.data);
        let allPropertyList = await getAllProperty();
        this.setState({allPropertyList : allPropertyList.data.data });
        let allTenantRes = await getAllTenant();
        this.setState({allTenants : allTenantRes.data.data });
    }

  populateData = (data) => {
   if(data){
      this.setState({
         id:data.id,
         priority:data.priority,
         status:data.status,
         tenant:data.tenant_id,
         property:data.property_id,
         pet:data.pet,
         description_title:data.description_title,
         description:data.description,
         video_image_list : data.files,
         availabilityDetails : data.maintenanceassigned ? true : false,
         availability : data.maintenanceassigned ? data.maintenanceassigned.availability : '',
         notify_tenants : data.maintenanceassigned ? data.maintenanceassigned.notify_tenants : '',
         assigned_to : data.maintenanceassigned ? data.maintenanceassigned.assigned_to : '',
         requested_by : data.maintenanceassigned ? data.maintenanceassigned.requested_by : ''
      });
   }
   console.log(data, 'data data');
  }

   render() {
      return (
         <>
            <div className="content-wrapper">
               <div className="page-header">
                  <div className="left-cell">
                     <h3 className="page-title">Edit Maintenance Request</h3>
                  </div>
               </div>
               <div className="detail-cont">
                  <div className="row">
                     <div className="col-md-12 grid-margin stretch-card flex-column">
                        <div className="card mb-4">
                           <div className="card-body pt-0">
                              <form className="forms-sample">
                                 <div className="row">
                                    <div className="col-md-6">
                                       <div className="form-group">
                                          <label htmlFor="exampleInputPriority">Priority </label>
                                          <select className="form-control tex-select select_btn" id="exampleInputPriority" value={this.state.priority} onChange={(e)=>this.setValue(e,'priority')}>
                                             <option className="unselect" key="unselect">Select Priority</option>
                                             <option value="urgent" key="urgent">Urgent</option>
                                             <option value="high" key="high">High</option>
                                             <option value="medium" key="medium">Medium</option>
                                             <option value="low" key="low">Low</option>
                                          </select>
                                       </div>
                                       {this.validator.message('priority', this.state.priority, 'required')}
                                    </div>
                                    <div className="col-md-6">
                                       <div className="form-group">
                                          <label htmlFor="exampleInputPriority">MO </label>
                                          <p className="poppins-font">MO-549786</p>
                                       </div>
                                    </div>
                                    <div className="col-md-6">
                                       <div className="form-group">
                                          <label htmlFor="exampleInputPriority">Property </label>
                                          <select className="form-control tex-select select_btn" id="exampleInputPriority" value={this.state.property} onChange={(e)=>this.setValue(e,'property')}>
                                             <option className="unselect">Select Property</option>
                                             {
                                                this.state.allPropertyList.map((row) => (
                                                    <option value={row.id} key={row.id}>{row.property_name+' ('+row.street_address + ', ' +row.city+')'}</option>
                                                ))
                                             }

                                          </select>
                                       </div>
                                       {this.validator.message('property', this.state.property, 'required')}
                                    </div>
                                    <div className="col-md-6">
                                       <div className="form-group">
                                          <label htmlFor="exampleInputPriority">Status </label>
                                          <select className="form-control tex-select select_btn" id="exampleInputPriority" value={this.state.status} onChange={(e)=>this.setValue(e,'status')}>
                                             <option  value="new" key="0">New</option>
                                             <option  value="in_progress" key="1">In progress</option>
                                             <option  value="resolved" key="2">Resolved</option>
                                          </select>
                                       </div>
                                       {this.validator.message('status', this.state.status, 'required')}
                                    </div>
                                    <div className="col-md-6">
                                       <div className="form-group">
                                          <label htmlFor="exampleInputPriority">Tenant </label>
                                          <select className="form-control tex-select select_btn" id="exampleInputPriority"  value={this.state.tenant} onChange={(e)=>this.setValue(e,'tenant')}>
                                             <option className="unselect" key="unselect">Select Tenant</option>
                                             {
                                                this.state.allTenants.map((row) => (
                                                    <option value={row.id} key={row.id}>{row.first_name+' '+row.last_name}</option>
                                                ))
                                             }
                                          </select>
                                       </div>
                                       {this.validator.message('tenant', this.state.tenant, 'required')}
                                    </div>
                                    <div className="col-md-6">
                                       <div className="form-group">
                                          <label htmlFor="exampleInputPriority">Pet </label>
                                          <select className="form-control tex-select select_btn" id="exampleInputPriority" value={this.state.pet} onChange={(e)=>this.setValue(e,'pet')}>
                                             <option className="unselect">Have Pet?</option>
                                             <option value="yes" key="1">Yes</option>
                                             <option value="no" key="2">No</option>
                                          </select>
                                       </div>
                                       {this.validator.message('pet', this.state.pet, 'required')}
                                    </div>
                                    <div className="col-md-12">
                                       <div className="form-group">
                                          <label htmlFor="exampleInputEnterDT">Description Title </label>
                                          <input type="text" className="form-control" id="exampleInputEnterDT" placeholder="" value={this.state.description_title} onChange={(e)=>this.setValue(e,'description_title')}/>
                                       </div>
                                       {this.validator.message('description_title', this.state.description_title, 'required')}
                                    </div>
                                    <div className="col-md-12">
                                       <div className="form-group">
                                          <label htmlFor="exampleInputEnterDT">Description </label>
                                          <textarea className="form-control" rows="7" value={this.state.description} onChange={(e)=>this.setValue(e,'description')}></textarea>
                                       </div>
                                       {this.validator.message('description', this.state.description, 'required')}
                                    </div>
                                    <div className="col-md-6">
                                       <div className="form-group">
                                          <label>Add short video to visualise the issue.</label>
                                          <div className="drop_box mar_drop_box ">
                                             <div className="upload-btn-wrapper">
                                                <button className="btn bg-orange btn-cus text-white">
                                                   <i className="mdi mdi-cloud-upload text-muted"></i>
                                                   <p>Upload your video here </p>
                                                </button>
                                                <input type="file" name="myfile"   onChange={(e) => { this.doFileUpload(e, 'video') }}/>
                                             </div>
                                          </ div>
                                       </div>

                                       <div className="file-upload-names">
                                          <div className="row row-theme">
                                          { this.getFileElements('video') }
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-md-6">
                                       <div className="form-group">
                                          <label>Add photos to visualise the issue.</label>
                                          <div className="drop_box mar_drop_box ">
                                             <div className="upload-btn-wrapper">
                                                <button className="btn bg-orange btn-cus text-white">
                                                   <i className="mdi mdi-cloud-upload text-muted"></i>
                                                   <p>Upload your image here </p>
                                                </button>
                                                <input type="file" name="myfile" multiple onChange={(e) => { this.doFileUpload(e, 'file') }} />
                                             </div>
                                          </div>
                                       </div>

                                       <div className="file-upload-names">
                                          <div className="row row-theme">
                                          { this.getFileElements('file') }
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="service-pro-mar mt-4">
                                    <div className="form-group row-spro-mar-check">
                                       <div className="form-check">
                                          <label className="form-check-label">
                                          <input type="checkbox" name="checkbox" className="form-check-input" checked = {this.state.availabilityDetails ? true : false } onClick={(e) => this.toggleAvailability(e) } /> When will service pro is allowed to enter into the house
                                          <i class="input-helper"></i></label>
                                       </div>
                                    </div>

                                    {
                                       this.state.availabilityDetails ? (
                                          <div className="row row-spro-mar" >
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label htmlFor="exampleInputPriority">Availability </label>
                                                <div className="input-group input-group-icon mb-3">
                                                   <input type="datetime-local" className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={this.state.availability} onChange={(e)=>this.setValue(e,'availability')}/>
                                                   <div className="input-group-append p-0">
                                                      <span className="input-group-text" id="basic-addon2"><i className="mdi mdi-calendar"></i></span>
                                                   </div>
                                                </div>
                                             </div>
                                          {this.validator.message('availability', this.state.availability, 'required')}
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label htmlFor="exampleInputPriority">Notify Tenants </label>
                                                <select className="form-control tex-select select_btn" id="exampleInputPriority" value={this.state.notify_tenants} onChange={(e)=>this.setValue(e,'notify_tenants')}>
                                                   <option className="unselect">Yes</option>
                                                   <option className="unselect">No</option>
                                                </select>
                                             </div>
                                             {this.validator.message('notify_tenants', this.state.notify_tenants, 'required')}
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label htmlFor="exampleInputEnterDT">Assigned To </label>
                                                <input type="text" className="form-control" id="exampleInputEnterDT" placeholder="" value={this.state.assigned_to} onChange={(e)=>this.setValue(e,'assigned_to')}/>
                                             </div>
                                             {this.validator.message('assigned_to', this.state.assigned_to, 'required')}
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label htmlFor="exampleInputEnterDT">Requested By </label>
                                                <input type="text" className="form-control" id="exampleInputEnterDT" placeholder="" value={this.state.requested_by} onChange={(e)=>this.setValue(e,'requested_by')}/>
                                             </div>
                                             {this.validator.message('requested_by', this.state.requested_by, 'required')}
                                          </div>
                                       </div>
                                       ):""
                                    }

                                 </div>
                              </form>
                           </div>
                        </div>
                        {
                            (JSON.parse(localStorage.getItem('userDetalis')).user_type != 'admin' )?
                                <div className="card  mb-4">
                                    <div className="card-body p-0">
                                        <div className="d-flex justify-content-between">
                                            <Link to = "/maintenance" type="button" className="btn btn-outline-dark">Cancel</Link>
                                            <button className="btn bg-orange btn-cus mr-2 text-white"  disabled={ this.state.requestInprogress} onClick={this.submitForm}>{ this.state.requestInprogress ? (<Loader className="btn theme-btn-orange" msg="Creating new request..." />) : 'Create a request' }</button>
                                        </div>
                                    </div>
                                </div>
                            :''
                        }
                     </div>
                  </div>
               </div>
            </div>
         </>
        );
    }
}
// export default AddMaintenaince;
export default withRouter(EditMaintenance);
