import React from "react";
import SimpleReactValidator from 'simple-react-validator';
import { getallCountries } from "../../Services/Miscellaneous"
import { updateUserAddress } from "../../Services/Property";
import Loading from "../../common/Misc/Loader";
import showNotification from "../../helpers/Notify";


class Address extends React.Component{
    constructor (props){
        super (props);
        this.state = {
            street_address: '',
            unit: '',
            city: '',
            zip: '',
            state: '',
            country: '',
            all_countries: [],
            requestInprogress : false 
        };
          
          this.validator = new SimpleReactValidator();
            this.submitForm =async ()=> {
              if (this.validator.allValid()) {
              this.setState({requestInprogress : true});
              let res = await updateUserAddress(this.state);
              this.setState({requestInprogress : false});
              if(res.data.success){
                showNotification(res.data.message, 'success');
              } else {
                showNotification(res.data.message, 'error');
              }
              } else {
                this.validator.showMessages();
                this.forceUpdate();
              }
            }
    }

    setValue = (e, field) => {
        this.setState({[field] : e.target.value})
    }

     componentDidMount = async() =>{
        let response = await getallCountries(); 
        this.setState({all_countries: response.data.data});
        console.log(this.props.data, 'props data');
        if(Object.keys(this.props.data).length){
            this.setState({
                street_address: this.props.data.address,
                unit: this.props.data.unit,
                city: this.props.data.city,
                zip: this.props.data.postal_code,
                state: this.props.data.state,
                country: this.props.data.country
            });

        }
     }

    render(){
        return (
        <>
            <div className="card mb-4">
                <div className="card-header d-flex align-items-start">
                    <h3 className="d-inline-block align-middle">Address</h3>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputUsername1">Street address</label>
                            <input type="text" className="form-control" name="street_address"id="exampleInputUsername1" placeholder="Enter your street address" value={this.state.street_address} onChange={(e)=>this.setValue(e,'street_address')}/>
                            </div>
                            {this.validator.message('street_address', this.state.street_address, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputUsername2">Unit/App.#</label>
                            <input type="text" className="form-control"  name="unit" id="exampleInputUsername2" placeholder="Also enter name" value={this.state.unit} onChange={(e)=>this.setValue(e,'unit')}/>
                            </div>
                            {this.validator.message('unit', this.state.unit, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputUsername2">City</label>
                            <input type="text" className="form-control"  name="city" id="exampleInputUsername2" placeholder="Enter the city name" value={this.state.city} onChange={(e)=>this.setValue(e,'city')}/>
                            </div>
                            {this.validator.message('city', this.state.city, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputUsername2">Zip / Postal code</label>
                            <input type="text" className="form-control" name="zip" id="exampleInputUsername2" placeholder="Enter zip / postal code" value={this.state.zip} onChange={(e)=>this.setValue(e,'zip')}/>
                            </div>
                            {this.validator.message('zip', this.state.zip, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputUsername2">State / Region</label>
                            <input type="text" className="form-control"  name="state" id="exampleInputUsername2" placeholder="Enter the state / regon name" value={this.state.state} onChange={(e)=>this.setValue(e,'state')}/>
                            </div>
                            {this.validator.message('state', this.state.state, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleSelectTex">Country</label>
                            <select className="form-control tex-select select_btn" name="country" id="exampleSelectTex" value={this.state.country} onChange={(e)=>this.setValue(e,'country')}>  
                                <option className="unselect">Select country</option>
                                    {
                                    this.state.all_countries.map((option, index) => (
                                        <option value={option.country_code} key={index}>{option.country_name}</option>
                                    ))
                                    } 
                            </select>
                            </div>
                            {this.validator.message('country', this.state.country, 'required')}
                        </div>
                    </div>
                    <button className="btn bg-orange btn-cus mr-2 text-white"  disabled={ this.state.requestInprogress}  onClick={this.submitForm}>{ this.state.requestInprogress ? (<Loading className="btn theme-btn-orange" msg="updating address..." />) : 'Update' }</button>
                </div>
            </div>
        </>
            
        )
    }
}
export default Address;