import React from 'react';
import { Link} from 'react-router-dom';
// import History from '../History';

class AdminNav extends React.Component {
    constructor(props){
        super(props);
          this.state = {
            user_role : '',
            activeLink : 'dashboard',
            activeMenu: false,
            contactShow : false,
            maintainanceShow : false,
            mileageShow : false,
            transactionShow : false,
        };
        //this.setPathLink()
    }

    setPathLink = () => {
        let pathName = window.location.pathname.split('/');
        var pathFiltered = pathName.filter(function (el) {
            return  el != '';
        });
        this.setState({activeLink:pathFiltered.join('/')});
    }

    componentDidMount = () => {
        this.setPathLink();
        let user_details = localStorage.getItem('userDetalis');
        user_details  = JSON.parse(user_details);
        this.setState({user_role : user_details.user_type});
    }
    goToPage = async (path) => {
        await this.setState({
            activeLink : path
        });
    }

    logoutUser = (e) => {
        localStorage.clear();
        window.location.href = '/';
    }

    toggleMenu = (path, vars) => {
        this.setState({  activeLink : path});
        this.setState({[vars]: true});

    }

    closeOtherMenu = () => {
        this.setState({
            contactShow : false,
            maintainanceShow : false,
            mileageShow : false,
            transactionShow : false,
        })

    }


  render() {
    return (
        <>
            <li className={ (this.state.activeLink === 'dashboard') ? 'nav-item  active' : 'nav-item '} onClick={(e) => { this.goToPage('dashboard')}}>
                <Link className="nav-link " to="/dashboard">
                <img src="assets/admin/images/dashboard/dashboard-icon.png" className="dashboard-icon" alt="dashboard"/>
                <img src="assets/admin/images/dashboard/dashboard-active-icon.png" className="dashboard-icon-hover" alt="dashboard-icon"/>
                <span className="menu-title">Dashboard</span>
                </Link>
            </li>
            <li className={ (this.state.activeLink === 'properties') ? 'nav-item  active' : 'nav-item '}  onClick={(e) => { this.goToPage('properties')}}>
                <Link className="nav-link" to="/properties">
                <img src="assets/admin/images/dashboard/property-icon.png" className="dashboard-icon" alt="property"/>
                <img src="assets/admin/images/dashboard/property-active-icoon.png" alt="property-active" className="dashboard-icon-hover"/>
                <span className="menu-title">Properties</span>
                </Link>
            </li>

            <li className={ (this.state.activeLink === 'applications') ? 'nav-item  active' : 'nav-item '}  onClick={(e) => { this.goToPage('applications')}}>
                <Link className="nav-link" to="/applications">
                    <img src="assets/admin/images/dashboard/td-application-icon.png" className="dashboard-icon" alt="message"/>
                    <img src="assets/admin/images/dashboard/td-application-active-icon.png" alt="message-active" className="dashboard-icon-hover"/>
                    <span className="menu-title">Applications</span>
                </Link>
            </li>

            {
                (this.state.user_role != 'admin' )?
                    <>
                        <li className={ (this.state.activeLink === 'document-template') ? 'nav-item  active' : 'nav-item '}  onClick={(e) => { this.goToPage('document-template')}}>
                            <Link className="nav-link" to="/document-template">
                                <img src="assets/admin/images/dashboard/td-application-icon.png" className="dashboard-icon" alt="message"/>
                                <img src="assets/admin/images/dashboard/td-application-active-icon.png" alt="message-active" className="dashboard-icon-hover"/>
                                <span className="menu-title">Document Template</span>
                            </Link>
                        </li>
                        <li className={ ['contacts', 'add-tenant'].indexOf(this.state.activeLink) !== -1  ? 'nav-item cursorPointer active' : 'nav-item cursorPointer'} >
                            <a className="nav-link" aria-expanded={((['contacts', 'add-tenant'].indexOf(this.state.activeLink) !== -1 ) && (this.state.contactShow)) ?'true':'false'} onClick={(e) => {this.toggleMenu('contacts', 'contactShow')}} >
                            <img src="assets/admin/images/dashboard/contact-icon.png" className="dashboard-icon" alt="contact-icon"/>
                            <img src="assets/admin/images/dashboard/contact-active-icon.png" className="dashboard-icon-hover" alt="contact-active"/>
                            <span className="menu-title">Contacts</span>
                            <i className="menu-arrow"></i>
                            </a>
                            <div className={  ( (['contacts', 'add-tenant'].indexOf(this.state.activeLink) !== -1 ) && this.state.contactShow)?'collapse show':'collapse' } id="menu-transaction">
                                <ul className="nav flex-column sub-menu">
                                <li className="nav-item" onClick={(e) => { this.goToPage('contacts') }} >
                                    <Link className={ this.state.activeLink === 'contacts'?'nav-link active':'nav-link' }  to="contacts">Contacts</Link>
                                </li>
                                <li className="nav-item" onClick={(e) => { this.goToPage('add-tenant') }} >
                                    <Link className={ this.state.activeLink === 'add-tenant'?'nav-link active':'nav-link' } to="add-tenant">Add Tenant</Link>
                                </li>
                                </ul>
                            </div>
                        </li>
                    </>
                :
                <>
                    <li className={ (this.state.activeLink === 'landlords') ? 'nav-item  active' : 'nav-item '}  onClick={(e) => { this.goToPage('landlords')}}>
                        <Link className="nav-link" to="/landlords">
                            <img src="assets/admin/images/dashboard/td-application-icon.png" className="dashboard-icon" alt="landlord"/>
                            <img src="assets/admin/images/dashboard/td-application-active-icon.png" alt="landlord-active" className="dashboard-icon-hover"/>
                            <span className="menu-title">Landlords</span>
                        </Link>
                    </li>
                    <li className={ (this.state.activeLink === 'tenant-users' ) ? 'nav-item  active' : 'nav-item '}  onClick={(e) => { this.goToPage('tenant-users')}}>
                        <Link className="nav-link" to="/tenant-users">
                            <img src="assets/admin/images/dashboard/td-application-icon.png" className="dashboard-icon" alt="tenants"/>
                            <img src="assets/admin/images/dashboard/td-application-active-icon.png" alt="tenants-active" className="dashboard-icon-hover"/>
                            <span className="menu-title">Tenants</span>
                        </Link>
                    </li>
                </>
            }

            {/* Maintainance start */}
            <li className={ ['maintenance', 'new-maintenance'].indexOf(this.state.activeLink) !== -1  ? 'nav-item cursorPointer active' : ' cursorPointer nav-item '}  >
                <a className="nav-link" aria-expanded={((['maintenance', 'new-maintenance'].indexOf(this.state.activeLink) !== -1 ) && (this.state.maintainanceShow)) ?'true':'false'} onClick={(e) => {this.toggleMenu('maintenance', 'maintainanceShow')}} >
                <img src="assets/admin/images/dashboard/maintaince-icon.png" className="dashboard-icon" alt="maintaince"/>
                <img src="assets/admin/images/dashboard/maintaince-active-icon.png" alt="maintaince-active"
                    className="dashboard-icon-hover"/>
                <span className="menu-title">Maintenance</span>
                <i className="menu-arrow"></i>
                </a>
                <div className={  ( (['maintenance', 'new-maintenance'].indexOf(this.state.activeLink) !== -1 ) && this.state.maintainanceShow)?'collapse show':'collapse' } id="menu-transaction">
                    <ul className="nav flex-column sub-menu">
                    <li className="nav-item" onClick={(e) => { this.goToPage('maintenance') }} >
                        <Link className={ this.state.activeLink === 'maintenance'?'nav-link active':'nav-link' }  to="maintenance">Maintenance</Link>
                    </li>
                    {
                        this.state.user_role != 'admin'?
                            <li className="nav-item" onClick={(e) => { this.goToPage('new-maintenance') }} >
                                <Link className={ this.state.activeLink === 'new-maintenance'?'nav-link active':'nav-link' } to="new-maintenance">Add Reuest</Link>
                            </li>
                        :''
                    }
                    </ul>
                </div>
            </li>
            {/* maintainance end */}
            {
                (this.state.user_role != 'tenant' )?
                    <li className={ (this.state.activeLink === 'notification') ? 'nav-item  active' : 'nav-item '}  onClick={(e) => { this.goToPage('notification')}}>
                        <Link className="nav-link" to="notification">
                        <img src="assets/admin/images/dashboard/message-icon.png" className="dashboard-icon" alt="message"/>
                        <img src="assets/admin/images/dashboard/message-active-icon.png" alt="message-active"
                            className="dashboard-icon-hover"/>
                        <span className="menu-title">Notification</span>
                        </Link>
                    </li>
                :''
            }
            <li className={ (this.state.activeLink === 'email') ? 'nav-item  active' : 'nav-item '}  onClick={(e) => { this.goToPage('email')}}>
                <Link className="nav-link" to="/email">
                    <img src="assets/admin/images/dashboard/td-application-icon.png" className="dashboard-icon" alt="message"/>
                    <img src="assets/admin/images/dashboard/td-application-active-icon.png" alt="message-active" className="dashboard-icon-hover"/>
                    <span className="menu-title">Email</span>
                </Link>
            </li>
            <li className={ (this.state.activeLink === 'messages') ? 'nav-item  active' : 'nav-item '}  onClick={(e) => { this.goToPage('message')}}>
                <Link className="nav-link" to="messages">
                <img src="assets/admin/images/dashboard/message-icon.png" className="dashboard-icon" alt="message"/>
                <img src="assets/admin/images/dashboard/message-active-icon.png" alt="message-active"
                    className="dashboard-icon-hover"/>
                <span className="menu-title">Messages</span>
                </Link>
            </li>
            <li className={ ((this.state.activeLink === 'mileage') || (this.state.activeLink === 'add-mileage' ) ) ? 'nav-item cursorPointer active' : 'cursorPointer nav-item '}  >
                <a className="nav-link" aria-expanded={(((this.state.activeLink === 'mileage') || (this.state.activeLink === 'add-mileage' ) ) && (this.state.mileageShow)) ?'true':'false'}  onClick={(e) => {this.toggleMenu('mileage', 'mileageShow')}}>
                <img src="assets/admin/images/dashboard/mileage-icon.png" className="dashboard-icon" alt="mileage" />
                <img src="assets/admin/images/dashboard/mileage-active-icon.png" alt="mileage-active"
                    className="dashboard-icon-hover" />
                <span className="menu-title">Mileage</span>
                <i className="menu-arrow"></i>
                </a>
                <div className={  ( ((this.state.activeLink === 'mileage') || (this.state.activeLink === 'add-mileage' ) ) && this.state.mileageShow)?'collapse show':'collapse' } id="menu-mileage">
                    <ul className="nav flex-column sub-menu">
                    <li className="nav-item" onClick={(e) => { this.goToPage('mileage') }} >
                        <Link className={ this.state.activeLink === 'mileage'?'nav-link active':'nav-link' }  to="mileage">Mileage</Link>
                    </li>
                    {
                        this.state.user_role != 'admin'?
                            <li className="nav-item" onClick={(e) => { this.goToPage('add-mileage') }} >
                                <Link className={ this.state.activeLink === 'add-mileage'?'nav-link active':'nav-link' } to="add-mileage">Add Mileage</Link>
                            </li>
                        :''
                    }
                    </ul>
                </div>
            </li>

            <li className={ ((this.state.activeLink === 'transaction') || (this.state.activeLink === 'add-transaction' ) ) ? 'nav-item cursorPointer active' : ' cursorPointer nav-item '}  >
                <a className="nav-link" aria-expanded={(((this.state.activeLink === 'transaction') || (this.state.activeLink === 'add-transaction' ) ) && (this.state.transactionShow)) ?'true':'false'} onClick={(e) => {this.toggleMenu('transaction', 'transactionShow')}} >
                <img src="assets/admin/images/dashboard/transition-icon.png" className="dashboard-icon" alt="transition" />
                <img src="assets/admin/images/dashboard/transition-active-icon.png" alt="transition-active"
                    className="dashboard-icon-hover" />
                <span className="menu-title">Transaction</span>
                <i className="menu-arrow"></i>
                </a>
                <div className={  ( ((this.state.activeLink === 'transaction') || (this.state.activeLink === 'add-transaction' ) ) && this.state.transactionShow)?'collapse show':'collapse' } id="menu-transaction">
                    <ul className="nav flex-column sub-menu">
                    <li className="nav-item" onClick={(e) => { this.goToPage('transaction') }} >
                        <Link className={ this.state.activeLink === 'transaction'?'nav-link active':'nav-link' }  to="transaction">Transaction</Link>
                    </li>
                    {
                        this.state.user_role != 'admin'?
                            <li className="nav-item" onClick={(e) => { this.goToPage('add-transaction') }} >
                                <Link className={ this.state.activeLink === 'add-transaction'?'nav-link active':'nav-link' } to="add-transaction">Add Transaction</Link>
                            </li>
                        :''
                    }
                    </ul>
                </div>
            </li>

            <li className={ (this.state.activeLink === 'reports') ? 'nav-item  active' : 'nav-item '}  onClick={(e) => { this.goToPage('reports')}}>
                <Link className="nav-link" data-bs-toggle="collapse" to="reports" aria-expanded="false"
                    aria-controls="general-pages">
                <img src="assets/admin/images/dashboard/reports-icon.png" alt="reports" className="dashboard-icon"/>
                <img src="assets/admin/images/dashboard/reports-active-icon.png" alt="reports-active"
                    className="dashboard-icon-hover"/>
                <span className="menu-title">Reports</span>
                </Link>
            </li>
        </>
    );
  }
}

export default AdminNav;
