import React from "react";
import SimpleReactValidator from 'simple-react-validator';
import { getAllPropertyLocation, deleteFile } from "../../Services/Tenants" ;
class Generalinformation extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            all_locations : [],
            id:'',
            first_name : '',
            last_name : '',
            email : '',
            company_name : '',
            gender : '',
            occupation : '',
            dob : '',
            phone_number : '',
            location : '',
            rent : '',
            lease_term_start_date : '',
            lease_term_last_date : '',
            upload_documents : [],
            upload_lease : [],
        };

        this.validator = new SimpleReactValidator();
        this.submitForm = async ()=> {
            if (this.validator.allValid()) {
                return { status : true , data:this.state };
            } else {
                this.validator.showMessages();
                this.forceUpdate();
                return { status : false , data:[] };
            }
        }
    }
    setValue = (e, field)=>{

        console.log(e.target.value);
        this.setState({[field]:e.target.value});
   }
    componentDidMount = async () => {
        let allLocations = await getAllPropertyLocation();
        this.setState({  all_locations : allLocations.data.data});
        if(this.props.generalDetails){
            this.populateDetails(this.props.generalDetails);
        }
    }

    populateDetails = (details) => {
        this.setState({
            id:details.id,
            first_name : details.first_name,
            last_name : details.last_name,
            email : details.email,
            company_name : details.company_name,
            gender : details.gender,
            occupation : details.occupation,
            dob : details.birth_date,
            phone_number : details.phone,
            rent : details.rent,
            lease_term_start_date : '',
            lease_term_last_date : '',
        });
        console.log(details, 'uip');

        if(details.user_docs?.length > 0){
            let tempArr = [];
            details.user_docs.map((row) => {
                tempArr.push({
                    id : row.id,
                    data : '',
                    path : row.file,
                    type: row.type,
                    name : this.getFileName(row.file)
                });
            });

            console.log(tempArr, 'uip');
            this.setState({upload_documents : tempArr});
        }
    }

    getFileName = (path) => {
        let arr = path.split('/');

        return typeof arr[2] != 'undefined' ? arr[2] : '';


    }

    formatAddress = (row) => {
        return row.property_name + ' [' + row.street_address + ', ' + row.city + ', ' + row.state + ', '+ row.country + ']';
    }

    uploadDocuments = async (e, type) => {
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let  reader = new FileReader()
            await reader.readAsDataURL(files[i]);
             reader.onload = () => {
                let all_docs = this.state.upload_documents;
                let tempObj = {
                    id:'',
                    data : reader.result,
                    path : '',
                    type: type,
                    name : files[i].name
                };
                all_docs.push(tempObj);
                this.setState({upload_documents : all_docs});
            };
        }
    }

    deleteFile = async (index) =>{
        let all_docs = this.state.upload_documents;

        if(all_docs[index].id){
            await deleteFile(all_docs[index].id);
        }

        all_docs.splice(index, 1);
        this.setState({
            upload_documents : all_docs
        });
    }
    render(){
        return(
            <>
                <div className="card mb-4">
                    <div className="card-body pt-0">
                    <form className="forms-sample">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="exampleInputName">First Name </label>
                                    <input type="text" className="form-control" name="first_name" id="exampleInputName" placeholder="" value={this.state.first_name} onChange={(e)=>this.setValue(e,'first_name')}/>
                                </div>
                                {this.validator.message('first_name', this.state.first_name, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="exampleInputName">Last Name </label>
                                <input type="text" className="form-control" name="last_name" id="exampleInputName" placeholder="" value={this.state.last_name} onChange={(e) =>this.setValue(e,'last_name')}/>
                                </div>
                                {this.validator.message('last_name', this.state.last_name, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="exampleInputName">Email </label>
                                <input type="email" className="form-control" name="email" id="exampleInputName" placeholder="" value={this.state.email} onChange={(e) =>this.setValue(e,'email')}/>
                                </div>
                                {this.validator.message('email', this.state.email, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="exampleInputName">Comapny Name </label>
                                <input type="text" className="form-control" name="company_name" id="exampleInputName" placeholder="" value={this.state.company_name} onChange={(e) =>this.setValue(e,'company_name')}/>
                                </div>
                                {this.validator.message('company_name', this.state.company_name, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="exampleInputPriority">Gender </label>
                                <select className="form-control tex-select select_btn" name="gender" id="exampleInputPriority" value={this.state.gender} onChange={(e) =>this.setValue(e,'gender')}>
                                    <option value="m" key={"m"} >Male</option>
                                    <option value="f" key={"f"} >Female</option>
                                    <option value="t" key={"t"} >Other/Transgender</option>
                                </select>
                                </div>
                                {this.validator.message('gender', this.state.gender, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="exampleInputEnterDT">Occupation </label>
                                <input type="text" className="form-control" name="occupation" id="exampleInputEnterDT" placeholder="" value={this.state.occupation} onChange={(e) =>this.setValue(e,'occupation')}/>
                                </div>
                                {this.validator.message('occupation', this.state.occupation, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="exampleInputPriority">Date of Birth </label>
                                    <div className="input-group input-group-icon mb-3">
                                        <input type="date" className="form-control" name="dob" aria-label="Recipient's username" aria-describedby="basic-addon2" value={this.state.dob} onChange={(e) =>this.setValue(e,'dob')}/>
                                        <div className="input-group-append p-0">
                                            <span className="input-group-text" id="basic-addon2"><i className="mdi mdi-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>
                                {this.validator.message('occupation', this.state.occupation, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="exampleInputName">Phone Number </label>
                                    <input type="tel" className="form-control" name="phone_number" id="exampleInputName" placeholder="" value={this.state.phone_number} onChange={(e) =>this.setValue(e,'phone_number')}/>
                                </div>
                                {this.validator.message('phone_number', this.state.phone_number, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="exampleInputPriority">Location </label>
                                    <select className="form-control tex-select select_btn" name="location" id="exampleInputPriority"  onChange={(e) =>this.setValue(e,'location')}>
                                        {
                                            this.state.all_locations.length > 0 ?
                                            (
                                                this.state.all_locations.map((row) => {
                                                    return <option value={row.id} key={"Location"+row.id}>{ this.formatAddress(row) }</option>;
                                                })
                                            )
                                            :
                                            <option className="unselect">No Property Added Yet.</option>
                                        }
                                    </select>
                                </div>
                                {this.validator.message('phone_number', this.state.phone_number, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEnterDT">Rent Amount</label>
                                    <input type="number" className="form-control" name="rent" id="exampleInputEnterDT" placeholder="$0.00" value={this.state.rent} onChange={(e) =>this.setValue(e,'rent')}/>
                                </div>
                                {this.validator.message('rent', this.state.rent, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="exampleInputPriority">Lease Term Start Date </label>
                                    <div className="input-group input-group-icon mb-3">
                                        <input type="date" className="form-control" name="lease_term_start_date" aria-label="Recipient's username" aria-describedby="basic-addon2" value={this.state.lease_term_start_date} onChange={(e) =>this.setValue(e,'lease_term_start_date')}/>
                                        <div className="input-group-append p-0">
                                            <span className="input-group-text" id="basic-addon2"><i
                                                className="mdi mdi-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>
                                {this.validator.message('lease_term_start_date', this.state.lease_term_start_date, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="exampleInputPriority">Lease Term Last Date </label>
                                <div className="input-group input-group-icon mb-3">
                                    <input type="date" className="form-control" name="lease_term_last_date" aria-label="Recipient's username" aria-describedby="basic-addon2" value={this.state.lease_term_last_date} onChange={(e) =>this.setValue(e,'lease_term_last_date')}/>
                                    <div className="input-group-append p-0">
                                        <span className="input-group-text" id="basic-addon2"><i
                                            className="mdi mdi-calendar"></i></span>
                                    </div>
                                </div>
                                </div>
                                {this.validator.message('lease_term_last_date', this.state.lease_term_last_date, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label>Upload documents </label>
                                <div className="drop_box mar_drop_box">
                                    <div className="upload-btn-wrapper">
                                        <button className="btn bg-orange btn-cus text-white">
                                            <i className="mdi mdi-cloud-upload text-muted"></i>
                                            <p>Upload documents</p>
                                        </button>
                                        <input type="file" name="upload_documents" allow="jpg,png,jpeg, pdf" multiple onChange={(e) => this.uploadDocuments(e, 'doc')}/>
                                    </div>
                                </div>
                                <ul>
                                    {
                                        this.state.upload_documents.map((row, index) => {
                                        if(row.type === 'doc')
                                            return (<li>{row.name} <span onClick={()=>this.deleteFile(index)}> X</span></li>)
                                        })
                                    }
                                </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label>Upload lease </label>
                                <div className="drop_box mar_drop_box ">
                                    <div className="upload-btn-wrapper">
                                        <button className="btn bg-orange btn-cus text-white">
                                            <i className="mdi mdi-cloud-upload text-muted"></i>
                                            <p>Upload lease</p>
                                        </button>
                                        <input type="file" name="upload_lease"  allow="jpg,png,jpeg, pdf" multiple onChange={(e) => this.uploadDocuments(e, 'lease')}/>
                                    </div>
                                </div>
                                <ul>
                                    {
                                        this.state.upload_documents.map((row, index) => {
                                        if(row.type === 'lease')
                                            return (<li>{row.name} <span onClick={()=>this.deleteFile(index)}> X</span></li>)
                                        })
                                    }
                                </ul>
                                </div>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </>
        )
    }
}
export default Generalinformation;
