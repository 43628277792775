import React from "react";
import SimpleReactValidator from 'simple-react-validator';
class AdditionalIncome extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            income_source: '',
            monthly_income: '',
            count : 1,
        };
        this.validator = new SimpleReactValidator();
        this.validateForm =async()=> {
             if(this.validator.allValid()) {
                console.log('Valid', this.state);
                 return {status : true, data : this.state};                
             }else{
                 this.validator.showMessages();
                 this.forceUpdate();
                 console.log('inValid', this.state);
                 return {status : false, data : []};
             }
            
         } 
    }

    setValue = (e, field) => {
        this.setState({[field] : e.target.value})
    }
    
    componentDidMount = async() => {
       // console.log(this.props   , 'sdf')
        if(this.props.incomeIndex){
            this.setState({count : this.props.incomeIndex + 1});
        }

        if(this.props?.oldData){
            this.setState({
                ...this.props.oldData
            })
        }
    }
    render(){
        return(
            <>      
                <div className="ai-Occupants-info">
                    <h5 className="as-tittle-h5">Source { this.state.count } <span onClick={()=>this.props.deleteAdditionalIncome(this.props.incomeIndex)}>Delete</span></h5>
                    <div className="row row-theme">
                        <div className="col-md-6">
                        <div className="form-group">
                            <label for="exampleInputIncomeS">income source* </label>
                            <input type="text" name="income_source" className="form-control" id="exampleInputName"
                                placeholder=""  value = {this.state.income_source} onChange={(e)=>this.setValue(e,'income_source')}/>
                        </div>
                        {this.validator.message('income_source', this.state.income_source, 'required')}
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                            <label for="exampleInputMonthlyI">Monthly income* </label>
                            <input type="number" name="monthly_income" className="form-control" id="exampleInputLastName"
                                placeholder="" value = {this.state.monthly_income} onChange={(e)=>this.setValue(e,'monthly_income')}/>
                        </div>
                        {this.validator.message('monthly_income', this.state.monthly_income, 'required')}
                        </div>
                    </div>
                </div>                   
            </>
        )
    }
}
export default AdditionalIncome; 