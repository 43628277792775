import React from "react";
import EmergencyContact from "./EmergencyContact";

class Step4 extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            additionalEmergencyContactArray : [],
        };
        this.submitForm =async()=> {  
            let emergencyContact = [];
            let errorStatus = false;
            this.state.additionalEmergencyContactArray.map( async (row, index) => {
                let data = await this[row.refInst].validateForm();
                if(data.status)
                    emergencyContact.push({ ...row, data:data.data});
                    if(this.state.additionalEmergencyContactArray.length == (parseInt(index)+1))
                    this.moveToNextStep(errorStatus, { additionalEmergencyContactArray : emergencyContact});
                else{
                    errorStatus = true;
                    return;
                }
            });
        }   
        
        this.moveToNextStep = async(errorStatus, request)=>{
            if(!errorStatus){
                this.props.componentData('step4Data', request);
                this.props.changeStepVar(5);
            }
        } 
    }
    
    setValue = (e, field) => {
        this.setState({[field] : e.target.value})
    }
    //Add multiple pets
    addEmergencyContact = () => {
        let allEmergencyContact = this.state.additionalEmergencyContactArray;
        let last_index = 0;
        allEmergencyContact.map((row) => ( last_index = row.index ));
        let new_index = ++last_index;
        allEmergencyContact.push({ index : new_index , refInst : 'ref' + new_index , data : []});
        this.setState({ ...this.state,
            additionalEmergencyContactArray : allEmergencyContact
        });

    }
    
    getAdditionalEmergencyContact = () => {
        return this.state.additionalEmergencyContactArray.map((row, index) => {
            return <EmergencyContact ref={instance => {this[row.refInst] = instance }} emergencyContactIndex={index} deleteAdditionalEmergencyContact={this.deleteAdditionalEmergencyContact} oldData={row.data}/> ;
        });
    }

    deleteAdditionalEmergencyContact = (index) => {
        let allEmergencyContact = this.state.additionalEmergencyContactArray;
        allEmergencyContact.splice(index, 1);
        this.setState({additionalEmergencyContactArray : allEmergencyContact})
    }

    componentDidMount = async() => {
        this.setState({
            property_id : this.props.property_id
        });
        if(this.props?.oldData){
            this.setState({
                additionalEmergencyContactArray :this.props.oldData.additionalEmergencyContactArray
            })
            this.getAdditionalEmergencyContact();
        }
    }

    render(){
        return(
            <> 
                <div className="col-md-12 grid-margin stretch-card flex-column">
                    <div className="card mb-4">
                        <div className="card-body pt-0">
                            <h4 className="heading-h4 font-normal mb-4">Emergency contact </h4>
                            <form className="forms-sample mt-4">
                                {
                                    this.state?.additionalEmergencyContactArray ? this.getAdditionalEmergencyContact() : ''
                                }
                                <button className="btn btn-link btn-link-lg p-0" type="button" onClick={()=>{this.addEmergencyContact()}}> <i className="mdi mdi-plus-box"></i> <span>Add emergency contact</span> </button>
                            </form>
                        </div>
                    </div>
                    <div className="card  mb-4">
                        <div className="card-body p-0">
                            <div className="d-flex justify-content-between">
                                <button type="button" className="btn btn-dark" onClick={(e) => this.props?.changeStepVar(3)}><i className="mdi mdi-arrow-left"></i> Back</button>
                                <button className="btn bg-orange btn-cus mr-2 text-white" onClick={this.submitForm}>Next <i className="mdi mdi-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default Step4; 