import { useEffect, useState } from "react";
import ComponentLoader from "../../../common/ComponentLoader";
import Card from "react-bootstrap/Card";
import { withRouter } from "../../../helpers/withRouter";
import { View as ViewServices, SelectedAction } from "./Services";
import { useNavigate } from "react-router-dom";
const View = (props) => {

    const navigate = useNavigate();

    const [isLoad,setIsLoad] = useState(false);
    const [viewDetail,setViewDetail] = useState({})
    const [backLocation, setBackLocation] = useState(
        localStorage.getItem("emailLinkActive")
    );

    useEffect(() => {
        getComposeView();
        if (backLocation == "") {
            setBackLocation(localStorage.setItem("emailLinkActive", "/email"));
        }
    }, []);

    const capitalize = (s) => {
        if( typeof s[0]   != 'undefined' ){
            return s[0].toUpperCase() + s.slice(1);
        }
        return "";
    };

    const getComposeView = async () => {
        setIsLoad(true)
        let paremeter = {
            ids:[props.params.id],
            type:'read',
            column:'read'
        }
        let response = await ViewServices({ id: props.params.id });
                       await SelectedAction(paremeter)
        setViewDetail(response.data.data);
        setIsLoad(false);
    }


    const getMailName = (sendTo) => {


            if (typeof sendTo != "undefined" && backLocation == '/email/send') {
                let data = JSON.parse(sendTo);
                let stringName = [];
                let stringEmail = [];
                let dp = data[0].name;
                data.forEach((element) => {
                    stringName.push(element.name);
                    stringEmail.push(element.value);
                });
                dp = dp.split(" ");
                dp = dp[0][0] + dp[1][0];
                return {
                    name: stringName.join(", "),
                    email: stringEmail.join(", "),
                    dp: dp,
                };
            }else if (typeof viewDetail != "undefined") {
                let first_name = ( typeof viewDetail.username != 'undefined' )? viewDetail?.username?.first_name:'';
                let last_name = ( typeof viewDetail.username != 'undefined' )? viewDetail?.username?.last_name:'';
                let email = ( typeof viewDetail.username != 'undefined' )? viewDetail?.username?.email:'';
                return {
                    name: capitalize(first_name) + " " + capitalize(last_name),
                    email: email,
                    dp: capitalize(first_name)[0] + capitalize(last_name)[0],
                };
            }
    }

    const handlebackToLocation = () => {
        navigate(backLocation);
    }


    return (
        <div className="col-md-10 position-relative composeViewlayout">
            {isLoad ? <ComponentLoader /> : ""}
            <div className="row row-theme">
                <div className="col-md-12">
                    <div className="titleComposeContainer">
                        <div className="title">
                            <h4>
                                <i className="mdi mdi-arrow-left" onClick={(e) => handlebackToLocation(e)} ></i>
                                {viewDetail.subject}
                            </h4>
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <Card>
                        <Card.Header className="d-flex">
                            <div className="avatar-img me-3">
                                {getMailName(viewDetail?.send_to)?.dp}
                            </div>
                            <div className="pt-1 avatar-name">
                                <p className="fw-bold mb-0">
                                    {getMailName(viewDetail?.send_to)?.name}
                                </p>
                                <p className="small text-muted">
                                    {getMailName(viewDetail?.send_to)?.email}
                                </p>
                            </div>
                            <div className="pt-1 avatar-time">
                                <p className="small text-muted mb-1">
                                    {viewDetail.created_at}
                                </p>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <p className="m-4">{viewDetail.body}</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default withRouter(View);
