import React, { Suspense  } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';


import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import NotFound from "./pages/NotFound";


import ContactListing from './pages/admin/Contacts/Index';
import AddTenant from './pages/admin/Contacts/AddTenant';
import EditTenant from './pages/admin/Contacts/EditTenant';
import Maintenance from './pages/admin/Maintenance/Index';
import TenantDetails from './pages/admin/Contacts/TenantDetails'


import {MileageList, MileageAdd,MileageView,MileageEdit} from "./pages/admin/Mileage";

import { DTList,DTCreate,DTView } from "./pages/admin/DocumentTemplate";

import Reports from './pages/admin/Reports/Index';


import Tenants from './components/Properties/Tenants';
import MarketValue from './components/Properties/MarketValue';
import Accounting from './components/Properties/Accounting';

import AddMaintenaince from './pages/admin/Maintenance/AddMaintenaince';
import EditMaintenance from './pages/admin/Maintenance/EditMaintenance';

// Teanant Pages
// import PropertySearch from './pages/Tenant/PropertySearch';
import Application from './pages/Tenant/Application';
import Transactions from "./pages/admin/Transactions/Transactions";
import AddTransaction from "./pages/admin/Transactions/AddTransaction";
import EditTransaction from "./pages/admin/Transactions/EditTransaction";
import TransactionDetails from "./pages/admin/Transactions/TransactionDetails";
import Landlords from "./pages/admin/Landlord/List";
import LandlordDetails from "./pages/admin/Landlord/LandlordDetails";
import TenantLists from "./pages/admin/Tenant/List";

//Activity Log
import ActivityLog from './pages/admin/Comman/ActivityLog';
import NotificationView from './pages/admin/Comman/NotificationView';


import Profile from "./pages/admin/Profile";
import history from './History';
import getLRStyleSheet from "./LRStyleSheet"
import { adminStyleSheet} from "./AdminStyleSheet"

import {AddRentList} from "./pages/Tenant/Rent/Index"
import {Pay} from "./pages/Tenant/Payment"
import { NList, NSend, NView } from './pages/Notification';

import { Email, Inbox, Send, View, Important } from "./pages/admin/Email";

import Messages from "./pages/admin/Messages/Index.js";

import { createStore  } from "redux";
import rootReducer from './rootReducer';
import { Provider } from 'react-redux';


// Lazy Load Landlord/Admin
import PropertyDetails from "./pages/admin/Properties/PropertyDetails";
import AddProperty from "./pages/admin/Properties/AddProperty";
import EditProperty from "./pages/admin/Properties/EditProperty";
const Properties = React.lazy(() => import("./pages/admin/Properties/Properties.js"));


const Dashboard = React.lazy(() => import("./pages/admin/Dashboard/Dashboard.js"));
const RentApplications = React.lazy(() => import("./pages/admin/Applications/Index.js"));
const LandlordApplicationDetails = React.lazy(() => import("./pages/admin/Applications/Detail.js"));
const LandlordEditApplication = React.lazy(() => import("./pages/admin/Applications/EditApplication.js"));

// Lazy Load Tenant
const TenantDashboard = React.lazy(() => import("./pages/Tenant/Dashboard/Index.js"));
//const [ AddRentList ] = React.lazy(() => import("./pages/Tenant/Rent"));
const MyApplications = React.lazy(() => import("./pages/Tenant/MyApplications/Index.js"));
const PropertySearch = React.lazy(() => import("./pages/Tenant/PropertySearch.js"));
const Support = React.lazy(() => import("./pages/Tenant/Support/Index.js"));

const Login = React.lazy(() => import("./pages/admin/Login.js"));
const Register = React.lazy(() => import("./pages/admin/Register.js"));
const ForgotPassword = React.lazy(() => import("./pages/admin/ForgotPassword.js"));
const RequestDetails = React.lazy(() => import("./pages/Tenant/MyApplications/Details.js"));
const TenantTransaction = React.lazy(() => import("./pages/Tenant/Rent/Detail.js"));


const store = createStore(rootReducer);


export default function App() {
  let location = window.location.pathname;

  if(localStorage.getItem('token') && ( location === '/' || location === '/digital-tenant-web/') )
    history.push('dashboard/');

  if(location === '/' || location === '/register'|| location === '/login' || location === '/forgot-password' || location === '/digital-tenant-web/' || location === '/digital-tenant-web/login' || location === '/digital-tenant-web/register' || location === '/digital-tenant-web/forgot-password'){
    getLRStyleSheet();
  } else{
    adminStyleSheet();
  }

  const RouteDetails = [

       {path:'not-found',element:<NotFound />,lazy:0},
       {path:'dashboard',element:<Dashboard />,lazy:1},
       {path:'properties',element:<Properties />,lazy:1},
       {path:'applications',element:<RentApplications />,lazy:1},
       {path:'application-details',element:<LandlordApplicationDetails />,lazy:1},
       {path:'edit-application-details',element:<RequestDetails />,lazy:1},
       {path:'transaction-details',element:<TenantTransaction />,lazy:1},

       {path:'properties/property-details',element:<PropertyDetails />,lazy:0},
       {path:'edit-property',element:<EditProperty />,lazy:0},
       {path:'add-property',element:<AddProperty />,lazy:0},

       {path:'contacts',element:<ContactListing />,lazy:0},
       {path:'maintenance',element:<Maintenance />,lazy:0},
       {path:'new-maintenance',element:<AddMaintenaince />,lazy:0},
       {path:'edit-maintenance',element:<EditMaintenance />,lazy:0},
       {path:'mileage',element:<MileageList />,lazy:0},
       {path:'add-mileage',element:<MileageAdd />,lazy:0},
       {path:'mileage/details/:id',element:<MileageEdit />,lazy:0},
       {path:'edit-details/:id',element:<MileageEdit />,lazy:0},
       {path:'reports',element:<Reports />,lazy:0},
       {path:'transaction',element:<Transactions />,lazy:0},
       {path:'add-transaction',element:<AddTransaction />,lazy:0},
       {path:'edit-transaction',element:<EditTransaction />,lazy:0},
       {path:'transaction-details',element:<TransactionDetails />,lazy:0},
       {path:'profile',element:<Profile />,lazy:0},
       {path:'tenants',element:<Tenants />,lazy:0},
       {path:'add-tenant',element:<AddTenant />,lazy:0},
       {path:'view-tenant',element:<TenantDetails />,lazy:0},
       {path:'edit-tenant',element:<EditTenant />,lazy:0},
       {path:'market-value',element:<MarketValue />,lazy:0},
       {path:'accounting',element:<Accounting />,lazy:0},
       {path:'application',element:<Application />,lazy:0},
       {path:'activity-log',element:<ActivityLog />,lazy:0},

       {path:'notification-view/:id/:userid',element:<NotificationView />,lazy:0},
       {path:'document-template',element:<DTList />,lazy:0},
       {path:'document-create',element:<DTCreate />,lazy:0},
       {path:'document-view',element:<DTView />,lazy:0},
       {path:'landlords',element:<Landlords />,lazy:0},
       {path:'landlord-details/:id',element:<LandlordDetails />,lazy:0},
       {path:'tenant-users',element:<TenantLists />,lazy:0},

        /* teannt routes */
       {path:'tenant-dashboard',element:<TenantDashboard />,lazy:1},
       {path:'rent',element:<AddRentList />,lazy:1},
       {path:'pay',element:<Pay />,lazy:0},
       {path:'my-applications',element:<MyApplications />,lazy:1},
       {path:'property-search',element:<PropertySearch />,lazy:1},
       {path:'messages',element:<Messages />,lazy:0},
       {path:'notification',element:<NList />,lazy:0},
       {path:'send-notification',element:<NSend />,lazy:0},
       {path:'view-notification/:id',element:<NView />,lazy:0},
       {path:'support',element:<Support />,lazy:1},

  ]

  return (
    <BrowserRouter basename='/' forceRefresh={true}>
      <Routes>
        <Route path="/" element={<Layout />}>
            {
                RouteDetails.map((row,i) =>
                    <Route key={i} path={row.path}
                        element={(row.lazy)?
                                    <Suspense fallback={<div className="loading"></div>}>{row.element}</Suspense>
                                :row.element } />
                )
            }

            <Route path="/email" element={<Email />}>
                <Route path="/email" element={<Inbox />} />
                <Route path="/email/send" element={<Send />} />
                <Route path="/email/view/:id" element={<View />} />
                <Route path="/email/important" element={<Important />} />
            </Route>
        </Route>

        <Route  index element={<Suspense fallback={<div className="loading"></div>}><Login/></Suspense>}/>
        <Route path="register" element={<Suspense fallback={<div className="loading"></div>}><Register/></Suspense>}/>
        <Route path="forgot-password" element={<Suspense fallback={<div className="loading"></div>}><ForgotPassword/></Suspense>}/>
      </Routes>
    </BrowserRouter>
  );
}
document.addEventListener("DOMContentLoaded", function(event) {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <Provider store={store} >
    <GoogleOAuthProvider clientId={ process.env.REACT_APP_GOOGLE_CLIENT_ID }>
      <App />
    </GoogleOAuthProvider>
    </Provider>
  );
});

