import React from "react";
import SimpleReactValidator from 'simple-react-validator';
import { deleteFile, getAllQuestions } from "../../../Services/Tenants" ;
import Loader from "../../../common/Misc/Loader";
import {Button,Form} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import SignNowModal from './Modals/SignNowModal'


class Step5 extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            question1: '',
            question1Answer: '',
            question2: '',
            question2Answer: '',
            question3: '',
            question3Answer: '',
            question4: '',
            question4Answer: '',
            question5: '',
            question5Answer: '',
            question6: '',
            question6Answer: '',
            upload_documents : [],
            terms_policy : '',
            all_questions: [],
            additionalScreeningQuestionArray : [],
            showUploadPopup:false,
            uploadData:[],
            signModalShow:false,
            signatureData:'',
        };

        this.validator = new SimpleReactValidator();
        this.submitForm =async()=> {
            if (this.validator.allValid()) {
                let allDBQuestions = this.state.all_questions;
                allDBQuestions.map((row, index) => {
                    allDBQuestions[index]['answer']= this.state['question'+row.id];
                    allDBQuestions[index]['comment']= this.state['question'+row.id+'Answer'];
                });
                await this.props.componentData('step5Data', allDBQuestions);
                await this.props.componentData('step6Data', {signature:this.state.signatureData});
                await this.props.submitForm();
            } else {
                this.validator.showMessages();
                this.forceUpdate();
            }
        }
    }

    setValue = (e, field, type) => {
        if(type === 'checkbox'){
            this.setState({[field] : e.target.value})
        }else if(type === 'tp'){
                let value = e.target.checked ? 'yes' : '';
                this.setState({...this.state, [field]:value});
        }else{
            this.setState({...this.state, [field] : e.target.value})
        }
    }




    uploadDocuments = async (e, type) => {
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let  reader = new FileReader()
            await reader.readAsDataURL(files[i]);
             reader.onload = () => {
                let all_docs = this.state.upload_documents;
                let tempObj = {
                    id:'',
                    data : reader.result,
                    path : '',
                    type: type,
                    name : files[i].name
                };
                all_docs.push(tempObj);
                this.setState({upload_documents : all_docs});
            };
        }
    }

    deleteFile = async (index) =>{
        let all_docs = this.state.upload_documents;

        if(all_docs[index].id){
            await deleteFile(all_docs[index].id);
        }

        all_docs.splice(index, 1);
        this.setState({
            upload_documents : all_docs
        });
    }

    componentDidMount = async() => {
        let allQuestions = await getAllQuestions();

        console.log(allQuestions, 'allQuestions44');
        this.setState({all_questions: allQuestions.data.data});
        if(this.props?.oldData){
            this.setState({
                ...this.props.oldData
            })
        }
    }

    handleSignNow = async  () => {
        this.setState({signModalShow:!this.state.signModalShow});
       /*  var userDetails =  JSON.parse(localStorage.getItem('userDetalis'));
        var response    =  await signnowCreateUser(userDetails);
        if( typeof response.data.data.access_token !== 'undefined' || response.data.data.access_token != '' ){
            this.setState({'showUploadPopup':true});
        } */
    }

    handleCloseModal = () => {
        this.setState({showUploadPopup:false})
    }

    handleUploadData = (name,e) => {
        console.log( name,e );
    }

    handleSubmitUpload = (e) => {
        e.preventDefault();


    }

    handleGetDataImage = (e) => {
        this.setState({signatureData:e})
    }

    render(){
        return(
            <>
                <div className="row application-step-five">
                    <div className="col-md-12 grid-margin stretch-card flex-column">
                        <div className="card mb-4">
                            <div className="card-body pt-0">
                                <h4 className="heading-h4 font-normal mb-4">Screening questions</h4>
                                <form className="forms-sample mt-4">
                                <ul className="sq-list">
                                {
                                    this.state.all_questions.map((question, index)=>{
                                        return (
                                            <li>
                                        <p className="sq-question">{ question.question}</p>
                                        <div className="sq-answer">
                                            <div className="form-group sq-form-group">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input" name={"radio"+index} id="optionsRadios1" value="no" onClick={(e)=>{this.setValue(e,  question.name, 'checkbox', question.id)}}/> No
                                                    <i class="input-helper"></i>
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                <input type="radio" className="form-check-input" name={"radio"+index} id="optionsRadios2" value="yes" onClick={(e)=>{this.setValue(e, question.name, 'checkbox', question.id)}}/> Yes
                                                <i class="input-helper"></i>
                                                </label>
                                            </div>
                                            </div>
                                            {this.validator.message(question.name, this.state[question.name], 'required')}
                                            <div className="row row-theme" style={{'display':( this.state[question.name] == 'yes' ? 'block' : 'none')}}>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control"  onChange={(e)=>{this.setValue(e, question.name+'Answer', 'text', question.id)}}/>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </li>

                                        );

                                    })
                                }

                                </ul>
                                </form>
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-body pt-0">
                                <h4 className="heading-h4 font-normal mb-4">Attachments & Disclaimer</h4>
                                <form className="forms-sample mt-4">
                                <div className="row row-theme">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Have you can upload the requested attachments.  </label>
                                            <div className="drop_box mar_drop_box ">
                                            <div className="upload-btn-wrapper">
                                                <button className="btn bg-orange btn-cus text-white">
                                                    <i className="mdi mdi-cloud-upload text-muted"></i>
                                                    <p>Upload of photo ID</p>
                                                </button>
                                                <input type="file" name="upload_documents" allow="jpg,png,jpeg, pdf" multiple onChange={(e) => this.uploadDocuments(e, 'doc')}/>
                                            </div>
                                            </div>
                                            <ul>
                                                {
                                                    this.state.upload_documents.map((row, index) => {
                                                    if(row.type === 'doc')
                                                        return (<li>{row.name} <span onClick={()=>this.deleteFile(index)}> X</span></li>)
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident,
                                            similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi
                                            optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus
                                            saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis
                                            doloribus asperiores repellat.
                                        </p>
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident,
                                            similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi
                                            optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.
                                        </p>
                                        <p><strong>Important - Read carefully before signing</strong></p>
                                        <p>Have questions? Call the Tenant Information Hotline: (123) 234-23456</p>
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti</p>
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesenti deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                                            Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem
                                            quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
                                            reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.
                                        </p>
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident,
                                            similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi
                                            optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.
                                        </p>
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesenti deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                                            Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas voluptates repudiandae sint et molestiae non recusandae.
                                            Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.
                                        </p>
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident,
                                            similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expeditedit quo minus id quod maxime placeat facere possimus.
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-check form-check-flat form-check-primary form-check-acknowledge mt-5">
                                            <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" onClick={(e)=>this.setValue(e,'terms_policy', 'tp')}/> I acknowledge that I have read and understood this disclaimer. By such actions, I further agree to abide by any and all of the terms.
                                            <i class="input-helper"></i>
                                            </label>
                                        </div>
                                        <hr>
                                        </hr>
                                    </div>
                                    {
                                        this.validator.message('terms_policy', this.state.terms_policy, 'required', {messages: {in: 'Please select terms & policy.'}})
                                    }
                                    <div className="col-md-12">
                                        <div className="signature-form">
                                            <div variant="primary" className="signButtonContain cp" onClick={(e) => this.handleSignNow(e)}>
                                                {
                                                    (this.state.signatureData !== '')?
                                                        <img src={this.state.signatureData} />
                                                    :'Click to sign'
                                                }

                                            <p className="mt--27px" >{this.validator.message('signature', this.state.signatureData, 'required', {messages: {in: 'Please select terms & policy.'}})}</p>
                                            </div>
                                            <SignNowModal
                                                show={this.state.signModalShow}
                                                onHide={() => this.setState({signModalShow:false})}
                                                handleGetDataImage = {(e) => this.handleGetDataImage(e)  }
                                            />
                                            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesenti deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                        </div>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                        <div className="card  mb-4">
                            <div className="card-body p-0">
                                <div className="d-flex justify-content-between">
                                <button type="button" className="btn btn-dark" onClick={(e) => this.props?.changeStepVar(4)}><i className="mdi mdi-arrow-left"></i> Back</button>
                                <button className="btn bg-orange btn-cus mr-2 text-white" disabled= {this.state.requestInprogress} onClick={this.submitForm}>{this.state.requestInprogress ? (<Loader className="btn theme-btn-orange" msg="Please wait..."/>) : 'Submit'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <>
                    <Modal show={this.state.showUploadPopup} onHide={(e) => this.handleCloseModal(e) } centered  >
                        <Modal.Header closeButton>
                        <Modal.Title>Upload Doc</Modal.Title>
                        </Modal.Header>
                        <Form>
                            <Modal.Body>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>To</Form.Label>
                                        <input type='email' placeholder="Enter email" className="form-control"  onChange={ (e) => this.handleUploadData.bind(this,'sendTo')} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>You can only upload doc or pdf format</Form.Label>
                                        <div class="drop_box mar_drop_box ">
                                            <div class="upload-btn-wrapper">
                                                <button class="btn bg-orange btn-cus text-white">
                                                    <i class="mdi mdi-cloud-upload text-muted"></i>
                                                    <p>Upload Pdf or Doc</p>
                                                </button>
                                                <input className="form-control" type="file" id="uploadedFile" name="upload_documents" onChange={ (e) => this.handleUploadData.bind(this,'file')} />
                                            </div>
                                        </div>
                                    </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={(e) => this.handleCloseModal(e) } >
                                Close
                            </Button>
                            <Button variant="primary" onClick={(e) => this.handleSubmitUpload(e) } >
                                Upload
                            </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                </>
            </>
        )
    }

}
export default Step5;
