import React from 'react';

class MessageNotificationsEmpty extends React.Component {

    render() {
        return (
            <div className={"dropdown-menu-right navbar-dropdown preview-list"} aria-labelledby="messageDropdown">
                <h6 className="p-3 mb-0">Messages</h6>
                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center">No Messages</h6>
            </div>
        );
    }
}

export default MessageNotificationsEmpty;
