import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import SendMail from "./SendMail";
import  './style.css'

const Mail = () => {

    const [toggleSendMail,setToggleSendMail] = useState(false)
    const handleCloseComposeBox = (event) => {
         setToggleSendMail(event);
     };

    const handleSideBarMenu = (event) => {
        const elements = document.querySelectorAll(".composeNavLink");
        elements.forEach((element) => {
            element.classList.remove("active");
        });
        localStorage.setItem(
            "emailLinkActive",
            event.target.getAttribute("href")
        );
        event.target.parentNode.classList.add('active');
    }

    return (
        <div className="content-wrapper pb-0">
            <section className="row-chat mt-4">
                <div className="card-chat" id="chat3">
                    <div className="card-chat-body">
                        <div className="row-email-page position-relative">
                            <div className="grid-email-page">
                                <div className="grid-body">
                                    <div className="row row-theme">
                                        <div className="col-md-2">
                                            <div className="compose-btn">
                                                <button
                                                    className="btn btn-block btn-primary w-100"
                                                    onClick={(e) =>
                                                        setToggleSendMail(
                                                            !toggleSendMail
                                                        )
                                                    }
                                                >
                                                    &nbsp;&nbsp;COMPOSE
                                                </button>
                                            </div>
                                            <div className="mt-4 left-email-sec">
                                                <ul className="nav nav-pills nav-stacked">
                                                    <li
                                                        className="composeNavLink active"
                                                        onClick={(e) =>
                                                            handleSideBarMenu(e)
                                                        }
                                                    >
                                                        <Link to="/email">
                                                            {" "}
                                                            <i className="mdi mdi-email-outline"></i>{" "}
                                                            Inbox{" "}
                                                        </Link>
                                                    </li>
                                                    <li
                                                        className="composeNavLink"
                                                        onClick={(e) =>
                                                            handleSideBarMenu(e)
                                                        }
                                                    >
                                                        <Link to="/email/send">
                                                            <i className="mdi mdi-send"></i>{" "}
                                                            Sent{" "}
                                                        </Link>
                                                    </li>
                                                    <li
                                                        className="composeNavLink"
                                                        onClick={(e) =>
                                                            handleSideBarMenu(e)
                                                        }
                                                    >
                                                        <Link to="/email/important">
                                                            <i className="mdi mdi-star-outline"></i>{" "}
                                                            Important{" "}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <Outlet />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {toggleSendMail ? (
                        <SendMail
                            handleCloseComposeBox={handleCloseComposeBox}
                        />
                    ) : (
                        ""
                    )}
                </div>
            </section>
        </div>
    );
};

export default Mail;
