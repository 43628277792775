export const getErrorMessage = (myObj) => {
    if(typeof myObj == 'object'){
        for(const row in myObj){
            return myObj[row][0];
        }
    }else{
        return myObj;
    }
};

export const getDiferrentKeys = (affarFrom, arrayTo) => {
    let newArray = [];
    arrayTo.map((row) => {
        if(affarFrom.indexOf(row) === -1){
            newArray.push(row);
        }
    });
    return newArray;
}

export const getBannerImage = (obj) => {

    if(obj.length > 0){
        let img = <img  src="assets/admin/images/feature-2.png" alt="feature-1"/>;
        for(const key in obj){
            if(obj[key].type === 'banner'){
               img = <img  src={process.env.REACT_APP_SITE_URL + obj[key].path} alt="feature-1"/>;
           }
        }
        return img;
    } else {
        return ( <img  src="assets/admin/images/feature-2.png" alt="feature-1"/>);
    }
}

export const getQueryStringParams = () => {
    let query = window.location.search;
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
 };

const accordingBy12 = (date) => {

var todayDate = new Date(date);
var getTodayDate = todayDate.getDate();
var getTodayMonth =  todayDate.getMonth()+1;
var getTodayFullYear = todayDate.getFullYear();
var getCurrentHours = todayDate.getHours();
var getCurrentMinutes = todayDate.getMinutes();
var getCurrentAmPm = getCurrentHours >= 12 ? 'PM' : 'AM';
getCurrentHours = getCurrentHours % 12;
getCurrentHours = getCurrentHours ? getCurrentHours : 12;
getCurrentMinutes = getCurrentMinutes < 10 ? '0'+getCurrentMinutes : getCurrentMinutes;
var getCurrentDateTime = getCurrentHours + ' ' + getCurrentMinutes + ' ' + getCurrentAmPm;
return getCurrentDateTime;

}

export const timeSince = (date) => {
        var dateAndTime = new Date(date).toDateString();
        var dateSplit = dateAndTime.split(' ')
        return accordingBy12(date) + ' | ' + dateSplit[1] + ' ' + dateSplit[2];

        /* var seconds = Math.floor((new Date() - date) / 1000);
        var interval = seconds / 31536000;
        if (interval > 1) {
            return Math.floor(interval) + " years ago";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months ago";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days ago";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " min ago";
        }
        return " just now"; */
    }


export const getFirstAlphaLetter = (name) => {
    if( typeof name != 'undefined' ){
        let nameArray = name.split(' ');
        return `${nameArray[0][0].toUpperCase()}${nameArray[1][0].toUpperCase()}`;
    }

    return "";
}

export const capitalize = (s) => {
        return s[0].toUpperCase() + s.slice(1);
};

export const limitMsgShow = (message) => {
    if( typeof message != 'undefined' ){
        if( message.length > 25 ){
            return message.substr(0,25) + "..."
        }
        return message;
    }

    return '';
}
