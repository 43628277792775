import { Link} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { useEffect, useState } from 'react';
import { NotificationList } from './Services';
import Paginate from '../admin/Comman/Paginate';
import { withRouter } from '../../helpers/withRouter';

const List = () => {

    const [resetData,setResetData] = useState(false);
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const [resetBtn, setResetBtn] = useState(false);
    const [filterData, setFilterData] = useState({
        start_date: "",
        end_date: "",
    });
    const [limitData,setLimitData] = useState({
                                        search:"",
                                        page:0,
                                        limit:15,
                                    })

    useEffect(() => {
        getNotificationList(limitData);
    }, [limitData]);

    const getNotificationList = async (limitData) => {
        setList([]);
        setTotal(0);
        let response = await NotificationList(limitData);
        setList(response.data.data.result);
        setTotal(response.data.data.total);
    }

    const handlePageCallback = (event) => {
        setLimitData({...limitData,page:parseInt(event.selected) });

    }

    const handleSearchFilter = (e) => {
        let keyword = e.target.value;
        setLimitData({ ...limitData, search: keyword,page:0 });
    }

    const handleFilterData = (name,e) => {
        setFilterData({ ...filterData, [name]: e.target.value });
    }

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        if (filterData.start_date != "" && filterData.end_date != "")
            setDropdownIsOpen(false);
            let mergeDate = {...limitData,...filterData};
            setFilterData({start_date:'',end_date:''})
            getNotificationList(mergeDate);
    };

    const toggleDropdown = () => {
        setDropdownIsOpen(!dropdownIsOpen);
    };

    const handleResetData = () => {
        setLimitData({
            search: null,
            page: 0,
            limit: 10,
            start_date: null,
            end_date: null,
        });
    }

    return (
        <div className="content-wrapper">
            <div className="page-header">
                <div className="left-cell">
                    <h3 className="page-title">Notification</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                Notification
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="page-header-two">
                <div className="inner-page-header-two">
                    <div className="cell-row ">
                        <div className="left-cell">
                            <h4 className="poppins-font">
                                {total} <span>Total</span>
                            </h4>
                        </div>
                        <div className="right-cell">
                            <div className="inner-right-cell">
                                <ul className="navbar-nav navbar-nav-right">
                                    <li className="search-field d-none d-md-block">
                                        <form className="d-flex align-items-center h-100">
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control bg-transparent border-0"
                                                    placeholder="Search Notification"
                                                    value={
                                                        limitData.search || ""
                                                    }
                                                    onChange={(e) =>
                                                        handleSearchFilter(e)
                                                    }
                                                />
                                                <div className="input-group-prepend bg-transparent">
                                                    <img
                                                        src="assets/images/dashboard/search-icon.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </li>
                                    <Dropdown
                                        as="li"
                                        className="custom-dropdown filter-item filter-item-pp"
                                        onToggle={(e) => toggleDropdown(e)}
                                        show={dropdownIsOpen}
                                    >
                                        <Dropdown.Toggle
                                            as="a"
                                            align="end"
                                            className="btn"
                                        >
                                            <i className="mdi mdi-filter-outline"></i>{" "}
                                            filter by date
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu align="end">
                                            <form
                                                onSubmit={(e) =>
                                                    handleFilterSubmit(e)
                                                }
                                            >
                                                <div className="form-group mb-0">
                                                    <h5>Search by date</h5>
                                                    <div className="row row-theme row-price-range">
                                                        <div className="col">
                                                            <div className="">
                                                                <input
                                                                    type="date"
                                                                    value={
                                                                        filterData.start_date ||
                                                                        ""
                                                                    }
                                                                    className="form-control poppins-font"
                                                                    aria-label="Recipient's username"
                                                                    aria-describedby="basic-addon2"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleFilterData(
                                                                            "start_date",
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className=" ">
                                                                <input
                                                                    type="date"
                                                                    className="form-control poppins-font"
                                                                    aria-label="Recipient's username"
                                                                    aria-describedby="basic-addon2"
                                                                    value={
                                                                        filterData.end_date ||
                                                                        ""
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleFilterData(
                                                                            "end_date",
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="custom-dropdown-footer">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        Show result
                                                    </button>
                                                </div>
                                            </form>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {resetBtn ? (
                                        <li className="import-item">
                                            <button
                                                type="button"
                                                class="btn btn-secondary"
                                                onClick={handleResetData}
                                            >
                                                Reset
                                            </button>
                                        </li>
                                    ) : (
                                        ""
                                    )}

                                    <li className="add-transaction cus-btn">
                                        <Link
                                            to="../send-notification"
                                            className="bg-light-green"
                                        >
                                            <i className="mdi mdi-plus"></i>{" "}
                                            Send Notification
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4 data-table-box maintenance-data-table-box">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body pt-0">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span className="form-check form-check-flat form-check-primary">
                                                    <label className="form-check-label">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                        />
                                                        <i className="input-helper"></i>
                                                    </label>
                                                </span>
                                            </th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Send To</th>
                                            <th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.length > 0 ? (
                                            list.map((row) => (
                                                <tr key={row.id}>
                                                    <td>
                                                        <span className="form-check form-check-flat form-check-primary">
                                                            <label className="form-check-label">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                />
                                                                <i className="input-helper"></i>
                                                            </label>
                                                        </span>
                                                    </td>
                                                    <td>{row.title}</td>
                                                    <td>{row.description}</td>
                                                    <td>{row.send_to}</td>
                                                    <td>{row.created_at}</td>
                                                    <td>
                                                        <Link
                                                            className="vd-btn"
                                                            to={
                                                                "/view-notification/" +
                                                                row.id
                                                            }
                                                        >
                                                            View details
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan="5"
                                                    className="text-center"
                                                >
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <Paginate
                                pageCount={total}
                                limit={limitData.limit}
                                handlePageCallback={handlePageCallback}
                                forcePage={limitData.page}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


}

export default withRouter(List);
