import {initializeApp} from 'firebase/app';
import {getAuth,createUserWithEmailAndPassword} from 'firebase/auth';
import { ref, getDownloadURL,getStorage,uploadBytes,deleteObject } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";



import {
    getFirestore,
    Timestamp,
    addDoc,
    collection,
    query,
    where,
    getDocs,
    onSnapshot,
    orderBy,
    doc, setDoc,getDoc,updateDoc,collectionGroup,startAt,endAt
} from "firebase/firestore";
import { authenticatedPost } from "../src/Services/core";
import {  immediateToast } from "izitoast-react";

const firebaseConfig = {
  apiKey: "AIzaSyBl4FAXBpEug2SVOINY9dmy9uTbDHIoch4",
  authDomain: "digital-tenant.firebaseapp.com",
  projectId: "digital-tenant",
  storageBucket: "digital-tenant.appspot.com",
  messagingSenderId: "769812084428",
  appId: "1:769812084428:web:389a6cdfe63ca3c2d83469",
  measurementId: "G-3F52B9N436"
}

const app = initializeApp(firebaseConfig);
const auth = getAuth()
const db = getFirestore();
const storage = getStorage(app);
const messaging  = getMessaging(app);

function  requestPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {

        getToken(messaging,{ vapidKey: 'BA_ixwbhrc6sNNmdxZ_ch2SB9LRUTqOpG13LWxiNkb22kF-s1HOIon7ETf2UM1ucgJ_jt1mnUo7epQ3KV5xEAiY' }).then((currentToken) => {
            if (currentToken) {
                    localStorage.setItem('firebase_push_token',currentToken);
                    authenticatedPost({'firebase_push_token':currentToken},`${process.env.REACT_APP_BASE_URL}AddFirebasePushToken`);
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token.', err);
        });
    }else{
        console.log( 'not permission ' )
    }
  })

}

requestPermission();

const handleFileUploadOnFirebaseStorage = async (bucketName, file,withTime) => {
    var imageLink = '';
    var originalName = file.name;
    var extension = originalName.split('.').pop();
    var fileName = `${bucketName}/${withTime}-${file.name}` ;
    var storageRef = ref(storage, `${fileName}`);

    var showUrl = await uploadBytes(storageRef, file).then( async (snapshot)  => {
        var getUrl = {'name':snapshot.metadata.name,'origialName':originalName,'extension':extension, 'url': await getDownloadURL(snapshot.ref).then((downloadURL) => downloadURL  )};
        return getUrl;
    });
    if( showUrl != '' ){
        return showUrl;
    }
};


onMessage(messaging, (payload) => {
    const userId =  ( localStorage.getItem('userDetalis') != null )?JSON.parse(localStorage.getItem('userDetalis')).firebase_uid:'' ;
    new Notification(payload.notification.title,{body:payload.notification.body,icon:payload.notification.image});
    if( userId != '' ){
        var users = JSON.parse(payload.data["gcm.notification.showNotic"]);
        for(let i = 0;i < users.length;i++ ){
            if( users[i] == userId ){
                immediateToast("info", {
                    class:'notificationToast',
                    id: 'haduken',
                    title: payload.notification.title,
                    icon: 'mdi mdi-bell-outline',
                    displayMode: 2,
                    message: payload.notification.body,
                    position: 'bottomRight',
                    theme:'Light',
                    image: payload.notification.image,
                    balloon: true,
                    timeout:5000,
                    progressBarColor: 'rgb(239, 106, 61)',
                    layout: 2,
                    backgroundColor:'white',
                    titleColor:'#ef6a3d',
                })
            }
        }

    }

});


export {
    app,
    auth,
    db,
    storage,
    onMessage,
    messaging,
    handleFileUploadOnFirebaseStorage,
    ref,
    deleteObject,
    getDownloadURL,
    Timestamp,
    addDoc,
    collection,
    query,
    where,
    getDocs,
    getAuth,
    onSnapshot,
    orderBy,
    doc, setDoc,getDoc,updateDoc,collectionGroup,startAt,endAt,getFirestore,
    createUserWithEmailAndPassword

};
