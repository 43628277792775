import React from "react";
import { addCard , generateToken ,  getAllCards } from "../../Services/UserProfile";
import SimpleReactValidator from 'simple-react-validator';
import showNotification from "../../helpers/Notify";
import Loading from "../../common/Misc/Loader";
import { MaskField } from 'react-mask-field';


class CardDetails extends React.Component{

   constructor (props){
        super (props);
        this.state = {
            allCards : [],
            first_name :"",
            last_name : "",
            card_number:"",
            expiry_date:"",
            cvv_number :"",
            address : "",
            emal:"",
            zip :"",
            selected_card:'',
            token : ''
        };

      this.validator = new SimpleReactValidator();
      this.submitForm = async ()=> {
         if (this.validator.allValid()) {
            this.setState({requestInprogress : true});
            let request = {
                ...this.state
            };
            let res = await generateToken(request);

            console.log(res.data.token, '--55--');
            this.setState({requestInprogress : false});            
            if(res.data.token){
               this.setState({
                  token : res.data.token
               });
               

               window.openLightbox();
               // var paymentFields = {
               //    ssl_txn_auth_token: res.data.token
               // };
               // var callback = {
               //    onReady: function () {
               //       document.getElementById("id_payment").style.display = "block";
               //    },
               //    onError: function (error) {
               //       this.showResult("error", error);
               //    },
               //    onCancelled: function () {
               //       this.showResult("cancelled", "");
               //    },
               //    onDeclined: function (response) {
               //       this.showResult("declined", JSON.stringify(response, null, '\t'));
               //    },
               //    onApproval: function (response) {
               //       this.showResult("approval", JSON.stringify(response, null, '\t'));
               //    }
               // };
               // var options = {
               //    frame: document.getElementById('id_payment'),
               //    cssOverride: "html, body {background-color: rgba(244,246,246,1.0) } " +
               //       ".hpm-content {box-shadow:unset; margin-top: unset;} " +
               //       ".hpm-input-short {margin-bottom: 0px;} " +
               //       "md-card {box-shadow:unset;background-color:#f4f6f6;}"
               // };
               // PayWithConverge.open(paymentFields, callback, options);
               // return false;
            }
         } else {
         this.validator.showMessages();
         this.forceUpdate();
         }
      }
   }

   showResult = (status, error) => {
      console.log(status, error);
   }

   setValue = (e, field) => {
      if(field === 'display_as_company'){
         let value = e.target.checked ? 'yes' : 'no';
         this.setState({[field] : value})
      }else{
         this.setState({[field] : e.target.value})
      }
   }

   componentDidMount = async () => {
    const allCards = await getAllCards();
    this.setState({
        allCards : allCards.data.data
    });
    if(allCards.data.data.length > 0){
        this.setState({
            first_name :allCards.data.data[0].ssl_first_name,
            last_name : allCards.data.data[0].ssl_last_name,
            card_number:allCards.data.data[0].ssl_card_no,
            expiry_date:allCards.data.data[0].ssl_exp_date,
            cvv_number :"",
            address : "",
            zip :"",
            selected_card:allCards.data.data[0].ssl_token  
        });
    }
   }

   update_card = (e) =>{
    if(e.target.value === ''){
        this.setState({
            first_name :'',
            last_name : '',
            card_number:'',
            expiry_date:'',
            cvv_number :"",
            address : "",
            zip :"", 
        });

    } else {
        this.state.allCards.map((row, index)=>{
            if(row.ssl_token === e.target.value){
                this.setState({
                    first_name :row.ssl_first_name,
                    last_name : row.ssl_last_name,
                    card_number:row.ssl_card_no,
                    expiry_date:row.ssl_exp_date,
                    cvv_number :"",
                    address : "",
                    zip :"", 
                });
            }
        });
    }
   }

   render(){
      return (
         <>
            <div className="card mb-4">
               <div className="card-header d-flex align-items-start">
                  <h3 className="d-inline-block align-middle">Card Information</h3>
               </div>
               <div className="card-body">
                  <div className="row">

                  {/* <div className="col-md-12">
                        <div className="form-group">
                           <label htmlFor="exampleSelectTex">All Card</label>
                           <select className="form-control tex-select select_btn" value={this.state.selected_card} name = "tax_indentity_type"   id="exampleSelectTex" onChange={(e) => this.update_card(e)} >
                              <option className="unselect" value="" key={'unselect'}>Add New Card</option>
                              {
                                this.state.allCards?.length > 0 ? (
                                    this.state.allCards.map((row)=>{
                                        return (<option value={row.ssl_token} key={row.id} >{ row.ssl_first_name + ' '+ row.ssl_last_name + ' ('+ row.ssl_card_no +')' }</option>)
                                    })
                                ) : "No Card Added Yet"
                              }
                              
                           </select>
                        </div>
                     </div> */}

                     <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="exampleInputUsername1">First Name</label>
                           <input type="text" className="form-control" name = "first_name" id="exampleInputUsername1" placeholder="Enter your first name" value={this.state.first_name} onChange={(e)=>this.setValue(e,'first_name')}/>
                        </div>
                        <input type="hidden" value={this.state.token} id="token"/>
                        {this.validator.message('first_name', this.state.first_name, 'required')}
                     </div>
                     <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="exampleInputUsername2">Last Name</label>
                           <input type="text" className="form-control" name = "last_name" id="exampleInputUsername2" placeholder="Also your last name" value={this.state.last_name} onChange={(e)=>this.setValue(e,'last_name')}/>
                        </div>
                        {this.validator.message('last_name', this.state.last_name, 'required')}
                     </div>
                     <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="exampleInputUsername2">Email</label>
                           <input type="text" className="form-control" name = "last_name" id="exampleInputUsername2" placeholder="Also your email" value={this.state.email} onChange={(e)=>this.setValue(e,'email')}/>
                        </div>
                        {this.validator.message('email', this.state.email, 'required')}
                     </div>
                    
                     {/* <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="exampleInputUsername2">Card Number</label>
                           <MaskField mask="____-____-____-____" replacement={{ _: /\d/ }} name = "card number" className="form-control" placeholder="xxxx-xxxx-xxxx-xxxx" value={this.state.card_number} onChange={(e)=>this.setValue(e,'card_number')} />
                           
                        </div>
                        {this.validator.message('card_number', this.state.card_number, 'required')}
                     </div> */}
                     
                     {/* <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="exampleInputText3">Expiry Date</label>
                           <MaskField  mask="__/__" replacement={{ _: /\d/ }} className="form-control" name = "expirt_dare" id="exampleInputText3" placeholder="mm/yy" value={this.state.expiry_date} onChange={(e)=>this.setValue(e,'expiry_date')}/>
                        </div>
                        {this.validator.message('expiry_date', this.state.expiry_date, 'required')}
                     </div> */}

                     {/* <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="exampleInputText3">CVV Number</label>
                           <MaskField mask="___" replacement={{ _: /\d/ }} className="form-control" name = "cvv_number" id="exampleInputText3" placeholder="xxx" value={this.state.cvv_number} onChange={(e)=>this.setValue(e,'cvv_number')}/>
                        </div>
                        {this.validator.message('cvv_number', this.state.cvv_number, 'required')}
                     </div> */}
                     <div className="col-md-8">
                        <div className="form-group">
                           <label htmlFor="exampleInputText3">Address</label>
                           <input type="text" className="form-control" name = "address" id="exampleInputText3" placeholder="Enter your address" value={this.state.address} onChange={(e)=>this.setValue(e,'address')}/>
                        </div>
                        {this.validator.message('address', this.state.address, 'required')}
                     </div>
                     <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="exampleInputText3">Zip</label>
                           <MaskField  mask="________" replacement={{ _: /./ }} className="form-control" name = "zip" id="exampleInputText3" placeholder="Enter your Zip" value={this.state.zip} onChange={(e)=>this.setValue(e,'zip')}/>
                        </div>
                        {this.validator.message('zip', this.state.zip, 'required')}
                     </div>
                     
                  </div>
                  <iframe id="id_payment" style={{"width":"550px", "height":"515px", "border":"0px", "display":"none"}}></iframe>
                     <button className="btn bg-orange btn-cus mr-2 text-white" disabled={ this.state.requestInprogress}  onClick={this.submitForm}>{ this.state.requestInprogress ? (<Loading className="btn theme-btn-orange" msg="In progress..." />) : 'Proceed' }</button>    
               </div>
            </div>
         </>   
      )
   }
}

export default CardDetails;