import React from "react";
import Generalinformation from "../../../components/Contacts/Generalinformation";
import AddAddress from "../../../components/Contacts/AddAddress";
import EmergencyContact from "../../../components/Contacts/EmergencyContact";
import AddPet from "../../../components/Contacts/AddPet";
import AddVehicles from "../../../components/Contacts/AddVehicles";
import AddNotes from "../../../components/Contacts/AddNotes";
import {  updateTenant, getTenantDetails } from "../../../Services/Tenants" ;
import { getErrorMessage, getQueryStringParams } from "../../../helpers/Utils";
import showNotification from "../../../helpers/Notify";
import { Link } from 'react-router-dom';

class EditTenant extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            profile_pic_path :'',
            toggleEmergencyContact : false,
            toggleAddVehicles : false,
            emergencyContactForm : [],
            addVehicleForm : [],
            details : null
        };           

        this.submitForm =async ()=> {
           let  errorStatus = false;


           let add_vehicle_data = [];
            this.state.addVehicleForm.map( async (row) => {
                let data = await this[row.ref].submitForm();
                if(data.status)
                    add_vehicle_data.push(data.data);
                else{
                    errorStatus = true;
                    return false;
                }
            });

            let emergency_contact_data = [];
            this.state.emergencyContactForm.map(async (row) => {
                 let data = await this[row.ref].submitForm();
                 if(data.status){
                    emergency_contact_data.push(data.data);
                 }else{
                    errorStatus = true;
                    return false;
                 }
            });

            let generalInformation = {}
            let generalInfo = await this.generalChild.submitForm();
            if(generalInfo.status){
                    generalInformation = generalInfo.data;
            }else{
                    errorStatus = true;
            }

           let addUserAddress = {}
           let addAddress = await this.addressChild.submitForm();
           if(addAddress.status){
             addUserAddress = addAddress.data;
           }else{
                errorStatus = true;
                return;
           }

            let addUserPet = {}
           let addPet = await this.petChild.submitForm();
           if(addPet.status){
             addUserPet = addPet.data;
           }else{
                errorStatus = true;
                return;
           }

            let addUserNote = {}
          let addNote = await this.noteChild.submitForm();
           if(addNote.status){
             addUserNote = addNote.data;
           }else{
                errorStatus = true;
                return;
           }
            
            
            // response.map((row) => {
            //     emergency_contact_data.push(row.data);
            //     if(!row.status)
            //         errorStatus = true; 
            //         return false;
            // })

            
            
           let request =  {            
            ...generalInformation,
            user_address : addUserAddress,
            user_pets : addUserPet,
            user_contacts : emergency_contact_data,
            user_vehicles : add_vehicle_data,
            ...addUserNote,    
            profile_pic_path : this.state.profile_pic_path,         
           }
           let response = await updateTenant(request);           
           this.setState({requestInprogress : false});
           if(response.data.success){
                showNotification(getErrorMessage(response.data.message), 'success');
                this.redirectUser();
           } else {
               showNotification(getErrorMessage(response.data.message), 'error');
           }
        }
    }

    redirectUser()
    {
        this.props.navigate('/tenants')
    }
         
    getErrorMEssage = (myObj) =>{
        if(typeof myObj == 'object'){
            for(const row in myObj){
                return myObj[row][0];
            }
        }else{
            return myObj;
        }
    }

    setValue = (e, field)=>{
        if(field === 'terms_policy'){
            this.setState({...this.state, [field]:e.target.checked});
        }else{
            this.setState({...this.state, [field]:e.target.value});
        }
    }
    
    
    componentDidMount = async () => {
        let params = getQueryStringParams();
        if(typeof params['id'] != 'undefined'){
            let tenant_details_res = await getTenantDetails(params['id']);
            this.populateContactDetails(tenant_details_res.data.data);
        }
    }

    populateContactDetails = (details) => {
        console.log(details, 'All Details');
        if(details){
            this.setState({
                details : details,
                profile_pic_path : details.image
            });
        }

        // Emergency Contact Details Asssign if have value
        if(details.emergencycontact?.length > 0){
            let tempArr = [];
            details.emergencycontact.map((row,index) => {
                tempArr.push({
                    key : index, ref : 'ecfInstance'+index, data:row
                });
            });
            this.setState({
                emergencyContactForm : tempArr
            });

        }else{
            let tempArr = [{key : 0, ref : 'ecfInstance0', data:[]}];
            this.setState({
                emergencyContactForm : tempArr
            });
        }

        // Vehicle Details Asssign if have value
        if(details.user_vehicles?.length > 0){
            let tempArr = [];
            details.user_vehicles.map((row,index) => {
                tempArr.push({
                    key : index, ref : 'avInstance'+index, data:row
                });
            });
            this.setState({
                addVehicleForm : tempArr
            });

        }else{
            let tempArr = [{key : 0, ref : 'avInstance0', data:[]}];
            this.setState({
                addVehicleForm : tempArr
            });
        }
    }

    addEmergencyContact = () => {
        let allEF = this.state.emergencyContactForm;
        let last_index = 0;
        
        allEF.map((row) => ( last_index = row.key ));
        let new_index = ++last_index;
        allEF.push({ key : new_index , ref : 'ecfInstance'+new_index , data:[]});
        this.setState({ ...this.state,
            emergencyContactForm : allEF
        });
    }
    
    rerenderMultipleEF = () => {
        let form = [];
        this.state.emergencyContactForm.map((row) => {
            return form.push(<EmergencyContact  ref={instance => {this[row.ref] = instance }} data={row.data}/> );
        });
    return form;
    }
    
                    
    addVehicle = () => {
        let allVehicle = this.state.addVehicleForm;
        let last_index = 0;
        
        allVehicle.map((row) => ( last_index = row.index ));
        let new_index = ++last_index;
        allVehicle.push({ index : new_index , ref : 'avInstance'+ new_index , data:[]});
        this.setState({ ...this.state,
            addVehicleForm : allVehicle
        });
    }
    rerenderMultipleVehicle = () => {
        let form = [];
        this.state.addVehicleForm.map((row) => {
        return form.push(<AddVehicles  ref={instance => {this[row.ref] = instance }} data={row.data}/>);
        });
        return form;
    }

    uplodProfilePic = (e) => {
        alert('KKK');

        let file = e.target.files;
        var reader = new FileReader();
        reader.readAsDataURL(file[0])
        reader.onload = () => {
            this.setState({
                profile_pic_path : reader.result
            });
        };
    }

    render(){
        return(
            <>
                <div className="content-wrapper">
                    <div className="page-header">
                        <div className="left-cell">
                            <h3 className="page-title">Edit tenant</h3>
                        </div>
                    </div>
                    <div className="detail-cont">
                        <div className="row">
                            <div className="col-md-8 grid-margin stretch-card flex-column">
                                {
                                    this.state.details ? <Generalinformation ref={instance => {this.generalChild = instance}}  generalDetails={this.state.details}  /> : ''
                                }

                                {
                                    this.state.details?.address ? <AddAddress ref={instance => {this.addressChild = instance}}  addressDetails={this.state.details.address}/> : ''
                                }
                                <div className="card card-accordion-theme mb-4">
                                <div className="card-header arrow-add-right d-flex align-items-start">
                                    <span className={"btn-header-link " + (this.state.toggleEmergencyContact?"" : "collapsed")} data-bs-toggle="collapse" onClick={(e) => {this.setState({toggleEmergencyContact : !this.state.toggleEmergencyContact})}}
                                    role="button" aria-expanded="false" aria-controls="collapseExample1">
                                    <h3 className="d-inline-block align-middle"><i className="mdi mdi-plus-circle-outline"></i>
                                        <span>Emergency Contact</span>
                                    </h3>
                                    </span>
                                </div>
                                <div className={"collapse" + (this.state.toggleEmergencyContact ? "show" : "")} id="collapseExample1">
                                <div className="card-body">
                                    {
                                    this.rerenderMultipleEF()
                                    }     
                                    <div className="col-md-12">
                                        <button className="btn btn-link btn-link-lg p-0 aov-show" type="button" onClick={(e)=>{this.addEmergencyContact()}}> <i
                                            className="mdi mdi-plus-box"></i> <span>Add other emergency contact</span> </button>
                                    </div>
                                </div>
                                </div>
                            </div>

                            {
                                this.state.details?.user_pets ? <AddPet ref={instance => {this.petChild = instance}}  petData={this.state.details.user_pets}/> : ''
                            }
                            <div className="card card-accordion-theme mb-4">
                                <div className="card-header arrow-add-right d-flex align-items-start">
                                <span className= {"btn-header-link "+ (this.state.toggleAddVehicles ? "":"collapsed")} data-bs-toggle="collapse" onClick={(e) => {this.setState({toggleAddVehicles : !this.state.toggleAddVehicles})}}
                                role="button" aria-expanded="false" aria-controls="collapseExample3">
                                <h3 className="d-inline-block align-middle"><i className="mdi mdi-plus-circle-outline"></i>
                                    <span>Add a Vehicles</span>
                                </h3>
                                </span>
                                </div>
                                <div className={"collapse" + (this.state.toggleAddVehicles ? "show": "")} id="collapseExample3">
                                <div className="card-body"> 
                                {
                                this.rerenderMultipleVehicle()
                                }
                                <button className="btn btn-link btn-link-lg p-0 aov-show" type="button" onClick={(e)=>{this.addVehicle()}}> <i className="mdi mdi-plus-box"></i> <span>Add other vehicle</span>
                                </button>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.details?.notes ? <AddNotes ref={instance => {this.noteChild = instance}}  data={this.state.details.notes}/> : ''
                        }
                        <div className="card  mb-4">
                            <div className="card-body p-0">
                                <div className="d-flex justify-content-between">
                                <Link  to ="/contacts" type="button" className="btn btn-outline-dark">Cancel</Link>
                                <button className="btn bg-orange btn-cus mr-2 text-white" onClick={this.submitForm}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 grid-margin stretch-card right-side-profile-cell">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card mb-4">
                                <div className="card-header d-flex align-items-start">
                                    <h3 className="d-inline-block align-middle">Profile photo</h3>
                                </div>
                                <div className="profile-pic-wrapper mt-4">
                                    <div className="pic-holder1 mb-0">
                                        <img id="profilePic" className="pic" src={ this.state.profile_pic_path ? this.state.profile_pic_path : "assets/admin/images/profile-img.jpg"} alt="profile pic"/>
                                    <input className="uploadProfileInput" type="file" name="profile_pic" accept="image/*"  onclick={(e) => { this.uplodProfilePic(e) }} />
                                        <label htmlFor="newProfilePhoto" className="upload-file-block">
                                            <div className="text-center">
                                            <div className="mb-2">
                                                <i className="fa fa-camera fa-2x"></i>
                                            </div>
                                            <div className="fs-cus-20">
                                                Upload <br></br> photo
                                            </div>
                                            </div>
                                        </label>
                                    </div>
                                    {/* <div className="fs-cus-20">
                                        Upload photo
                                    </div> */}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                    <footer className="footer">
                    <div className="container-fluid d-flex justify-content-center">
                        <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright ©
                        <b>DigitalTenant</b> 2022. All Rights Reserved.</span>
                    </div>
                    </footer>
                </div>
            </>
        )
    }
}
export default EditTenant;

