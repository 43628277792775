import React from 'react';
import { withRouter } from "../../../helpers/withRouter";
import { getQueryStringParams } from "../../../helpers/Utils";
import { getTenantDetails } from "../../../Services/Tenants" ;
import { Link } from 'react-router-dom';
class TenantDetails extends React.Component {
   constructor (props) {
        super(props);
        this.state = {
            id:'',
            details : [],
            activeTab : 'tenant-profile'
        };
   }

   componentDidMount = async() => {
      let params = getQueryStringParams();
      if(typeof params['id'] != 'undefined'){
         let tenant_details_res = await getTenantDetails(params['id']);
         this.setState({
            details : tenant_details_res.data.data
         });
      }
   }



    componentWillReceiveProps = async () => {
        let params = getQueryStringParams();
        if(typeof params['id'] != 'undefined'){
            let tenant_details_res = await getTenantDetails(params['id']);
            this.setState({
                details : tenant_details_res.data.data
            });
        }

    }

   changeTab = (tab) => {
      this.setState({ activeTab : tab });
   }

    render() {
        return (
            <div className="content-wrapper">
            <div className="page-header">
               <div className="left-cell">
                  <h3 className="page-title">{
                        (JSON.parse(localStorage.getItem('userDetalis')).user_type == 'admin' )?
                            'Tenant Details'
                        :'Contacts Details'
                  }</h3>
                  <nav aria-label="breadcrumb">
                     <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            {
                                (JSON.parse(localStorage.getItem('userDetalis')).user_type == 'admin' )?
                                    <Link to="/tenant-users">Tenants </Link>
                                :<Link to="/contacts">Contacts </Link>
                            }
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {
                                (JSON.parse(localStorage.getItem('userDetalis')).user_type == 'admin' )?
                                    'Tenant Details'
                                :'Contacts details'
                            }

                        </li>
                     </ol>
                  </nav>
               </div>
            </div>
            {
                (JSON.parse(localStorage.getItem('userDetalis')).user_type != 'admin' )?
                    <div className="inner_all_transaction_record_box d-flex">
                    <div className="action-btns-group ml-auto">
                        <div className="innner-action-btns-group">
                            <Link type="button" to={"/edit-tenant?id="+this.state.details.id} className="btn btn-dark btn-fw">edit</Link>
                            <button type="button" className="btn btn-dark btn-fw">Send connection</button>
                            <button type="button" className="btn btn-dark btn-fw">Move in</button>
                            <button type="button" className="btn btn-dark btn-fw">Create invoice </button>
                            <button type="button" className="btn btn-dark btn-fw">archive</button>
                        </div>
                    </div>
                    </div>
                :''
            }
            <div className="row mt-4">
               <div className="col-md-3">
                  <div className="stretch-card mb-4">
                     <div className="card left-tenant-detail">
                        <div className="card-body pt-0 text-center">
                           <div className="tenant-profile-img mb-4">
                              <div className="online tp-img"><img className="img-fluid" src={ this.state.details.image ? this.state.details.image : "assets/admin/images/profile-img.jpg"} alt="tenanat-1"/></div>
                              <h4 className="heading-h4">{ this.state.details.first_name  + ' ' + this.state.details.last_name }</h4>
                              <p>{ this.state.details.company_name }</p>
                           </div>
                           <div className="text-left">
                              {
                                 this.state.details.address ? (
                                    <p>{ this.state.details.address.address }<br></br>
                                 { this.state.details.address.city +', '+this.state.details.address.state + ' ' + this.state.details.address.country + ' ' + this.state.details.address.postal_code}
                              </p>

                                 ) : 'No Address Added'
                              }

                              <ul className="list-tenant-left">
                                 <li>
                                    <p><strong>Deposits</strong></p>
                                    <span className="color-theme">$1200.00 </span>
                                 </li>
                                 <li>
                                    <p><strong>Outstanding</strong></p>
                                    <span className="color-theme">$1110.00 </span>
                                 </li>
                                 <li>
                                    <p><strong>Credits</strong></p>
                                    <span className="color-theme">$130.00 </span>
                                 </li>
                              </ul>
                              <div className="link-tf-td">
                                 <a href="#">Tenant Financial Report <i className="mdi mdi-arrow-right"></i></a>
                                 <a href="#">Send a notice <i className="mdi mdi-arrow-right"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-9">
                  <div className="stretch-card flex-column flp-section-two">
                     <div className="card mb-4">
                        <ul className="nav nav-pills nav-pills-theme mb-5" id="pills-tab" role="tablist">
                           <li className={ "nav-item" }  role="presentation" onClick={() => this.changeTab('tenant-profile')}>
                              <button className={"nav-link "  + (this.state.activeTab === 'tenant-profile' ? 'active' : '')} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="false">Tenant Profile</button>
                           </li>
                           <li className={ "nav-item"  } role="presentation" onClick={() => this.changeTab('lease-details')}>
                              <button className={"nav-link " + (this.state.activeTab === 'lease-details' ? 'active' : '')} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="true">Lease Details</button>
                           </li>
                        </ul>
                        <div className="tab-content tab-content-theme" id="pills-tabContent">
                           <div className= {"tab-pane fade" + (this.state.activeTab === 'tenant-profile' ? ' active show' : '')}  id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                              <div className="card-header d-flex align-items-start">
                                 <h3 className="d-inline-block align-middle">General information</h3>
                              </div>
                              <div className="card-body">
                                 <div className="row row-theme">
                                    <div className="col-md-12">
                                       <ul className="list-md mb-1">
                                          <li><strong>First Name</strong><span> : &nbsp; { this.state.details.first_name }  </span></li>
                                          <li><strong>Last Name </strong><span> : &nbsp; { this.state.details.last_name } </span></li>
                                          <li><strong>Email</strong><span> : &nbsp; { this.state.details.email }</span></li>
                                          <li><strong>Gender</strong><span> : &nbsp; { this.state.details.gender === 'm' ? 'Male' :  ( this.state.details.gender === 'f'?'Female' : 'Other/Transgender')   }</span></li>
                                          <li><strong>Date of birth</strong><span> : &nbsp; { this.state.details.birth_date ? this.state.details.birth_date : 'N/A' }</span></li>
                                          <li><strong>Occupation</strong><span> : &nbsp; { this.state.details.occupation ? this.state.details.occupation : 'N/A' }</span></li>
                                          <li><strong>Phone Number</strong><span> : &nbsp; { this.state.details.phone ? this.state.details.phone : 'N/A' }  </span></li>
                                          <li><strong>Rent Amount </strong><span> : &nbsp; { this.state.details.rent ? '$'+this.state.details.rent : 'N/A' }</span></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                              <hr></hr>
                              <div className="card-header d-flex align-items-start">
                                 <h3 className="d-inline-block align-middle">Emergency Contact </h3>
                              </div>
                              <div className="card-body">
                                 <div className="row row-theme">
                                    <div className="col-md-12">
                                       {
                                          this.state.details.emergencycontact?.length > 0 ? (
                                             this.state.details.emergencycontact.map((row) => {
                                                return (
                                                   <ul className="list-md mb-1">
                                                      <li><strong>First Name</strong><span> : &nbsp; { row.first_name }  </span></li>
                                                      <li><strong>Last Name </strong><span> : &nbsp; { row.last_name } </span></li>
                                                      <li><strong>Email</strong><span> : &nbsp; { row.email } </span></li>
                                                      <li><strong>Phone Number</strong><span> : &nbsp; { row.phone }   </span></li>
                                                      <li><strong>Relationship </strong><span> : &nbsp; { row.relationship } </span></li>
                                                      <li><strong>Description </strong><span> : &nbsp; { row.detail } </span></li>
                                                   </ul>
                                                )
                                             })

                                          ) : "N/A"
                                       }
                                    </div>
                                 </div>
                              </div>

                              <hr></hr>
                              <div className="card-header d-flex align-items-start">
                                 <h3 className="d-inline-block align-middle">Pets</h3>
                              </div>
                              <div className="card-body">
                                 <div className="row row-theme">
                                    <div className="col-md-12">

                                    {
                                       this.state.details.user_pets?.length > 0 ? (

                                          this.state.details.user_pets.map((eachPet)=> {
                                             return <>
                                                <div className="d-flex align-items-center mb-4">
                                                   <div className="dog-image">
                                                      <img className="img-fluid" src={ eachPet.image ? eachPet.image : "assets/admin/images/cute-dog-headshot.jpg"} alt="tenanat-1" />
                                                   </div>
                                                   <div>
                                                      <h5 className="fw-bold">
                                                         { eachPet.name }
                                                      </h5>
                                                   </div>
                                                </div>
                                                <ul className="list-md mb-0">
                                                   <li><strong>Type</strong><span> : &nbsp; { eachPet.pet_type }  </span></li>
                                                   <li><strong>Weight</strong><span> : &nbsp; { eachPet.weight  }</span></li>
                                                   <li><strong>Bread</strong><span> : &nbsp; { eachPet.breed } </span></li>
                                                </ul>
                                             </>
                                          })
                                       ) : "N/A"
                                    }
                                    </div>
                                 </div>
                              </div>
                              <hr></hr>
                              <div className="card-header d-flex align-items-start">
                                 <h3 className="d-inline-block align-middle">Vehicles</h3>
                              </div>
                              <div className="card-body">
                                 <div className="row row-theme">
                                    <div className="col-md-12">
                                       {
                                          this.state.details.user_vehicles?.length > 0 ? (
                                             this.state.details.user_vehicles.map((eachVehicle) => {
                                                return (
                                                   <ul className="list-md mb-0">
                                                      <li><strong>Type</strong><span> : &nbsp; { eachVehicle.vehicle_type } </span></li>
                                                      <li><strong>Make</strong><span> : &nbsp; { eachVehicle.make } </span></li>
                                                      <li><strong>Model</strong><span> : &nbsp; { eachVehicle.model } </span></li>
                                                      <li><strong>Year</strong><span> : &nbsp; { eachVehicle.year } </span></li>
                                                      <li><strong>Color</strong><span> : &nbsp; { eachVehicle.color } </span></li>
                                                      <li><strong>License</strong><span> : &nbsp; { eachVehicle.license } </span></li>
                                                   </ul>
                                                );
                                             })
                                          ) : "N/A"
                                       }
                                    </div>
                                 </div>
                              </div>
                              <hr></hr>
                              <div className="card-header d-flex align-items-start">
                                 <h3 className="d-inline-block align-middle">Notes</h3>
                              </div>
                              <div className="card-body">
                                 <div className="row row-theme mb-4">
                                    <div className="col-md-10">
                                       <p> {  this.state.details.notes ? this.state.details.notes : 'N/A' }</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className={"tab-pane fade"  + (this.state.activeTab === 'lease-details' ? ' active show' : '')} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">
                              <div className="card-header d-flex align-items-start">
                                 <h3 className="d-inline-block align-middle">Lease information</h3>
                              </div>
                              <div className="card-body">
                                 <div className="row row-theme">
                                    <div className="col-md-12">
                                       {
                                          this.state.details.lease_detail ? (
                                             <ul className="list-md mb-1">
                                                <li><strong>Location</strong><span> : &nbsp; 10112 Cavalier Ct, St. Louis, MO 63134  </span></li>
                                                <li><strong>Monthly Rent Amount</strong><span> : &nbsp; ${ this.state.details.lease_detail.amount }  </span></li>
                                                <li><strong>Start Date </strong><span> : &nbsp; { this.state.details.lease_detail.start_date }</span></li>
                                                <li><strong>End Date</strong><span> : &nbsp; { this.state.details.lease_detail.last_date }</span></li>
                                                <li><strong>Lease type</strong><span> : &nbsp; Month to Month</span></li>
                                             </ul>
                                          ) : "N/A"
                                       }

                                    </div>
                                 </div>
                              </div>
                              <hr></hr>
                              <div className="card-body pt-0">
                                 <div className="row row-theme">
                                    <div className="col-md-12">
                                       <ul className="list-md mb-1">
                                          <li><strong>Payment Due Date</strong><span> : &nbsp; June 5, 2020  </span></li>
                                          <li><strong>Grace Period</strong><span> : &nbsp; --  </span></li>
                                          <li><strong>Late Fee Fixed or Percent of Rent </strong><span> : &nbsp; --</span></li>
                                          <li><strong>Rent Increase Fixed or Percent of Rent</strong><span> : &nbsp; --</span></li>
                                          <li><strong>Security Deposit Amount</strong><span> : &nbsp; $20</span></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
            );
        }
}
export default withRouter(TenantDetails);
