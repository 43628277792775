import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

const Paginate = (props) =>  {

const [pageCount,setPageCount] = useState(0);

const handlePageClick = (event) => {
    props.handlePageCallback(event);
}

 useEffect(() => {
    setPageCount(Math.ceil(props.pageCount/parseInt(props.limit)));
  }, [props,pageCount]);



  return ((
      <>
          <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              className="pagination"
              previousLinkClassName="back-page page-btn"
              activeLinkClassName="active"
              nextLinkClassName="next-page page-btn"
              forcePage={props.forcePage}
          />
      </>
  ): "");
}

export default Paginate;
