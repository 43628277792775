import axios from "axios";
export const postCall = async (request, url ) => {
    return await axios
    .post(url, request)
    .then((response) => {
        return response;
    });
}

export const authenticatedPost = async (request, url ) => {
    return await axios
    .post(
        url,
        request,
        {
            headers: {
            Authorization: 'Bearer '+localStorage.getItem('token')
            }
        })
    .then((response) => {
        return response;
    });
}

export const authenticatedGet = async (url) => {
    return await axios
    .get(
        url,
        {
            // timeout: 2500,
            headers: {
            Authorization: 'Bearer '+localStorage.getItem('token')
            }
        })
    .then((response) => {
        return response;
    }).catch((err)=>{
        // alert(err.message);
    });
}

export const authenticatedParamGet = async (request,url) => {
    return await axios
    .get(
        url,
        {
        headers: {
            Authorization: 'Bearer '+localStorage.getItem('token')
        },
        params: request
        })
    .then((response) => {
        return response;
    });
}

export const getCall = async (url) => {
    return await axios
    .get(url)
    .then((response) => {
        return response;
    });
}
