
import { useState, useEffect,useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { usersList, NotificationSend } from './Services';
import SimpleReactValidator from 'simple-react-validator';
import showNotification from "../../helpers/Notify";
import { getErrorMessage } from "../../helpers/Utils";
import PushNotification from '../../common/PushNoification';
import { useNavigate } from "react-router-dom";

import SendNotification from '../../common/SendNotification';

const Send = (props) => {

    const navigate = useNavigate();

    const animatedComponents = makeAnimated();
    const [landlord,setLandlord] = useState([]);
    const [tenant,setTenant] = useState([]);
    const [inputValues,setInputValues] = useState([]);
    const simpleValidator = useRef(new SimpleReactValidator())
    const [, forceUpdate] = useState();
    const [isLoad,setIsLoad] = useState(false);

    useEffect(() => {
        getUsers();
    },[])

    const getUsers = async () => {
        let response = await usersList();
        setLandlord(response.data.data.landlord);
        setTenant(response.data.data.tenant);
    }

    const handleInputData = (name,e) => {
        if(name == 'landlord' || name == 'tenant' ){
            var data = e
            setInputValues({...inputValues,[name]:e});
        }else{
            var data = e.target.value;
        }
        setInputValues({...inputValues,[name]:data});
    }

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages()
            forceUpdate(1)
        }else{
            if( (typeof inputValues.landlord == 'undefined' || inputValues.landlord.length == 0 ) && (typeof inputValues.tenant == 'undefined' || inputValues.tenant.length == 0) ){
                var msgError = 'Please select minimum one landlord or tenant';
                if(JSON.parse(localStorage.getItem('userDetalis')).user_type != 'admin' ){
                    msgError = 'Please select minimum one tenant'
                }
                showNotification(getErrorMessage(msgError), 'error');
            }else{
                setIsLoad(true);
                let response = await NotificationSend(inputValues);
                if( response.data.success  ){
                    PushNotification(response.data.data)
                    setIsLoad(false);
                    showNotification(getErrorMessage("Notification Successfully send"), "success");
                    navigate("/notification");


                }
            }
        }
    }

    return(
        <div className="content-wrapper">
            {
                isLoad?
                    <div className='loading'></div>
                :''
            }
            <div className="page-header">
                <div className="left-cell">
                    <h3 className="page-title">Send Notification</h3>
                </div>
            </div>
            <div className="detail-cont">
                <div className="row">
                    <form className="forms-sample" onSubmit={(e) => handleSubmitForm(e) } >
                        <div className="col-md-12 grid-margin stretch-card flex-column">
                            <div className="card mb-4">
                                <div className="card-body pt-0">
                                        <div className="row">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPriority">Title</label>
                                                <div className="input-group input-group-icon mb-3">
                                                    <input type="text" className="form-control poppins-font" aria-label="Recipient's username"
                                                        aria-describedby="basic-addon2" name="title" value={ inputValues.title || ''} onChange={(e) => handleInputData('title',e)}  />
                                                </div>
                                                {simpleValidator.current.message('title', inputValues.title, 'required')}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPriority">Description</label>
                                                <div className="input-group input-group-icon mb-3">
                                                    <textarea className='form-control' rows={6} value={inputValues.description || ''}  onChange={(e) => handleInputData('description',e)} >
                                                        { inputValues.description || ''}
                                                    </textarea>
                                                </div>
                                            {simpleValidator.current.message('description', inputValues.description, 'required')}
                                            </div>
                                            {
                                                (JSON.parse(localStorage.getItem('userDetalis')).user_type == 'admin')?
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPriority">Landlord</label>
                                                            <Select
                                                                classNames="controlWidth"
                                                                closeMenuOnSelect={true}
                                                                components={animatedComponents}
                                                                defaultValue={[]}
                                                                isMulti
                                                                options={landlord}
                                                                onChange={(e) => handleInputData('landlord',e)}
                                                            />

                                                    </div>
                                                :''

                                            }
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPriority">Tenant</label>
                                                <Select
                                                    classNames="controlWidth"
                                                    closeMenuOnSelect={true}
                                                    components={animatedComponents}
                                                    isMulti
                                                    options={tenant}
                                                    onChange={(e) => handleInputData('tenant',e)}
                                                />

                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className="card  mb-4">
                                <div className="card-body p-0">
                                    <div className="d-flex justify-content-between">
                                        <Link to= "/notification" type="button" className="btn btn-outline-dark">Cancal</Link>
                                        <button type="submit"  className="btn bg-orange btn-cus mr-2 text-white">Send</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )

}

export default Send;
