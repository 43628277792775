
import React from "react";
import Select from 'react-select';
import { getAllProperty } from "../../../Services/Property";
import SimpleReactValidator from 'simple-react-validator';
import { EditMileage,ViewMileage } from "../../../Services/Mileage";
import showNotification from "../../../helpers/Notify";
import { getErrorMessage } from "../../../helpers/Utils";
import { withRouter } from "../../../helpers/withRouter";
import { Link } from "react-router-dom";


class Edit extends React.Component{

    constructor (props){
        super (props);
        this.state = {
             propertiesOption: [],
             formData:{},
             from_property:0,
             to_property:0

        };
        this.validator = new SimpleReactValidator({autoForceUpdate: this});
        this.editMileage = () => {
            this.createMileage(this.validator.allValid());
        }

    }

    componentDidMount = async () =>{
        console.log(this.props)
        let response = await getAllProperty();
        await this.propertyOptions(response.data.data);
        let details = await ViewMileage({'id':this.props.params.id});
        this.setState({formData:details.data.data});

        for(var i = 0;i < response.data.data.length; i++ ){
            if( details.data.data.from_properties.id == response.data.data[i].id ){
                this.setState({from_property:i});
            }
            if( details.data.data.to_properties.id == response.data.data[i].id ){
                this.setState({to_property:i});
            }

        }

    }

    propertyOptions(response){
        let Options = [];
        for(var i = 0;i < response.length;i++ ){
            Options.push({ value: response[i].id, label: response[i].street_address,key:i });
        }
        this.setState(() => ({propertiesOption : Options}));
    }

    propertiesData(name,fieldType,e) {
        var fields = this.state.formData;
        if( fieldType == 'select' ){
            fields[name] = e.value;
            if( name ==  'from_property' ){
                this.setState({'from_property':e.key});
            }else if( name ==  'to_property' ){
                this.setState({'to_property':e.key});
            }
        }else{
            fields[name] = e.target.value;
        }
        this.setState({fields})

    }

    createMileage = async (checkValid) => {
        if( !checkValid ){
            this.validator = new SimpleReactValidator({autoForceUpdate: this});
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        let response = await EditMileage(this.state.formData);
        if(response.data.success){
            showNotification(getErrorMessage(response.data.message), 'success');
        } else {
            showNotification(getErrorMessage(response.data.message), 'warning');
        }
        this.props.navigate('/mileage');
    }



    render(){

        return (
            <>
                <div class="content-wrapper">
                    <div class="page-header">
                        <div class="left-cell">
                            <h3 class="page-title">Edit Mileage</h3>
                        </div>
                        <div class="right-cell">
                            <form action="">
                                <div class="breadcrumb date_picker">
                                <div class="breadcrumb-item active calender" aria-current="page">
                                    <div class="ui calendar" id="example2">
                                        <div class="ui input left icon">
                                            <i class="calendar icon"></i>
                                            <input type="text" placeholder="calendar" />
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="detail-cont">
                        <div class="row">
                            <div class="col-md-12 grid-margin stretch-card flex-column">
                                <div class="card mb-4">
                                    <div class="card-body pt-0">
                                        <form class="forms-sample"  >
                                            <div class="row">
                                                <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputPriority">Trip Date</label>
                                                    <div class="input-group input-group-icon mb-3">

                                                        <input type="date" class="form-control poppins-font" aria-label="Recipient's username"
                                                            aria-describedby="basic-addon2" name="trip_date" value={this.state.formData.trip_date || ''}  onChange={this.propertiesData.bind(this,'trip_date','input')} />
                                                        <div class="input-group-append p-0">
                                                            <span class="input-group-text" id="basic-addon2"><i
                                                                class="mdi mdi-calendar"></i></span>
                                                        </div>
                                                    </div>
                                                    {this.validator.message('trip_date',this.state.formData.trip_date, 'required')}
                                                </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputPurpose">Business Name </label>
                                                        <input type="text" class="form-control" id="exampleInputPurpose"
                                                            placeholder="" value={this.state.formData.business_name || ''} name="business_name" onChange={this.propertiesData.bind(this,'business_name','input')} />

                                                    </div>
                                                    {this.validator.message('business_name',this.state.formData.business_name, 'required')}
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputPriority">From Property </label>
                                                        <Select classNamePrefix="select" className="poppins-font basic-single" name="from_property" value={this.state.propertiesOption[this.state.from_property]}  options={this.state.propertiesOption} onChange={this.propertiesData.bind(this,'from_property','select')}
                                                                />
                                                    </div>
                                                    {this.validator.message('from_property',this.state.formData.from_property, 'required')}
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputPriority">To Property </label>
                                                            <Select classNamePrefix="select" className="poppins-font basic-single" name="to_property" value={this.state.propertiesOption[this.state.to_property]}  options={this.state.propertiesOption} onChange={this.propertiesData.bind(this,'to_property','select')}
                                                                />
                                                    </div>
                                                    {this.validator.message('to_property',this.state.formData.to_property, 'required')}
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputPurpose">Purpose </label>
                                                        <input type="text" class="form-control" id="exampleInputPurpose"
                                                            placeholder="" name="purpose" value={this.state.formData.purpose || ''}  onChange={this.propertiesData.bind(this,'purpose','input')} />
                                                    </div>
                                                    {this.validator.message('purpose',this.state.formData.purpose, 'required')}
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputMiles">Miles </label>
                                                        <input type="text" class="form-control poppins-font" id="exampleInputMiles"
                                                            placeholder="13.62" name="miles" value={this.state.formData.miles || ''}  onChange={this.propertiesData.bind(this,'miles','input')} />
                                                    </div>
                                                    {this.validator.message('miles',this.state.formData.miles, 'required|numeric')}
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputAmount">Amount </label>
                                                        <input type="text" class="form-control poppins-font" id="exampleInputAmount"
                                                            placeholder="$0.00" name="amount" value={this.state.formData.amount || ''}  onChange={this.propertiesData.bind(this,'amount','input')} />
                                                    </div>
                                                    {this.validator.message('amount',this.state.formData.amount, 'required|numeric')}
                                                </div>
                                            </div>


                                        </form>
                                    </div>
                                </div>
                                <div class="card  mb-4">
                                    <div class="card-body p-0">
                                        <div class="d-flex justify-content-between">
                                            <Link to={'/mileage'} className="btn btn-outline-dark" >Cancal</Link>
                                            <button type="button" onClick={this.editMileage} class="btn bg-orange btn-cus mr-2 text-white">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withRouter(Edit);

