

import { useEffect, useState } from 'react';
import { getDocs, query, collectionGroup, db,collection,where } from '../../../firebase';
import { withRouter } from '../../../helpers/withRouter';
import { getDatabase, ref, child, get,onValue } from "firebase/database";
import ComponentLoader from '../../../common/ComponentLoader';
import { capitalize, timeSince } from '../../../helpers/Utils';

const NotificationView = (props) => {

    const [isLoad,setIsLoad] = useState(0);
    const [details,setDetails] = useState({});

    useEffect(() => {
        getNotificationDetail();
    },[props])

    const getNotificationDetail = async () => {
        setIsLoad(1)
        let userid = localStorage.getItem('firebase_uid');
        var querySnapshotAdmin = await getDocs(query(collection(db, "users"),where("uid", "==", props.params.userid)));
        let data = []
        var fbdata = await getDocs(query(collection(db, "notification",userid,'list')));
        fbdata.forEach((snapshot) => {
            if( snapshot.id == props.params.id ){
                var snapData = snapshot.data();
                var snapTimestamp = snapshot.data().timestamp.toDate()
                snapData.timestamp = timeSince(snapTimestamp)
                snapData.sendBy = capitalize(querySnapshotAdmin.docs[0].data().displayName)
                data.push(snapData)
            }
        });
        setDetails(data);
        setIsLoad(0)
    }

    return(
        <>
        <div className="content-wrapper ">

            <div className="page-header">
                <div className="left-cell">
                    <h3 className="page-title">Notificaton</h3>
                    <nav aria-label="breadcrumb">
                    </nav>
                </div>
            </div>
            <div className="tenants-list-view mt-4 mb-5">
                <div className="inner-tenants-list-view">
                    <div className="row">
                        <div className="col-xl-12" >
                            <div className="card-body">
                                <div className="col-lg-12 grid-margin stretch-card activity-feed-col ">
                                    <div className="card position-relative p-0">
                                        {
                                            isLoad?<ComponentLoader />:''
                                        }
                                        <div className='p-30'>
                                            <div className="card-header d-flex align-items-start">
                                                <h3 className="d-inline-block align-middle">Notification</h3>
                                            </div>
                                            <div className="card-body ">
                                                <h3>{ ( typeof details[0] && typeof details[0]?.title !== 'undefined')?details[0]?.title:'' }</h3>
                                                <p>{ ( typeof details[0] && typeof details[0]?.body !== 'undefined')?details[0]?.body:'' }</p>
                                                <p> { ( typeof details[0] && typeof details[0]?.sendBy !== 'undefined')? 'Send By ' + details[0]?.sendBy:'' }</p>
                                                <p>{ ( typeof details[0] && typeof details[0]?.timestamp !== 'undefined')?details[0]?.timestamp:'' }</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default withRouter(NotificationView);
