import React from "react";
import Loader from "../../../common/Misc/Loader";
import SimpleReactValidator from 'simple-react-validator';
import AdditionalOccupant from "./AdditionalOccupant";
import AdditionalPet from "./AdditionalPet";
import AdditionalVehicle from "./Additionalvehicle";
class Step2 extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            has_additional_occupant : true,
            has_pet : true,
            has_vehicle : true,
            additionalOccupantArray : [],
            additionalPetArray : [],
            additionalVehicleArray : [],
        };
        
        this.validator = new SimpleReactValidator();
        this.submitForm =async()=> {
            let occupants = [];
            let errorStatus = false;
            let vm = this;

            if(this.state.has_additional_occupant){
                this.state.additionalOccupantArray.map( async (row) => {
                    let data = await this[row.refInst].validateForm();
                    if(data.status)
                        occupants.push({ ...row, data:data.data});
                    else{
                        errorStatus = true;
                        return;
                    }
                });
            }
            
            let petInformation = [];
            if(this.state.has_pet){
                this.state.additionalPetArray.map( async (row) => {
                    let data = await this[row.refInst].validateForm();
                    if(data.status)
                        petInformation.push({ ...row, data:data.data});
                    else{
                        errorStatus = true;
                        return;
                    }
                });
            }

            let vehicleInformation = [];
            if(this.state.has_vehicle){
                this.state.additionalVehicleArray.map( async (row, index) => {
                    let data = await this[row.refInst].validateForm();
                    if(data.status)
                        vehicleInformation.push({ ...row, data:data.data});
                        // if(this.state.additionalVehicleArray.length == (parseInt(index)+1))
                    else{
                        errorStatus = true;
                        return;
                    }
                });
            }


            setTimeout(function(){
                vm.moveToNextStep(errorStatus, { additionalOccupantArray : occupants,additionalPetArray :petInformation,additionalVehicleArray : vehicleInformation});
            },1500);
        }

       
    }
    moveToNextStep = async(errorStatus, request)=>{
        if(!errorStatus){
            this.props.componentData('step2Data', request);
            this.props.changeStepVar(3);
        }
    } 
    setValue = (e, field) => {
        this.setState({[field] : e.target.value})
    }


    //Add Multiple Occupants
    addOccupant = () => {
        let allOccuPants = this.state.additionalOccupantArray;
        let last_index = 0;
        allOccuPants.map((row) => ( last_index = row.index ));
        let new_index = ++last_index;
        allOccuPants.push({ index : new_index , refInst : 'ref' + new_index , data :[]});
        this.setState({ ...this.state,
            additionalOccupantArray : allOccuPants
        });
    }

    getAdditionalForm = () => {
        return this.state.additionalOccupantArray.map((row, index) => {
            return <AdditionalOccupant ref={instance => {this[row.refInst] = instance }} occupantIndex={index} deleteAdditionalOccupant={this.deleteAdditionalOccupant} oldData = {row.data}/> ;
        });
    }

    deleteAdditionalOccupant = (index) => {
        let allOccuPants = this.state.additionalOccupantArray;
        allOccuPants.splice(index, 1);
        this.setState({additionalOccupantArray : allOccuPants})
    }
    //end multiple occupants

    //Add multiple pets
    addPet = () => {
        let allPets = this.state.additionalPetArray;
        let last_index = 0;
        allPets.map((row) => ( last_index = row.index ));
        let new_index = ++last_index;
        allPets.push({ index : new_index , refInst : 'refPet' + new_index, data :[] });
        this.setState({ ...this.state,
            additionalPetArray : allPets
        });

    }
    getAdditionalPetForm = () => {
        return this.state.additionalPetArray.map((row, index) => {
            return <AdditionalPet ref={instancee => {this[row.refInst] = instancee }} petIndex={index} deleteAdditionalPet={this.deleteAdditionalPet} oldData = {row.data}/> ;
        });
    }

    deleteAdditionalPet = (index) => {
        let allPets = this.state.additionalPetArray;
        allPets.splice(index, 1);
        this.setState({additionalPetArray : allPets})
    }
    //end multiple pets

    //start multiple vehicle 
    addVehicle = () => {
        let allVehicles = this.state.additionalVehicleArray;
        let last_index = 0;
        allVehicles.map((row) => ( last_index = row.index ));
        let new_index = ++last_index;
        allVehicles.push({ index : new_index , refInst : 'refVehicle' + new_index, data :[] });
        this.setState({ ...this.state,
            additionalVehicleArray : allVehicles
        });
    }

    getAdditionalVehicleForm = () => {
        return this.state.additionalVehicleArray.map((row, index) => {
            return <AdditionalVehicle ref={instancee => {this[row.refInst] = instancee }} vehicleIndex={index} deleteAdditionalVehicle={this.deleteAdditionalVehicle} oldData = {row.data}/> ;
        });
    }

    deleteAdditionalVehicle = (index) => {
        let allVehicles = this.state.additionalVehicleArray;
        allVehicles.splice(index, 1);
        this.setState({additionalVehicleArray : allVehicles})
    }

      //end multiple vehicle

    componentDidMount = async() => {
        if(this.props?.oldData){
            this.setState({
                additionalOccupantArray :this.props.oldData.additionalOccupantArray,
                additionalPetArray :this.props.oldData.additionalPetArray,
                additionalVehicleArray :this.props.oldData.additionalVehicleArray
            });

            if(this.props.oldData.additionalOccupantArray.length > 0){
                this.setState({
                    has_additional_occupant : true
                });
            }
            if(this.props.oldData.additionalPetArray.length > 0){
                this.setState({
                    has_pet : true
                });                
            }
            if(this.props.oldData.additionalVehicleArray.length > 0){
                this.setState({
                    has_vehicle : true
                });
            }
            this.getAdditionalForm();
            this.getAdditionalPetForm();
            this.getAdditionalVehicleForm();
        }
    }

    updateAdditionalFields = (e, variable) => {
        this.setState({
            [ variable ] : e.target.checked 
        })
    }

    render(){
        return (
            <>
                <div className="row application-step-two">
                    <div className="col-md-12 grid-margin stretch-card flex-column">
                        <div className="card mb-4">
                            <div className="card-body pt-0">
                                <div className="mb-4">
                                    <h4 className="heading-h4 font-normal">Additional Occupants</h4>
                                    <p>Please provide the information about the additional occupants below. If you want to apply jointly, please enable the toggle and the co-applicants will receive the link to apply</p>
                                </div>
                                <div className="ao-switch">
                                    <section className="mb-0" id="model-53">
                                    <div className="card-toggle-yes">
                                        <div className="toggle-switch">
                                            <input type="checkbox" id="check-53"  defaultChecked checked = {this.state.has_additional_occupant ? true : false } onChange={(e) => { this.updateAdditionalFields(e, 'has_additional_occupant')}}/>
                                            <label htmlFor="check-53"></label>
                                        </div>
                                    </div>
                                    </section>
                                    <p>Do you have any additional occupants that will live with you?</p>
                                </div>

                                {
                                    this.state.has_additional_occupant ? (
                                        <form className="forms-sample mt-4">
                                            <div className="ai-Occupants-info-outer">
                                            { 
                                                
                                                this.state?.additionalOccupantArray ? this.getAdditionalForm() : ''
                                            }
                                            <hr>
                                            </hr>
                                            </div>
                                            <button className="btn btn-link btn-link-lg p-0" type="button" onClick={()=>{this.addOccupant()}}> <i className="mdi mdi-plus-box"></i> <span>Add an
                                            occupant</span> </button>
                                        </form>
                                    ) : ""
                                }
                                
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-body pt-0">
                                <div className="mb-4">
                                    <h4 className="heading-h4 font-normal">Pet Information</h4>
                                    <p>Please provide the information about the pets below.</p>
                                </div>
                                <div className="ao-switch">
                                    <section className="mb-0" id="model-53">
                                    <div className="card-toggle-yes">
                                        <div className="toggle-switch">
                                            <input type="checkbox" id="check-53" defaultChecked checked = {this.state.has_pet ? true : false } onChange={(e) => { this.updateAdditionalFields(e, 'has_pet')}}/>
                                            <label htmlFor="check-53"></label>
                                        </div>
                                    </div>
                                    </section>
                                    <p>Do you have any pets that will live with you?</p>
                                </div>
                                {
                                    this.state.has_pet ? (
                                        <form className="forms-sample mt-4">
                                            <div className="ai-pet-info-outer">                                   
                                            {
                                                this.getAdditionalPetForm()
                                            }                                  
                                            <hr>
                                            </hr>
                                            </div>
                                            <button className="btn btn-link btn-link-lg p-0" type="button" onClick={()=>{this.addPet()}}> <i className="mdi mdi-plus-box"></i> <span>Add an
                                            pet</span> </button>
                                        </form>

                                    ) : ""
                                }
                                
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-body pt-0">
                                <div className="mb-4">
                                    <h4 className="heading-h4 font-normal">Vehicle Information</h4>
                                    <p>Please provide the information about the vehicles below.</p>
                                </div>
                                <div className="ao-switch">
                                    <section className="mb-0" id="model-53">
                                    <div className="card-toggle-yes">
                                        <div className="toggle-switch">
                                            <input type="checkbox" id="check-53" onChange={(e) => { this.updateAdditionalFields(e, 'has_vehicle')}}/>
                                            <label htmlFor="check-53"></label>
                                        </div>
                                    </div>
                                    </section>
                                    <p>Do you have any vehicles that will live with you?</p>
                                </div>
                                {
                                    this.state.has_vehicle ? (
                                        <form className="forms-sample  mt-4">
                                            <div className="ai-vehicles-info-outer">
                                            {
                                                this.getAdditionalVehicleForm()
                                            } 
                                            <hr>
                                            </hr>
                                            </div>
                                            <button className="btn btn-link btn-link-lg p-0" type="button" onClick={()=>{this.addVehicle()}}> <i className="mdi mdi-plus-box"></i> <span>Add an
                                            vehicle</span> </button>
                                        </form>
                                    ) : ""
                                }
                            </div>
                        </div>
                        <div className="card  mb-4">
                        <div className="card-body p-0">
                            <div className="d-flex justify-content-between">
                                <button type="button" className="btn btn-dark" onClick={(e) => this.props?.changeStepVar(1)}><i className="mdi mdi-arrow-left"></i> Back</button>
                                <button className="btn bg-orange btn-cus mr-2 text-white" disabled={ this.state.requestInprogress}  onClick={this.submitForm}>{ this.state.requestInprogress ? (<Loader className="btn theme-btn-orange" msg="Please Wait..." />) : 'Next' }<i className="mdi mdi-arrow-right"></i></button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default Step2;