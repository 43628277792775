import React from "react";
import {getAllActivityLog} from "../../../Services/Miscellaneous";
class ActivityLog extends React.Component{
    constructor(props){
        super(props);
        this.state = {
             allActivityLog : [],
        };
    }

    componentDidMount = async() => {
        await this.fetchActivityLog();        
    }

    fetchActivityLog = async () => {
        let allActivityRes = await getAllActivityLog();
        this.setState({
            allActivityLog : allActivityRes.data.data
        });
    }

    render(){
        return(
            <> 
            <div className="content-wrapper">
                <div className="page-header">
                <div className="left-cell">
                    <h3 className="page-title">Activity log</h3>
                    <nav aria-label="breadcrumb">
                    </nav>
                </div>
                </div>
                <div className="page-header-two">
                <div className="inner-page-header-two">
                    <div className="cell-row">
                        <div className="left-cell">
                            <h4>
                                <span>Total :</span>67
                            </h4>
                        </div>
                        <div className="right-cell">
                            <div className="inner-right-cell">
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="tenants-list-view mt-4 mb-5">
                    <div className="inner-tenants-list-view">         
                        <div className="row">
                            <div className="col-xl-12">        
                                <div className="card-body p-0">
                                {/* Activity log Start */}
                                <div className="col-lg-12 grid-margin stretch-card activity-feed-col ">
                                    <div className="card">
                                        <div className="card-header d-flex align-items-start">
                                            <h3 className="d-inline-block align-middle">Activity Feed</h3>
                                            <button type="button" className="btn btn-outline-danger btn-sm ml-auto">+ Add
                                            Task</button>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive pb-3">
                                            <table className="table table-borderless">
                                                <tbody>
                                                {
                                                    this.state.allActivityLog?.length > 0 ? (
                                                    this.state.allActivityLog?.map((row)=>{
                                                    return (
                                                        <tr>
                                                        <td>
                                                        <img src="assets/admin/images/faces/face1.jpg" className="me-2"  alt="pic"/>
                                                        <span className="activity_content">
                                                        <span className="sm_text">1 min ago</span> <br></br>
                                                        <span className="lg_text">{ row.comment }</span>
                                                        </span>
                                                        </td>
                                                        </tr>
                                                        )
                                                    })                                  
                                                       ) : <span className="no-data-found">No Activity found</span>
                                                    }
                                                </tbody>
                                            </table>
                                            </div>
                                            {/*  <a href="/#" className="danger-text"> - More Feed</a> */}
                                        </div>
                                    </div>
                                </div>
                                {/* Activity log End */}
                                </div>
                            </div>
                        </div>                                         
                    </div>
                </div>        
            </div>              
            </>
        )
    }
}
export default ActivityLog;