import React, { useEffect, useState } from "react";
import { collection, query, where,doc, setDoc,onSnapshot,orderBy,addDoc,getDoc,collectionGroup,getDocs } from "firebase/firestore";
import { auth,db } from '../../../firebase';
import { timeSince } from "../../../helpers/Utils";
import { capitalize } from "lodash";

const Userlist = (props) => {
  const [data, setData] = useState({});
  const [row, setRow] = useState(props.details);
  const [countMsg, setCountMsg] = useState(0);
  useEffect(() => {
    setRow(props.details);
    var id = props.adminUid > props.usersId?props.adminUid + props.usersId:props.usersId + props.adminUid
    let unsub = onSnapshot(doc(db,'lastMsg',id),( doc ) => {
        let lastMsg = doc.data();
        if( typeof lastMsg != 'undefined' && 'timestamp' in lastMsg ){
            let msgdate = doc.data().timestamp.toDate();
            let shortmsg = doc.data().messages;
            if( shortmsg.length > 15 ){
                shortmsg.substr(0,15);
            }
            lastMsg.shortmsg = shortmsg;
            lastMsg.timeage = timeSince(msgdate);
            lastMsg.uid = doc.id;
            setData(lastMsg);
        }
    })

    let unsubChat = onSnapshot(query(collectionGroup(db, 'chat'),where('join_id','==',id),orderBy('timestamp','desc')),(querySnapshotChild) => {
            var countMsg = 0;
            var i = 0;
            querySnapshotChild.forEach((snapshot) => {
                if( snapshot.exists() && i == 0 ){
                        let lastMsg = snapshot.data();
                        let msgdate = snapshot.data().timestamp.toDate();
                        let shortmsg = snapshot.data().messages;
                        if( shortmsg.length > 15 ){
                            shortmsg = shortmsg.substr(0,15)+'....';
                        }
                        lastMsg.shortmsg = shortmsg;
                        lastMsg.timeage = timeSince(msgdate);
                        lastMsg.uid = snapshot.id;
                        setData(lastMsg) ;
                    i++;
                }
                if( snapshot.data().unread ){
                    countMsg++;
                }
            })
            setCountMsg(countMsg);
        })

    return () => [unsub(),unsubChat() ];
  }, [props]);

    const handleSelectedUser = (event) => {
        props.handleUserActiveCallback(event.target.getAttribute('selectedname'),
            event.target.getAttribute('selectedid'),event.target.getAttribute('selectnickname'))
    }
    return (
    <>
        <li className={((props.selectChat == row?.uid)?' chat-unread chat-active cp':' chat-unread cp' )} >
            <a  onClick={handleSelectedUser} selectedid={row?.uid} selectedname={capitalize(row?.details.displayName)} selectnickname = {row?.nickname}  ></a>
            <div className="d-flex justify-content-between p-2 border-bottom">
                <div className="d-flex flex-row">
                    <div className="avatar-img avatar-online me-3">
                        {row.nickname}
                    </div>
                    <div className="pt-1 avatar-name">
                        <p className="fw-bold mb-0">{typeof  row.details.displayName != 'undefined'? capitalize(row.details.displayName) :''} <span className="small text-muted mb-1" >{capitalize(row.details.type)}</span> </p>
                        {
                            (typeof data.images != 'undefined' && data.images != '')?
                                <p className="small text-muted"><i class="mdi mdi-image"></i>{data.shortmsg != '' ? data.shortmsg:' Files'}</p>
                            :
                            <p className="small text-muted">{data.shortmsg}</p>
                        }

                    </div>
                </div>
                <div className="pt-1 avatar-time">
                <p className="small text-muted mb-1">{data.timeage}</p>
                {countMsg && data.sender_id != props.adminUid ?<span className="badge bg-success poppins-font rounded-pill float-end">{countMsg}</span>:''}

                </div>
            </div>
        </li>
    </>
  );
};

export default Userlist;
