import React from "react";
import {getAllCountry } from "../../../Services/Miscellaneous";
import SimpleReactValidator from 'simple-react-validator';
class AdditionalRentalHistory extends React.Component{
      constructor(props){
          super(props);
          this.state = {
            move_in_date: '',
            move_out_date: '',
            current_residence: '',
            street_address: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            unit: '',
            monthly_rent: '',
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '', 
            count : 1,
            no_landlord : false,
          };

        this.validator = new SimpleReactValidator();
        this.validateForm =async()=> {
            // this.props.changeStepVar(3);
            if(this.validator.allValid()) {
                console.log('Valid', this.state);
                return {status : true, data : this.state};                
            }else{
                this.validator.showMessages();
                this.forceUpdate();
                console.log('inValid', this.state);
                return {status : false, data : []};
            }        
        }
      }

    setValue = (e, field) => {
        this.setState({[field] : e.target.value})
    }

    componentDidMount = async() => {
        let resCountry = await getAllCountry();
        this.setState({all_countries: resCountry.data.data});
        //console.log(this.props   , 'sdf')
        if(this.props.rentalIndex){
            this.setState({count : this.props.rentalIndex + 1});
        }

        if(this.props?.oldData){
            this.setState({
                ...this.props.oldData
            })
        }

    }

    noLandlord = (e, variable) => {
        this.setState(
            {
                [variable] : e.target.checked
            }
        )
    }

      render(){
        return(
            <>
            <div className="ai-residence-info">
                <h5 className="as-tittle-h5">Residence { this.state.count } <span onClick={()=>this.props.deleteAdditionalRentalHistory(this.props.rentalIndex)}>Delete</span> </h5>
                <div className="row row-theme">
                    <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputName">Move in date </label>
                        <input type="date" name="move_in_date" className="form-control" id="exampleInputName" placeholder="" value = {this.state.move_in_date} onChange={(e)=>this.setValue(e,'move_in_date')}/>
                    </div>
                    {this.validator.message('move_in_date', this.state.move_in_date, 'required')}
                    </div>
                    <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputName">Move out date </label>
                        <input type="date" name="move_out_date" className="form-control" id="exampleInputMiddleName" placeholder="" value = {this.state.move_out_date} onChange={(e)=>this.setValue(e,'move_out_date')}/>
                    </div>
                    {this.validator.message('move_out_date', this.state.move_out_date, 'required')}
                    </div>
                    <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputPriority">Current residence? </label>
                        <section id="model-53">
                            <div className="card-toggle-yes">
                                <div className="toggle-switch">
                                <input type="checkbox" name="current_residence" id="check-53" onChange={(e)=>this.setValue(e,'current_residence')}/>
                                <label htmlFor="check-53"></label>
                                </div>
                            </div>
                        </section>
                    </div>
                    {this.validator.message('current_residence', this.state.current_residence, 'required')}
                    </div>
                    <div className="col-md-8">
                    <div className="form-group">
                        <label htmlFor="exampleInputName">Street address* </label>
                        <textarea className="form-control ta-form-control" name="street_address" value = {this.state.street_address} onChange={(e)=>this.setValue(e,'street_address')}></textarea>
                    </div>
                    {this.validator.message('street_address', this.state.street_address, 'required')}
                    </div>
                    <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputCity">City* </label>
                        <input type="text" name="city" className="form-control" id="exampleInputCity" placeholder="" value = {this.state.city} onChange={(e)=>this.setValue(e,'city')}/>
                    </div>
                    {this.validator.message('city', this.state.city, 'required')}
                    </div>
                    <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputCity">State / region* </label>
                        <input type="text" name="state" className="form-control" id="exampleInputCity" placeholder="" value = {this.state.state} onChange={(e)=>this.setValue(e,'state')}/>
                    </div>
                    {this.validator.message('state', this.state.state, 'required')}
                    </div>
                    <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputZip">Zip* </label>
                        <input type="text" name="zip" className="form-control" id="exampleInputZip"  placeholder="" value = {this.state.zip} onChange={(e)=>this.setValue(e,'zip')}/>
                    </div>
                    {this.validator.message('zip', this.state.zip, 'required')}
                    </div>
                    <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputName">Country* </label>
                        <select  className="form-control tex-select select_btn" name="country" id="exampleInputName" value = {this.state.country} onChange={(e)=>this.setValue(e,'country')}>
                        <option className="unselect">Enter country</option>   
                            {
                                this.state.all_countries?.map((option) => (
                                    <option value={option.country_code} key={option.country_code}>{option.country_name}</option>
                            ))
                            }    
                        </select>
                    </div>
                    {this.validator.message('country', this.state.country, 'required')}
                    </div>
                    <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputUnit">Unit / Ap. # </label>
                        <input type="text" name="unit" className="form-control" id="exampleInputUnit" placeholder="" value = {this.state.unit} onChange={(e)=>this.setValue(e,'unit')}/>
                    </div>
                    {this.validator.message('unit', this.state.unit, 'required')}
                    </div>
                    <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputMonthlyRrent">Monthly rent </label>
                        <input type="number" name="monthly_rent" className="form-control" id="exampleInputMonthlyRrent" placeholder="0.00" value = {this.state.monthly_rent} onChange={(e)=>this.setValue(e,'monthly_rent')}/>
                    </div>
                    {this.validator.message('monthly_rent', this.state.monthly_rent, 'required')}
                    </div>
                    <div className="col-md-12">
                    <div className="form-group">
                        <div className="form-check form-check-flat form-check-primary">
                            <label className="form-check-label">
                            <input type="checkbox" className="form-check-input" onChange={(e) => {this.noLandlord(e, 'no_landlord')}}/>landlord<i className="input-helper"></i></label>
                        </div>
                    </div>
                    </div>
                </div>
                {
                    this.state.no_landlord ? (
                    <div className="row row-theme">
                        <div className="col-md-12">
                        <h5 className="as-tittle-h5">Landlord / Property Manager</h5>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="exampleInputName">First Name* </label>
                            <input type="text" name="first_name" className="form-control" id="exampleInputName" placeholder="" value = {this.state.first_name} onChange={(e)=>this.setValue(e,'first_name')}/>
                        </div>
                        {this.validator.message('first_name', this.state.first_name, 'required')}
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="exampleInputName">Last Name* </label>
                            <input type="text" name="last_name" className="form-control" id="exampleInputLastName" placeholder="" value = {this.state.last_name} onChange={(e)=>this.setValue(e,'last_name')}/>
                        </div>
                        {this.validator.message('last_name', this.state.last_name, 'required')}
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="exampleInputName">Email* </label>
                            <input type="email" name="email" className="form-control"  id="exampleInputName" placeholder="" value = {this.state.email} onChange={(e)=>this.setValue(e,'email')}/>
                        </div>
                        {this.validator.message('email', this.state.email, 'required')}
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="exampleInputName">Phone Number* </label>
                            <input type="email" name= "phone_number" className="form-control poppins-font" id="exampleInputName" placeholder="" value = {this.state.phone_number} onChange={(e)=>this.setValue(e,'phone_number')}/>
                        </div>
                        {this.validator.message('phone_number', this.state.phone_number, 'required')}
                        </div>
                        <div className="col-md-12">
                        <hr></hr>
                        </div>
                    </div>
                    ) : ""
                }
            </div>         
            </>
        )
      }
}

export default AdditionalRentalHistory;