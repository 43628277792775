import React from 'react';
import { Link} from 'react-router-dom';
import MessageNotifications from '../components/TopNav/MessageNotifications';
import MessageNotificationsEmpty from '../components/TopNav/MessageNotificationsEmpty';
import Notifications from '../components/TopNav/Notifications';
import { db } from '../firebase';
import { collection, query, where, getDocs,doc, onSnapshot,updateDoc, collectionGroup } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import Dropdown from 'react-bootstrap/Dropdown';
import { SearchList } from './Services';
import { connect } from 'react-redux';

class TopNav extends React.Component {
    constructor(props){
        super(props);
           this.state = {
            toggleProfile : false,
            toggleNotifications : false,
            toggleMessages : false,
            user_name : '',
            initial : '',
            imagePath : '',
            countMsg:0,
            countNotific:0,
            searchKeyword:'',
            searchResults:{},
            searchIconBar:"mdi mdi-magnify",
            isLoaded:false
        };

    }
    toggleProfile = () => {
        this.hideAll();
        this.setState({toggleProfile : !this.state.toggleProfile});
    }
    toggleNotifications = async () => {
        this.hideAll();
        this.setState({toggleNotifications : !this.state.toggleNotifications});
        var userId = localStorage.getItem('firebase_uid');
        if( this.state.toggleNotifications ){
            const listByUser = query(collectionGroup(db, 'list'), where('resiver_id', '==', userId));
            const updateUnread = await getDocs(listByUser);
            const myDb = getDatabase();
            updateUnread.docs.forEach(snapshot => {
                const washingtonRef = doc(db,snapshot.ref.path);
                updateDoc(washingtonRef, {
                    unread: false
                });

            })
        }
    }

    toggleNotificationsToggle = () => {
        this.setState({toggleNotifications : !this.state.toggleNotifications});
    }

    toggleMessages = () => {
        this.hideAll();
        this.setState({toggleMessages : !this.state.toggleMessages});
    }

    hideAll = () => {
        this.setState({toggleProfile :false});
        this.setState({toggleNotifications : false});
        this.setState({toggleMessages : false});
    }
    toggleSideNav = () => {
        var  body = document.body;
        body.classList.toggle("sidebar-icon-only");
    }

    logoutUser = (e) => {
        localStorage.clear();
        window.location.href = '/';
    }
    componentDidMount = async () =>{
        let user_details = localStorage.getItem('userDetalis');
        user_details  = JSON.parse(user_details);
        this.setState({user_name : user_details.first_name + ' ' + user_details.last_name})
        this.setState({initial : user_details.first_name.charAt(0) + ' ' + user_details.last_name.charAt(0)})
        this.setState({imagePath : user_details.image})

        /*  messages count  */
        var Uid = localStorage.getItem('firebase_uid');
        const getIds = [];
        onSnapshot(collection(db, "lastMsg"), (querySnapshotChild) => {
            var countData = 0;
            querySnapshotChild.forEach((doc) => {
                if( doc.data().reciver_id === Uid && doc.data().unread ){
                    countData++;
                }
                this.setState({countMsg:countData});
            })
        });


        /*  notification count  */
        var userId = localStorage.getItem('firebase_uid');
        var msgRef =  collection(db,'notification',userId,'list');
        var q  = query(msgRef,where("unread", "==", true));
        let unsub  = onSnapshot(q,(querySnapshot) => {
            var count  = 0;
            var unread = false;
            querySnapshot.forEach((doc) => {
                    count++;
            })
            this.setState({countNotific:count});
        })

        return () => [unsub()];
    }

   /*  componentWillReceiveProps = (nextProps) => {
        if( !nextProps.UserUidCode.UserUidCode.ShowHideMsgToggle ){
            this.setState({toggleMessages : false});
        }
    } */

    componentDidUpdate = (prevProps,prevState)  => {
        if ( !prevProps.UserUidCode.UserUidCode.ShowHideMsgToggle && (prevState.toggleMessages || prevState.toggleNotifications ) )  {
            this.setState({toggleMessages:false})
            this.setState({toggleNotifications:false})
        }
    }

    handleSearchData = async (e) => {
        let keyword = e.target.value;
        this.setState({searchKeyword:keyword})
        if( keyword.length > 0 ){
            this.setState({searchIconBar:"mdi mdi-window-close"});
        }else{
            this.setState({searchIconBar:"mdi mdi-magnify"});
        }
        if( keyword.length > 0 && keyword.length < 3  ){
            this.setState({searchResults:[]})
        }else if(keyword.length > 2){
            this.setState({isLoaded:true});
            this.setState({searchIconBar:'searchLoading'});
            let response = await SearchList({search:keyword});
            this.setState({isLoaded:false});
            this.setState({searchIconBar:'mdi mdi-window-close'});
            this.setState({searchResults:response.data.data});
        }

    }

    handleEraseSearch = () => {
        if( this.state.searchKeyword.length > 0 ){
            this.setState({searchIconBar:"mdi mdi-magnify"});
            this.setState({searchResults:[]});
            this.setState({searchKeyword:""});
        }
    }

    handleGoToLink = () => {
        this.setState({searchIconBar:"mdi mdi-magnify"});
        this.setState({searchResults:[]});
        this.setState({searchKeyword:""});
    }


    render() {
        return (
            <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <Link className="navbar-brand brand-logo" to="dashboard">
                    <img src="assets/admin/images/dashboard/logo.svg" alt="logo" />
                    </Link>
                    <Link className="navbar-brand brand-logo-mini" to="dashboard">
                    <img src="assets/admin/images/dashboard/dashboard-logo-mini.png" alt="logo"/>
                    </Link>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-stretch">
                    <div className="navbar-toggler navbar-toggler align-self-center hamburger" data-toggle="minimize" onClick={(e)=> { this.toggleSideNav(e)}}> <div className="hamburgerlines">
                        <span className="lines line1"></span>
                        <span className="lines line2"></span>
                        <span className="lines line3"></span>
                    </div>
                    </div>
                    {
                        (JSON.parse(localStorage.getItem('userDetalis')).user_type != 'tenant' )?
                            <div className="search-field d-none d-md-block">
                                <div className="input-group">
                                <input type="text" className="form-control bg-transparent border-0" value={this.state.searchKeyword || ''} placeholder="Search..." onChange={(e) => this.handleSearchData(e)} />
                                <div className="input-group-prepend bg-transparent">
                                    <i className={this.state.searchIconBar + " searchBarIcon" } onClick={(e) => this.handleEraseSearch(e)} ></i>
                                    {/* <img src="assets/admin/images/dashboard/search-icon.png" alt="pic" /> */}
                                </div>
                                </div>
                                {
                                    ( this.state.searchResults != '' && this.state.searchKeyword.length > 0 && this.state.searchKeyword.length < 3 )?
                                        <div className="search-listing text-center">
                                            <ul>
                                                <li>The minimum input is 3 characters</li>
                                            </ul>
                                        </div>
                                    :( this.state.searchKeyword.length > 2 && this.state.searchResults != '' )?
                                        <div className="search-listing">
                                            {
                                                this.state.searchResults.map((row,i) =>
                                                    {

                                                        var keyName = ( typeof Object.keys(row)[0] != 'undefined' ? Object.keys(row)[0]:'');
                                                        return(
                                                            keyName != '' ?
                                                                <>
                                                                <h6>{keyName}</h6>
                                                                <ul key={keyName} >
                                                                    {
                                                                        row[keyName].map((rowChild,j) =>
                                                                            <>
                                                                                <li key={keyName + (++j) + i} >
                                                                                    <Link to={rowChild.link}  onClick={(e) => this.handleGoToLink(e) }>
                                                                                        <p className='title' style={{ frontWeight:700 }}>{rowChild.title}</p>
                                                                                        <p className='desc'>{rowChild.head}</p>
                                                                                    </Link>
                                                                                </li>
                                                                            </>
                                                                        )
                                                                    }

                                                                </ul>
                                                                </>
                                                            :''
                                                        )
                                                    }
                                                )
                                            }
                                        </div>
                                    :( this.state.searchKeyword.length > 2 && this.state.searchResults.length == 0 && !this.state.isLoaded  )?
                                        <div className="search-listing text-center ">
                                            <div className="resultNotFond">
                                                <p><i className='mdi mdi-magnify'></i></p>
                                                <p>No result found. Try again</p>
                                            </div>
                                        </div>
                                    :''


                                }
                            </div>
                            :''
                    }

                    <ul className="navbar-nav navbar-nav-right bsDropDown">
                         <Dropdown as="li" className="nav-item cp" onToggle={(e) =>  this.toggleMessages(e)}  show={this.state.toggleMessages} >
                            <Dropdown.Toggle as="a" variant="success" id="dropdown-basic" className='nav-link count-indicator dropdown-toggle'>
                                <i className="mdi mdi-email-outline"></i>
                                <span className="count-symbol">{this.state.countMsg}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ marginTop:"0 !important",top:"100% !important",width:'400px' }} align="end" >
                                {
                                    (this.state.countMsg > 0)?<MessageNotifications toggleMessages={this.state.toggleMessages} getToggleMessageStatus={(e) => this.getToggleMessageStatus(e)} />:
                                    <MessageNotificationsEmpty toggleMessages={this.state.toggleMessages} />

                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown as="li" className="nav-item cp" align="end" onToggle={(e) =>  this.toggleNotificationsToggle(e)}  show={this.state.toggleNotifications} >
                            <Dropdown.Toggle as="a" variant="success" id="dropdown-basic" className='nav-link count-indicator dropdown-toggle'>
                                <i className="mdi mdi-bell-outline"></i>
                                <span className="count-symbol">{this.state.countNotific}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ marginTop:"0 !important",top:"100% !important",width:'400px' }}  align="end" >
                                <Notifications  toggleNotifications={this.state.toggleNotifications} />
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown as="li" className="nav-item nav-profile" >
                            <Dropdown.Toggle as="a" variant="success" id="dropdown-basic" className='nav-link count-indicator dropdown-toggle'>
                                <div className="nav-profile-img">
                                    {
                                        this.state.imagePath?<img src={process.env.REACT_APP_SITE_URL + this.state.imagePath} alt="pic" /> :this.state.initial
                                    }
                                    <span className="availability-status online"></span>
                                </div>
                                <div className="nav-profile-text">
                                    <p className="mb-1 text-black" >{this.state.user_name}</p>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ marginTop:"0 !important",top:"100% !important" }} >
                            <div className={"navbar-dropdown" + (this.state.toggleProfile ? " show" : '' )} aria-labelledby="profileDropdown">
                                    <span className="dropdown-item" >
                                        <i className="mdi mdi-cached me-2 text-success"></i> <Link to = "profile">Profile </Link></span>
                                    <a className="dropdown-item" >
                                        <i className="mdi mdi-cached me-2 text-success"></i> <Link to = "activity-log">Activity Log </Link></a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" onClick={(e) => {this.logoutUser(e)}}>
                                    <i className="mdi mdi-logout me-2 text-primary"></i> Signout </a>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ul>
                    <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                        <span className="mdi mdi-menu"></span>
                    </button>
                </div>
            </nav>


         );
    }
}

const mapStateToProps = state => ({
    UserUidCode:state.UserUidCode
})

export default connect(mapStateToProps)(TopNav);
