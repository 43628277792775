import {  ActiveChatByUser } from '../constants';
const initialState = {
    ActiveUserDetails:{},
    ShowHideMsgToggle:true
}
const  UserUidCode = (state = initialState,action) =>  {
    switch (action.type) {
        case ActiveChatByUser:
                return {
                    ...state,
                    ActiveUserDetails:action.data,
                    ShowHideMsgToggle:false
                }
        break;
        default:
            return state;
    }
}

export default UserUidCode;
