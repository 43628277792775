import { documentId } from 'firebase/firestore';
import React from 'react';
import {
    auth,
    db,
    collection,
    query,
    onSnapshot,
    orderBy,
    where,
} from "../../firebase";

import { withRouter } from '../../helpers/withRouter';

import {connect} from 'react-redux';
import { ActiveChatUser } from '../../ReduxServices/Action';
import { Link } from 'react-router-dom';


class Notifications extends React.Component {
    constructor(props){
        super(props);
           this.state = {
                userIds:[],
                adminUid:'',
                list:[],
                toggleNotifications:this.props.toggleNotifications
            };
    }

    componentDidMount = () => {
        var userId = localStorage.getItem('firebase_uid');
        var msgRef =  collection(db,'notification',userId,'list');
        var q  = query(msgRef,orderBy('timestamp','desc'));
        onSnapshot(q,(querySnapshot) => {
            var notificationList = [];
            var listIds  = []
            querySnapshot.forEach((doc) => {
                    console.log( doc );
                    let data = doc.data();
                    data.timeSince = this.timeSince(doc.data().timestamp.toDate())
                    data.nId = doc.id
                    listIds.push(doc.id);
                    notificationList.push(data)
            })
            this.setState({list:notificationList})
        })

        //return () => [unsub()];
    }

    timeSince(date){
        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = seconds / 31536000;
        if (interval > 1) {
            return Math.floor(interval) + " years ago";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months ago";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days ago";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " min ago";
        }
        return " just now";
    }

    handleNotificationView = (nId,rId,goto) => {
        this.props.ActiveChatUser({rId})
        if( goto != '' ){
            this.props.navigate(`${goto}`)
        }else{
            this.props.navigate(`/notification-view/${nId}/${rId}`)
        }
    }

    render() {
        return (
            <div className={"dropdown-menu-right navbar-dropdown preview-list"} aria-labelledby="notificationDropdown">
            <h6 className="p-3 mb-0">Notifications</h6>
            {
                this.state.list.length?
                    this.state.list.map((row,i) =>

                        (i < 5)?
                        <div key={i} className="cp" onClick={(e) => this.handleNotificationView(row.nId,row.sender_id,row.goto) } >
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item preview-item" >
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-success">
                                        <img src={row.images} />
                                    </div>
                                </div>
                                <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                                <h6 className="preview-subject font-weight-normal mb-1">{row.title}</h6>
                                <p className="text-gray ellipsis mb-0"> {(row.body.length > 60 )?row.body.substring(0,60):row.body} </p>
                                <p className="text-gray ellipsis mb-0">{row.timeSince}</p>
                                </div>
                            </a>
                        </div>:''

                    )
                :<div key={1} >
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item preview-item">
                        <div className="preview-thumbnail">
                        <div className="preview-icon bg-success">
                            <i className="mdi mdi-calendar"></i>
                        </div>
                        </div>
                        <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                        <p className="text-gray ellipsis mb-0">No Notification</p>
                        </div>
                    </a>
                </div>
            }

            <div className="dropdown-divider"></div>
            <h6 className="p-3 mb-0 text-center">See all notifications</h6>
            </div>
        );
    }
}


const MapDispachToProps = dispatch => ({
    ActiveChatUser:(userIds)  => dispatch(ActiveChatUser(userIds))
});
const mapStateToProps = state => ({
    UserUidCode:state.UserUidCode
})
export default connect(mapStateToProps,MapDispachToProps)(withRouter(Notifications));

