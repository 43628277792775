
import { authenticatedGet, authenticatedPost } from "./core.js"
export const getcontactDetails = async () => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'get-details');
}

export const updateUserGeneralInformation = async(request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'update-users');
}

export const getAllPropertyLocation = async () => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'tenant/all-property');
}

export const getAllPetType = async () => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'tenant/all-pettype');
}

export const getAllVehicleType = async () => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'tenant/all-vehicletype');
}

export const addTenant = async (request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'tenant/add-tenant');
}
export const updateTenant = async (request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'tenant/update-tenant');
}
export const fetchAllTenant = async (request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'tenant/get-landlord-tenant');
}
export const getTenantDetails = async (id) => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'tenant/tenant-details/'+id);
}

export const deleteTenant = async (id) => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'tenant/delete-tenant/'+id);
}

export const deleteFile = async (id) => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'tenant/delete-file/'+id);
}

export const addApplication = async (request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'tenant/application');
}

export const getAllQuestions = async () => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'tenant/all-questions');
}

export const getTenantApplication = async(page) => {
    return await authenticatedGet( process.env.REACT_APP_BASE_URL+'tenant/get-all-application?page='+page);
}

export const signnowCreateUser = async (request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'signnow/create-user');
}

export const deleteApplicationRequest = async(id) => {
    return await authenticatedGet( process.env.REACT_APP_BASE_URL+'tenant/delete-application-request?id='+id);
}

export const getAllTenantTransactions = async(request) => {
    return await authenticatedPost( request, process.env.REACT_APP_BASE_URL+'tenant/all-transactions');
}

export const getDashboardData = async() =>{
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'tenant/dashboard');
}
