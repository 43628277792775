import React from "react";
import SimpleReactValidator from 'simple-react-validator';

class EmergencyContact extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: "",
            phone_number: '',
            relationship: '',
            details: '',
            count : 1
        };
        this.validator = new SimpleReactValidator();
        this.validateForm =async()=> {
            if (this.validator.allValid()) {
                return { status : true, data : this.state };  
            } else {
            this.validator.showMessages();
            this.forceUpdate();  
            return { status : false, data : []};  
             }
        } 
    }

    setValue = (e, field) => {
        this.setState({[field] : e.target.value})
    }

    componentDidMount = async() => {
        //console.log(this.props   , 'sdf')
        if(this.props.emergencyContactIndex){
            this.setState({count : this.props.emergencyContactIndex + 1});
        }

        if(this.props?.oldData){
            this.setState({
                ...this.props.oldData
            })
        }
    }

    render(){
        return(
            <> 
                <div className="ai-residence-info-outer">
                    <div className="ai-residence-info">
                        <h5 className="as-tittle-h5">Emergency contact {this.state.count}<span onClick={()=>this.props.deleteAdditionalEmergencyContact(this.props.emergencyContactIndex)}>Delete</span></h5>
                        <div className="row row-theme">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="exampleInputName">First Name* </label>
                                    <input type="text" name="first_name" className="form-control" id="exampleInputName" placeholder="Enter first name" value={this.state.first_name} onChange={(e)=>this.setValue(e,'first_name')}/>
                                </div>
                                {this.validator.message('first_name', this.state.first_name, 'required')}
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="exampleInputName">Last Name* </label>
                                    <input type="text" name="last_name" className="form-control" id="exampleInputLastName" placeholder="Enter last name" value={this.state.last_name} onChange={(e)=>this.setValue(e,'last_name')}/>
                                </div>
                                {this.validator.message('last_name', this.state.last_name, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="exampleInputName">Email* </label>
                                    <input type="email" name="email" className="form-control" id="exampleInputName" placeholder="enter email" value={this.state.email} onChange={(e)=>this.setValue(e,'email')}/>
                                </div>
                                {this.validator.message('email', this.state.email, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="exampleInputName">Phone Number* </label>
                                    <input type="tel" name= "phone_number" className="form-control poppins-font" id="exampleInputName" placeholder="enter phone number" value={this.state.phone_number} onChange={(e)=>this.setValue(e,'phone_number')}/>
                                </div>
                                {this.validator.message('phone_number', this.state.phone_number, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="exampleInputRelationship">Relationship </label>
                                    <input type="text" name="relationship" className="form-control" id="exampleInputRelationship" placeholder="enter relationship" value={this.state.relationship} onChange={(e)=>this.setValue(e,'relationship')}/>
                                </div>
                                {this.validator.message('relationship', this.state.relationship, 'required')}
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <h5 className="as-tittle-h5">Details</h5>
                                    <textarea className="form-control" name="details" value={this.state.details} onChange={(e)=>this.setValue(e,'details')}></textarea>
                                </div>
                                {this.validator.message('details', this.state.details, 'required')}
                            </div>
                        </div>
                        <div className="row row-theme">
                            <div className="col-md-12">
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default EmergencyContact; 