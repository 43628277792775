
import { authenticatedGet, authenticatedPost } from "./core.js"


export const addTranscation = async (request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'add-transaction');
}


export const getAllTransactionList = async (request) => {
    return await authenticatedPost(request,process.env.REACT_APP_BASE_URL+'transaction-list');
}

export const allTenantByUserId = async (request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'tenant/tenants-by-user-id');
}

export const getTransactionById = async (id) => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'transaction-by-id/'+id);
}

export const updateTransaction = async (request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'edit-transaction');
}

export const deleteTransaction = async (id) => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'delete-transaction/'+id);
}

export const transactionFilter = async (request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'transaction-filter');
}

export const netCashFlow = async (id) => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'net-cash-flow');
}

export const processTransaction = async (id) => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'process-transaction/'+id);
}



