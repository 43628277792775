import { useEffect, useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";

import { SendList, Important } from "./Services";

import ComponentLoader from "../../../common/ComponentLoader";
import Paginate from "../Comman/Paginate";
import { Link, useNavigate } from "react-router-dom";

const Send = (props) => {

    const navigate = useNavigate();

    const capitalize = (s) => {
        return s[0].toUpperCase() + s.slice(1);
    };

    const [composeList, setComposeList] = useState([]);
    const [limitData, setLimitData] = useState({
        search: "",
        page: 0,
        limit: 15,
    });
    const [total, setTotal] = useState(0);
    const [isLoad, setIsLoad] = useState(false);

    useEffect(() => {
        getComposeList();
        localStorage.setItem("emailLinkActive", "/email/send");
    }, [limitData]);

    const getComposeList = async () => {
        setIsLoad(true);
        let response = await SendList(limitData);
        setComposeList(response.data.data.data);
        setTotal(response.data.data.total);
        setIsLoad(false);
    };

    const handlePageCallback = async (event) => {
        setLimitData({ ...limitData, page: parseInt(event.selected) });
    };

    const handleSearchFilter = (e) => {
        let keyword = e.target.value;
        setLimitData({ ...limitData, search: keyword, page: 0 });
    };

    const sendToColumn = (sendToData)=> {
        let data = JSON.parse(sendToData);
        let stringData = [];
        if( data.length > 0 ){
            for(let i = 0;i < data.length;i++ ){
                stringData.push(data[i].name);
            }
            let afterJoin = stringData.join(", ");
            return afterJoin.length > 20 ? afterJoin.substr(0, 25) + ' ... ' : afterJoin;
        }
        return "";
    }

    const handleViewCompose = (id) => {
        navigate(`/email/view/${id}`);
    };

    const handleImportantCompose = async (id, important) => {
        let response = await Important({ id: id, important: important });
        getComposeList()
    };

    return (
        <div className="col-md-10 position-relative">
            {isLoad ? <ComponentLoader /> : ""}
            <div className="row row-theme">
                <div className="col-md-12 search-form">
                    <form action="#" className="text-right">
                        <div className="input-group">
                            <i className="mdi mdi-magnify searchBarIconEmail"></i>
                            <input
                                type="text"
                                className="form-control input-sm"
                                placeholder="Search"
                                value={limitData.search || ""}
                                onChange={(e) => handleSearchFilter(e)}
                            />
                        </div>
                    </form>
                </div>
                <div className="col-sm-12 d-flex">
                    <div className="checkInputContainer" >
                        <input type="checkbox"  />
                    </div>
                    <div className="btn-group">
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-default"  as="button">
                                Action{" "}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item > Mark as read{" "}</Dropdown.Item>
                                <Dropdown.Item >Mark as important</Dropdown.Item>
                                <Dropdown.Item >Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="padding"></div>
            <div className="table-responsive position-relative">
                <table className="table">
                    <tbody>
                        {composeList.length > 0 ? (
                            composeList.map((row,i) => (
                                <tr key={i} >
                                    <td className="action">
                                        <input type="checkbox" />
                                        <i
                                            className={
                                                "mdi mdi-star-outline " +
                                                ((row.important)
                                                    ? "selected"
                                                    : "")
                                            }
                                            onClick={(e) =>
                                                handleImportantCompose(
                                                    row.id,
                                                    row.important
                                                )
                                            }
                                        ></i>
                                    </td>
                                    <td
                                        className="name"
                                        onClick={(e) =>
                                            handleViewCompose(row.id)
                                        }
                                    >
                                        <div>
                                            To:
                                            {sendToColumn(row.send_to)}
                                        </div>
                                    </td>
                                    <td
                                        className="subject"
                                        onClick={(e) =>
                                            handleViewCompose(row.id)
                                        }
                                    >
                                        <div>{row.body}</div>
                                    </td>
                                    <td
                                        className="time"
                                        onClick={(e) =>
                                            handleViewCompose(row.id)
                                        }
                                    >
                                        {row.created_at}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center">
                                    No send mail
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <Paginate
                pageCount={total}
                limit={limitData.limit}
                handlePageCallback={handlePageCallback}
                forcePage={limitData.page}
            />
        </div>
    );
};

export default Send;
