import { authenticatedPost, authenticatedGet } from "./core";

export const addNewMileage = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'mileage/add');
}

export const listMileage = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'mileage/list');
}

export const ViewMileage = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'mileage/view');
}

export const EditMileage = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'mileage/edit');
}

export const deleteMileage = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'mileage/delete');
}



/* export const uploadFile = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'maintenance/upload-file');
}

export const deleteFile = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'maintenance/delete-file');
}

export const getAllTenant = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'tenant/tenants-by-user-id');
}

export const getAllMaintenance = async () => {
   return await authenticatedGet(process.env.REACT_APP_BASE_URL+'maintenance/all-maintenance');
}

export const getMaintenanceById = async (id) => {
   return await authenticatedGet(process.env.REACT_APP_BASE_URL+'maintenance/maintenance-by-id/'+id);
}

export const editMaintenance = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'maintenance/edit-maintenance');
}

export const filterResult = async (request) => {
   return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'maintenance/filter-result');
}

export const generateMOID = async () => {
   return await authenticatedGet(process.env.REACT_APP_BASE_URL+'maintenance/generate-moid');
} */

