import React from "react";
import Loader from "../../../common/Misc/Loader";
import SimpleReactValidator from 'simple-react-validator';

import AdditionalRentalHistory from "./AdditionalRentalHistory";
import AdditionalEmploymentHistory from "./AdditionalEmploymentHistory";
import AdditionalIncome from "./AdditionalIncome";
class Step3 extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            have_additional_income : false,
            property_id:null,
            additionalEmploymentArray : [],
            additionalRentalArray : [],
            additionalIncomeArray : [],
        };
        this.validator = new SimpleReactValidator();
        this.submitForm =async()=> {
            let rentalHistory = [];
            let errorStatus = false;
            this.state.additionalRentalArray.map( async (row) => {
                let data = await this[row.refInst].validateForm();
                if(data.status)
                    rentalHistory.push({ ...row, data:data.data});
                else{
                    errorStatus = true;
                    return;
                }
            });


            let employment = [];
            this.state.additionalEmploymentArray.map( async (row) => {
                let data = await this[row.refInst].validateForm();
                if(data.status)
                   employment.push({ ...row, data:data.data});
                else{
                    errorStatus = true;
                    return;
                }
            }); 
            

            let incomeHistory = [];
            this.state.additionalIncomeArray.map( async (row, index) => {
                let data = await this[row.refInst].validateForm();
                if(data.status)
                    incomeHistory.push({ ...row, data:data.data});
                    if(this.state.additionalIncomeArray.length == (parseInt(index)+1))
                    this.moveToNextStep(errorStatus, { additionalEmploymentArray : employment,additionalRentalArray :rentalHistory,additionalIncomeArray : incomeHistory});
                else{
                    errorStatus = true;
                    return;
                }
            });
            
        } 

        this.moveToNextStep = async(errorStatus, request)=>{
            if(!errorStatus){
                this.props.componentData('step3Data', request);
                this.props.changeStepVar(4);
            }
        } 
    }

    setValue = (e, field) => {
        this.setState({...this.state, [field] : e.target.value})
    }        

         //Add multiple Rental History
        addRentalHistory = () => {
            let allRentalHistory = this.state.additionalRentalArray;
            let last_index = 0;
            allRentalHistory.map((row) => ( last_index = row.index ));
            let new_index = ++last_index;
            allRentalHistory.push({ index : new_index , refInst : 'refRent' + new_index , data :[]});
            this.setState({ ...this.state,
                additionalRentalArray : allRentalHistory
            });
        }

        getAdditionalRentalForm = () => {
            return this.state.additionalRentalArray.map((row, index) => {
                return <AdditionalRentalHistory ref={instance => {this[row.refInst] = instance }} rentalIndex={index} deleteAdditionalRentalHistory={this.deleteAdditionalRentalHistory} oldData = {row.data}/> ;
            });
        }
        deleteAdditionalRentalHistory = (index) => {
            let allRentalHistory = this.state.additionalRentalArray;
            allRentalHistory.splice(index, 1);
            this.setState({additionalRentalArray : allRentalHistory})
        }
        //end multiple Rental History


          //Add multiple Employment History
        addEmploymentHistory = () => {
            let allEmploymentHistory = this.state.additionalEmploymentArray;
            let last_index = 0;
            allEmploymentHistory.map((row) => ( last_index = row.index ));
            let new_index = ++last_index;
            allEmploymentHistory.push({ index : new_index , refInst : 'refEmp' + new_index, data :[] });
            this.setState({ ...this.state,
                additionalEmploymentArray : allEmploymentHistory
            });
        }
        getAdditionalEmploymentForm = () => {
            return this.state.additionalEmploymentArray.map((row, index) => {
                return <AdditionalEmploymentHistory ref={instancee => {this[row.refInst] = instancee }} employmentIndex={index} deleteAdditionalEmploymentHistory={this.deleteAdditionalEmploymentHistory} oldData = {row.data}/> ;
            });
        }
        deleteAdditionalEmploymentHistory = (index) => {
            let allEmploymentHistory = this.state.additionalEmploymentArray;
            allEmploymentHistory.splice(index, 1);
            this.setState({additionalEmploymentArray : allEmploymentHistory})
        }
        //end multiple Employment History

        

        updateAdditionalFields = (e, variable) => {
            this.setState({
                [ variable ] : e.target.checked 
            })
        }


        //Add multiple Icome Source
        addIncome = () => {
            let allIncome = this.state.additionalIncomeArray;
            let last_index = 0;
            allIncome.map((row) => ( last_index = row.index ));
            let new_index = ++last_index;
            allIncome.push({ index : new_index , refInst : 'refIncome' + new_index, data :[]});
            this.setState({ ...this.state,
                additionalIncomeArray : allIncome
            });
        }
        getAdditionalIncomeForm = () => {
            return this.state.additionalIncomeArray.map((row, index) => {
                return <AdditionalIncome ref={instancee => {this[row.refInst] = instancee }} incomeIndex={index} deleteAdditionalIncome={this.deleteAdditionalIncome} oldData = {row.data}/> ;
            });
        }
        deleteAdditionalIncome = (index) => {
            let allIncome = this.state.additionalIncomeArray;
            allIncome.splice(index, 1);
            this.setState({additionalIncomeArray : allIncome})
        }
        //end multiple income source



        componentDidMount = async() => {
            this.setState({
                property_id : this.props.property_id
            });

            if(this.props?.oldData){
                this.setState({
                    additionalEmploymentArray :this.props.oldData.additionalEmploymentArray,
                    additionalRentalArray :this.props.oldData.additionalRentalArray,
                    additionalIncomeArray :this.props.oldData.additionalIncomeArray,
                })
                this.getAdditionalRentalForm();
                this.getAdditionalEmploymentForm();
                this.getAdditionalIncomeForm();
            }
        }

    render(){
        return(
            <>
                <div className="row application-step-three">
                    <div className="col-md-12 grid-margin stretch-card flex-column">    
                        <div className="card mb-4">
                            <div className="card-body pt-0">
                                <h4 className="heading-h4 font-normal mb-4">Rental History </h4>
                                <form className="forms-sample mt-4">
                                    <div className="ai-residence-info-outer">
                                    {
                                        this.state?.additionalRentalArray ? this.getAdditionalRentalForm() : ''
                                    }  
                                    </div>
                                    <button className="btn btn-link btn-link-lg p-0" type="button" onClick={()=>{this.addRentalHistory()}}> <i className="mdi mdi-plus-box"></i> <span>Add residence</span> </button>
                                    {/* <div className="form-group mt-5">
                                        <label htmlFor="exampleInputName">Additional residence history information</label>
                                        <textarea className="form-control" name="additional_residence">
                                        </textarea> 
                                    </div> */}
                                </form>
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-body pt-0">
                                <h4 className="heading-h4 font-normal mb-4">Employment history </h4>
                                <form className="forms-sample mt-4">
                                    <div className="ai-residence-info-outer">
                                    {
                                        this.state?.additionalEmploymentArray ? this.getAdditionalEmploymentForm() : ''
                                    }  
                                    </div>
                                    <button className="btn btn-link btn-link-lg p-0" type="button" onClick={()=>{this.addEmploymentHistory()}}> <i className="mdi mdi-plus-box"></i> <span>Add Employment History</span> </button>
                                    {/* <div className="form-group mt-5">
                                        <label for="exampleInputName">Additional employment history information</label>
                                        <textarea className="form-control">
                                        </textarea> 
                                    </div> */}
                                </form>
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-body pt-0">
                                <div className="mb-4">
                                    <h4 className="heading-h4 font-normal">Additional income</h4>
                                </div>
                                <div className="ao-switch">
                                    <section className="mb-0" id="model-53">
                                        <div className="card-toggle-yes">
                                        <div className="toggle-switch">
                                            <input type="checkbox" id="check-53" onChange={(e) => { this.updateAdditionalFields(e, 'have_additional_income')}}/>
                                            <label for="check-53"></label>
                                        </div>
                                        </div>
                                    </section>
                                    <p>Do you have any additional income?</p>
                                </div>
                                {
                                    this.state. have_additional_income ? (
                                        <form className="forms-sample mt-4">
                                            <div className="ai-Occupants-info-outer">
                                                {
                                                    this.state?.additionalIncomeArray ? this.getAdditionalIncomeForm() : ''
                                                }   
                                                <hr>
                                                </hr>
                                            </div>
                                            <button className="btn btn-link btn-link-lg p-0" type="button" onClick={()=>{this.addIncome()}}><i className="mdi mdi-plus-box"></i> <span>Add an income source</span> </button>
                                        </form>
                                    ) : ""
                                }
                            </div>
                        </div>
                        <div className="card  mb-4">
                            <div className="card-body p-0">
                                <div className="d-flex justify-content-between">
                                <button type="button" className="btn btn-dark" onClick={(e) => this.props?.changeStepVar(2)}><i className="mdi mdi-arrow-left"></i> Back</button>
                                <button className="btn bg-orange btn-cus mr-2 text-white" disabled={this.state.requestInprogress} onClick={this.submitForm}>{this.state.requestINprogress ? (<Loader className="btn theme-btn-orange" msg="Please wait..." />): 'Next' }<i className="mdi mdi-arrow-right"></i></button>      
                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default Step3; 