import React from "react";
import Step1 from "../../components/Tenant/Application/Step1";
import Step2 from "../../components/Tenant/Application/Step2";
import Step3 from "../../components/Tenant/Application/Step3";
import Step4 from "../../components/Tenant/Application/Step4";
import Step5 from "../../components/Tenant/Application/Step5";
import { addApplication } from "../../Services/Tenants" ;
import { getPropertyByUnit } from "../../Services/Property" ;
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../common/Footer";
import Loader from "../../common/Misc/Loader";
import {  getQueryStringParams } from "../../helpers/Utils";
import {withRouter } from "../../helpers/withRouter"
import showNotification from "../../helpers/Notify";
import { getErrorMessage } from "../../helpers/Utils";
import SendNotification from "../../common/SendNotification";
class Application extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            step : 1,
            userData : {},
            property_id : null,
            unit_id : null,
            prevStep : '',
            property_details : [],
            "step1Data" : null,
            "step2Data" : {
                additionalOccupantArray : [{index : 0 , refInst : 'ref0', data : []}],
                additionalPetArray : [{index : 0 , refInst : 'refPet0' , data : []}],
                additionalVehicleArray : [{index : 0 , refInst : 'refVehicle0', data : []}]
            },
            "step3Data" : {
                additionalEmploymentArray : [{index : 0 , refInst : 'refemp0', data : []}],
                additionalRentalArray : [{index : 0 , refInst : 'refRent0' , data : []}],
                additionalIncomeArray : [{index : 0 , refInst : 'refIncome0', data : []}],
            },
            "step4Data" : {
                additionalEmergencyContactArray : [{index : 0 , refInst : 'ref0', data : []}]
            },
            "step5Data" : {},

        };
    }

    getQueryStringParams = query => {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params, param) => {
                        let [key, value] = param.split('=');
                        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                        return params;
                    }, {}
                )
            : {}
    };

    componentDidMount = async() => {
        let query = getQueryStringParams(window.location.search);
        let response = await getPropertyByUnit(query.id);
        if(response.data.data){
            this.setState({
                property_id : response.data.data.property_id,
                unit_id : response.data.data.id,
                property_details : response.data.data
            })
        }else{
        }
    }

    componentData = (step, data) => {
        this.setState({
            [step] : data
        });

    }

    submitForm = async () => {
        let response = await addApplication(this.state);
        if(response.data.success){
            showNotification(getErrorMessage(response.data.message), 'success');
            SendNotification(response.data.sendnotification);
            this.props.navigate('/property-search');
        } else {
            showNotification(getErrorMessage(response.data.message), 'error');
        }
    }

    getContent = () => {
        let step = this.state.step;
        switch(step){
            case 2:
                return <Step2 componentData={this.componentData} oldData={ this.state.step2Data} changeStepVar={this.changeStep} userData={this.state.userData} sendNotification={this.notification}/>;
            case 3:
                return <Step3 componentData={this.componentData} oldData={ this.state.step3Data } changeStepVar={this.changeStep} sendNotification={this.notification}/>;
            case 4:
                return <Step4 componentData={this.componentData} oldData={ this.state.step4Data } changeStepVar={this.changeStep}  sendNotification={this.notification}/>;
            case 5:
                return <Step5 componentData={this.componentData} oldData={ this.state.step5Data } changeStepVar={this.changeStep}  sendNotification={this.notification} submitForm={this.submitForm}/>;
            default:
                return <Step1 componentData={this.componentData} oldData={ this.state.step1Data } changeStepVar={this.changeStep}  sendNotification={this.notification} property_id = {this.state.property_id} property_details = { this.state.property_details }/>
        }
    }

    updateUserDetails = (userData) => {
        this.setState({...this.state, userData : userData})
    }

    changeStep = (step) => {
        this.setState({step : step});
        this.getContent();
    }

    notification = (msg) => {
         toast.success(msg, {autoClose:2000});
    }

    render(){
        return(
            <>
            <div className="content-wrapper">
            <ToastContainer/>
                <div className="page-header">
                    <div className="left-cell">
                        <h3 className="page-title">Application information</h3>
                    </div>
                </div>
                <div className="detail-cont">
                    <div className="progressbar-container">
                        <ul className="progressbar clearfix mt-5 mb-4">
                        <li className="active ">Applicant information</li>
                        <li className={this.state.step >= 2 ? "active" :''}>Occupants & Other</li>
                        <li className={this.state.step >= 3 ? "active" :''}>Rental & Employment </li>
                        <li className={this.state.step >= 4 ? "active" :''}>Contacts & References</li>
                        <li className={this.state.step >= 5 ? "active" :''}>Screening process</li>
                        </ul>
                    </div>
                        {
                            this.getContent()
                        }
                </div>
                <Footer/>
            </div>
            </>
        )
    }
}
export default withRouter(Application);
