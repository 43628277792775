import React from "react";
import { updateUserGeneralInformation } from "../../Services/UserProfile";
import SimpleReactValidator from 'simple-react-validator';
import showNotification from "../../helpers/Notify";
import Loading from "../../common/Misc/Loader";

class Generalinformation extends React.Component{

   constructor (props){
        super (props);
        this.state = {
            first_name: '',
            last_name: '',
            company_name: '',
            phone: '',
            tax_indentity_type: '',
            taxpayer_id: '',
            account_mode : null,
            requestInprogress : false,
            display_as_company : 'no',
            user_type : ''
        };

      this.validator = new SimpleReactValidator();
      this.submitForm = async ()=> {
         if (this.validator.allValid()) {
            this.setState({requestInprogress : true});
            let request = {
               first_name: this.state.first_name,
               last_name: this.state.last_name,
               company_name: this.state.company_name,
               phone: this.state.phone,
               tax_type: this.state.tax_indentity_type,
               tax_payer_id: this.state.taxpayer_id,
               account_mode : this.state.account_mode,
               display_as_company : this.state.display_as_company
            };
            let res = await updateUserGeneralInformation(request);
            this.setState({requestInprogress : false});
            if(res.data.success){
               showNotification(res.data.message, 'success');
            } else {
               showNotification(res.data.message, 'error');
            }
         } else {
         this.validator.showMessages();
         this.forceUpdate();
         }
      }
   }

   setValue = (e, field) => {
      if(field === 'display_as_company'){
         let value = e.target.checked ? 'yes' : 'no';
         this.setState({[field] : value})
      }else{
         this.setState({[field] : e.target.value})
      }
   }

   componentDidMount = async () => {
      if(this.props.data){
         this.setState({
            ...this.state,
            first_name: this.props.data.first_name,
            last_name: this.props.data.last_name,
            company_name: this.props.data.company_name,
            phone: this.props.data.phone,
            tax_indentity_type: this.props.data.tax_type,
            taxpayer_id: this.props.data.tax_payer_id,
            account_mode: this.props.data.mode,
            display_as_company : this.props.data.display_as_company,
            user_type : this.props.data.user_type
         });
      };
   }

   render(){
      return (
         <>
            <div className="card mb-4">
               <div className="card-header d-flex align-items-start">
                  <h3 className="d-inline-block align-middle">General information</h3>
               </div>
               <div className="card-body">
                  <div className="row">
                     <div className="col-md-6">
                        <div className="form-group">
                           <label htmlFor="exampleInputUsername1">First Name</label>
                           <input type="text" className="form-control" name = "first_name" id="exampleInputUsername1" placeholder="Enter your first name" value={this.state.first_name} onChange={(e)=>this.setValue(e,'first_name')}/>
                        </div>
                        {this.validator.message('first_name', this.state.first_name, 'required')}
                     </div>
                     <div className="col-md-6">
                        <div className="form-group">
                           <label htmlFor="exampleInputUsername2">Last Name</label>
                           <input type="text" className="form-control" name = "last_name" id="exampleInputUsername2" placeholder="Also your last name" value={this.state.last_name} onChange={(e)=>this.setValue(e,'last_name')}/>
                        </div>
                        {this.validator.message('last_name', this.state.last_name, 'required')}
                     </div>
                     {
                        this.state.user_type === 'landlord' ? (
                           <div className="col-md-12">
                              <div className="form-group">
                                 <label htmlFor="exampleInputUsername2">Company Name</label>
                                 <input type="text" className="form-control" name = "company_name" id="exampleInputUsername2" placeholder="Enter your company name" value={this.state.company_name} onChange={(e)=>this.setValue(e,'company_name')}/>
                                 <div className="form-check form-check-flat form-check-primary">
                                    <label className="form-check-label">
                                       <input type="checkbox" className="form-check-input" checked={ this.state.display_as_company  === 'yes' ? true: false }   onChange={(e)=>this.setValue(e,'display_as_company')}/>
                                       Dispaly as a company? <i className="input-helper"></i></label>
                                 </div>
                              </div>
                           </div>
                        ) :""
                     }
                     <div className="col-md-6">
                        <div className="form-group">
                           <label htmlFor="exampleInputUsername2">Phone Number</label>
                           <input type="tel" className="form-control"  name = "phone" id="exampleInputUsername2" placeholder="Enter phone number" value={this.state.phone} onChange={(e)=>this.setValue(e,'phone')}/>
                        </div>
                        {this.validator.message('phone', this.state.phone, 'required')}
                     </div>
                     <div className="col-md-6">
                        <div className="form-group">
                           <label htmlFor="exampleSelectTex">Tax indentity type</label>
                           <select className="form-control tex-select select_btn" name = "tax_indentity_type" id="exampleSelectTex" value={this.state.tax_indentity_type} onChange={(e)=>
                              this.setValue(e,'tax_indentity_type')}>
                              <option className="unselect" key={'unselect'}>Select indentity type</option>
                              <option value="ssn" key={'ssn'} >SSN</option>
                              <option value="ssn2" key={'ssn2'} >SSN2</option>
                           </select>
                        </div>
                        {this.validator.message('tax_indentity_type', this.state.tax_indentity_type, 'required')}
                     </div>
                     <div className="col-md-6 taxpayer-box">
                        <div className="form-group">
                           <label htmlFor="exampleInputText3">Taxpayer Id (SSN)</label>
                           <input type="text" className="form-control" name = "taxpayer_id" id="exampleInputText3" placeholder="xxx-xxx-xxxx" value={this.state.taxpayer_id} onChange={(e)=>this.setValue(e,'taxpayer_id')}/>
                        </div>
                        {this.validator.message('taxpayer_id', this.state.taxpayer_id, 'required')}
                     </div>

                     {
                        this.state.user_type === 'landlord' ? (
                           <div className="col-md-6 account-mode-box">
                              <div className="form-group row pt-4 account-mode-box">
                                 <label className="col-sm-3 col-form-label">Account Mode</label>
                                 <div className="col-sm-3">
                                    <div className="form-check">
                                       <label className="form-check-label">
                                          <input type="radio" className="form-check-input" name="membershipRadios" checked={ this.state.account_mode  === 'landloard' ? true: false }  value="landloard" onChange={(e)=>this.setValue(e,'account_mode')} /> Landlord <i className="input-helper"></i>
                                       </label>
                                    </div>
                                 </div>
                                 <div className="col-sm-5 ">
                                    <div className="form-check">
                                       <label className="form-check-label">
                                          <input type="radio" className="form-check-input" name="membershipRadios" checked={ this.state.account_mode  === 'property_manager' ? true: false }  value="property_manager" onChange={(e)=>this.setValue(e,'account_mode')}/> Property Manager <i className="input-helper"></i>
                                       </label>
                                    </div>
                                 </div>
                              </div>
                           </div>

                        ):""
                     }

                  </div>
                     <button className="btn bg-orange btn-cus mr-2 text-white" disabled={ this.state.requestInprogress}  onClick={this.submitForm}>{ this.state.requestInprogress ? (<Loading className="btn theme-btn-orange" msg="updating data..." />) : 'Update' }</button>
               </div>
            </div>
         </>
      )
   }
}

export default Generalinformation;
