import { useState,useRef, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Tab,Tabs,FloatingLabel,Form } from 'react-bootstrap';
import TextSignature from '../TextSignature'
import SignatureCanvas from 'react-signature-canvas';


const SignNowModal = (props) => {

    const [signName,setSignName] = useState('');

    const [ sigPad,setSigPad ] = useState({});
    const [ clearSigPad,setClearSigPad ] = useState(false);
    const [tabActive,setTabActive] = useState(0);
    const [signImage,setSignImage] = useState('');
    const [error,setError] = useState(0);

    const handleTestSignNow = (e) => {
        setSignName(e.target.value)
    }

    const handleClearSignDraw = () => {
        sigPad.clear()
    }

    const handleChangeTab = (tabNumb) => {
        setSignImage('')
        if( tabNumb == 'Type_Signature' ){
            setTabActive(0);
            setError(0)
        }else if( tabNumb == 'Draw_Signature' ){
            setTabActive(1)
            setError(0)
        }
    }

    const trim = () => {
        var imgUrl = '';
        var error = false;
        if( tabActive ){
            if( sigPad.isEmpty() ){
                setError(1)
                error = true;
            }else{
                imgUrl = sigPad.getTrimmedCanvas().toDataURL('image/png');
                setSignImage(imgUrl);
            }
        }else{
            if( signName == '' ){
                setError(1)
                error = true;
            }else{
                imgUrl = signImage;
            }
        }

        if( !error ){
            setError(0)
            props.handleGetDataImage(imgUrl);
            props.onHide()
        }

  }



    return(
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName='reactFloatinglabelInputs'
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Signature
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey={tabActive?'Draw_Signature':'Type_Signature'} id="fill-tab-example" className="mb-3" fill onSelect={(e) => handleChangeTab(e) }  >
                    <Tab eventKey="Type_Signature" title="Type Signature" onSelect={(e) => handleChangeTab(0) } >
                        <FloatingLabel controlId="floatingInput" label="Your Name*" className="mb-4" >
                            <Form.Control type="text" placeholder="Your Name" onChange={(e) => handleTestSignNow(e)} />
                            { (!tabActive && error )?
                                    <p className='srv-validation-message custom-error' >Required Field</p>
                                :''
                            }
                        </FloatingLabel>

                        <TextSignature className="signNowContainer" name={signName} defaultImg={""}
                            getImage={(e) =>  setSignImage(e) } x={0} y={40} height={68}
                            font="italic 40px Brush Script MT" />

                    </Tab>
                    <Tab eventKey="Draw_Signature" title="Draw Signature" onSelect={(e) => handleChangeTab(1) } >
                        <div className='signatureCanvasContainer position-relative'>
                            <SignatureCanvas penColor='black' throttle={5}
                                canvasProps={{width: 470, height: 200, className: 'signNowDrawContainer'}}  minWidth="0.1" maxWidth="1.5" ref={(ref) => { setSigPad(ref);  }} onEnd = { (e) =>  setClearSigPad(true)  } />
                            { clearSigPad?<p className='cp position-absolute signPadClear' onClick={(e) => handleClearSignDraw()}>Clear</p>:'' }
                            { (tabActive &&  error  )?
                                    <p className='srv-validation-message custom-error' >Required Sign</p>
                                :''
                            }
                            <p className='mt--27px text-center'>Signature Draw</p>
                        </div>

                    </Tab>
                </Tabs>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={trim}>Agree</Button>
            </Modal.Footer>
            </Modal>

    )

}

export default SignNowModal;
