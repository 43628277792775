import React from "react";
import { uploadFile, deleteFile } from "../../../Services/Property";
import { getErrorMessage } from "../../../helpers/Utils";
import { ToastContainer, toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
class AdditionalPet extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            pet_type: '',
            pet_name: '',
            weight: '',
            breed: '',
            uploadedFileList : [],
            profile_pic_path : '',
            count : 1
        };

        this.validator = new SimpleReactValidator();
        this.validateForm =async()=> {
            if(this.validator.allValid()) {
                return {status : true, data : this.state};                
            }else{
                this.validator.showMessages();
                this.forceUpdate();
                return {status : false, data : []};
            } 
        } 


        let request =  
        {            
            profile_pic_path : this.state.profile_pic_path,  
        }
    
    }

    setValue = (e, field) => {
        this.setState({[field] : e.target.value})
    }

    componentDidMount = async() => {
        if(this.props.petIndex){
            this.setState({count : this.props.petIndex + 1});
        }
        if(this.props?.oldData){
            this.setState({
                ...this.props.oldData
            })
        }
    }


    doFileUpload = async(e, type) => {
        let files = e.target.files;
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            console.log(files[i], '321');
            formData.append(`file`, files[i]);
            formData.append(`type`, type);
            let response = await uploadFile(formData); 
            if(response.data.success){
                let new_obj = {
                    id : response.data.data.file_id,
                    file_name : files[i]['name'],
                    file_path : response.data.data.file_path,
                    file_type : files[i]['type'],
                    type:type
                };
                if(type === 'banner'){
                    this.setState({'profile_pic_path' : response.data.data.file_path});
                }
                let imageList =  this.state.uploadedFileList;
                imageList.push(new_obj);
                this.setState({
                    uploadedFileList : imageList
                });

            }else{
                let error_message = getErrorMessage(response.data.message);
                toast.warning(error_message, {autoClose:10000});
            }
            
        }
    }

    deleteFile = async(e, row) => {
        let request = {
            file_id : row.id
        };
        let response = await deleteFile(request);
        if(response.data.success){
            var delete_index = -1;
            let imageList =  this.state.uploadedFileList;
            imageList.map((all_row, index) => {
                if(row.id === all_row.id)
                 delete_index = index;
                 return delete_index;
            });
            if(delete_index !== -1){
                imageList.splice(delete_index, 1);
                this.setState({
                    uploadedFileList : imageList
                });
            }
        } else {
            let error_message = getErrorMessage(response.data.message);
            toast.warning(error_message, {autoClose:10000});
        }
    }


    render(){
        return(
            <>
                <div className="ai-pet-info">
                    <h5 className="as-tittle-h5">Pet { this.state.count}  <span onClick={()=>this.props.deleteAdditionalPet(this.props.petIndex)}>Delete</span></h5>
                    <div className="row row-theme">
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputName">Type* </label>
                            <select className="form-control tex-select select_btn" name= "pet_type" id="exampleInputName"  value={this.state.pet_type} onChange={(e)=>this.setValue(e,'pet_type')}>
                                <option className="unselect" key="1">Select Pet Type</option>
                                <option value="cat" key="2">Cat</option>
                                <option value="small_dog" key="3">Small Dog</option>
                                <option value="large_dog" key="4">Large Dog</option>
                            </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputName">Name* </label>
                            <input type="text" name= "pet_name" className="form-control" id="exampleInputLastName" placeholder="Enter name"  value={this.state.pet_name} onChange={(e)=>this.setValue(e,'pet_name')}/>
                            </div>
                            {this.validator.message('pet_name', this.state.pet_name, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputName">Weight Ib* </label>
                            <input type="number" name= "weight" className="form-control" id="exampleInputLastName" placeholder="Enter weight"  value={this.state.weight} onChange={(e)=>this.setValue(e,'weight')}/>
                            </div>
                            {this.validator.message('weight', this.state.weight, 'required')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputName">Breed </label>
                            <input type="text" name= "breed" className="form-control" id="exampleInputLastName" placeholder="Enter breed"  value={this.state.breed} onChange={(e)=>this.setValue(e,'breed')}/>
                            </div>
                            {this.validator.message('breed', this.state.breed, 'required')}
                        </div>
                        <div className="col-md-12">
                            <div className="form-group mb-0">
                            <div className="profile-pic-wrapper small-profile-pic">
                                <div className="pic-holder mb-0">
                                {
                                    this.state.profile_pic_path === '' ?  <img id="profilePic" className="pic" src="assets/admin/images/profile-img.jpg" alt="pic"/> :  <img id="profilePic" className="pic" src={ process.env.REACT_APP_SITE_URL + this.state.profile_pic_path} alt="pic"/>
                                }
                                    <input className="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto" accept="image/*" style={{opacity: "0"}} onChange={(e) => { this.doFileUpload(e, 'banner') }} allow="jpg,png,jpeg"/>
                                    <label htmlFor="newProfilePhoto" className="upload-file-block">
                                        <div className="text-center">
                                        <div className="mb-2">
                                            <i className="fa fa-camera fa-2x"></i>
                                        </div>
                                        <div className="text-uppercase">
                                            Upload <br></br> photo
                                        </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="">
                                    Upload pet image
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </>
        )
    }
     

}
export default AdditionalPet;