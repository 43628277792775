import React from 'react';
import { Link} from 'react-router-dom';
import AdminNav from "./AdminNav";
import TenantNav from "./TenantNav";
// import History from '../History';

class LeftNav extends React.Component {
    constructor(props){
        super(props);
           this.state = {
            user_role : '',
            activeLink : 'dashboard'
        };
    }

    componentDidMount = () => {
        let user_details = localStorage.getItem('userDetalis');
        user_details  = JSON.parse(user_details);
        this.setState({user_role : user_details.user_type});
    }
    goToPage = (path) => {
        this.setState({
            activeLink : path
        });
    }

    logoutUser = (e) => {
        localStorage.clear();
        window.location.href = '/';
    }
  render() {
    return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
                {
                    this.state.user_role === 'tenant' ? <TenantNav/> : <AdminNav/>
                }
            </ul>
            <div className="logout-wrapper">
                <a href="/#"  onClick={(e) => {this.logoutUser(e)}}><span><img src="assets/admin/images/dashboard/logout-icon.png" alt="logout"/></span>Logout</a>
            </div>
        </nav>
    );
  }
}
export default LeftNav;
