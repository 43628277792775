import React from 'react';
import MessageList from './MessageList';
import { db, collection, query, where, getDocs, getAuth } from "../../firebase";
import ComponentLoader from '../../common/ComponentLoader'

class MessageNotifications extends React.Component {
    constructor(props){
        super(props);
           this.state = {
            adminId:'',
            tenantList:[],
            isLoad:0
        };
    }

    componentDidMount = async () => {
        this.setState({isLoad:1});
        var authDetails = JSON.parse(localStorage.getItem('userDetalis'));
        var adminId = authDetails.id;
        var authType = authDetails.user_type
        var authEmail = authDetails.email
        this.setState({adminId:adminId});
        var auth = getAuth();
        var getUsers = 'landlord';
        if( authType == 'landlord' ){
            getUsers = 'tenant';
        }
        if (authType == "admin") {
            getUsers = "landlord";
        }
        var querySnapshotAdmin = await getDocs(query(collection(db, "users"),where("uid", "==", localStorage.getItem('firebase_uid'))));
        var querySnapshot = await getDocs(query(collection(db, "users"),where("type", "==", getUsers)));
        var userDetails = [];
        querySnapshot.forEach((doc) => {
            userDetails.push({'uid':doc.id,'details':doc.data()});
        })
        this.setState({'tenantList':userDetails});
        this.setState({isLoad:0});

    }


    render() {

        return (
            <div className="" aria-labelledby="messageDropdown">
                <h6 className="p-3 mb-0">Messages</h6>
                <ul className={ `list-unstyled mb-0 messageNotificationUl` }>
                    {
                        this.state.isLoad?<li className='position-relative h-120px'><ComponentLoader /></li>:''
                    }
                    {
                        this.state.tenantList.length > 0?
                            this.state.tenantList.map((row,i) =>
                                <MessageList key={i} row={row} adminId={this.state.adminId} usersId={row.uid}  />

                        ):''
                    }


                </ul>
            </div>
        );
    }
}

export default MessageNotifications;
