import {ActiveChatByUser} from '../constants';
const ActiveChatUser = (data) =>  {
    return {
        type:ActiveChatByUser,
        data:data
    }
}

export {
    ActiveChatUser
}
