import React from "react";
import axios from "axios";
import SimpleReactValidator from 'simple-react-validator';
import { getallCurrency, getAllCountry } from "../../Services/Miscellaneous"
import { time_zone as tz } from "../../helpers/Constants"
import Select from 'react-select'
import showNotification from "../../helpers/Notify";
import Loading from "../../common/Misc/Loader";
import { updateUserSetting } from "../../Services/UserProfile";

class AdditionalSetting extends React.Component{
    constructor (props){
        super (props);
        this.state = {
            time_zone: '',
            currency: '',
            date_format: '',
            time_format: '',
            measurement: '',
            allCurrency : [],
            requestInprogress : false 
        };

        this.validator = new SimpleReactValidator();
        this.submitForm = async ()=> {
            if (this.validator.allValid()) {
            this.setState({requestInprogress : true});
            let request = {
                time_zone: this.state.time_zone.label,
                currency: this.state.currency.label,
                date_format: this.state.date_format,
                time_format: this.state.time_format,
            }
            let res   =await updateUserSetting(request);
            this.setState({requestInprogress : false});
            if(res.data.success){
                showNotification(res.data.message, 'success');
             } else {
                showNotification(res.data.message, 'error');
             } 
            } else {
            this.validator.showMessages();
            this.forceUpdate();
            }
        }
    }

    componentDidMount = async() => {
        let response = await getallCurrency();
        this.setState({allCurrency : response.data.data});
        if(this.props.data){
            this.setState({
               ...this.state,
               time_zone: {label : this.props.data.timezone , value : this.props.data.timezone},
               currency: {label : this.props.data.currency , value : this.props.data.currency},
               date_format: this.props.data.date_time,
               time_format: this.props.data.time_format,
            }); 
         };  
        console.log('statte params', this.props)
    }

    setOptionValue = (e, field) => {
        this.setState({[field] : e})
    }

    setValue = (e, field) => {
        this.setState({[field] : e.target.value})
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
      };
    render(){
        return (
            <>
                <div className="card mb-4">
                    <div className="card-header d-flex align-items-start">
                        <h3 className="d-inline-block align-middle">Additional Setting</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="exampleSelectTex">Time Zone</label>
                                    <Select options={tz}  value={this.state.time_zone}   onChange={(e)=> this.setOptionValue(e,'time_zone')}/>
                                </div>
                                {this.validator.message('time_zone', this.state.time_zone, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="exampleSelectTex">Currency</label>
                                    <Select options={this.state.allCurrency}  value={this.state.currency}   onChange={(e)=> this.setOptionValue(e,'currency')}/>
                                </div>
                                {this.validator.message('currency', this.state.currency, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="exampleInputUsername2">Date Format</label>
                                <select className="form-control tex-select select_btn" name="time_format" id="exampleSelectTex" value={this.state.date_format} onChange={(e)=>
                                    this.setValue(e,'date_format')}>
                                    <option className="unselect" key="un">Select Date Format</option>
                                    <option value='dd-mm-yyyy' key="12">dd-mm-yyyy</option>
                                    <option value='mm-dd-yyyy' key="24">mm-dd-yyyy</option>
                                </select>
                                    
                                </div>
                                {this.validator.message('date_format', this.state.date_format, 'required')}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="exampleSelectTex">Time Format</label>
                                <select className="form-control tex-select select_btn" name="time_format" id="exampleSelectTex" value={this.state.time_format} onChange={(e)=>
                                    this.setValue(e,'time_format')}>
                                    <option className="unselect" key="un">Select Time Format</option>
                                    <option value='12' key="12">12 Hr Format</option>
                                    <option value='24' key="24">24 Hr Format</option>
                                </select>
                                </div>
                                {this.validator.message('time_format', this.state.time_format, 'required')}
                            </div>
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="exampleSelectTex">Measurement</label>
                                <select className="form-control tex-select select_btn" name="measurement" id="exampleSelectTex" value={this.state.measurement} onChange={(e)=>
                                    this.setValue(e,'measurement')}>
                                    <option className="unselect" key="un">Select time measurement</option>
                                    <option value='am' key="am">AM</option>
                                    <option value='pm' key="pm">PM</option>
                                </select>
                                </div>
                                {this.validator.message('measurement', this.state.measurement, 'required')}
                            </div> */}
                        </div>
                        <button className="btn bg-orange btn-cus mr-2 text-white" onClick={this.submitForm} disabled={ this.state.requestInprogress}>{ this.state.requestInprogress ? (<Loading className="btn theme-btn-orange" msg="updating data..." />) : 'Update' }</button>
                    </div>
                </div>
            </>
            
        )
    }
}
export default AdditionalSetting;