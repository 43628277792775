import React from "react";
import SimpleReactValidator from 'simple-react-validator';
import {getAllCountry } from "../../../Services/Miscellaneous";
class AdditionalEmploymentHistory extends React.Component{
   constructor(props){
    super(props);
    this.state = {
        additional_residence: '',
        start_date: '',
        end_date: '',
        current_employment: '',
        company_name: '',
        position: '',
        work_email: '',
        work_phone: '',
        work_street_address: '',
        work_city: '',
        work_state: '',
        work_zip: '',
        work_country: '',
        office_number: '',
        monthly_income: '',
        supervisor_first_name: '',
        supervisor_last_name: '',
        supervisor_email: '',
        supervisor_phone_number: '',
        count : 1,
    };

    this.validator = new SimpleReactValidator();
    this.validateForm =async()=> {
         if(this.validator.allValid()) {
            console.log('Valid', this.state);
             return {status : true, data : this.state};                
         }else{
             this.validator.showMessages();
             this.forceUpdate();
             console.log('inValid', this.state);
             return {status : false, data : []};
         }
        
     } 
   }

setValue = (e, field) => {
    this.setState({[field] : e.target.value})
}

componentDidMount = async() => {
    let resCountry = await getAllCountry();
    this.setState({all_countries: resCountry.data.data});

    if(this.props.employmentIndex){
        this.setState({count : this.props.employmentIndex + 1});
    }
    if(this.props?.oldData){
        this.setState({
            ...this.props.oldData
        })
    }
}
   render(){
    return (
        <>
        <div className="ai-residence-info">
            <h5 className="as-tittle-h5">Employer { this.state.count } <span onClick={()=>this.props.deleteAdditionalEmploymentHistory(this.props.employmentIndex)}>Delete</span> </h5>
            <div className="row row-theme">
                <div className="col-md-4">
                <div className="form-group">
                    <label for="exampleInputName">Start date </label>
                    <input type="date" name="start_date" className="form-control" id="exampleInputName" placeholder="" value = {this.state.start_date} onChange={(e)=>this.setValue(e,'start_date')}/>
                </div>
                {this.validator.message('start_date', this.state.start_date, 'required')}
                </div>
                <div className="col-md-4">
                <div className="form-group">
                    <label for="exampleInputName">End date </label>
                    <input type="date" name="end_date" className="form-control" id="exampleInputMiddleName" placeholder="" value = {this.state.end_date} onChange={(e)=>this.setValue(e,'end_date')}/>
                </div>
                {this.validator.message('end_date', this.state.end_date, 'required')}
                </div>
                <div className="col-md-4">
                <div className="form-group">
                    <label for="exampleInputPriority">Current employment? </label>
                    <section id="model-53">
                        <div className="card-toggle-yes">
                            <div className="toggle-switch">
                            <input type="checkbox" id="check-53" name= "current_employment"/>
                            <label for="check-53"></label>
                            </div>
                        </div>
                    </section>
                </div>
                </div>
                <div className="col-md-6">
                <div className="form-group">
                    <label for="exampleInputCompanyN">Company name* </label>
                    <input type="text" name="company_name" className="form-control" id="exampleInputCompanyN"  placeholder="" value = {this.state.company_name} onChange={(e)=>this.setValue(e,'company_name')}/>
                </div>
                {this.validator.message('company_name', this.state.company_name, 'required')}
                </div>
                <div className="col-md-6">
                <div className="form-group">
                    <label for="exampleInputPosition">Position/Title* </label>
                    <input type="text" name="position" className="form-control" id="exampleInputPosition" placeholder="" value = {this.state.position} onChange={(e)=>this.setValue(e,'position')}/>
                </div>
                {this.validator.message('position', this.state.position, 'required')}
                </div>
                <div className="col-md-6">
                <div className="form-group">
                    <label for="exampleInputWorkemail">Work email </label>
                    <input type="text" name="work_email" className="form-control" id="exampleInputWorkemail"  placeholder="" value = {this.state.work_email} onChange={(e)=>this.setValue(e,'work_email')}/>
                </div>
                {this.validator.message('work_email', this.state.work_email, 'required')}
                </div>
                <div className="col-md-6">
                <div className="form-group">
                    <label for="exampleInputWorkphone">Work phone </label>
                    <input type="text" name="work_phone" className="form-control" id="exampleInputWorkphone" placeholder="" value = {this.state.work_phone} onChange={(e)=>this.setValue(e,'work_phone')}/>
                </div>
                {this.validator.message('work_phone', this.state.work_phone, 'required')}
                </div>
                <div className="col-md-8">
                <div className="form-group">
                    <label for="exampleInputName">Street address* </label>
                    <textarea className="form-control ta-form-control" name= "work_street_address" value = {this.state.work_street_address} onChange={(e)=>this.setValue(e,'work_street_address')}></textarea>
                </div>
                {this.validator.message('work_street_address', this.state.work_street_address, 'required')}
                </div>
                <div className="col-md-4">
                <div className="form-group">
                    <label for="exampleInputCity">City* </label>
                    <input type="text" name="work_city" className="form-control" id="exampleInputCity" placeholder="" value = {this.state.work_city} onChange={(e)=>this.setValue(e,'work_city')}/>
                </div>
                {this.validator.message('work_city', this.state.work_city, 'required')}
                </div>
                <div className="col-md-4">
                <div className="form-group">
                    <label for="exampleInputCity">State / region* </label>
                    <input type="text" name="work_state" className="form-control" id="exampleInputCity" placeholder="" value = {this.state.work_state} onChange={(e)=>this.setValue(e,'work_state')}/>
                </div>
                {this.validator.message('work_state', this.state.work_state, 'required')}
                </div>
                <div className="col-md-4">
                <div className="form-group">
                    <label for="exampleInputZip">Zip* </label>
                    <input type="text" name="work_zip" className="form-control" id="exampleInputZip" placeholder="" value = {this.state.work_zip} onChange={(e)=>this.setValue(e,'work_zip')}/>
                </div>
                {this.validator.message('work_zip', this.state.work_zip, 'required')}
                </div>
                <div className="col-md-4">
                <div className="form-group">
                    <label for="exampleInputName">Country* </label>
                    <select  className="form-control tex-select select_btn" name="work_country" id="exampleInputName" value = {this.state.work_country} onChange={(e)=>this.setValue(e,'work_country')}>
                    <option className="unselect">Enter country</option>   
                        {
                            this.state.all_countries?.map((option) => (
                                <option value={option.country_code} key={option.country_code}>{option.country_name}</option>
                            ))
                        }    
                    </select>
                </div>
                {this.validator.message('work_country', this.state.work_country, 'required')}
                </div>
                <div className="col-md-4">
                <div className="form-group">
                    <label for="exampleInputOfficeN">Office number </label>
                    <input type="text" name="office_number" className="form-control" id="exampleInputOfficeN"  placeholder="" value = {this.state.office_number} onChange={(e)=>this.setValue(e,'office_number')}/>
                </div>
                {this.validator.message('office_number', this.state.office_number, 'required')}
                </div>
                <div className="col-md-4">
                <div className="form-group">
                    <label for="exampleInputMonthlyI">Monthly income* </label>
                    <input type="text" name="monthly_income" className="form-control" id="exampleInputMonthlyI"  placeholder="" value = {this.state.monthly_income} onChange={(e)=>this.setValue(e,'monthly_income')}/>
                </div>
                {this.validator.message('monthly_income', this.state.monthly_income, 'required')}
                </div>
            </div>
            <div className="row row-theme">
                <div className="col-md-12">
                <h5 className="as-tittle-h5">Supervisor information</h5>
                </div>
                <div className="col-md-6">
                <div className="form-group">
                    <label for="exampleInputName">First Name* </label>
                    <input type="text" name="supervisor_first_name" className="form-control" id="exampleInputName"  placeholder="" value = {this.state.supervisor_first_name} onChange={(e)=>this.setValue(e,'supervisor_first_name')}/>
                </div>
                {this.validator.message('supervisor_first_name', this.state.supervisor_first_name, 'required')}
                </div>
                <div className="col-md-6">
                <div className="form-group">
                    <label for="exampleInputName">Last Name* </label>
                    <input type="text" name="supervisor_last_name" className="form-control" id="exampleInputLastName"  placeholder="" value = {this.state.supervisor_last_name} onChange={(e)=>this.setValue(e,'supervisor_last_name')}/>
                </div>
                {this.validator.message('supervisor_last_name', this.state.supervisor_last_name, 'required')}
                </div>
                <div className="col-md-6">
                <div className="form-group">
                    <label for="exampleInputName">Email* </label>
                    <input type="email" name="supervisor_email" className="form-control" id="exampleInputName" placeholder="" value = {this.state.supervisor_email} onChange={(e)=>this.setValue(e,'supervisor_email')}/>
                </div>
                {this.validator.message('supervisor_email', this.state.supervisor_email, 'required')}
                </div>
                <div className="col-md-6">
                <div className="form-group">
                    <label for="exampleInputName">Phone Number* </label>
                    <input type="email" name= "supervisor_phone_number" className="form-control poppins-font" id="exampleInputName" placeholder="" value = {this.state.supervisor_phone_number} onChange={(e)=>this.setValue(e,'supervisor_phone_number')}/>
                </div>
                {this.validator.message('supervisor_phone_number', this.state.supervisor_phone_number, 'required')}
                </div>
                <div className="col-md-12">
                <hr></hr>
                </div>
            </div>
        </div>           
        </>
    )
   }
}

export default AdditionalEmploymentHistory;