import { authenticatedGet, authenticatedPost } from "./core.js"
export const getUserDetails = async () => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'get-details');
}

export const updateUserGeneralInformation = async(request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'update-users');
}

export const updateUserSetting = async (request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'update-user-setting');
 }

export const updateUserProfileImage = async (request) => {
return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'update-profile-photo');
}

export const updatePassword = async (request) => {
return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'update-user-password');
}

export const generateScreeningRequest = async (request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'generate-screening-request');
}

export const addCard = async (request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'add-card');
}

export const getAllCards = async () => {
    return await authenticatedGet(process.env.REACT_APP_BASE_URL+'get-all-card');
}
export const generateToken = async (request) => {
    return await authenticatedPost(request, process.env.REACT_APP_BASE_URL+'transunion/generate-token');
}

 