import React from 'react';
import SimpleReactValidator from 'simple-react-validator';
class CustomField extends React.Component {
    constructor(props){
        super(props);
           this.state = {
            custom_featue : ''
           };
           this.validator = new SimpleReactValidator();
    }

    validateForm = (e) => {
        e.preventDefault();
        if(this.validator.allValid()) {
            let oldDataArray = this.props.oldData;
            if(oldDataArray.indexOf(this.state.custom_featue) !== -1){
                this.props.showMessage('You have already added this, please try something else.', '');
            } else {
                this.props.addCustomInput(this.state.custom_featue, this.props.type);
                this.setState({custom_featue : ''});
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setValue = (e, field)=>{
        this.setState({...this.state, [field]:e.target.value});
   }
   handleEnter = (e) => {
    if(e.keyCode === 13){
        e.preventDefault();
        this.validateForm(e);
    }
   }
    render() {
        return (
            <>
                <form className="form-inline form-add-custom-feature" onKeyDown={(e) => { this.handleEnter(e)}} >
                    <div className="form-group">
                        <input type="text" className="form-control"  placeholder={this.props.label} value={this.state.custom_featue} onChange={(e)=>this.setValue(e,'custom_featue')}/>
                        { this.validator.message('custom_featue', this.state.custom_featue, 'required') }
                    </div>
                    
                    <a href="/#" onClick={(e)  => { this.validateForm(e)}}><i className="mdi mdi-plus-box"></i></a>
                    <a  href="/#"><i className="mdi mdi-delete" onClick={(e)  => {e.preventDefault(); this.props.removeCustomInput(this.props.type)}}></i></a>
                </form>
            </>
            );
    }
}

export default CustomField;
 