import {  toast } from 'react-toastify';
const settings = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

const showNotification = (message, type) => {
    switch(type){
        case "success":
            toast.success(message, settings);
            break;
        case "warning":
            toast.warning(message, settings);
            break;
        case "error":
            toast.error(message, settings);
            break;
        case "info":
            toast.info(message, settings);
            break;
        default:
            toast(message);
            break;
    }
}

export default showNotification;
