import { Outlet } from "react-router-dom";
import TopNav from "../common/TopNav";
import LeftNav from "../common/LeftNav";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "izitoast-react/dist/iziToast.css";


const Layout = () => {

  return (
    <>
    <ToastContainer/>
       <div className="container-scroller">
          <TopNav/>
          <div className="container-fluid page-body-wrapper">
          <LeftNav/>
              <div className="main-panel">
                  <Outlet/>
              </div>
          </div>
      </div>
    </>
  )
};

export default Layout;
