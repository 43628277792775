import React from "react";
import {getAllCountry } from "../../Services/Miscellaneous";
import SimpleReactValidator from 'simple-react-validator';

class AddAddress extends React.Component{
    constructor(props){
        super(props)
            this.state = {
                street_address : '',
                city : '',
                state : '',
                zip : '',
                country : '',
                all_countries: [],
            };
        this.validator = new SimpleReactValidator();
        this.submitForm =async ()=> {
            if (this.validator.allValid()) {
                return { status : true , data:this.state };
            } else {
                this.validator.showMessages();
                this.forceUpdate();
                return { status : false , data:[] };
            }
        }
    }

    setValue = (e, field)=>{
        this.setState({...this.state, [field]:e.target.value});
    }

    componentDidMount = async () => {
        let resCountry = await getAllCountry();
        this.setState({all_countries: resCountry.data.data});
        if(this.props.addressDetails){
            this.populateDetails(this.props.addressDetails);
        }
    }

    populateDetails = (details) => {
        this.setState({
            id : details.id,
            street_address : details.address,
            city : details.city,
            state : details.state,
            zip : details.postal_code,
            country : details.country,
        });

        // Multiple Emergency Contact Form
    }

    render(){
        return(
            <>
                <div className="card card-accordion-theme mb-4">
                    <div className="card-header arrow-add-right d-flex align-items-start">
                        <span  className="btn-header-link collapsed" data-bs-toggle="collapse" onClick={ (e) => { this.setState({toggleAddress : !this.state.toggleAddress})}}
                            role="button" aria-expanded="false" aria-controls="collapseExample">
                            <h3 className="d-inline-block align-middle"><i className="mdi mdi-plus-circle-outline"></i>
                                <span>Add Address</span>
                            </h3>
                        </span>
                        </div>
                        <div className={"collapse " + (this.state.toggleAddress ? "show":"")} id="collapseExample">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEnterAddress">Street address</label>
                                        <input type="text" className="form-control" name="street_address" id="exampleInputEnterAddress" placeholder="Enter Address" value={this.state.street_address} onChange={(e) =>this.setValue(e,'street_address')}/>
                                    </div>
                                    {this.validator.message('street_address', this.state.street_address, 'required')}
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputCity">City*</label>
                                        <input type="text" className="form-control" name="city" id="exampleInputCity" placeholder="Enter city" value={this.state.city} onChange={(e) =>this.setValue(e,'city')}/>
                                    </div>
                                    {this.validator.message('city', this.state.city, 'required')}
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputStateRegion">State / Region*</label>
                                        <input type="text" className="form-control" name="state" id="exampleInputStateRegion" placeholder="Enter state / Region" value={this.state.state} onChange={(e) =>this.setValue(e,'state')}/>
                                    </div>
                                    {this.validator.message('state', this.state.state, 'required')}
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputZip">Zip*</label>
                                        <input type="text" className="form-control" name="zip" id="exampleInputZip" placeholder="Enter zip" value={this.state.zip} onChange={(e) =>this.setValue(e,'zip')}/>
                                    </div>
                                    {this.validator.message('zip', this.state.zip, 'required')}
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputCountry">Country*</label>
                                        <select className="form-control tex-select select_btn" name="country" id="exampleInputCountry" value={this.state.country} onChange={(e) =>this.setValue(e,'country')}>
                                            <option className="unselect">Enter country</option>
                                            {
                                                this.state.all_countries.map((option,i) => (
                                                    <option value={option.country_code} key={"Entercountry" + i}>{option.country_name}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                    {this.validator.message('country', this.state.country, 'required')}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default AddAddress;
