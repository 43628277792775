import React from "react";
import {  getQueryStringParams } from "../../../helpers/Utils";
import { getTransactionById, deleteTransaction} from '../../../Services/Transaction';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import showNotification from "../../../helpers/Notify";
class TransactionDetails extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            details : {},
            deleteId : null

        };
    }

    componentDidMount = async () => {
        let params = getQueryStringParams();
        if(typeof params['id'] != 'undefined'){
            let transaction_details_res = await getTransactionById(params['id']);
            console.log(transaction_details_res, 'transaction_details_res');
            this.setState({
                details : transaction_details_res.data.data
            });

            //this.populateContactDetails(transaction_details_res.data.data);
        }

    }


    deleteThisTransaction(id) {
        this.setState({deleteId : id});
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.hideAlert();
            }
          });

    }

    onCancel(){
        this.setState({
            alert: null
        });

    }

    hideAlert = async () => {
        let deleteResponse = await deleteTransaction(this.state.deleteId);
        if(deleteResponse.data.success){
            showNotification(deleteResponse.data.message, 'success');
            await this.fetchData();
        } else {
            showNotification(deleteResponse.data.message, 'warning');
        }
    }
    render(){
        return(
            <>
            {
                <div className="content-wrapper">
                    <div className="page-header">
                        <div className="left-cell">
                            <h3 className="page-title">Transaction Deatils</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to = "/transaction">Transaction </Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> details</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="right-cell">
                            <form action="">
                                <div className="breadcrumb date_picker">
                                <div className="breadcrumb-item active calender" aria-current="page">
                                    <div className="ui calendar" id="example2">
                                        <div className="ui input left icon">
                                            <i className="calendar icon"></i>
                                            <input type="text" placeholder="calendar"/>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>


                    <div className="all_transaction_record_box row mb-4">
                        <div className="inner_all_transaction_record_box d-flex">
                            <div className="action-btns-group ml-auto">
                                <div className="innner-action-btns-group">
                                <Link  to ={"/edit-transaction?id="+this.state.details.id} type="button" className="btn btn-dark btn-fw">edit</Link>
                                <button type="button" className="btn btn-dark btn-fw">duplicate</button>
                                <button type="button" className="btn btn-dark btn-fw">void</button>
                                <button type="button" className="btn btn-dark btn-fw">print</button>
                                <button type="button" className="btn btn-dark btn-fw" title="Delete"  onClick={() => {this.deleteThisTransaction(this.state.details.id)}}>delete</button>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row row-theme mt-4">
                        <div className="col-md-4">
                            <div className="stretch-card flex-column flp-section-two">
                                <div className="card card-transaction mb-4">
                                <div className="card-header d-flex align-items-start">
                                    <h3 className="d-inline-block align-middle">Transaction</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row row-theme">
                                        <div className="col-md-12">
                                            <div className="transaction-top">
                                            <h4 className="heading-h4 text-center poppins-font">{this.state.details.description_title}</h4>
                                            <p className="text-muted poppins-font">Transaction on { this.state.details.transaction_date }</p>
                                            </div>
                                            <section className="svg-container svg-rent-progress">
                                            <svg className="radial-progress" data-countervalue="1000" viewBox="0 0 80 80">
                                                <circle className="bar-static" cx="40" cy="40" r="35"></circle>
                                                <circle className="bar--animated" cx="40" cy="40" r="35" style={{"stroke-dashoffset": "217.8"}}></circle>
                                                <text className="countervalue start" x="50%" y="48%" transform="matrix(0, 1, -1, 0, 80, 0)">${this.state.details.amount}</text>
                                            </svg>
                                            <div className="paid-svg-rent-progress">Paid</div>
                                            </section>
                                            <div className="rent-paid-lp d-flex justify-content-between poppins-font">
                                            <div className="left-rent-paid-lp font16"><strong>$0.00</strong> Left</div>
                                            <div className="right-rent-paid-lp font16"><strong>${ this.state.details.amount }</strong> Paid</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="card-header d-flex align-items-start">
                                    <h3 className="d-inline-block align-middle">Payers</h3>
                                </div>

                                <div className="card-body">
                                    <div className="list-payers">
                                        <Link to={"/view-tenant?id="+(this.state?.details?.payer?.id ? this.state.details.payer.id : '5')}>
                                            {
                                                this.state.details?.payer?.image ? <img src={ this.state.details.payer.image } alt=""/> : "" 
                                            }
                                            
                                            <span>
                                                { this.state.details?.payer?.first_name  + ' ' + this.state.details?.payer?.last_name}
                                                </span>
                                        </Link>
                                        {/* <a href="/#"><img src="assets/admin/images/tenanat-1.jpg" alt=""/> <span>Melanie Sandy</span></a>
                                        <a href="/#"><img src="assets/admin/images/tenanat-1.jpg" alt=""/> <span>Destiny McGarrahan</span></a> */}
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="stretch-card flex-column flp-section-two">
                                <div className="card id-card-summary mb-4">
                                <div className="card-header d-flex align-items-start">
                                    <h3 className="d-inline-block align-middle">Summary <small>(Invoice details)</small></h3>
                                </div>
                                <div className="card-body">
                                    <div className="row row-theme">
                                        <div className="col-md-12">
                                            <ul className="list-md mb-4">
                                            <li><strong>Property</strong><span> <span className="colu">:</span> &nbsp;
                                            {
                                                this.state.details?.property?.property_name
                                            }</span></li>
                                            <li><strong>Transaction ID </strong><span> : &nbsp; { this.state.details?.transaction_details?.ssl_txn_id}</span></li>
                                            <li><strong>Type</strong><span> : &nbsp; Income / Recurring Monthly </span></li>
                                            <li><strong>Lease</strong><span> : &nbsp; 28</span></li>
                                            </ul>
                                            <p>Rent Payment for <strong> { this.state.details.transaction_date} </strong>. </p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="stretch-card flex-column flp-section-two">
                                <div className="card mb-4">
                                <div className="card-header d-flex align-items-start">
                                    <h3 className="d-inline-block align-middle"> Payments & Activity <small>(1 records)</small>  </h3>

                                </div>
                                <div className="card-body">
                                    <div className="data-table-box data-table-box-nw">
                                        <table className="table table-striped dt-table-custom">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Date and time</th>
                                                <th>Item amount</th>
                                                <th style={{"width":"60px"}}>&nbsp;</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{ this.state.details?.transaction_details?.ssl_txn_id} </td>
                                                <td>{this.state.details?.payer?.first_name  + ' ' + this.state.details?.payer?.last_name}</td>
                                                <td>{ this.state.details.transaction_date}</td>
                                                <td className="text-success">${ this.state.details.amount  } </td>
                                                <td>
                                                    <div className="dropdown dropdown-action">
                                                        <a href="/#" className="btn btn-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="mdi mdi-dots-vertical"></i>
                                                        </a>
                                                        <ul className="dropdown-menu">
                                                        <li><a className="dropdown-item" href="/#">Edit</a></li>
                                                        <li><a className="dropdown-item" href="/#">Refund</a></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="stretch-card flex-column flp-section-two">
                                <div className="card mb-4">
                                    <div className="card-header d-flex align-items-start">
                                        <h3 className="d-inline-block align-middle">Attachments</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row row-theme">
                                            <div className="col-md-12">
                                                <ul className="list-group list-attachments font16">
                                                <li className="list-group-item"><i className="mdi mdi-file-document"></i>Seeking-professional-financial...2021.pdf</li>
                                                {/* <li className="list-group-item"><i className="mdi mdi-file-document"></i>Seeking-professional-financial...2021.pdf</li>
                                                <li className="list-group-item"><i className="mdi mdi-file-document"></i>Seeking-professional-financial...2021.pdf</li>
                                                <li className="list-group-item"><i className="mdi mdi-file-document"></i>Seeking-professional-financial...2021.pdf</li>
                                                <li className="list-group-item"><i className="mdi mdi-file-document"></i>Seeking-professional-financial...2021.pdf</li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <div className="container-fluid d-flex justify-content-center">
                            <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright ©
                            <b>DigitalTenant</b> 2022. All Rights Reserved.</span>
                        </div>
                    </footer>

                </div>
            }


            </>
        )
    }
}
export default TransactionDetails;
