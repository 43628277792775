import React from "react";
import { fetchAllTenant, deleteTenant } from "../../../Services/Tenants" ;
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import showNotification from "../../../helpers/Notify";
import Dropdown from 'react-bootstrap/Dropdown';

class ContactListing extends React.Component{
    constructor (props){
        super (props);
        this.state = {
            allTenants : [],
            alert: null,
            deleteId : null,
            searchTenant:''
        };
    }

    componentDidMount = async() => {
        await this.fetchData();
    }

    fetchData = async ( filters = {} ) => {
        let allTenantRes = await fetchAllTenant(filters);
        this.setState({
            allTenants : allTenantRes.data.data
        });
    }

    getAddress = (r) => {
       return  r.address +', '+r.unit +', '+r.city +', '+r.state +', '+r.country+' ('+r.postal_code+')';
    }
    setimage = (e) => {
        e.target.src = "assets/admin/images/profile-img.jpg";
    }

    deleteThisGoal(id) {
        this.setState({deleteId : id});
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.hideAlert();
            }
          })
      }

    hideAlert = async () => {
        let deleteResponse = await deleteTenant(this.state.deleteId);
        if(deleteResponse.data.success){
            showNotification(deleteResponse.data.message, 'success');
            await this.fetchData();
        } else {
            showNotification(deleteResponse.data.message, 'warning');
        }
    }

    handleSearchtenant = async (e) => {
        var keyWords = e.target.value;
        this.setState({searchTenant:keyWords});
        this.fetchData({search:keyWords});
    }

    render(){
        return (
            <>
                <div className="content-wrapper">
                    <div className="page-header">
                    <div className="left-cell">
                        <h3 className="page-title">Contacts / Tenants</h3>
                        <nav aria-label="breadcrumb">
                        </nav>
                    </div>

                    </div>
                    <div className="page-header-two">
                    <div className="inner-page-header-two">
                        <div className="cell-row">
                            <div className="left-cell">
                                <h4>
                                 <span>Total :</span> { this.state.allTenants.length}
                                </h4>
                            </div>
                            <div className="right-cell">
                                <div className="inner-right-cell">
                                <ul className="navbar-nav navbar-nav-right">
                                    <li className="search-field d-none d-md-block">
                                        <form className="d-flex align-items-center h-100" action="#">
                                            <div className="input-group">
                                            <input type="text" className="form-control bg-transparent border-0" placeholder="Search Tenants" value={this.state.searchTenant||''} onChange={(e) => this.handleSearchtenant(e) } />
                                            <div className="input-group-prepend bg-transparent">
                                                <img src="assets/admin/images/dashboard/search-icon.png" alt=""/>
                                            </div>
                                            </div>
                                        </form>
                                    </li>
                                    {/* <Dropdown as="li" className="custom-dropdown filter-item filter-item-pp">
                                        <Dropdown.Toggle as="a" >
                                            <i className="mdi mdi-filter-outline"></i> filter
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu align="end">
                                            <form>
                                                <div class="form-group">
                                                    <h5>Property &amp; Units </h5>
                                                    <select class="form-select" aria-label="Default select example">
                                                    <option selected="">Cavalier CT, St Louis</option>
                                                    <option value="1">Ellingwood - Colorado Springs</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <h5>Lease status</h5>
                                                    <select class="form-select" aria-label="Default select example">
                                                        <option selected="">Active</option>
                                                        <option value="1">Future</option>
                                                        <option value="2">Signature pending</option>
                                                        <option value="3">Insurance pending</option>
                                                        <option value="4">Expires in</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <h5>Lease type</h5>
                                                    <select class="form-select" aria-label="Default select example">
                                                        <option selected="">Fixed</option>
                                                        <option value="1">Month-to-month</option>
                                                    </select>
                                                </div>
                                                <div class="form-group mb-0">
                                                    <h5>Connection</h5>
                                                    <select class="form-select" aria-label="Default select example">
                                                        <option selected="">Connected</option>
                                                        <option value="1">Not connected</option>
                                                    </select>
                                                </div>
                                                <div class="custom-dropdown-footer">
                                                    <button type="button" class="btn btn-primary">Show result</button>
                                                </div>
                                            </form>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                    <li className="new-property-item">
                                    <Link to="/add-tenant">
                                        + add Tenant
                                    </Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="tenants-list-view mt-4 mb-5">
                        <div className="inner-tenants-list-view">
                            {
                                this.state.allTenants.length > 0 ? (
                                    this.state.allTenants.map((row)=>{
                                        return (
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="card">
                                                    <div className="card-body p-0">
                                                        <div className="row  mx-0 pt-4 px-2 align-items-center ">
                                                            <div className="col-xl-3 mb-sm-4 mb-3 align-items-center  media">
                                                                <div className="img-box-cus online">
                                                                <img className="me-sm-4 me-3 img-fluid rounded" src={row.image ? row.image : 'assets/admin/images/profile-img.jpg' }  alt="tenanat-1"/>
                                                                </div>
                                                                <div className="media-body">
                                                                <h3 className=" text-black font-w600 mb-1">{ row.first_name  + ' ' + row.last_name }</h3>
                                                                <span className="d-block mb-lg-0 mb-0">{ row.company_name }</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 mb-3">
                                                                <small className="mb-2 d-block">Location</small>
                                                                <span className="text-black font-w600">{ row.address ?  this.getAddress(row.address) : 'N/A'}</span>
                                                            </div>
                                                            <div className="col-xl-3  mb-3 text-lg-center">
                                                                <small className="mb-2 d-block">Phone Number</small>
                                                                <span className="text-black font-w600"> { row.phone }</span>
                                                            </div>
                                                            <div className="col-xl-2 mb-3">
                                                                <small className="mb-2 d-block">Email Address</small>
                                                                <span className="text-black font-w600">{ row.email }</span>
                                                            </div>
                                                            <div className="col-xl-2  mb-4 d-flex  action-btns-cell justify-content-end ">
                                                                <div className="template-demo d-flex justify-content-between flex-nowrap ">

                                                                    <Link to={ "/view-tenant?id=" + row.id }  className="btn  btn-rounded btn-icon view-btn" >
                                                                            <i className="mdi mdi-eye"></i>
                                                                    </Link>
                                                                    <Link to= { "/edit-tenant?id=" + row.id }  className="btn  btn-rounded btn-icon edit-btn">
                                                                            <i className="mdi mdi-lead-pencil"></i>
                                                                    </Link>
                                                                    <button type="button" className="btn btn-rounded btn-icon delete-btn" title="Delete"  onClick={() => this.deleteThisGoal(row.id)}>
                                                                        <i className="mdi mdi-delete"></i>
                                                                    </button>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : <span className="no-data-found">No user found.<br/>Please add some to list down here.</span>
                            }

                        </div>
                    </div>


                </div>
            </>
        )
    }
}
export default ContactListing;
