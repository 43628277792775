import React from "react";
import { listMileage } from "../../../Services/Mileage";
import { Link} from 'react-router-dom';
import {withRouter } from "../../../helpers/withRouter";
import  Paginate  from "../Comman/Paginate";
import Dropdown from 'react-bootstrap/Dropdown';


class List extends React.Component{

    constructor (props){
        super (props);
        this.state = {
        list : [],
        total :0,
        search:null,
        page:0,
        limit:10,
        mile:0,
        deduction:0,
        dropdownIsOpen:false,
        resetData:false,
        searchProperty:'',
        filters:{},
        };
    }
    componentDidMount = async () => {
      let parameter = {
            search:this.state.search,
            page:this.state.page,
            limit:this.state.limit
      }

      let result = await listMileage(parameter);
      if( result ){
        let lists = result.data.data
        this.setState({list:lists.data.result});
        this.setState({total:lists.data.total});
        this.setState({deduction:lists.data.total_deduction});
        this.setState({mile:lists.data.total_mile});
      }
    }

    handleFilterData = async (field,e) => {
        let filters = this.state.filters;
        filters[field] = e.target.value
        this.setState({ filters })
    }

    handleResetData = async () =>{
        this.setState({resetData:false})
        let parameter = {
            search:this.state.search,
            page:this.state.page,
            limit:this.state.limit
        }
        this.setState({filters:{}})

        let result = await listMileage(parameter);
        if( result ){
            let lists = result.data.data
            this.setState({list:lists.data.result});
            this.setState({total:lists.data.total});
            this.setState({deduction:lists.data.total_deduction});
            this.setState({mile:lists.data.total_mile});
        }
    }

    searchMileage = async (value) => {
        let parameter = {
            search:value,
            page:this.state.page,
            limit:this.state.limit
        }
        this.setState({search:value});
        let lists = await listMileage(parameter);
        this.setState({list:lists.data.data.data.result});
        this.setState({total:lists.data.data.data.total});
    }

    handlePageCallback = async (event) => {
        let parameter = {
            search:this.state.search,
            page:parseInt(event.selected),
            limit:this.state.limit
        }
        this.setState({page:event});
        let lists = await listMileage(parameter);
        this.setState({list:lists.data.data.data.result});
    }

    handleFilterSubmit = async (e) => {
        e.preventDefault();
        if( typeof this.state.filters.start_date != 'undefined' && typeof this.state.filters.end_date != 'undefined' ){
            this.setState({list :[]});
            let parameter = {
                search:"",
                page:this.state.page,
                limit:this.state.limit,
                filters:this.state.filters

            }

            let result = await listMileage(parameter);
                if( result ){
                    let lists = result.data.data
                    this.setState({list:lists.data.result});
                    this.setState({total:lists.data.total});
                    this.setState({deduction:lists.data.total_deduction});
                    this.setState({mile:lists.data.total_mile});
                }
            this.setState({dropdownIsOpen:false});
            this.setState({resetData:true});
        }
    }

    toggleDropdown = () =>{  this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen }) }

    render(){

        return (
            <>
            <div className="content-wrapper">
                <div className="page-header">
                    <div className="left-cell">
                        <h3 className="page-title">Mileage</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">Mileage</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row top-grid-view">
                    <div className="col-md-4 stretch-card grid-margin">
                        <div className="card  card-img-holder text-white">
                            <div className="card-body">
                            <img src="assets/images/deduction-icon.png" className="card-img-absolute"
                                alt="total_maintenance" />
                            <h4 className="font-weight-normal mb-3 color-gray">Total Deduction 2022
                            </h4>
                            <h2 className="color-dark">{ this.state.deduction }</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 stretch-card grid-margin">
                        <div className="card  card-img-holder text-white">
                            <div className="card-body">
                            <img src="assets/images/miles-driven-icon.png" className="card-img-absolute "
                                alt="miles-driven-icon" />
                            <h4 className="font-weight-normal mb-3 color-gray">Miles Driven 2022
                            </h4>
                            <h2 className="color-dark">{ this.state.mile }</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 stretch-card grid-margin">
                        <div className="card  card-img-holder text-white">
                            <div className="card-body">
                            <img src="assets/images/driven-icon.png" className="card-img-absolute" alt="driven-icon" />
                            <h4 className="font-weight-normal mb-3 color-gray">Drives 2022
                            </h4>
                            <h2 className="color-dark">{ this.state.total }</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-header-two">
                    <div className="inner-page-header-two">
                        <div className="cell-row ">
                            <div className="left-cell">
                            <h4 className="poppins-font">
                                {this.state.total} <span>Total</span>
                            </h4>
                            </div>
                            <div className="right-cell">
                            <div className="inner-right-cell">
                                <ul className="navbar-nav navbar-nav-right">
                                    <li className="search-field d-none d-md-block">
                                        <form className="d-flex align-items-center h-100" action="#">
                                        <div className="input-group">
                                            <input type="text" className="form-control bg-transparent border-0"
                                                placeholder="Search Mileage" value={this.state.search || ''} onChange={(input) => this.searchMileage(input.target.value)} />
                                            <div className="input-group-prepend bg-transparent">
                                                <img src="assets/images/dashboard/search-icon.png" alt="" />
                                            </div>
                                        </div>
                                        </form>
                                    </li>
                                    <Dropdown as="li" className="custom-dropdown filter-item filter-item-pp"  onToggle={(e) =>  this.toggleDropdown(e)}  show={this.state.dropdownIsOpen} >
                                        <Dropdown.Toggle as="a" align="end" className="btn" >
                                            <i className="mdi mdi-filter-outline"></i> filter by date
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu align="end"  >
                                                <form onSubmit={this.handleFilterSubmit.bind(this)} >
                                                    <div className="form-group mb-0">
                                                        <h5>Search by date</h5>
                                                        <div className="row row-theme row-price-range">
                                                            <div className="col">
                                                            <div className="">
                                                                <input type="date" value={this.state.filters.start_date || ''} onChange={this.handleFilterData.bind(this,'start_date')}  className="form-control poppins-font" aria-label="Recipient's username" aria-describedby="basic-addon2"  />
                                                            </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className=" ">
                                                                <input type="date" value={this.state.filters.end_date || ''} onChange={this.handleFilterData.bind(this,'end_date')} className="form-control poppins-font" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="custom-dropdown-footer">
                                                        <button type="submit" className="btn btn-primary">Show result</button>
                                                    </div>
                                                </form>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {
                                        this.state.resetData?
                                            <li className="import-item">
                                                <button type="button" class="btn btn-secondary" onClick={(e) => this.handleResetData() } >Reset</button>
                                            </li>
                                        :''
                                    }
                                    {
                                        (JSON.parse(localStorage.getItem('userDetalis')).user_type != 'admin')?
                                            <li className="add-transaction cus-btn">
                                                <Link to="../add-mileage" className="bg-light-green" ><i className="mdi mdi-plus"></i> Add
                                                Mileage</Link>
                                            </li>
                                        :''
                                    }
                                    <li className="download-item cus-btn">
                                        <a  className="bg-orange">Export <i
                                        className="fa fa-download mr-3"></i></a>
                                    </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4 data-table-box maintenance-data-table-box">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body pt-0">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                        <th>
                                            <span className="form-check form-check-flat form-check-primary">
                                            <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" /><i className="input-helper"></i></label>
                                            </span>
                                        </th>
                                        <th>Trip Date</th>
                                        <th>Business Name</th>
                                        <th>From Property</th>
                                        <th>To Property</th>
                                        <th>Purpose</th>
                                        <th>Miles</th>
                                        <th>Amount</th>
                                        <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    this.state.list.length > 0 ?
                                        this.state.list.map((row) =>
                                            <tr key={row.id}>
                                                <td>
                                                    <span className="form-check form-check-flat form-check-primary">
                                                    <label className="form-check-label">
                                                    <input type="checkbox" className="form-check-input" /><i className="input-helper"></i></label>
                                                    </span>
                                                </td>
                                                <td>
                                                    { row.trip_date }
                                                </td>
                                                <td>{ row.business_name }</td>
                                                <td> { row.from_properties.street_address }</td>
                                                <td> { row.to_properties.street_address }</td>
                                                <td> { row.purpose }  </td>
                                                <td> { row.miles }  </td>
                                                <td> $ { row.amount }  </td>
                                                <td> <Link to=   {"details/"+row.id} mileageId={row.id} className="vd-btn">View Details</Link></td>
                                            </tr>
                                        )
                                        : <tr>
                                            <td colSpan={10}><div className="no-data-table">No Data Found</div></td>
                                          </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                             <Paginate pageCount={this.state.total}  limit={this.state.limit} handlePageCallback = {this.handlePageCallback} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}
export default List;
