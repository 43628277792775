import { Timestamp,db,addDoc,collection } from '../firebase';

const PushNotification = async (data) => {
        console.log('ddd', data );
        if( data.length > 0 ){
            for(let i = 0;i < data.length;i++ ){
                var title     = data[i].title;
                var body      = data[i].body;
                var images    = data[i].images;
                var unread    = true;
                let timestamp = Timestamp.fromDate(new Date());
                var resiver_id = data[i].reciver_id;
                var sender_id  = data[i].sender_id;
                var goto = "";
                if( typeof data[i].goto !== 'undefined' ){
                    goto       = data[i].goto;
                }
                addDoc(collection(db,"notification",resiver_id,"list"),{
                    title,
                    body,
                    resiver_id,
                    sender_id,
                    images,
                    unread,
                    timestamp,
                    goto
                })
            }
        }
}

export default PushNotification;


