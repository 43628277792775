import React from "react";
import { updatePassword } from "../../Services/UserProfile";
import SimpleReactValidator from 'simple-react-validator';
import showNotification from "../../helpers/Notify";
import Loading from "../../common/Misc/Loader";

class UpdateEmail extends React.Component{
    constructor (props){
        super (props);
        this.state = {
            password: '',
            password_confirmation: "",
            password_type : 'password',
            password_confirmation_type: "password",
            requestInprogress : false 
        };

        this.validator = new SimpleReactValidator();
            this.submitForm = async ()=> {
              if (this.validator.allValid()) {
                this.setState({requestInprogress : true});

                let res = await updatePassword(
                    {
                        password :  this.state.password,  
                        password_confirmation : this.state.password_confirmation
                    }
                );
                console.log(res, 'RES RES');
                this.setState({requestInprogress : false});            
                if(res.data.success){
                   showNotification(res.data.message, 'success');
                } else {
                   showNotification(res.data.message, 'error');
                }
               
              } else {
              this.validator.showMessages();
              this.forceUpdate();
              }
            }
    }

    setValue = (e, field) => {
        this.setState({[field] : e.target.value})
    }

    toggleInputType = (e, fileld_type) => {
        e.target.classList.toggle("mdi-eye")
        e.target.classList.toggle("mdi-eye-off");
        let type = this.state[fileld_type];
        this.setState({ [fileld_type] : type === 'password' ? 'text' : 'password' })
    }

    render(){
        return (
            <>
            <div className="card mb-4">
                <div className="card-header d-flex align-items-start">
                    <h3 className="d-inline-block align-middle">Update Password
                    </h3>
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input id="password-field" type={this.state.password_type} className="form-control" name="password"  value={this.state.password} onChange={(e)=>this.setValue(e,'password')}/>
                    <span toggle="#password-field" className="mdi mdi-eye  field-icon toggle-password" onClick={(e) => this.toggleInputType(e, 'password_type') }></span>
                </div>                
                {this.validator.message('password', this.state.password, 'required')}

                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Confirm Password</label>
                    <input id="password-field" type={this.state.password_confirmation_type} className="form-control" name="password" value={this.state.password_confirmation} onChange={(e)=>this.setValue(e,'password_confirmation')}/>
                    <span toggle="#password-field" className="mdi mdi-eye field-icon toggle-password" onClick={(e) => this.toggleInputType(e, 'password_confirmation_type') }></span>
                </div>                
                {this.validator.message('password_confirmation', this.state.password_confirmation, `required|in:${this.state.password}`, {messages: {in: 'Passwords need to match!'}})}                

                <button className="btn bg-orange btn-cus mr-2 text-white" onClick={() => this.submitForm() }  disabled={ this.state.requestInprogress}>{ this.state.requestInprogress ? (<Loading className="btn theme-btn-orange" msg="updating data..." />) : 'Update' }</button>
            </div>
            </>
            
        )
    }
}
export default UpdateEmail;