import React from "react";
class Reports extends React.Component{
    constructor (props){
        super (props);
        this.state = {

        };
    }
    render(){
        return (
            <>
                <div className="content-wrapper">
                <div className="page-header">
                    <div className="left-cell">
                        <h3 className="page-title">Reports </h3>
                    </div>
                    {/* <div className="right-cell">
                        <form action="">
                            <div className="breadcrumb date_picker">
                            <div className="breadcrumb-item active calender" aria-current="page">
                                <div className="ui calendar" id="example2">
                                    <div className="ui input left icon">
                                        <i className="calendar icon"></i>
                                        <input type="text" placeholder="calendar"/>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </form>
                    </div> */}
                </div>
                <div className="detail-cont">
                    <div className="row">
                       Contents
                    </div>
                </div>

                </div>
            </>
        )
    }
}
export default Reports;
