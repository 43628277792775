export const parking_options = [{
        label: "Call for availability",
        value: "call for availability",
    },
    {
        label: "Covered",
        value: "covered",
    },
    {
        label: "Dedicated Spot",
        value: "dedicated spot",
    },
    {
        label: "Driveway",
        value: "driveway",
    },
    {
        label: "Garage",
        value: "garage",
    },
    {
        label: "On street",
        value: "on street",
    },
    {
        label: "Private Lot",
        value: "private lot",
    },
    {
        label: "Other",
        value: "other",
    },
    {
        label: "None",
        value: "none",
    }
];

export const laundry_options = [{
        label: "In unit",
        value: "in unit",
    },
    {
        label: "On site",
        value: "on site",
    },
    {
        label: "Hook-ups",
        value: "hook ups",
    },
    {
        label: "Other",
        value: "other",
    },
    {
        label: "None",
        value: "none",
    },
];

export const air_conditioning_options = [{
        label: "Central air",
        value: "central air",
    },
    {
        label: "Window unit",
        value: "window unit",
    },
    {
        label: "Evaporative cooler",
        value: "evaporative cooler",
    },
    {
        label: "Other",
        value: "other",
    },
    {
        label: "None",
        value: "none",
    },
];


export const property_amenities = [{
        label: "Basketball court",
        value: "basketball court",
    },
    {
        label: "BBQ",
        value: "bbQ",
    },
    {
        label: "Business center",
        value: "Business center",
    },
    {
        label: "Clubhouse",
        value: "clubhouse",
    },
    {
        label: "Dog park",
        value: "dog park",
    },
    {
        label: "Elevator",
        value: "elevator",
    },
    {
        label: " Fitness center",
        value: "fitness center",
    },
    {
        label: "Game room",
        value: "game room",
    },
    {
        label: "Hot tub",
        value: "hot tub",
    },
    {
        label: "Near park",
        value: "near park",
    },
    {
        label: "Playground",
        value: "playground",
    },
    {
        label: "Pool",
        value: "pool",
    },
    {
        label: "Tennis court",
        value: "tennis court",
    },
    {
        label: "Theater room",
        value: "theater room",
    },
    {
        label: "Volleyball court",
        value: "volleyball court",
    },
    {
        label: "Pet washing station",
        value: "pet washing station",
    },


];

export const property_features = [{
        label: "Alarm",
        value: "alarm",
    },
    {
        label: "Furnished",
        value: "furnished",
    },
    {
        label: "Renovated",
        value: "renovated",
    },
    {
        label: "Hardwood floors",
        value: "hardwood floors",
    },
    {
        label: "Fireplace",
        value: "fireplace",
    },
    {
        label: "Fresh paint",
        value: "fresh paint",
    },
    {
        label: "Dishwasher",
        value: "dishwasher",
    },
    {
        label: "Walk-in closets",
        value: "walk in closets",
    },
    {
        label: "Internet",
        value: "internet",
    },
    {
        label: "Fenced yard",
        value: "fenced yard",
    },
    {
        label: "Tile",
        value: "tile",
    },
    {
        label: "Carpet",
        value: "carpet",
    },
    {
        label: "Storage",
        value: "Storage",
    },
    {
        label: "Unfurnished",
        value: "unfurnished",
    },
    {
        label: "Balcony, Deck, Patio",
        value: "balcony deck patio",
    },

];

export const beds = [{
        label: "1",
        value: "1",
    },
    {
        label: "2",
        value: "2",
    },
    {
        label: "3",
        value: "3",
    },
    {
        label: "4",
        value: "4",
    },
    {
        label: "5",
        value: "5",
    },
    {
        label: "6",
        value: "6",
    },
    {
        label: "7",
        value: "7",
    }, {
        label: "8",
        value: "8",
    }, {
        label: "9",
        value: "9",
    }, {
        label: "10",
        value: "10",
    }

];
export const baths = [{
        label: "1",
        value: "1",
    },
    {
        label: "1.5",
        value: "1.5",
    },
    {
        label: "2",
        value: "2",
    },
    {
        label: "2.5",
        value: "2.5",
    },
    {
        label: "3",
        value: "3",
    },
    {
        label: "3.5",
        value: "3.5",
    },
    {
        label: "4",
        value: "4",
    },
    {
        label: "4.5",
        value: "4.5",
    },
    {
        label: "5",
        value: "5",
    },
    {
        label: "5.5",
        value: "5.5",
    },
    {
        label: "6",
        value: "6",
    },
    {
        label: "6.5",
        value: "6.5",
    },
    {
        label: "7",
        value: "7",
    },
    {
        label: "7.5",
        value: "7.5",
    },
    {
        label: "8",
        value: "8",
    },
    {
        label: "8.5",
        value: "8.5",
    },
    {
        label: "9",
        value: "9",
    },
    {
        label: "9.5",
        value: "9.5",
    },
    {
        label: "10",
        value: "10",
    },
    {
        label: "10.5",
        value: "10.5",
    },

];
export const unit_type = [{
    label: 'Room',
    value: 'room'
}, {
    label: 'Apartment',
    value: 'apartment'
}];


export const property_utilities = [{
        label: "Heat",
        value: "heat",
    },
    {
        label: "Electricity",
        value: "electricity",
    },
    {
        label: "Gas",
        value: "gas",
    },
    {
        label: "Water",
        value: "water",
    },
    {
        label: "High Speed Internet",
        value: "high speed internet",
    },
    {
        label: "Sewer",
        value: "sewer",
    },
    {
        label: "Landscaping",
        value: "landscaping",
    },
    {
        label: "Lawn Maintanence",
        value: "lawn maintanence",
    },

];

export const property_appliances = [{
        label: "Dishwasher",
        value: "dishwasher",
    },
    {
        label: "Air Conditioning",
        value: "air conditioning",
    },
    {
        label: "Range",
        value: "range",
    },
    {
        label: "Grill",
        value: "grill",
    },
    {
        label: "Garbage Disposal",
        value: "garbage disposal",
    },
    {
        label: "Washer & Dryer Hookup",
        value: "washer & dryer hookup",
    },
    {
        label: "Microwave",
        value: "microwave",
    },
    {
        label: "Washer & Dryer On Site",
        value: "washer & dryer on site",
    },
    {
        label: "Washer & Dryer",
        value: "washer & dryer",
    },
    {
        label: "Oven",
        value: "oven",
    },
    {
        label: "Refrigerator",
        value: "refrigerator",
    },

];

export const time_zone = [
    { value: 'Europe/Andorra', label: 'Europe/Andorra' },
    { value: 'Asia/Dubai', label: 'Asia/Dubai' },
    { value: 'Asia/Kabul', label: 'Asia/Kabul' },
    { value: 'Europe/Tirane', label: 'Europe/Tirane' },
    { value: 'Asia/Yerevan', label: 'Asia/Yerevan' },
    { value: 'Antarctica/Casey', label: 'Antarctica/Casey' },
    { value: 'Antarctica/Davis', label: 'Antarctica/Davis' },
    { value: 'Antarctica/DumontDUrville', label: 'Antarctica/DumontDUrville' },
    { value: 'Antarctica/Mawson', label: 'Antarctica/Mawson' },
    { value: 'Antarctica/Palmer', label: 'Antarctica/Palmer' },
    { value: 'Antarctica/Rothera', label: 'Antarctica/Rothera' },
    { value: 'Antarctica/Syowa', label: 'Antarctica/Syowa' },
    { value: 'Antarctica/Troll', label: 'Antarctica/Troll' },
    { value: 'Antarctica/Vostok', label: 'Antarctica/Vostok' },
    { value: 'America/Argentina/Buenos_Aires', label: 'America/Argentina/Buenos_Aires' },
    { value: 'America/Argentina/Cordoba', label: 'America/Argentina/Cordoba' },
    { value: 'America/Argentina/Salta', label: 'America/Argentina/Salta' },
    { value: 'America/Argentina/Jujuy', label: 'America/Argentina/Jujuy' },
    { value: 'America/Argentina/Tucuman', label: 'America/Argentina/Tucuman' },
    { value: 'America/Argentina/Catamarca', label: 'America/Argentina/Catamarca' },
    { value: 'America/Argentina/La_Rioja', label: 'America/Argentina/La_Rioja' },
    { value: 'America/Argentina/San_Juan', label: 'America/Argentina/San_Juan' },
    { value: 'America/Argentina/Mendoza', label: 'America/Argentina/Mendoza' },
    { value: 'America/Argentina/San_Luis', label: 'America/Argentina/San_Luis' },
    { value: 'America/Argentina/Rio_Gallegos', label: 'America/Argentina/Rio_Gallegos' },
    { value: 'America/Argentina/Ushuaia', label: 'America/Argentina/Ushuaia' },
    { value: 'Pacific/Pago_Pago', label: 'Pacific/Pago_Pago' },
    { value: 'Europe/Vienna', label: 'Europe/Vienna' },
    { value: 'Australia/Lord_Howe', label: 'Australia/Lord_Howe' },
    { value: 'Antarctica/Macquarie', label: 'Antarctica/Macquarie' },
    { value: 'Australia/Hobart', label: 'Australia/Hobart' },
    { value: 'Australia/Currie', label: 'Australia/Currie' },
    { value: 'Australia/Melbourne', label: 'Australia/Melbourne' },
    { value: 'Australia/Sydney', label: 'Australia/Sydney' },
    { value: 'Australia/Broken_Hill', label: 'Australia/Broken_Hill' },
    { value: 'Australia/Brisbane', label: 'Australia/Brisbane' },
    { value: 'Australia/Lindeman', label: 'Australia/Lindeman' },
    { value: 'Australia/Adelaide', label: 'Australia/Adelaide' },
    { value: 'Australia/Darwin', label: 'Australia/Darwin' },
    { value: 'Australia/Perth', label: 'Australia/Perth' },
    { value: 'Australia/Eucla', label: 'Australia/Eucla' },
    { value: 'Asia/Baku', label: 'Asia/Baku' },
    { value: 'America/Barbados', label: 'America/Barbados' },
    { value: 'Asia/Dhaka', label: 'Asia/Dhaka' },
    { value: 'Europe/Brussels', label: 'Europe/Brussels' },
    { value: 'Europe/Sofia', label: 'Europe/Sofia' },
    { value: 'Atlantic/Bermuda', label: 'Atlantic/Bermuda' },
    { value: 'Asia/Brunei', label: 'Asia/Brunei' },
    { value: 'America/La_Paz', label: 'America/La_Paz' },
    { value: 'America/Noronha', label: 'America/Noronha' },
    { value: 'America/Belem', label: 'America/Belem' },
    { value: 'America/Fortaleza', label: 'America/Fortaleza' },
    { value: 'America/Recife', label: 'America/Recife' },
    { value: 'America/Araguaina', label: 'America/Araguaina' },
    { value: 'America/Maceio', label: 'America/Maceio' },
    { value: 'America/Bahia', label: 'America/Bahia' },
    { value: 'America/Sao_Paulo', label: 'America/Sao_Paulo' },
    { value: 'America/Campo_Grande', label: 'America/Campo_Grande' },
    { value: 'America/Cuiaba', label: 'America/Cuiaba' },
    { value: 'America/Santarem', label: 'America/Santarem' },
    { value: 'America/Porto_Velho', label: 'America/Porto_Velho' },
    { value: 'America/Boa_Vista', label: 'America/Boa_Vista' },
    { value: 'America/Manaus', label: 'America/Manaus' },
    { value: 'America/Eirunepe', label: 'America/Eirunepe' },
    { value: 'America/Rio_Branco', label: 'America/Rio_Branco' },
    { value: 'America/Nassau', label: 'America/Nassau' },
    { value: 'Asia/Thimphu', label: 'Asia/Thimphu' },
    { value: 'Europe/Minsk', label: 'Europe/Minsk' },
    { value: 'America/Belize', label: 'America/Belize' },
    { value: 'America/St_Johns', label: 'America/St_Johns' },
    { value: 'America/Halifax', label: 'America/Halifax' },
    { value: 'America/Glace_Bay', label: 'America/Glace_Bay' },
    { value: 'America/Moncton', label: 'America/Moncton' },
    { value: 'America/Goose_Bay', label: 'America/Goose_Bay' },
    { value: 'America/Blanc-Sablon', label: 'America/Blanc-Sablon' },
    { value: 'America/Toronto', label: 'America/Toronto' },
    { value: 'America/Nipigon', label: 'America/Nipigon' },
    { value: 'America/Thunder_Bay', label: 'America/Thunder_Bay' },
    { value: 'America/Iqaluit', label: 'America/Iqaluit' },
    { value: 'America/Pangnirtung', label: 'America/Pangnirtung' },
    { value: 'America/Atikokan', label: 'America/Atikokan' },
    { value: 'America/Winnipeg', label: 'America/Winnipeg' },
    { value: 'America/Rainy_River', label: 'America/Rainy_River' },
    { value: 'America/Resolute', label: 'America/Resolute' },
    { value: 'America/Rankin_Inlet', label: 'America/Rankin_Inlet' },
    { value: 'America/Regina', label: 'America/Regina' },
    { value: 'America/Swift_Current', label: 'America/Swift_Current' },
    { value: 'America/Edmonton', label: 'America/Edmonton' },
    { value: 'America/Cambridge_Bay', label: 'America/Cambridge_Bay' },
    { value: 'America/Yellowknife', label: 'America/Yellowknife' },
    { value: 'America/Inuvik', label: 'America/Inuvik' },
    { value: 'America/Creston', label: 'America/Creston' },
    { value: 'America/Dawson_Creek', label: 'America/Dawson_Creek' },
    { value: 'America/Fort_Nelson', label: 'America/Fort_Nelson' },
    { value: 'America/Vancouver', label: 'America/Vancouver' },
    { value: 'America/Whitehorse', label: 'America/Whitehorse' },
    { value: 'America/Dawson', label: 'America/Dawson' },
    { value: 'Indian/Cocos', label: 'Indian/Cocos' },
    { value: 'Europe/Zurich', label: 'Europe/Zurich' },
    { value: 'Africa/Abidjan', label: 'Africa/Abidjan' },
    { value: 'Pacific/Rarotonga', label: 'Pacific/Rarotonga' },
    { value: 'America/Santiago', label: 'America/Santiago' },
    { value: 'America/Punta_Arenas', label: 'America/Punta_Arenas' },
    { value: 'Pacific/Easter', label: 'Pacific/Easter' },
    { value: 'Asia/Shanghai', label: 'Asia/Shanghai' },
    { value: 'Asia/Urumqi', label: 'Asia/Urumqi' },
    { value: 'America/Bogota', label: 'America/Bogota' },
    { value: 'America/Costa_Rica', label: 'America/Costa_Rica' },
    { value: 'America/Havana', label: 'America/Havana' },
    { value: 'Atlantic/Cape_Verde', label: 'Atlantic/Cape_Verde' },
    { value: 'America/Curacao', label: 'America/Curacao' },
    { value: 'Indian/Christmas', label: 'Indian/Christmas' },
    { value: 'Asia/Nicosia', label: 'Asia/Nicosia' },
    { value: 'Asia/Famagusta', label: 'Asia/Famagusta' },
    { value: 'Europe/Prague', label: 'Europe/Prague' },
    { value: 'Europe/Berlin', label: 'Europe/Berlin' },
    { value: 'Europe/Copenhagen', label: 'Europe/Copenhagen' },
    { value: 'America/Santo_Domingo', label: 'America/Santo_Domingo' },
    { value: 'Africa/Algiers', label: 'Africa/Algiers' },
    { value: 'America/Guayaquil', label: 'America/Guayaquil' },
    { value: 'Pacific/Galapagos', label: 'Pacific/Galapagos' },
    { value: 'Europe/Tallinn', label: 'Europe/Tallinn' },
    { value: 'Africa/Cairo', label: 'Africa/Cairo' },
    { value: 'Africa/El_Aaiun', label: 'Africa/El_Aaiun' },
    { value: 'Europe/Madrid', label: 'Europe/Madrid' },
    { value: 'Africa/Ceuta', label: 'Africa/Ceuta' },
    { value: 'Atlantic/Canary', label: 'Atlantic/Canary' },
    { value: 'Europe/Helsinki', label: 'Europe/Helsinki' },
    { value: 'Pacific/Fiji', label: 'Pacific/Fiji' },
    { value: 'Atlantic/Stanley', label: 'Atlantic/Stanley' },
    { value: 'Pacific/Chuuk', label: 'Pacific/Chuuk' },
    { value: 'Pacific/Pohnpei', label: 'Pacific/Pohnpei' },
    { value: 'Pacific/Kosrae', label: 'Pacific/Kosrae' },
    { value: 'Atlantic/Faroe', label: 'Atlantic/Faroe' },
    { value: 'Europe/Paris', label: 'Europe/Paris' },
    { value: 'Europe/London', label: 'Europe/London' },
    { value: 'Asia/Tbilisi', label: 'Asia/Tbilisi' },
    { value: 'America/Cayenne', label: 'America/Cayenne' },
    { value: 'Africa/Accra', label: 'Africa/Accra' },
    { value: 'Europe/Gibraltar', label: 'Europe/Gibraltar' },
    { value: 'America/Godthab', label: 'America/Godthab' },
    { value: 'America/Danmarkshavn', label: 'America/Danmarkshavn' },
    { value: 'America/Scoresbysund', label: 'America/Scoresbysund' },
    { value: 'America/Thule', label: 'America/Thule' },
    { value: 'Europe/Athens', label: 'Europe/Athens' },
    { value: 'Atlantic/South_Georgia', label: 'Atlantic/South_Georgia' },
    { value: 'America/Guatemala', label: 'America/Guatemala' },
    { value: 'Pacific/Guam', label: 'Pacific/Guam' },
    { value: 'Africa/Bissau', label: 'Africa/Bissau' },
    { value: 'America/Guyana', label: 'America/Guyana' },
    { value: 'Asia/Hong_Kong', label: 'Asia/Hong_Kong' },
    { value: 'America/Tegucigalpa', label: 'America/Tegucigalpa' },
    { value: 'America/Port-au-Prince', label: 'America/Port-au-Prince' },
    { value: 'Europe/Budapest', label: 'Europe/Budapest' },
    { value: 'Asia/Jakarta', label: 'Asia/Jakarta' },
    { value: 'Asia/Pontianak', label: 'Asia/Pontianak' },
    { value: 'Asia/Makassar', label: 'Asia/Makassar' },
    { value: 'Asia/Jayapura', label: 'Asia/Jayapura' },
    { value: 'Europe/Dublin', label: 'Europe/Dublin' },
    { value: 'Asia/Jerusalem', label: 'Asia/Jerusalem' },
    { value: 'Asia/Kolkata', label: 'Asia/Kolkata' },
    { value: 'Indian/Chagos', label: 'Indian/Chagos' },
    { value: 'Asia/Baghdad', label: 'Asia/Baghdad' },
    { value: 'Asia/Tehran', label: 'Asia/Tehran' },
    { value: 'Atlantic/Reykjavik', label: 'Atlantic/Reykjavik' },
    { value: 'Europe/Rome', label: 'Europe/Rome' },
    { value: 'America/Jamaica', label: 'America/Jamaica' },
    { value: 'Asia/Amman', label: 'Asia/Amman' },
    { value: 'Asia/Tokyo', label: 'Asia/Tokyo' },
    { value: 'Africa/Nairobi', label: 'Africa/Nairobi' },
    { value: 'Asia/Bishkek', label: 'Asia/Bishkek' },
    { value: 'Pacific/Tarawa', label: 'Pacific/Tarawa' },
    { value: 'Pacific/Enderbury', label: 'Pacific/Enderbury' },
    { value: 'Pacific/Kiritimati', label: 'Pacific/Kiritimati' },
    { value: 'Asia/Pyongyang', label: 'Asia/Pyongyang' },
    { value: 'Asia/Seoul', label: 'Asia/Seoul' },
    { value: 'Asia/Almaty', label: 'Asia/Almaty' },
    { value: 'Asia/Qyzylorda', label: 'Asia/Qyzylorda' },
    { value: 'Asia/Qostanay', label: 'Asia/Qostanay' },
    { value: 'Asia/Aqtobe', label: 'Asia/Aqtobe' },
    { value: 'Asia/Aqtau', label: 'Asia/Aqtau' },
    { value: 'Asia/Atyrau', label: 'Asia/Atyrau' },
    { value: 'Asia/Oral', label: 'Asia/Oral' },
    { value: 'Asia/Beirut', label: 'Asia/Beirut' },
    { value: 'Asia/Colombo', label: 'Asia/Colombo' },
    { value: 'Africa/Monrovia', label: 'Africa/Monrovia' },
    { value: 'Europe/Vilnius', label: 'Europe/Vilnius' },
    { value: 'Europe/Luxembourg', label: 'Europe/Luxembourg' },
    { value: 'Europe/Riga', label: 'Europe/Riga' },
    { value: 'Africa/Tripoli', label: 'Africa/Tripoli' },
    { value: 'Africa/Casablanca', label: 'Africa/Casablanca' },
    { value: 'Europe/Monaco', label: 'Europe/Monaco' },
    { value: 'Europe/Chisinau', label: 'Europe/Chisinau' },
    { value: 'Pacific/Majuro', label: 'Pacific/Majuro' },
    { value: 'Pacific/Kwajalein', label: 'Pacific/Kwajalein' },
    { value: 'Asia/Yangon', label: 'Asia/Yangon' },
    { value: 'Asia/Ulaanbaatar', label: 'Asia/Ulaanbaatar' },
    { value: 'Asia/Hovd', label: 'Asia/Hovd' },
    { value: 'Asia/Choibalsan', label: 'Asia/Choibalsan' },
    { value: 'Asia/Macau', label: 'Asia/Macau' },
    { value: 'America/Martinique', label: 'America/Martinique' },
    { value: 'Europe/Malta', label: 'Europe/Malta' },
    { value: 'Indian/Mauritius', label: 'Indian/Mauritius' },
    { value: 'Indian/Maldives', label: 'Indian/Maldives' },
    { value: 'America/Mexico_City', label: 'America/Mexico_City' },
    { value: 'America/Cancun', label: 'America/Cancun' },
    { value: 'America/Merida', label: 'America/Merida' },
    { value: 'America/Monterrey', label: 'America/Monterrey' },
    { value: 'America/Matamoros', label: 'America/Matamoros' },
    { value: 'America/Mazatlan', label: 'America/Mazatlan' },
    { value: 'America/Chihuahua', label: 'America/Chihuahua' },
    { value: 'America/Ojinaga', label: 'America/Ojinaga' },
    { value: 'America/Hermosillo', label: 'America/Hermosillo' },
    { value: 'America/Tijuana', label: 'America/Tijuana' },
    { value: 'America/Bahia_Banderas', label: 'America/Bahia_Banderas' },
    { value: 'Asia/Kuala_Lumpur', label: 'Asia/Kuala_Lumpur' },
    { value: 'Asia/Kuching', label: 'Asia/Kuching' },
    { value: 'Africa/Maputo', label: 'Africa/Maputo' },
    { value: 'Africa/Windhoek', label: 'Africa/Windhoek' },
    { value: 'Pacific/Noumea', label: 'Pacific/Noumea' },
    { value: 'Pacific/Norfolk', label: 'Pacific/Norfolk' },
    { value: 'Africa/Lagos', label: 'Africa/Lagos' },
    { value: 'America/Managua', label: 'America/Managua' },
    { value: 'Europe/Amsterdam', label: 'Europe/Amsterdam' },
    { value: 'Europe/Oslo', label: 'Europe/Oslo' },
    { value: 'Asia/Kathmandu', label: 'Asia/Kathmandu' },
    { value: 'Pacific/Nauru', label: 'Pacific/Nauru' },
    { value: 'Pacific/Niue', label: 'Pacific/Niue' },
    { value: 'Pacific/Auckland', label: 'Pacific/Auckland' },
    { value: 'Pacific/Chatham', label: 'Pacific/Chatham' },
    { value: 'America/Panama', label: 'America/Panama' },
    { value: 'America/Lima', label: 'America/Lima' },
    { value: 'Pacific/Tahiti', label: 'Pacific/Tahiti' },
    { value: 'Pacific/Marquesas', label: 'Pacific/Marquesas' },
    { value: 'Pacific/Gambier', label: 'Pacific/Gambier' },
    { value: 'Pacific/Port_Moresby', label: 'Pacific/Port_Moresby' },
    { value: 'Pacific/Bougainville', label: 'Pacific/Bougainville' },
    { value: 'Asia/Manila', label: 'Asia/Manila' },
    { value: 'Asia/Karachi', label: 'Asia/Karachi' },
    { value: 'Europe/Warsaw', label: 'Europe/Warsaw' },
    { value: 'America/Miquelon', label: 'America/Miquelon' },
    { value: 'Pacific/Pitcairn', label: 'Pacific/Pitcairn' },
    { value: 'America/Puerto_Rico', label: 'America/Puerto_Rico' },
    { value: 'Asia/Gaza', label: 'Asia/Gaza' },
    { value: 'Asia/Hebron', label: 'Asia/Hebron' },
    { value: 'Europe/Lisbon', label: 'Europe/Lisbon' },
    { value: 'Atlantic/Madeira', label: 'Atlantic/Madeira' },
    { value: 'Atlantic/Azores', label: 'Atlantic/Azores' },
    { value: 'Pacific/Palau', label: 'Pacific/Palau' },
    { value: 'America/Asuncion', label: 'America/Asuncion' },
    { value: 'Asia/Qatar', label: 'Asia/Qatar' },
    { value: 'Indian/Reunion', label: 'Indian/Reunion' },
    { value: 'Europe/Bucharest', label: 'Europe/Bucharest' },
    { value: 'Europe/Belgrade', label: 'Europe/Belgrade' },
    { value: 'Europe/Kaliningrad', label: 'Europe/Kaliningrad' },
    { value: 'Europe/Moscow', label: 'Europe/Moscow' },
    { value: 'Europe/Simferopol', label: 'Europe/Simferopol' },
    { value: 'Europe/Kirov', label: 'Europe/Kirov' },
    { value: 'Europe/Astrakhan', label: 'Europe/Astrakhan' },
    { value: 'Europe/Volgograd', label: 'Europe/Volgograd' },
    { value: 'Europe/Saratov', label: 'Europe/Saratov' },
    { value: 'Europe/Ulyanovsk', label: 'Europe/Ulyanovsk' },
    { value: 'Europe/Samara', label: 'Europe/Samara' },
    { value: 'Asia/Yekaterinburg', label: 'Asia/Yekaterinburg' },
    { value: 'Asia/Omsk', label: 'Asia/Omsk' },
    { value: 'Asia/Novosibirsk', label: 'Asia/Novosibirsk' },
    { value: 'Asia/Barnaul', label: 'Asia/Barnaul' },
    { value: 'Asia/Tomsk', label: 'Asia/Tomsk' },
    { value: 'Asia/Novokuznetsk', label: 'Asia/Novokuznetsk' },
    { value: 'Asia/Krasnoyarsk', label: 'Asia/Krasnoyarsk' },
    { value: 'Asia/Irkutsk', label: 'Asia/Irkutsk' },
    { value: 'Asia/Chita', label: 'Asia/Chita' },
    { value: 'Asia/Yakutsk', label: 'Asia/Yakutsk' },
    { value: 'Asia/Khandyga', label: 'Asia/Khandyga' },
    { value: 'Asia/Vladivostok', label: 'Asia/Vladivostok' },
    { value: 'Asia/Ust-Nera', label: 'Asia/Ust-Nera' },
    { value: 'Asia/Magadan', label: 'Asia/Magadan' },
    { value: 'Asia/Sakhalin', label: 'Asia/Sakhalin' },
    { value: 'Asia/Srednekolymsk', label: 'Asia/Srednekolymsk' },
    { value: 'Asia/Kamchatka', label: 'Asia/Kamchatka' },
    { value: 'Asia/Anadyr', label: 'Asia/Anadyr' },
    { value: 'Asia/Riyadh', label: 'Asia/Riyadh' },
    { value: 'Pacific/Guadalcanal', label: 'Pacific/Guadalcanal' },
    { value: 'Indian/Mahe', label: 'Indian/Mahe' },
    { value: 'Africa/Khartoum', label: 'Africa/Khartoum' },
    { value: 'Europe/Stockholm', label: 'Europe/Stockholm' },
    { value: 'Asia/Singapore', label: 'Asia/Singapore' },
    { value: 'America/Paramaribo', label: 'America/Paramaribo' },
    { value: 'Africa/Juba', label: 'Africa/Juba' },
    { value: 'Africa/Sao_Tome', label: 'Africa/Sao_Tome' },
    { value: 'America/El_Salvador', label: 'America/El_Salvador' },
    { value: 'Asia/Damascus', label: 'Asia/Damascus' },
    { value: 'America/Grand_Turk', label: 'America/Grand_Turk' },
    { value: 'Africa/Ndjamena', label: 'Africa/Ndjamena' },
    { value: 'Indian/Kerguelen', label: 'Indian/Kerguelen' },
    { value: 'Asia/Bangkok', label: 'Asia/Bangkok' },
    { value: 'Asia/Dushanbe', label: 'Asia/Dushanbe' },
    { value: 'Pacific/Fakaofo', label: 'Pacific/Fakaofo' },
    { value: 'Asia/Dili', label: 'Asia/Dili' },
    { value: 'Asia/Ashgabat', label: 'Asia/Ashgabat' },
    { value: 'Africa/Tunis', label: 'Africa/Tunis' },
    { value: 'Pacific/Tongatapu', label: 'Pacific/Tongatapu' },
    { value: 'Europe/Istanbul', label: 'Europe/Istanbul' },
    { value: 'America/Port_of_Spain', label: 'America/Port_of_Spain' },
    { value: 'Pacific/Funafuti', label: 'Pacific/Funafuti' },
    { value: 'Asia/Taipei', label: 'Asia/Taipei' },
    { value: 'Europe/Kiev', label: 'Europe/Kiev' },
    { value: 'Europe/Uzhgorod', label: 'Europe/Uzhgorod' },
    { value: 'Europe/Zaporozhye', label: 'Europe/Zaporozhye' },
    { value: 'Pacific/Wake', label: 'Pacific/Wake' },
    { value: 'America/New_York', label: 'America/New_York' },
    { value: 'America/Detroit', label: 'America/Detroit' },
    { value: 'America/Kentucky/Louisville', label: 'America/Kentucky/Louisville' },
    { value: 'America/Kentucky/Monticello', label: 'America/Kentucky/Monticello' },
    { value: 'America/Indiana/Indianapolis', label: 'America/Indiana/Indianapolis' },
    { value: 'America/Indiana/Vincennes', label: 'America/Indiana/Vincennes' },
    { value: 'America/Indiana/Winamac', label: 'America/Indiana/Winamac' },
    { value: 'America/Indiana/Marengo', label: 'America/Indiana/Marengo' },
    { value: 'America/Indiana/Petersburg', label: 'America/Indiana/Petersburg' },
    { value: 'America/Indiana/Vevay', label: 'America/Indiana/Vevay' },
    { value: 'America/Chicago', label: 'America/Chicago' },
    { value: 'America/Indiana/Tell_City', label: 'America/Indiana/Tell_City' },
    { value: 'America/Indiana/Knox', label: 'America/Indiana/Knox' },
    { value: 'America/Menominee', label: 'America/Menominee' },
    { value: 'America/North_Dakota/Center', label: 'America/North_Dakota/Center' },
    { value: 'America/North_Dakota/New_Salem', label: 'America/North_Dakota/New_Salem' },
    { value: 'America/North_Dakota/Beulah', label: 'America/North_Dakota/Beulah' },
    { value: 'America/Denver', label: 'America/Denver' },
    { value: 'America/Boise', label: 'America/Boise' },
    { value: 'America/Phoenix', label: 'America/Phoenix' },
    { value: 'America/Los_Angeles', label: 'America/Los_Angeles' },
    { value: 'America/Anchorage', label: 'America/Anchorage' },
    { value: 'America/Juneau', label: 'America/Juneau' },
    { value: 'America/Sitka', label: 'America/Sitka' },
    { value: 'America/Metlakatla', label: 'America/Metlakatla' },
    { value: 'America/Yakutat', label: 'America/Yakutat' },
    { value: 'America/Nome', label: 'America/Nome' },
    { value: 'America/Adak', label: 'America/Adak' },
    { value: 'Pacific/Honolulu', label: 'Pacific/Honolulu' },
    { value: 'America/Montevideo', label: 'America/Montevideo' },
    { value: 'Asia/Samarkand', label: 'Asia/Samarkand' },
    { value: 'Asia/Tashkent', label: 'Asia/Tashkent' },
    { value: 'America/Caracas', label: 'America/Caracas' },
    { value: 'Asia/Ho_Chi_Minh', label: 'Asia/Ho_Chi_Minh' },
    { value: 'Pacific/Efate', label: 'Pacific/Efate' },
    { value: 'Pacific/Wallis', label: 'Pacific/Wallis' },
    { value: 'Pacific/Apia', label: 'Pacific/Apia' },
    { value: 'Africa/Johannesburg', label: 'Africa/Johannesburg' },
];