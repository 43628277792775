import { useEffect, useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";

import { Important, List, SelectedAction } from "./Services";

import ComponentLoader from "../../../common/ComponentLoader";
import Paginate from "../Comman/Paginate";
import { useNavigate } from "react-router-dom";

import showNotification from "../../../helpers/Notify";
import { getErrorMessage } from "../../../helpers/Utils";

const Inbox = (props) => {

    const navigate = useNavigate();

    const capitalize = (s) => {
        return s[0].toUpperCase() + s.slice(1);
    };

    const [composeList, setComposeList] = useState([]);
    const [limitData, setLimitData] = useState({
        search: "",
        page: 0,
        limit: 15,
    });
    const [total, setTotal] = useState(0);
    const [isLoad, setIsLoad] = useState(false);
    const [checkedData,setCheckedData ] = useState([]);

    useEffect(() => {
        getComposeList();
        localStorage.setItem(
            "emailLinkActive",
            '/email'
        );
    }, [limitData]);

    const getComposeList = async () => {
        setIsLoad(true);
        let response = await List(limitData);
        setComposeList(response.data.data.data);
        setTotal(response.data.data.total);
        setIsLoad(false);
    };

    const handlePageCallback = async (event) => {
        setLimitData({ ...limitData, page: parseInt(event.selected) });
    };

    const handleSearchFilter = (e) => {
        let keyword = e.target.value;
        setLimitData({ ...limitData, search: keyword, page: 0 });
    };

    const handleViewCompose = (id) => {
        navigate(`/email/view/${id}`);
    }

    const handleImportantCompose = async (id,important) => {
        await Important({id:id,important:important});
        getComposeList();
    }

    const handleSelectData = async (e) => {
        var checkList = document.querySelectorAll('.checkedList');
        var checkListId  = []
        for (let i = 0; i < checkList.length; i++) {
            if( e.target.checked ){
                checkList[i].checked = true;
                checkListId.push(checkList[i].value);
            }else{
                checkList[i].checked = false;
            }
        }
        setCheckedData(checkListId);
    }

    const handleSelectedAction = async (name) => {
        var type = 'update';
        var msg  = 'Update successfully';
        if( name == 'delete' ){
            type = name
            msg  = 'Delete successfully';
        }
        let paremeter = {
            ids:checkedData,
            type:type,
            column:name
        }
        await SelectedAction(paremeter);
        showNotification(getErrorMessage(msg), 'success');
        getComposeList();
        var checkList = document.querySelectorAll('input[type=checkbox]');
        var checkListId  = []
        for (let i = 0; i < checkList.length; i++) {
            checkList[i].checked = false;
        }
    }

    return (
        <div className="col-md-10 position-relative">
            {isLoad ? <ComponentLoader /> : ""}
            <div className="row row-theme">
                <div className="col-md-12 search-form">
                    <form action="#" className="text-right">
                        <div className="input-group">
                            <i className="mdi mdi-magnify searchBarIconEmail"></i>
                            <input
                                type="text"
                                className="form-control input-sm"
                                placeholder="Search"
                                value={limitData.search || ""}
                                onChange={(e) => handleSearchFilter(e)}
                            />
                        </div>
                    </form>
                </div>
                <div className="col-sm-12 d-flex">
                    <div className="checkInputContainer" >
                        <input type="checkbox" onChange={(e) => handleSelectData(e)}  />
                    </div>
                    <div className="btn-group">
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-default"  as="button">
                                Action{" "}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => handleSelectedAction('read')} > Mark as read{" "}</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => handleSelectedAction('important')}>Mark as important</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => handleSelectedAction('delete')}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="padding"></div>
            <div className="table-responsive position-relative">
                <table className="table">
                   <tbody>
                        {composeList.length > 0
                            ? composeList.map((row) => (
                                <tr className={!row.read ? "read" : ""} key={row.id}>
                                <td className="action">
                                    <input type="checkbox" className="checkedList" value={row.id} />
                                    <i
                                    className={
                                        "mdi mdi-star-outline " + (row.important ? "selected" : "")
                                    }
                                    onClick={(e) => handleImportantCompose(row.id, row.important)}
                                    ></i>
                                </td>
                                <td className="name" onClick={(e) => handleViewCompose(row.id)}>
                                    {capitalize(row.username.first_name)}{" "}
                                    {capitalize(row.username.last_name)}
                                </td>
                                <td className="subject" onClick={(e) => handleViewCompose(row.id)}>
                                    {row.body}
                                </td>
                                <td className="time" onClick={(e) => handleViewCompose(row.id)}>
                                    {row.created_at}
                                </td>
                                </tr>
                            ))
                            : <tr><td colSpan={3}>No Data Found</td></tr>}
                        </tbody>
                </table>
            </div>
            <Paginate
                pageCount={total}
                limit={limitData.limit}
                handlePageCallback={handlePageCallback}
                forcePage={limitData.page}
            />
        </div>
    );
};

export default Inbox;
