import React from "react";
import SimpleReactValidator from 'simple-react-validator';
import Loader from "../../../common/Misc/Loader";
import { updateTransaction, allTenantByUserId } from "../../../Services/Transaction" ;
import { getAllProperty } from '../../../Services/Property';
import showNotification from "../../../helpers/Notify";

import { getErrorMessage, getQueryStringParams } from "../../../helpers/Utils";
import { getTransactionById } from '../../../Services/Transaction';
import {withRouter } from "../../../helpers/withRouter"

class EditTransaction extends React.Component{
    constructor(props){
    super(props);
    this.state = {
        property: '',
        unit:'',
        transaction_date: '',
        transaction_type: '',
        paid_by : '',
        category: '',
        bank_account: '',
        amount : '',
        status: '',
        description_title: '',
        description: '',
        receipt_image : '',
        allPropertyList : [],  
        tenantByUserID : [],
        image_list :[],
        allUnits:[]
    };

    this.validator = new SimpleReactValidator();
        this.submitForm =async()=> {

            if (this.validator.allValid()) { 
                this.setState({requestInprogress : true});
                let allFileIds = [];
                this.state.image_list.map((row) => {
                    allFileIds.push(row.id);
                    return false;
                });
                let request = {
                    ...this.state,
                    file_ids:allFileIds,
                };
                let response = await updateTransaction(request);
                this.setState({requestInprogress : false});
                    if(response.data.success){
                        showNotification(getErrorMessage(response.data.message), 'success');
                        this.redirectUser();
                    } else {
                        showNotification(getErrorMessage(response.data.message), 'warning');
                    }
               
            } else {
            this.validator.showMessages();
            this.forceUpdate();
            } 
           
           
             
        } 
    }

    redirectUser()
    {
        this.props.navigate('/transaction')
    }

    
    setValue = (e, field)=>{
        this.setState({...this.state, [field]:e.target.value});
        if(field === 'property'){
            this.setUnit(e.target.value);
        }
     } 

     setUnit = (selected_id) => {
        console.log(selected_id, 'vselected_id');
        let allProperty = this.state.allPropertyList;
        allProperty.map((row) => {
            if(row.id == selected_id){

                console.log(row.property_types, 'property_types');
                this.setState({
                    allUnits : row.property_types
                });
            }
        });

    }


    componentDidMount = async() => {
        let allPropertyList = await getAllProperty();
        this.setState({allPropertyList : allPropertyList.data.data });
          

        if(this.props.generalDetails){
            this.populateDetails(this.props.generalDetails);
        }
           
        let tenantByUserID = await allTenantByUserId();
        this.setState({tenantByUserID : tenantByUserID.data.data });

        let params = getQueryStringParams();

        console.log('params', params);
        if(typeof params['id'] != 'undefined'){
            let transaction_details_res = await getTransactionById(params['id']);
            console.log('getTransactionById',transaction_details_res);
           this.populateDetails(transaction_details_res.data.data);
        }

    }

    populateDetails = (details) => {
        this.setState({
            id : details.id,
            property : details.property_id,
            transaction_date : details.transaction_date,
            transaction_type : details.transaction_type, 
            paid_by : details.payer_id,
            category : details.category, 
            bank_account : details.bank_account,
            amount : details.amount,
            status : details.status,
            description_title : details.description_title,
            description : details.description,
            payment_mode : details.payment_mode,
            //receipt_image : details.receipt_image,
        });

        // Multiple Emergency Contact Form
    }
          
    render(){
        return(
            <>  
                <div className="content-wrapper">
                    <div className="page-header">
                        <div className="left-cell">
                            <h3 className="page-title">Edit transaction</h3>
                        </div>
                        
                    </div>
                    <div className="detail-cont">
                        <div className="row">
                            <div className="col-md-12 grid-margin stretch-card flex-column">
                            <div className="card mb-4">
                                <div className="card-body pt-0">
                                    <form className="forms-sample">
                                        <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="exampleInputPriority">Property </label>
                                                <select className="form-control tex-select select_btn" name = "property" id="property" value={this.state.property} onChange={(e)=>this.setValue(e,'property')}>
                                                    <option className="unselect">Select Property</option>
                                                    {
                                                        this.state.allPropertyList.map((row) => (
                                                            <option value={row.id} key={row.id}>{row.property_name+' ('+row.street_address + ', ' +row.city+')'}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            {this.validator.message('property', this.state.property, 'required')}
                                            
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="exampleInputPriority">Select Property Unit </label>
                                                <select className="form-control tex-select select_btn" name = "unit" id="unit" value={this.state.unit} onChange={(e)=>this.setValue(e,'unit')}>
                                                    <option className="unselect">Select Unit</option>
                                                    {
                                                        this.state?.allUnits?.length > 0 ? (
                                                            this.state.allUnits.map((row) => (
                                                                <option value={row.id} key={row.id}>{row.unit+' ('+ row.unit_type +')'}</option>
                                                            ))
                                                        ) : ""
                                                    }
                                                </select>
                                            </div>
                                            {this.validator.message('unit', this.state.property, 'unit')}
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="exampleInputPriority">Transaction Date </label>
                                                <div className="input-group input-group-icon mb-3">
                                                    <input type="date" name= "transaction_date" id= "transaction_date" className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={this.state.transaction_date} onChange={(e)=>this.setValue(e,'transaction_date')}/>
                                                    
                                                    <div className="input-group-append p-0">
                                                    <span className="input-group-text" id="basic-addon2"><i className="mdi mdi-calendar"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.validator.message('transaction_date', this.state.transaction_date, 'required')}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="exampleInputPriority">Transaction Type</label>
                                                <select className="form-control tex-select select_btn" name= "transaction_type" id="transaction_type" value={this.state.transaction_type} onChange={(e)=>this.setValue(e,'transaction_type')}>
                                                    <option className="unselect">Select Transaction Type</option>
                                                    <option value ="money_in">Money In</option>
                                                    <option value ="money_out">Money Out</option>
                                                </select>
                                            </div>
                                            {this.validator.message('transaction_type', this.state.transaction_type, 'required')}
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="exampleInputPriority">Paid By</label>
                                                <select className="form-control tex-select select_btn" name = "paid_by" id="paid_by" value={this.state.paid_by} onChange={(e)=>this.setValue(e,'paid_by')}>
                                                    <option className="unselect">Select</option>
                                                    {
                                                        this.state.tenantByUserID.map((row) => (
                                                            <option value={row.id} key={row.id}>{row.first_name+ ' ' +row.last_name}</option>
                                                            
                                                        ))
                                                    }

                                                </select>
                                            </div>
                                            {this.validator.message('paid_by', this.state.paid_by, 'required')}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="exampleInputPriority">Category </label>
                                                <select className="form-control tex-select select_btn" name = "category" id="category" value={this.state.category} onChange={(e)=>this.setValue(e,'category')}>
                                                    <option className="unselect">Select Category</option>
                                                    <option value="utilities" key="1">Utilities</option>
                                                    <option value="rent" key="2">Rent</option>
                                                    <option value="monthly-service" key="3">Monthly Service</option>
                                                </select>
                                            </div>
                                            {this.validator.message('category', this.state.category, 'required')}
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="exampleInputPriority">Bank Account:  </label>
                                                <select className="form-control tex-select select_btn" name= "bank_account" id="bank_account" value={this.state.bank_account} onChange={(e)=>this.setValue(e,'bank_account')}>
                                                    <option className="unselect">Select Bank Account</option>
                                                    <option value="utilities" key="1">Utilities</option>
                                                    <option value="rent" key="2">Rent</option>
                                                    <option value="monthly-service" key="3">Monthly Service</option>
                                                </select>
                                            </div>
                                            {this.validator.message('bank_account', this.state.bank_account, 'required')}
                                        </div> */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="exampleInputEnterDT">Item Amount </label>
                                                <input type="number" disabled className="form-control" name = "amount" id="amount" placeholder="$0.00" value={this.state.amount} onChange={(e)=>this.setValue(e,'amount')}/>
                                            </div>
                                            {this.validator.message('amount', this.state.amount, 'required')}
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="exampleInputPriority">Payment Mode</label>
                                                <select className="form-control tex-select select_btn" name= "payment_mode" id="payment_mode" value={this.state.payment_mode} onChange={(e)=>this.setValue(e,'payment_mode')}>
                                                    <option className="unselect">Select Payment Mode</option>
                                                    <option value ="cash">Cash</option>
                                                    <option value ="credit_debit_card">Credit/Debit Card</option>
                                                    <option value ="cheque">Cheque</option>
                                                    <option value ="bank_transfer">Bank Transfer</option>
                                                    <option value ="other">Other</option>
                                                </select>
                                            </div>
                                            {this.validator.message('payment_mode', this.state.payment_mode, 'required')}
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="exampleInputPriority">Status</label>
                                                <select className="form-control tex-select select_btn" name = "status" id="status" value={this.state.status} onChange={(e)=>this.setValue(e,'status')}>
                                                <option className="unselect">Select Status</option>
                                                    {/* <option value ="pending">Pending</option> */}
                                                    {/* <option value ="in_transaction">In Transition</option> */}
                                                    <option value ="done">Paid</option>
                                                    {/* <option value ="void">Void</option> */}
                                                    {/* <option value ="cancel">Cancel</option> */}
                                                    <option value ="requested">Requested</option>
                                                    {/* <option value ="refund">Refund</option> */}
                                                </select>
                                            </div>
                                            {this.validator.message('status', this.state.status, 'required')}
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="exampleInputEnterDT">Description Title </label>
                                                <input type="text" className="form-control" name = "description_title" id="description_title" placeholder="" value={this.state.description_title} onChange={(e)=>this.setValue(e,'description_title')}/>
                                            </div>
                                            {this.validator.message('description_title', this.state.description_title, 'required')}
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="exampleInputEnterDT">Description </label> 
                                                <textarea className="form-control" name = "description" rows="7" value={this.state.description} onChange={(e)=>this.setValue(e,'description')}></textarea>
                                            </div>
                                            {this.validator.message('description', this.state.description, 'required')}
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Receipt Image: </label>    
                                                <div className="drop_box mar_drop_box ">
                                                    <div className="upload-btn-wrapper">
                                                    <button className="btn bg-orange btn-cus text-white">
                                                        <i className="mdi mdi-cloud-upload text-muted"></i>
                                                        <p>Upload Receipt Image </p>
                                                    </button>
                                                    <input type="file" name="receipt_image" value={this.state.receipt_image} onChange={(e)=>this.setValue(e,'receipt_image')}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.validator.message('receipt_image', this.state.receipt_image, 'required')}
                                        </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card  mb-4">
                                <div className="card-body p-0">
                                    <div className="d-flex justify-content-between">
                                        <button type="button" className="btn btn-outline-dark">Cancal</button>
                                        {/* <button className="btn bg-orange btn-cus mr-2 text-white"  onClick={this.submitForm}>Save</button> */}
                                        <button className="btn bg-orange btn-cus mr-2 text-white" disabled={ this.state.requestInprogress}  onClick={this.submitForm}>
                                        { this.state.requestInprogress ? (<Loader className="btn theme-btn-orange" msg= "Updating Transaction" /> ) : 'Update' }
                                    </button>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    
                    <footer className="footer">
                        <div className="container-fluid d-flex justify-content-center">
                            <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright ©
                            <b>DigitalTenant</b> 2022. All Rights Reserved.</span>
                        </div>
                    </footer>
                    
                </div>    
          
            </>
        )
    }
}
export default EditTransaction;
