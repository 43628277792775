import React from "react";
import SimpleReactValidator from 'simple-react-validator';
class AddPet extends React.Component{
    constructor(props){
        super(props)
            this.state = {
                pet_type : '',
                name : '',
                weight : '',
                breed : '',
                profile_pic : '',
            };
        this.validator = new SimpleReactValidator();
         this.submitForm =async ()=> {

            if (this.validator.allValid()) {
                return { status : true , data:this.state };
            } else {
                this.validator.showMessages();
                this.forceUpdate();
                return { status : false , data:[] };
            }
        }
    }
    setValue = (e, field)=>{
        this.setState({...this.state, [field]:e.target.value});
    }

    uplodProfilePic = (e) => {
        let file = e.target.files;
        var reader = new FileReader();
        reader.readAsDataURL(file[0])
        reader.onload = () => {
            this.setState({
                profile_pic : reader.result
            });
        };
    }

    componentDidMount = async () => {
        if(this.props.petData){
            this.populateDetails(this.props.petData);
        }
    }

    populateDetails = (details) => {
        this.setState({
            id:details.id,
            pet_type : details.pet_type,
            name : details.name,
            weight : details.weight,
            breed : details.breed,
            profile_pic : details.image,
        });

    }

    render(){
        return(
            <>
                <div className="card card-accordion-theme mb-4">
                    <div className="card-header arrow-add-right d-flex align-items-start">
                        <span className="btn-header-link collapsed" data-bs-toggle="collapse" onClick={(e) => {this.setState({toggleAddPet : !this.state.toggleAddPet})}}
                            role="button" aria-expanded="false" aria-controls="collapseExample2">
                            <h3 className="d-inline-block align-middle"><i className="mdi mdi-plus-circle-outline"></i>
                                <span>Add a Pet</span>
                            </h3>
                        </span>
                        </div>
                        <div className={"collapse" + (this.state.toggleAddPet ? "show":"")} id="collapseExample2">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputFirstName">Pet type</label>
                                        <select className="form-control tex-select select_btn" name="pet_type" id="exampleInputCountry" value={this.state.pet_type} onChange={(e) => this.setValue(e, 'pet_type')}>
                                            <option className="unselect" key={"unselect"} >Dog</option>
                                            <option key={"Cat"} >Cat</option>
                                            <option key={"Other"} >Other</option>
                                        </select>
                                    </div>
                                    {this.validator.message('pet_type', this.state.pet_type, 'required')}
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPetName">Name</label>
                                        <input type="text" className="form-control" name="name" id="exampleInputPetName" placeholder="" value={this.state.name} onChange={(e) =>this.setValue(e,'name')}/>
                                    </div>
                                    {this.validator.message('name', this.state.name, 'required')}
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputWeight">Weight</label>
                                        <input type="text" className="form-control" name="weight" id="exampleInputWeight" placeholder="" value={this.state.weight} onChange={(e) => this.setValue(e, 'weight')}/>
                                    </div>
                                    {this.validator.message('weight', this.state.weight, 'required')}
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputBread">Breed</label>
                                        <input type="text" className="form-control" name="breed" id="exampleInputBread" placeholder="" value={this.state.breed} onChange={(e) => this.setValue(e, 'breed')}/>
                                    </div>
                                    {this.validator.message('breed', this.state.breed, 'required')}
                                    </div>
                                    <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="profile-pic-wrapper small-profile-pic">
                                            <div className="pic-holder mb-0">
                                                {/* <img id="profilePic" className="pic" src="assets/admin/images/profile-img.jpg"/> */}
                                                <img id="profilePic" className="pic" src={this.state.profile_pic === '' ? "assets/admin/images/profile-img.jpg" : this.state.profile_pic} alt="profile pic"/>
                                                <input className="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto" accept="image/*" style={{opacity: "0"}}  onChange={(e) =>  this.uplodProfilePic(e) } />
                                                <label htmlFor="newProfilePhoto" className="upload-file-block">
                                                <div className="text-center">
                                                    <div className="mb-2">
                                                        <i className="fa fa-camera fa-2x"></i>
                                                    </div>
                                                    <div className="text-uppercase">
                                                        Upload <br></br> photo
                                                    </div>
                                                </div>
                                                </label>
                                            </div>
                                            <div className="">
                                                Upload photo
                                            </div>
                                        </div>
                                    </div>

                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
export default AddPet;
