import React from 'react';
import { beds, baths} from "../../helpers/Constants";
import SimpleReactValidator from 'simple-react-validator';
class PropertyAllDropDown extends React.Component {
    constructor(props){
        super(props);
           this.state = {
            toggleDiv : false
           };
           this.validator = new SimpleReactValidator();
    }
    componentDidMount = () => {
        console.log(this.props);
    }

    render() {
        return (
            <div className="card">
                <div className="card-header" id="propertyhead1">
                    <span  className={"btn btn-header-link  " + (this.state.toggleDiv ? "":"collapsed")} onClick={ (e) => { this.setState({toggleDiv : !this.state.toggleDiv})}}
                        data-toggle="collapse" data-target="#property1"
                        aria-expanded="true" aria-controls="property1" >Property {this.props.type}</span>
                    <div className="dropdown">
                        <div id="myDropdown" className="dropdown-content">
                        <a href="/#">Edit</a>
                        <a href="/#">Delete</a>
                        <a href="/#">Add</a>
                        </div>
                    </div>
                </div>
                <div id="property1" className={"collapse " + (this.state.toggleDiv ? "show":"")}
                    aria-labelledby="propertyhead1" data-parent="#property">
                    <div className="card-body">
                        <ul>
                            { this.props.data.length > 0 ?
                                this.props.data.map((row) => {
                                   return  <li><i className="mdi mdi-checkbox-marked-circle-outline"></i>{row[this.props.field]}</li>

                                })
                                :
                                "Nothing added yet."
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default PropertyAllDropDown;
 