import React from "react";
import SimpleReactValidator from 'simple-react-validator';
import { getErrorMessage } from "../../../helpers/Utils";
import Loader from "../../../common/Misc/Loader";
import { uploadFile, deleteFile, getPropertyById} from "../../../Services/Property";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class Step1 extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            company_name: '',
            display_as_a_company : '',
            dob: '',
            gender: '',
            ssn: '',
            driver_license: '',
            driver_license_state: '',
            preferred_move_in_date: new Date(),
            short_bio: '',
            image: '',
            property_id:null,
            tenant_id: '',
            error_msg : '',
            step : 1,
            uploadedFileList : [],
            requestInprogress : false,
        };
     

        this.validator = new SimpleReactValidator();
        this.submitForm =async()=> {
            if (this.validator.allValid()) { 
            let allFileIds = [];
            let request = {
                ...this.state,
                property_id:this.props.property_id,
                file_ids:allFileIds,
            };
            this.props.componentData('step1Data', request);
            this.props.changeStepVar(2);
            } else {
            this.validator.showMessages();
            this.forceUpdate();  
             }
        } 
    }

    setValue = (e, field) => {
        this.setState({...this.state, [field] : e.target.value})
    }     
   
    componentDidMount = async() => { 
        this.setState({
            property_id  :this.props.property_id
        });
        if(this.props?.oldData){
            this.setState({
                ...this.props.oldData
            })
        }
    }

    ///////Upload profile pic///////////
    doFileUpload = async(e, type) => {
        let files = e.target.files;
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append(`file`, files[i]);
            formData.append(`type`, type);
            let response = await uploadFile(formData); 
            if(response.data.success){
                let new_obj = {
                    id : response.data.data.file_id,
                    file_name : files[i]['name'],
                    file_path : response.data.data.file_path,
                    file_type : files[i]['type'],
                    type:type
                };
                if(type === 'banner'){
                    this.setState({'image' : response.data.data.file_path});
                }
                let imageList =  this.state.uploadedFileList;
                imageList.push(new_obj);
                this.setState({
                    uploadedFileList : imageList
                });
            }else{
                let error_message = getErrorMessage(response.data.message);
                toast.warning(error_message, {autoClose:10000});
            }
            
        }
    }

    deleteFile = async(e, row) => {
        let request = {
            file_id : row.id
        };
        let response = await deleteFile(request);
        if(response.data.success){
            var delete_index = -1;
            let imageList =  this.state.uploadedFileList;
            imageList.map((all_row, index) => {
                if(row.id === all_row.id)
                delete_index = index;
                return delete_index;
            });
            if(delete_index !== -1){
                imageList.splice(delete_index, 1);
                this.setState({
                    uploadedFileList : imageList
                });
            }
        } else {
            let error_message = getErrorMessage(response.data.message);
            toast.warning(error_message, {autoClose:10000});
        }
    }


    updateDateSelected = (date, field) => {
        this.setState({
            [field] : date
        });
    }
       
    render(){
        return(
            <>
                <div className="row application-step-one">
                    <div className="col-md-12 grid-margin stretch-card flex-column">
                        <div className="card mb-4">
                            <div className="card-body pt-0">
                                <h4 className="heading-h4 font-normal mb-3">Listing information</h4>
                                <div className="text-center">
                                    <div className="app-address-top">
                                    <p>{ this.props.property_details?.parent_property?.street_address }</p>
                                    <ul>
                                        <li>
                                            <span>Rent</span>
                                            <strong>${ this.props.property_details?.rent }</strong>
                                        </li>
                                        <li>
                                            <span>Deposit</span>
                                            <strong>${ this.props.property_details?.deposite }</strong>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="mb-0 ai-properties-feature-list mt-1">
                                    <div className="ai-inner-properties-feature-list">
                                    <ul>
                                        <li>
                                            <span className="ai-img-box">
                                            <img src="assets/admin/images/double-bed.png" alt="double-bed"/>
                                            </span> <span className="ai-number">{ this.props.property_details.beds }</span> bedroom
                                        </li>
                                        <li>
                                            <span className="ai-img-box">
                                            <img src="assets/admin/images/toilet.png" alt="toilet"/>
                                            </span>
                                            <span className="ai-number">{ this.props.property_details.baths }</span> bathroom
                                        </li>
                                        <li>
                                            <span className="ai-img-box">
                                            <img src="assets/admin/images/squre_feet.png" alt="squre-feet"/>
                                            </span>
                                            <span className="ai-number">{ this.props.property_details.size }</span> sq. ft
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-body pt-0">
                                <h4 className="heading-h4 font-normal mb-4">Applicant information</h4>
                                <form className="forms-sample">
                                    <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName">First Name* </label>
                                            <input  type="hidden"  name="property_id" value={this.state.property_id}/>
                                            <input  type="hidden"  name="tenant_id" value={this.state.tenant_id}/>
                                            <input type="text" name= "first_name" className="form-control" id="exampleInputName" placeholder=""  value={this.state.first_name} onChange={(e)=>this.setValue(e,'first_name')}/>
                                        </div>
                                        {this.validator.message('first_name', this.state.first_name, 'required')}
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName">Middle Name* </label>
                                            <input type="text" name= "middle_name" className="form-control" id="exampleInputMiddleName" placeholder=""  value={this.state.middle_name} onChange={(e)=>this.setValue(e,'middle_name')}/>
                                        </div>
                                        {this.validator.message('middle_name', this.state.middle_name, 'required')}
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName">Last Name* </label>
                                            <input type="text" name= "last_name" className="form-control" id="exampleInputLastName" placeholder=""  value={this.state.last_name} onChange={(e)=>this.setValue(e,'last_name')}/>
                                        </div>
                                        {this.validator.message('last_name', this.state.last_name, 'required')}
                                    </div>
                                    <div className="col-md-7">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName">Email* </label>
                                            <input type="email" name="email" className="form-control" id="exampleInputName" placeholder="" value={this.state.email} onChange={(e)=>this.setValue(e,'email')}/>
                                        </div>
                                        {this.validator.message('email', this.state.email, 'required')}
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName">Phone Number* </label>
                                            <input type="tel"  name= "phone_number" className="form-control poppins-font" id="exampleInputName" placeholder=""  value={this.state.phone_number} onChange={(e)=>this.setValue(e,'phone_number')}/>
                                        </div>
                                        {this.validator.message('phone_number', this.state.phone_number, 'required')}
                                    </div>
                                    <div className="col-md-7">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName">Comapny Name </label>
                                            <input type="text" name= "company_name"  className="form-control" id="exampleInputName" placeholder=""  value={this.state.company_name} onChange={(e)=>this.setValue(e,'company_name')}/>
                                        </div>
                                        {this.validator.message('company_name', this.state.company_name, 'required')}
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPriority">Display as a company? </label>
                                            <section id="model-53">
                                                <div className="cardx">
                                                <div className="toggle-switch">
                                                    <input type="checkbox" id="check-53" name="display_as_a_company" value={this.state.display_as_a_company} onChange={(e)=>this.setValue(e,'display_as_a_company')}/>
                                                    <label htmlFor="check-53"></label>
                                                </div>
                                                </div>
                                            </section>
                                        </div>
                        
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPriority">Date of Birth* </label>
                                            <div className="input-group input-group-icon mb-3">
                                            <DatePicker selected={this.state.dob} className="form-control poppins-font"  onChange={(date) => this.updateDateSelected(date, 'dob')} />
                                                {/* <input type="date"  name= "dob" className="form-control poppins-font" aria-label="Recipient's username" aria-describedby="basic-addon2"  value={this.state.dob} onChange={(e)=>this.setValue(e,'dob')}/> */}
                                                {/* <div className="input-group-append p-0">
                                                <span className="input-group-text" id="basic-addon2"><i
                                                    className="mdi mdi-calendar"></i></span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {this.validator.message('dob', this.state.dob, 'required')}
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPriority">Gender</label>
                                            <select className="form-control tex-select select_btn"  name= "gender" id="exampleInputPriority"  value={this.state.gender} onChange={(e)=>this.setValue(e,'gender')}>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                        {this.validator.message('gender', this.state.gender, 'required')}
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputOccupation">SSN #*</label>
                                            <input type="text" className="form-control"  name= "ssn" id="exampleInputOccupation" placeholder="XXX-XX-XXXX"  value={this.state.ssn} onChange={(e)=>this.setValue(e,'ssn')}/>
                                            <div className="form-check form-check-flat form-check-primary">
                                                <label className="form-check-label">
                                                <input type="checkbox" className="form-check-input"/> Not applicable</label>
                                            </div>
                                        </div>
                                        {this.validator.message('ssn', this.state.ssn, 'required')}
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEnterDT">Driver license* </label>
                                            <input type="text" className="form-control poppins-font"  name= "driver_license" id="exampleInputDL" placeholder=""  value={this.state.driver_license} onChange={(e)=>this.setValue(e,'driver_license')}/>
                                        </div>
                                        {this.validator.message('driver_license', this.state.driver_license, 'required')}
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPriority">Driver license state / region* </label>
                                            <select className="form-control tex-select select_btn poppins-font"  name= "driver_license_state" id="exampleInputDLS"  value={this.state.driver_license_state} onChange={(e)=>this.setValue(e,'driver_license_state')}>
                                                <option className="unselect">Select state / region </option>
                                                <option>California</option>
                                                <option>Texas</option>
                                            </select>
                                        </div>
                                        {this.validator.message('driver_license_state', this.state.driver_license_state, 'required')}
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPriority">Preferred move in date* </label>
                                            <div className="input-group input-group-icon mb-3">
                                            <DatePicker selected={this.state.preferred_move_in_date} className="form-control poppins-font"  onChange={(date) => this.updateDateSelected(date, 'preferred_move_in_date')} />

                                                {/* <input type="date" className="form-control poppins-font"  name= "preferred_move_in_date" aria-label="Recipient's username" aria-describedby="basic-addon2"  value={this.state.preferred_move_in_date} onChange={(e)=>this.setValue(e,'preferred_move_in_date')}/> */}
                                                {/* <div className="input-group-append p-0">
                                                <span className="input-group-text" id="basic-addon2"><i
                                                    className="mdi mdi-calendar"></i></span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {this.validator.message('preferred_move_in_date', this.state.preferred_move_in_date, 'required')}
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-body pt-0">
                                <form className="forms-sample">
                                    <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName">Short bio* </label>
                                            <textarea className="form-control" id="exampleInputtextareaBio" name= "short_bio" placeholder=""  value={this.state.short_bio} onChange={(e)=>this.setValue(e,'short_bio')}></textarea>
                                        </div>
                                        {this.validator.message('short_bio', this.state.short_bio, 'required')}
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPriority">Applicant photo* </label>
                                            <div className="profile-pic-wrapper mt-4 applicant-photo">
                                                <div className="pic-holder mb-0">
                                                {
                                                  this.state.image === '' ? <img id="profilePic" className="pic" src="assets/admin/images/profile-img.jpg" alt="pic"/> :  <img id="profilePic" className="pic" src={ process.env.REACT_APP_SITE_URL + this.state.image} alt="pic"/>
                                                }
                                                <input className="uploadProfileInput" type="file" name="image" id="newProfilePhoto" accept="image/*" style={{opacity: "0"}} onChange={(e) => { this.doFileUpload(e, 'banner') }} allow="jpg,png,jpeg"/>
                                                <label htmlFor="newProfilePhoto" className="upload-file-block">
                                                    <div className="text-center">
                                                        <div className="mb-2">
                                                            <i className="fa fa-camera fa-2x"></i>
                                                        </div>
                                                        <div className="text-uppercase">
                                                            Upload <br></br>photo
                                                        </div>
                                                    </div>
                                                </label>
                                                </div>
                                                <div className="fs-cus-20">
                                                Upload photo
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card  mb-4">
                            <div className="card-body p-0">
                                <div className="d-flex justify-content-between">
                                    <button style={{'visibility': 'hidden'}} type="button" className="btn btn-dark"><i className="mdi mdi-arrow-left"></i> Back</button>
                                    <button className="btn bg-orange btn-cus mr-2 text-white" disabled={ this.state.requestInprogress} onClick={this.submitForm}>{ this.state.requestInprogress ? (<Loader className="btn theme-btn-orange" msg="Please wait..." />): 'Next' }<i className="mdi mdi-arrow-right"></i></button>  
                                </div>
                            </div>
                        </div>
                        </div>
                </div>
            </>
        )
    }
}
export default Step1;