import React from 'react';
import { beds, baths, unit_type} from "../../helpers/Constants";
import SimpleReactValidator from 'simple-react-validator';
class MultipleUnitDetails extends React.Component {
    constructor(props){
        super(props);
           this.state = {
            unit:'',
            unit_type:'',
            beds : '',
            baths :'',
            size : '',
            rent:'',
            deposit:''
           };
           this.validator = new SimpleReactValidator();
    }

    validateForm = () => {
        if(this.validator.allValid()) {
            return {status : true, data : this.state};
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            return {status : false, data : []};
        }
    }

    setValue = (e, field)=>{
        this.setState({...this.state, [field]:e.target.value});
   }

   componentDidMount = async() => {
        if(this.props.data){
            this.setState({unit:this.props.data.unit})
            this.setState({unit_type:this.props.data.unit_type})
            this.setState({beds:this.props.data.beds})
            this.setState({baths:this.props.data.baths})
            this.setState({size:this.props.data.size})
            this.setState({rent:this.props.data.rent})
            this.setState({deposit:this.props.data.deposite})
        }
   }
    render() {
        return (
            <>
            <div className="row" >
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputUsername2">Unit</label>
                        <input type="text" className="form-control" name = "market_rent" id="exampleInputUsername2" placeholder="0.00" value={this.state.unit} onChange={(e)=>this.setValue(e,'unit')} />
                    </div>
                    {this.validator.message('unit', this.state.unit, 'required|numeric')}
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputCountry">Unity Type</label>
                        <select className="form-control tex-select select_btn" name = "beds"  id="exampleInputCountry" value={this.state.unit_type} onChange={(e)=>this.setValue(e,'unit_type')}>
                            <option className="unselect">Select Unit Type</option>
                            {unit_type.map((option) => (
                            <option value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
                    {this.validator.message('unit_type', this.state.unit_type, 'required')}
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputUsername2">Size, sq.ft </label>
                        <input type="number" className="form-control"  name = "size"  id="exampleInputUsername2" placeholder="0.00" value={this.state.size} onChange={(e)=>this.setValue(e,'size')}/>
                    </div>
                    {this.validator.message('size', this.state.size, 'required')}
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputCountry">Beds</label>
                        <select className="form-control tex-select select_btn" name = "beds"  id="exampleInputCountry"  value={this.state.beds} onChange={(e)=>this.setValue(e,'beds')}>
                            <option className="unselect">Select Beds</option>
                            {beds.map((option) => (
                            <option value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
                    {this.validator.message('beds', this.state.beds, 'required')}
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputCountry">Baths</label>
                        <select className="form-control tex-select select_btn" name = "baths" id="exampleInputCountry" value={this.state.baths} onChange={(e)=>
                            this.setValue(e,'baths')}>
                            <option className="unselect">Select Baths</option>
                            {baths.map((option) => (
                            <option value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
                    {this.validator.message('baths', this.state.baths, 'required')}
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputUsername2">Market rent*</label>
                        <input type="number" className="form-control" name = "market_rent" id="exampleInputUsername2" placeholder="0.00" value={this.state.rent} onChange={(e)=>this.setValue(e,'rent')}/>
                    </div>
                    {this.validator.message('rent', this.state.rent, 'required|numeric')}
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="exampleInputUsername2">Deposit</label>
                        <input type="number" className="form-control" name = "deposite" id="exampleInputUsername2" placeholder="0.00" value={this.state.deposit} onChange={(e)=>this.setValue(e,'deposit')}/>
                    </div>
                    {this.validator.message('deposit', this.state.deposit, 'required|numeric')}
                </div>
            </div>            
            </>            
         );
    }
}

export default MultipleUnitDetails;
 