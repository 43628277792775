import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import {withRouter } from "../../../helpers/withRouter";
import  Paginate  from "../Comman/Paginate";
import Dropdown from 'react-bootstrap/Dropdown';
import {TenantsList} from './Services';

const List = () => {

    const [list,setList] = useState([]);
    const [search,setSearch] = useState(null);
    const [page,setPage] = useState(0);
    const [limit,setLimit] = useState(10);
    const [active,setActive] = useState(0);
    const [notActive,setNotActive] = useState(0);
    const [dropdownIsOpen,setDropdownIsOpen] = useState(false);
    const [filters,setFilters] = useState("");

    useEffect(() => {
        let parameter = {
            search:search,
            page:page,
            limit:limit
        }
        getLandlord(parameter);
    },[])

    const getLandlord = async (parameter) => {
        let response = await TenantsList(parameter);
        if( typeof response.data.data.result != 'undefined' || response.data.data.result.length > 0 ){
            setList(response.data.data.result)
            setActive(response.data.data.active)
            setNotActive(response.data.data.not_active)
        }
    }

    const handleSearchFilter = async (e) => {
        let searchData =  e.target.value;
        setSearch(searchData);
        let parameter = {
            search:searchData,
            page:page,
            limit:limit
        }
        getLandlord(parameter);
    }

    const handlePageCallback = async (event) => {
        let parameter = {
            search:search,
            page:parseInt(event.selected),
            limit:limit
        }
        setPage(event)
        getLandlord(parameter);
    }

    const toggleDropdown = () => {
        setDropdownIsOpen(!dropdownIsOpen);
    }

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        let parameter = {
            search:"",
            page:page,
            limit:limit,
            filters:filters
        }
        setSearch('');
        setDropdownIsOpen(false);
        getLandlord(parameter);

    }

    return(
          <>
            <div className="content-wrapper">
                <div className="page-header">
                    <div className="left-cell">
                        <h3 className="page-title">Tenants</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">Tenants</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row top-grid-view">
                    <div className="col-md-4 stretch-card grid-margin">
                        <div className="card  card-img-holder text-white">
                            <div className="card-body">
                            <img src="assets/images/deduction-icon.png" className="card-img-absolute"
                                alt="total_maintenance" />
                            <h4 className="font-weight-normal mb-3 color-gray">Total Tenants
                            </h4>
                            <h2 className="color-dark">{ list.length }</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 stretch-card grid-margin">
                        <div className="card  card-img-holder text-white">
                            <div className="card-body">
                            <img src="assets/images/miles-driven-icon.png" className="card-img-absolute "
                                alt="miles-driven-icon" />
                            <h4 className="font-weight-normal mb-3 color-gray">Active
                            </h4>
                            <h2 className="color-dark">{ active }</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 stretch-card grid-margin">
                        <div className="card  card-img-holder text-white">
                            <div className="card-body">
                            <img src="assets/images/miles-driven-icon.png" className="card-img-absolute "
                                alt="miles-driven-icon" />
                            <h4 className="font-weight-normal mb-3 color-gray">Not Active
                            </h4>
                            <h2 className="color-dark">{ notActive }</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-header-two">
                    <div className="inner-page-header-two">
                        <div className="cell-row ">
                            <div className="left-cell">
                            <h4 className="poppins-font">
                                { list.length } <span>Total</span>
                            </h4>
                            </div>
                            <div className="right-cell">
                            <div className="inner-right-cell">
                                <ul className="navbar-nav navbar-nav-right">
                                    <li className="search-field d-none d-md-block">
                                        <form className="d-flex align-items-center h-100" action="#">
                                        <div className="input-group">
                                            <input type="text" className="form-control bg-transparent border-0"
                                                placeholder="Search Tenant" value={search || ''} onChange={(e) => handleSearchFilter(e) } />
                                            <div className="input-group-prepend bg-transparent">
                                                <img src="assets/images/dashboard/search-icon.png" alt="" />
                                            </div>
                                        </div>
                                        </form>
                                    </li>
                                    <Dropdown as="li" className="custom-dropdown filter-item filter-item-pp" onToggle={(e) =>  toggleDropdown(e)}  show={dropdownIsOpen} >
                                        <Dropdown.Toggle as="a" align="end" className="btn" >
                                            <i className="mdi mdi-filter-outline"></i> filter
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu align="end"  >
                                            <form>
                                                <div class="form-group">
                                                    <h5>Status</h5>
                                                        <select class="form-select" aria-label="Default select example" onChange={(e) => setFilters(e.target.value)} >
                                                            <option value=""  >Select All</option>
                                                            <option value="1" >Active</option>
                                                            <option value="0" >Not Active</option>
                                                        </select>
                                                </div>
                                            </form>
                                            <div class="custom-dropdown-footer">
                                                <button type="submit" class="btn btn-primary" onClick={(e) => handleFilterSubmit(e)} >Show result</button>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
{/*                                     <li className="download-item cus-btn">
                                        <a href="javascript:void(0)" className="bg-orange">Export <i
                                        className="fa fa-download mr-3"></i></a>
                                    </li> */}
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4 data-table-box maintenance-data-table-box">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body pt-0">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span className="form-check form-check-flat form-check-primary">
                                                <label className="form-check-label">
                                                <input type="checkbox" className="form-check-input" /><i className="input-helper"></i></label>
                                                </span>
                                            </th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>City</th>
                                            <th>State</th>
                                            <th>Country</th>
                                            <th>Postal Code</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list.length > 0?
                                                list.map((row) =>
                                                    <tr key={row.id}>
                                                        <td>
                                                            <span className="form-check form-check-flat form-check-primary">
                                                            <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" /><i className="input-helper"></i></label>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            { row.first_name } {row.last_name}
                                                        </td>
                                                        <td>{ row.email }</td>
                                                        <td> { (row.phone != null )?row.phone:'N/A' }</td>
                                                        <td> { (row.user_address[0].city != '' )?row.user_address[0].city:'N/A' }</td>
                                                        <td> { (row.user_address[0].state != '' )?row.user_address[0].state:'N/A' }</td>
                                                        <td> { (row.user_address[0].country != '' )?row.user_address[0].country:'N/A' }  </td>
                                                        <td> { (row.user_address[0].postal_code != '' )?row.user_address[0].postal_code:'N/A' }  </td>
                                                        <td> <Link to=   {"../view-tenant?id=" + row.id} className="vd-btn">View Details</Link></td>
                                                    </tr>
                                                )
                                            :<tr><td colspan="9" className="text-center" >No Tenants</td></tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <Paginate pageCount={list.length}  limit={limit} handlePageCallback = {handlePageCallback} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>

    )
}

export default withRouter(List)
