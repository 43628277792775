import { property } from "lodash";
import React from "react";
import Select from 'react-select';
import { getAllProperty } from "../../../Services/Property";
import SimpleReactValidator from 'simple-react-validator';
import { addNewMileage } from "../../../Services/Mileage";
import showNotification from "../../../helpers/Notify";
import { getErrorMessage } from "../../../helpers/Utils";
import {withRouter } from "../../../helpers/withRouter";
import Loader from "../../../common/Misc/Loader"
import SendNotification from "../../../common/SendNotification";
import { Link } from 'react-router-dom';

class Add extends React.Component{

    constructor (props){
        super (props);
        this.state = {
             propertiesOption: [],
             formData:{},
        };
        this.validator = new SimpleReactValidator({autoForceUpdate: this});
        this.addMileage = () => {
            this.createMileage(this.validator.allValid());
        }
    }

    componentDidMount = async () =>{
        let response = await getAllProperty();
        await this.propertyOptions(response.data.data);
    }

    propertyOptions(response){
        let Options = [];
        for(var i = 0;i < response.length;i++ ){
            Options.push({ value: response[i].id, label: response[i].street_address,key:i });
        }
        this.setState(() => ({propertiesOption : Options}));
    }

    propertiesData(name,fieldType,e) {
        var fields = this.state.formData;
        if( fieldType == 'select' ){
            fields[name] = e.value;
            fields[name+'_key'] = e.key;

        }else{
            fields[name] = e.target.value;
        }
        this.setState({fields})

    }

    createMileage = async (checkValid) => {
        if( !checkValid ){
            this.validator = new SimpleReactValidator({autoForceUpdate: this});
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        let response = await addNewMileage(this.state.formData);
        if(response.data.success){
            let notification = {'title':'Add new mileage By landlord','body':'Add new mileage By landlord'}
            SendNotification(notification);
            showNotification(getErrorMessage(response.data.message), 'success');
            this.props.navigate('/mileage')
        } else {
            showNotification(getErrorMessage(response.data.message), 'warning');
        }
    }

    render(){
        return (
            <>
                <div className="content-wrapper">
                    <div className="page-header">
                        <div className="left-cell">
                            <h3 className="page-title">Add Mileage</h3>
                        </div>

                    </div>
                    <div className="detail-cont">
                        <div className="row">
                            <div className="col-md-12 grid-margin stretch-card flex-column">
                                <div className="card mb-4">
                                    <div className="card-body pt-0">
                                        <form className="forms-sample"  >
                                            <div className="row">
                                                <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPriority">Trip Date</label>
                                                    <div className="input-group input-group-icon mb-3">

                                                        <input type="date" className="form-control poppins-font" aria-label="Recipient's username"
                                                            aria-describedby="basic-addon2" name="trip_date" value={this.state.formData.trip_date || ''}  onChange={this.propertiesData.bind(this,'trip_date','input')} />
                                                        <div className="input-group-append p-0">
                                                            <span className="input-group-text" id="basic-addon2"><i
                                                                className="mdi mdi-calendar"></i></span>
                                                        </div>
                                                    </div>
                                                    {this.validator.message('trip_date',this.state.formData.trip_date, 'required')}
                                                </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPurpose">Business Name </label>
                                                        <input type="text" className="form-control" id="exampleInputPurpose"
                                                            placeholder="" value={this.state.formData.business_name || ''} name="business_name" onChange={this.propertiesData.bind(this,'business_name','input')} />

                                                    </div>
                                                    {this.validator.message('business_name',this.state.formData.business_name, 'required')}
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPriority">From Property </label>
                                                        <Select classNamePrefix="select" className="poppins-font basic-single" name="from_property" value={this.state.propertiesOption[this.state.formData.from_property_key]}  options={this.state.propertiesOption} onChange={this.propertiesData.bind(this,'from_property','select')}
                                                                />
                                                    </div>
                                                    {this.validator.message('from_property',this.state.formData.from_property, 'required')}
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPriority">To Property </label>
                                                            <Select classNamePrefix="select" className="poppins-font basic-single" name="to_property" value={this.state.propertiesOption[this.state.formData.to_property]}  options={this.state.propertiesOption} onChange={this.propertiesData.bind(this,'to_property','select')}
                                                                />
                                                    </div>
                                                    {this.validator.message('to_property',this.state.formData.to_property, 'required')}
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPurpose">Purpose </label>
                                                        <input type="text" className="form-control" id="exampleInputPurpose"
                                                            placeholder="" name="purpose" value={this.state.formData.purpose || ''}  onChange={this.propertiesData.bind(this,'purpose','input')} />
                                                    </div>
                                                    {this.validator.message('purpose',this.state.formData.purpose, 'required')}
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputMiles">Miles </label>
                                                        <input type="text" className="form-control poppins-font" id="exampleInputMiles"
                                                            placeholder="13.62" name="miles" value={this.state.formData.miles || ''}  onChange={this.propertiesData.bind(this,'miles','input')} />
                                                    </div>
                                                    {this.validator.message('miles',this.state.formData.miles, 'required|numeric')}
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputAmount">Amount </label>
                                                        <input type="text" className="form-control poppins-font" id="exampleInputAmount"
                                                            placeholder="$0.00" name="amount" value={this.state.formData.amount || ''}  onChange={this.propertiesData.bind(this,'amount','input')} />
                                                    </div>
                                                    {this.validator.message('amount',this.state.formData.amount, 'required|numeric')}
                                                </div>
                                            </div>


                                        </form>
                                    </div>
                                </div>
                                <div className="card  mb-4">
                                    <div className="card-body p-0">
                                        <div className="d-flex justify-content-between">
                                            <Link to= "mileage" type="button" className="btn btn-outline-dark">Cancal</Link>
                                            <button type="button" onClick={this.addMileage} className="btn bg-orange btn-cus mr-2 text-white">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withRouter(Add);

