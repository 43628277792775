const ChatNotSelected = (props) => {

    return(
        <div className="chatNotSelected">
            <i className="mdi mdi-wechat"></i>
            <p>{props.title}</p>
        </div>
    )

}

export default ChatNotSelected;
