import React from 'react';
import { getAllTransactionList ,getTransactionById, transactionFilter, netCashFlow} from "../../../Services/Transaction" ;
import { getAllProperty } from '../../../Services/Property';
import { Link } from 'react-router-dom';
import  Paginate  from "../Comman/Paginate";

class Transactions extends React.Component{
    constructor(props){
        super(props);
        this.state = {
         transaction_list : [],
         total :0,
         search:null,
         page:0,
         limit:10,
         mile:0,
         deduction:0,
         transaction_by_id : [],
         searchText : '',
         searchDate : '',
         searchProperty : '',
         searchCategory : '',
         searchAccount : '',
         allPropertyList: [],
         allCashFlow:[]

        };
    }

   componentDidMount = async() => {
      let parameter = {
         search:this.state.search,
         page:this.state.page,
         limit:this.state.limit
      }
      let transaction_list = await getAllTransactionList(parameter);
      console.log(transaction_list, 'transaction');
      if( transaction_list ){
      this.setState({transaction_list : transaction_list.data.data.data });
      this.setState({total:transaction_list.data.data.count});

      let transaction_by_id = await getTransactionById();
      this.setState({transaction_by_id : transaction_by_id.data.data });

      let allPropertyList = await getAllProperty();
      this.setState({allPropertyList : allPropertyList.data.data });

      let allCashFlow = await netCashFlow();
      this.setState({allCashFlow : allCashFlow.data.data });
     }
   }

   searchMileage = async (value) => {
      let parameter = {
          search:value,
          page:this.state.page,
          limit:this.state.limit
      }
      this.setState({search:value});
      let transaction_list = await getAllTransactionList(parameter);
      this.setState({transaction_list : transaction_list.data.data.result });
      this.setState({total:transaction_list.data.data.total});
  }


   handlePageCallback = async (event) => {
      let parameter = {
         search:this.state.search,
         page:parseInt(event.selected),
         limit:this.state.limit
      }
      this.setState({page:event});
      let transaction_list = await getAllTransactionList(parameter);
      this.setState({transaction_list : transaction_list.data.data.result });
   }

   searchTransaction = async (value) => {
      let parameter = {
         search:value,
         page:this.state.page,
         limit:this.state.limit
      }
      this.setState({search:value});
      let transaction_list = await getAllTransactionList(parameter);
      this.setState({transaction_list : transaction_list.data.data.result });
      this.setState({total:transaction_list.data.data.total});
  }

   transactionSearchField = async(e) => {
       this.setState({...this.state, [e.target.name] : e.target.value});
       await this.transactionFilter();
   };

   transactionFilter = async () => {
      let filter = {
         searchText : this.state.searchText,
         searchDate : this.state.searchDate,
         searchProperty : this.state.searchProperty,
         category : this.state.searchCategory,
         account : this.state.searchAccount,
      };
      let result = await transactionFilter(filter);
      this.setState(
         {
            transaction_list : result.data.data,
         });
   }
    render(){
        return(
            <>
            <div className="content-wrapper">
               <div className="page-header">
                  <div className="left-cell">
                     <h3 className="page-title">Transactions</h3>
                     <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                           <li className="breadcrumb-item"><Link to="#">Transactions </Link></li>
                           <li className="breadcrumb-item active" aria-current="page">Accounting</li>
                        </ol>
                     </nav>
                  </div>
               </div>
               <div className="row top-grid-view">
                  <div className="col-md-4 stretch-card grid-margin">
                     <div className="card  card-img-holder text-white">
                        <div className="card-body">
                           <img src="assets/admin/images/al_income.png" className="card-img-absolute" alt="all_income"/>
                           <h4 className="font-weight-normal mb-3 color-gray">All Income
                           </h4>
                           <h2 className="color-dark">${this.state.allCashFlow.all_income}</h2>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-4 stretch-card grid-margin">
                     <div className="card  card-img-holder text-white">
                        <div className="card-body">
                           <img src="assets/admin/images/expen.png" className="card-img-absolute" alt="expen"/>
                           <h4 className="font-weight-normal mb-3 color-gray">All Expenses
                           </h4>
                           <h2 className="color-dark">${this.state.allCashFlow.all_expenses}</h2>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-4 stretch-card grid-margin">
                     <div className="card  card-img-holder text-white">
                        <div className="card-body">
                           <img src="assets/admin/images/new_cash.png" className="card-img-absolute" alt="new_cash"/>
                           <h4 className="font-weight-normal mb-3 color-gray">Net Cash Flow
                           </h4>
                           <h2 className="color-dark">${this.state.allCashFlow.netCashFlow}</h2>
                        </div>
                     </div>
                  </div>
               </div>
               {/* <div className="all_transaction_record_box row mb-4">
                  <div className="inner_all_transaction_record_box d-flex">
                     <div className="action-btns-group ml-auto">
                        <div className="innner-action-btns-group">
                           <Link  to="/edit-transaction" type="button" className="btn btn-dark btn-fw">edit</Link>
                           <button type="button" className="btn btn-dark btn-fw">duplicate</button>
                           <button type="button" className="btn btn-dark btn-fw">split</button>
                           <button type="button" className="btn btn-dark btn-fw">delete</button>
                        </div>
                     </div>
                  </div>
               </div> */}


               <div className="page-header-two">
                  <div className="inner-page-header-two">
                     <div className="cell-row ">
                        <div className="left-cell">
                           <h4>
                              129 <span>Total</span>
                           </h4>
                        </div>
                        <div className="right-cell">
                           <div className="inner-right-cell">
                              <ul className="navbar-nav navbar-nav-right">
                                 <li className="search-field d-none d-md-block">
                                    <form className="d-flex align-items-center h-100" action="/#">
                                       <div className="input-group">
                                          <input type="text" className="form-control bg-transparent border-0" placeholder="Search Transactions" name="searchText" onChange={(e) => { this.transactionSearchField(e) }}/>

                                          <div className="input-group-prepend bg-transparent">
                                             <img src="assets/admin/images/dashboard/search-icon.png" alt=""/>
                                          </div>
                                       </div>
                                    </form>
                                 </li>
                                 <li className="date-item">
                                 <input type="date" class="form-control" name="searchDate" value={this.state.searchDate} onChange={(e) => { this.transactionSearchField(e) }}/>
                                 </li>
                                 <li className="category-item">
                                    <select className="form-control tex-select select_btn" name="searchCategory" value={this.state.searchCategory} onChange={(e) => { this.transactionSearchField(e) }}>
                                          <option value="" className="unselect">Select Category</option>
                                          <option value="utilities">Utilities</option>
                                          <option value="mort-payment">Mort. Payment</option>
                                    </select>
                                 </li>
                                 <li className="property-item">
                                    <select className="form-control tex-select select_btn" name = "searchProperty" id="property"  value={this.state.searchProperty} onChange={(e) => { this.transactionSearchField(e) }}>
                                          <option className="unselect">Select Property</option>
                                          {
                                             this.state.allPropertyList.map((row) => (
                                                <option value={row.id} key={row.id}>{row.property_name+' ('+row.street_address + ', ' +row.city+')'}</option>
                                             ))
                                          }
                                    </select>
                                 </li>
                                 {
                                    (JSON.parse(localStorage.getItem('userDetalis')).user_type !== 'admin')?
                                        <li className="add-transaction cus-btn">
                                            <Link  to="/add-transaction" className="bg-light-green"><i className="mdi mdi-plus"></i> Add transaction</Link>
                                        </li>
                                    :''
                                 }

                                 <li className="download-item cus-btn">
                                    <Link to="#" className=" bg-orange" >Download  <i className="fa fa-download mr-3"></i></Link>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row mt-4 data-table-box" >
                  <div className="col-lg-12 grid-margin stretch-card">
                     <div className="card">
                        <div className="card-body pt-0">
                           <table className="table  table-striped">
                              <thead>
                                 <tr>
                                    <th>
                                       <span className="form-check form-check-flat form-check-primary">
                                       <label className="form-check-label">
                                       <input type="checkbox" className="form-check-input"/><i className="input-helper"></i></label>
                                       </span>
                                    </th>
                                    <th>Date</th>
                                    <th>Paid By</th>
                                    <th>Description</th>
                                    <th>Category </th>
                                    <th>Property</th>
                                    <th>Item amount</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                              {
                                 this.state.transaction_list.length > 0 ? (
                                    this.state.transaction_list.map((row)=>
                                    (
                                 <tr>
                                    <td>
                                       <span className="form-check form-check-flat form-check-primary">
                                       <label className="form-check-label">
                                       <input type="checkbox" className="form-check-input"/><i className="input-helper"></i></label>
                                       </span>
                                    </td>
                                    <td>{row.transaction_date}</td>
                                    <td>{row.payer ? (row.payer.first_name+ ' ' +row.payer.last_name) : ''}</td>
                                    <td>{row.description_title}</td>
                                    <td>{row.category} </td>
                                    <td>{row.property ? row.property.property_name : '' }</td>
                                    <td className="text-danger">{row.amount}</td>
                                    <td> <Link to = {"/transaction-details?id="+ row.id} className="vd-btn">view details</Link></td>
                                 </tr>

                                 )
                                       )
                                    ) : ( <tr>
                                       <td colSpan={8}><div className="no-data-table">No Data Found</div></td>
                                     </tr>)
                              }

                              </tbody>
                           </table>

                           {/* <div className="pagination">
                              <a  className="back-page page-btn"><i className="mdi mdi-chevron-left"></i></a>
                              <a  className="active">1</a>
                              <a  >2</a>
                              <a >3</a>
                              <a >4</a>
                              <a >5</a>
                              <a >6</a>
                              <a  className="next-page page-btn"><i className="mdi mdi-chevron-right"></i></a>
                            </div> */}
                        </div>
                        <Paginate pageCount={this.state.total}  limit={this.state.limit} handlePageCallback = {this.handlePageCallback} />
                     </div>
                  </div>
               </div>
               <footer className="footer">
                  <div className="container-fluid d-flex justify-content-center">
                     <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright ©
                     <b>DigitalTenant</b> 2022. All Rights Reserved.</span>
                  </div>
               </footer>
            </div>


            </>
        )
    }
}
export default Transactions;
