import { connectStorageEmulator } from "firebase/storage";
import { useEffect, useState } from "react";
import { Card, Button, InputGroup, Form } from "react-bootstrap";
import Select,{ components } from "react-select";
import { composeList, sendToEmail } from "./Services";
import ComponentLoader from '../../../common/ComponentLoader';
import Swal from "sweetalert2";
import showNotification from "../../../helpers/Notify";
import { getErrorMessage } from "../../../helpers/Utils";

const SendMail = (props) => {

    const [attachmentFile,setAttachmentFile] = useState([]);
    const [list,setList] = useState([]);
    const [isLoad,setIsLoad] = useState(false);
    const [sendMail,setSendMail] = useState({
                                     to:'',
                                     subject:'',
                                     message:''
                                    });
    const windowHeight = (window.innerHeight - 350);

    useEffect(() => {
        getUsersList();

    },[])

    const getUsersList = async () => {
        setIsLoad(true);
        let response = await composeList();
        setList(response.data.data);
        setIsLoad(false);
    }


    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                To
            </components.DropdownIndicator>
        );
    };

    const SelectStyle = {
        control: (base) => ({
            ...base,
            border: 0,
            boxShadow: "none",
        }),
    };

    const handleFileChange = (event) => {
        if (event.target.files.length > 0){
            var files = [];
            if( attachmentFile.length > 0 ){
                for (let i = 0; i < attachmentFile.length; i++) {
                        files.push(attachmentFile[i]);
                }
            }
            for (let i = 0; i < event.target.files.length; i++) {
                files.push(event.target.files[i]);
            }

            setAttachmentFile(files);
        };
    };



    const handleAttachmentRemove = fileKey => {
        if (attachmentFile.length > 0) {
            var files = [];
            for (let i = 0; i < attachmentFile.length; i++) {
                if (i != fileKey){
                    files.push(attachmentFile[i]);
                }
            }
            setAttachmentFile(files);
        }
    }

    const handleHideComposeBox = () => {
        props.handleCloseComposeBox(false);
    }

    const handleSendToMail = (name,e) => {
        var values = '';
        if( typeof e.target != 'undefined' ){
            values = e.target.value
        }else{
            values = e;
        }
        setSendMail({...sendMail,[name]:values})
    }

    const handleSubmitToMail = async (e) => {
        e.preventDefault();
        var composeFormData = new FormData();
        if (attachmentFile.length > 0){
            for (let i = 0; i < attachmentFile.length; i++) {
                composeFormData.append("attachment[]", attachmentFile[i]);
            }
        }
        composeFormData.append("sendDetails", JSON.stringify(sendMail));
        if( sendMail.to == '' ){
            Swal.fire({
                text: "Please specify at least one recipient",
                customClass: {
                    confirmButton: "btn btn-block btn-primary",
                },
            });
            return false;
        }else if( sendMail.subject == '' && sendMail.message == ''  ){
            Swal.fire({
                title: "Are you sure?",
                text: "Send this message without a subject or text in the body?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ef6a3d",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.isConfirmed) {
                    sendToEmail(composeFormData);
                    props.handleCloseComposeBox(false);
                    showNotification(getErrorMessage('Email successfully send'), 'success');
                }
            });
        }

        if( sendMail.subject != '' || sendMail.message != ''  ){
            sendToEmail(composeFormData);
            props.handleCloseComposeBox(false);
            showNotification(getErrorMessage('Email successfully send'), 'success');
        }

    }


    return (
        <>
            <form onSubmit={(e) => handleSubmitToMail(e)}>
                <Card className="emailCompose" style={{ top:windowHeight }}>
                    <Card.Header>
                        <span>Compose Mail</span>
                        <span onClick={(e) => handleHideComposeBox()}>
                            <i className="mdi mdi-close"></i>
                        </span>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <Select
                                isMulti
                                name="to-send"
                                options={list}
                                className="basic-multi-select composeToField"
                                classNamePrefix="select"
                                placeholder=""
                                components={{
                                    DropdownIndicator,
                                    IndicatorSeparator: () => null,
                                }}
                                styles={SelectStyle}
                                onChange={(event) =>
                                    handleSendToMail("to", event)
                                }
                            />
                            <InputGroup
                                size="sm"
                                as="label"
                                for="composeSubjectField"
                            >
                                <InputGroup.Text
                                    className="composeSubjectInput"
                                    id="inputGroup-sizing-sm"
                                >
                                    Subject
                                </InputGroup.Text>
                                <Form.Control
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    className="composeSubjectField"
                                    id="composeSubjectField"
                                    onChange={(event) =>
                                        handleSendToMail("subject", event)
                                    }
                                />
                            </InputGroup>
                            <textarea
                                className="composeTextarea form-control"
                                rows="6"
                                placeholder="Messages"
                                onChange={(event) =>
                                    handleSendToMail("message", event)
                                }
                            ></textarea>
                            <div className="attechmentContainer">
                                {attachmentFile.length > 0
                                    ? attachmentFile.map((row, i) => (
                                          <div
                                              className="attechmentFile"
                                              key={i}
                                          >
                                              <p className="fileDetails">
                                                  {row.name}
                                              </p>
                                              <p
                                                  className="fileRemove"
                                                  title="Remove attachment"
                                                  onClick={(e) =>
                                                      handleAttachmentRemove(i)
                                                  }
                                              >
                                                  <i className="mdi mdi-close"></i>
                                              </p>
                                          </div>
                                      ))
                                    : ""}
                            </div>
                        </Card.Text>
                        <div className="composerFooter">
                            <div className="composerAction">
                                <div className="sendButton">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="mb-3 ms-4"
                                    >
                                        Send
                                    </Button>
                                </div>
                                <div className="doc-upload">
                                    <label for="file-input">
                                        <i className="mdi mdi-image-filter-none"></i>
                                    </label>
                                    <input
                                        id="file-input"
                                        type="file"
                                        accept="image/*,.pdf,.doc"
                                        multiple
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </form>
        </>
    );
};

export default SendMail;
