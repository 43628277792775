import React from "react";
import SimpleReactValidator from 'simple-react-validator';
import Generalinformation from "../../components/Profile/Generalinformation";
import Address from "../../components/Profile/Address";
import AdditionalSetting from "../../components/Profile/AdditionalSetting";
import UpdateEmail from "../../components/Profile/UpdateEmail";
import { getUserDetails, updateUserProfileImage } from "../../Services/UserProfile";
import showNotification from "../../helpers/Notify";
import CardDetails from "../../components/Profile/CardDetails";

class Profile extends React.Component{
  constructor(props){
      super(props);
        this.state = {
          general_information : {},
          address : {},
          additional_setting : {},
          profile_pic_path : '',
          email : '',
          user_type : 'tenant'
        };
        this.validator = new SimpleReactValidator();
  }

  componentDidMount = async () => {
    let userDetails = await getUserDetails();
    this.setState({ 
      ...this.state,  
      general_information : userDetails.data.data.general_information ,
      address : userDetails.data.data.address,
      additional_setting : userDetails.data.data.additional_setting,
      profile_pic_path : userDetails.data.data.general_information.image,
      email :  userDetails.data.data.general_information.email,
      user_type : userDetails.data.data.general_information.user_type
    });
  }

  doFileUpload = async(e, type) => {
    let files = e.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append(`file`, files[i]);
        formData.append(`type`, type);
        let response = await updateUserProfileImage(formData); 
        if(response.data.success){
          this.setState({'profile_pic_path' : response.data.data});
            showNotification('Successfully updated', 'success');      
        }else{
          showNotification('some error occured.', 'success'); 
        }
    }
}

  render(){
    return(
    <>
    <div className="content-wrapper">
      <div className="page-header">
          <div className="left-cell">
            <h3 className="page-title">Profile Details </h3>
          </div>
          {/* <div className="right-cell">
            <div className="breadcrumb date_picker">
              <div className="breadcrumb-item active calender" aria-current="page">
                <div className="ui calendar" id="example2">
                    <div className="ui input left icon">
                      <i className="calendar icon"></i>
                      <input type="text" placeholder="calendar"/>
                    </div>
                </div>
              </div>
            </div>
          </div> */}
      </div>
      <div className="detail-cont">
          <div className="row">
            <div className="col-md-8 grid-margin stretch-card flex-column">
                  {
                    (this.state.general_information && Object.keys(this.state.general_information).length )?  (<Generalinformation data={this.state.general_information}/>) : ""
                  }
                  {
                    (Object.keys(this.state.address).length) ?  (<Address  data={this.state.address}/>) : ""
                  }
                  {
                    ( Object.keys(this.state.additional_setting).length) ? <AdditionalSetting  data={this.state.additional_setting}/> : ""
                  } 

                  <CardDetails/>
            </div>
            
            <div className="col-md-4 grid-margin stretch-card right-side-profile-cell">
                <div className="row">
                  <div className="col-sm-12">
                      <div className="card mb-4">
                        <div className="card-header d-flex align-items-start">
                            <h3 className="d-inline-block align-middle">Select profile photo
                            </h3>
                        </div>
                        <div className="profile-pic-wrapper mt-4">
                            <div className="pic-holder">
                                {
                                  this.state.profile_pic_path === '' ?  <img id="profilePic" className="pic" src="assets/admin/images/profile-img.jpg" alt="pic"/> :  <img id="profilePic" className="pic" src={ this.state.profile_pic_path} alt="pic"/>
                                }
                              {/* <img id="profilePic" className="pic" src="assets/admin/images/profile-img.jpg" alt=""/> */}
                              <input className="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto" accept="image/*"  onChange={(e) => { this.doFileUpload(e, 'banner') }} style={{opacity: "0"}}/>
                              <label htmlFor="newProfilePhoto" className="upload-file-block">
                                  <div className="text-center">
                                    <div className="mb-2">
                                        <i className="fa fa-camera fa-2x"></i>
                                    </div>
                                    <div className="text-uppercase">
                                        Update <br /> Profile Photo
                                    </div>
                                  </div>
                              </label>
                            </div>
                            <div className="fs-cus-20">
                              Upload Profile Photo
                            </div>
                        </div>
                      </div>
                      <UpdateEmail/>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <footer className="footer">
          <div className="container-fluid d-flex justify-content-center">
            <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright ©
            <b>DigitalTenant</b> 2022. All Rights Reserved.</span>
          </div>
      </footer>
    </div>
    </>
    )
  }
}
export default Profile;