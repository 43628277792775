import React from "react";
import { getAuth } from "firebase/auth";
import { db,storage,handleFileUploadOnFirebaseStorage,ref,deleteObject,getDownloadURL,collection, query, where, getDocs,doc, setDoc,onSnapshot,orderBy,addDoc,getDoc,updateDoc,Timestamp, collectionGroup,startAt,endAt } from '../../../firebase';
import './style.css';
import { timeSince } from "../../../helpers/Utils";
import { toast } from "react-toastify";

import ComponentLoader from '../../../common/ComponentLoader'

import ChatNotSelected from "./ChatNotSelected";

import {connect} from 'react-redux';

import UserList from "./Userlist";

import { list } from "./Services";



class Messages extends React.Component{


    constructor (props){
        super (props);
        this.state = {
            tenantList:[],
            inputMessage:'',
            selectChat:null,
            adminId:null,
            adminUid:null,
            setAllMessages:{},
            activeUser:'',
            lastMessageByUser:{},
            searchUser:'',
            fileLinks:[],
            nickname:'',
            adminNickName:'',
            fileUpload:false,
            imageType:['jpg','jpeg','png','gif'],
            isLoad:0,
            isChatLoad:0

        };
        this.context = this.context
       this.messagesEndRef = React.createRef();
    }


   handleKeyPress = async (event) => {
        if( ( (event.code === 'Enter' || event.type === 'click') && (this.state.fileLinks != '' || this.state.inputMessage) )     ){
            var messages = this.state.inputMessage;
            document.getElementById('inputMessageBox').value = '';
            event.target.value = '';
            var reciver_id = this.state.selectChat ;
            var sender_id = this.state.adminUid;
            var timestamp = Timestamp.fromDate(new Date());
            var id = sender_id > reciver_id ? sender_id + reciver_id:reciver_id + sender_id;
            var join_id = id;
            var sendBy = sender_id + reciver_id;
            var images = '';
            if( this.state.fileLinks.length > 0 ){
                images = JSON.stringify(this.state.fileLinks);
            }
            try{
                if( reciver_id && sender_id ){

                    await setDoc(doc(db,'lastMsg',id),{
                        join_id,
                        messages,
                        images,
                        reciver_id,
                        sender_id,
                        timestamp,
                        unread:true
                    })
                    await addDoc(collection(db,"messages",id,"chat"),{
                        join_id,
                        messages,
                        reciver_id,
                        sender_id,
                        timestamp,
                        images,
                        unread:true,
                        sendBy
                    })

                }
                if (reciver_id) {
                    this.handleShowMessages(id,sendBy);

                }
            }catch(error){
                console.log( error )
            }
            this.setState({inputMessage:''})
            this.setState({fileUpload:false});
            this.setState({fileLinks:[]})

        }
    }

    handleUploadDoc = async (e) => {
        document.getElementById('sendMsgButton').disabled = true;
        document.getElementById('inputMessageBox').readOnly  = true;

        var files = e.target.files;
        if( files.length > 0 && files.length < 6 ){
            this.setState({fileUpload:true})
            let fileUrls = [];
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                var withTime = Date.now() + i;
                var downloadFileResponse = await handleFileUploadOnFirebaseStorage('files/', file, withTime);
                fileUrls.push(downloadFileResponse);
            }
            this.setState({fileLinks:fileUrls});
            document.getElementById('sendMsgButton').disabled = false;
            document.getElementById('inputMessageBox').readOnly  = false;

        }
        if( files.length > 5 ){
            toast.error('You can send up to 5 files')
        }
    }

    handleDeteleFile = async (event) => {
        var fileName =  event.target.getAttribute('imgurlname');
        var desertRef = ref(storage, `files/${fileName}`);
        deleteObject(desertRef).then(() => {
            var newFileSet = [];
            for(var i = 0; i < this.state.fileLinks.length; i++ ) {
                if( this.state.fileLinks[i].name != fileName  ){
                    newFileSet.push(this.state.fileLinks[i]);
                }
            }
            if( !newFileSet.length ){
                this.setState({fileUpload:false})
            }
            this.setState({fileLinks:newFileSet});
        }).catch((error) => {
            console.log( error );
        });

    }

    handleDownloadFile = (image) => {
        getDownloadURL(ref(storage, image))
        .then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                var blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            /* const img = document.getElementById('myimg');
            img.setAttribute('src', url); */
        })
        .catch((error) => {
            console.log( error );
            // Handle any errors
        });

    }

    handleUpdateReadMsg = async (id,sendBy) => {
        const unreadMsg = await getDocs(query(collectionGroup(db, "chat"), where('join_id','==',id),where('unread','==',true)));
        unreadMsg.forEach((snapshot) => {
            if( snapshot.data().sendBy != sendBy ){
                updateDoc(doc(db,snapshot.ref.path), {
                        unread: false
                    });
            }
        });

    }

    handleShowMessages = async (id,sendBy) => {
        this.handleUpdateReadMsg(id,sendBy);
        var msgRef =  collection(db,'messages',id,'chat');
        var q  = query(msgRef,orderBy('timestamp','asc'));
        await onSnapshot(doc(db,'lastMsg',id),( doc ) => {
            if( typeof doc.data() != 'undefined' ){
                let lastMsg = doc.data();
                let msgdate = doc.data().timestamp.toDate();
                lastMsg.timeage = timeSince(msgdate)
                lastMsg.uid = doc.id;
                this.setState({lastMessageByUser:lastMsg})
            }
        })
        await onSnapshot(q,(querySnapshot) => {
            var msgs = [];
            querySnapshot.forEach((snapshot) => {
                if( snapshot.exists() ){
                    let images = '';
                    let msgdate = snapshot.data().timestamp.toDate();
                    let AllData = snapshot.data();
                    if( snapshot.data().images.length > 0 && snapshot.data().images != '' && typeof snapshot.data().images != 'undefined' ){
                        images  = JSON.parse(snapshot.data().images);
                        AllData.images = images;
                    }else{
                        AllData.images = [];
                    }
                    msgs.push({id:snapshot.id,details: AllData,timeage:timeSince(msgdate)})
                }
            })
            this.setState({setAllMessages:msgs});
        })

    }

    handleReadMessages = async (userId) => {}

    handleAllUserData = async (searchUser = '') => {

        this.setState({isLoad:1});
        let parameter = {searchUser:""};
        if( searchUser != '' ){
           parameter = {searchUser:searchUser}
        }
        var response = await list(parameter);
        var getusers = response.data.data;
        var querySnapshotAdmin = response.data.adminDetails

        if( getusers != '' ){
            var querySnapshot = await getDocs(query(collection(db, "users"),where("uid", "in", getusers) ));
            var userDetails = [];
            var userDocId = [];
            var createJoinId = [];
            var firebase_uid = querySnapshotAdmin.firebase_uid;
            querySnapshot.forEach((snap) => {
                if( snap.exists() ){
                    if( firebase_uid != snap.id ){
                        userDetails.push({'uid':snap.id,'details':snap.data()});
                        var joinId = firebase_uid > snap.id?firebase_uid + snap.id:snap.id + firebase_uid;
                        createJoinId.push(joinId)
                        userDocId.push(snap.id);
                    }
                }else{
                    createJoinId = [];
                }
            })
            if( createJoinId.length > 0 ){
                await onSnapshot(query(collection(db, 'lastMsg'),where('join_id','in',createJoinId),orderBy('timestamp','desc')),(querySnapshotChild) => {
                    var activeChatUsers = [];
                    querySnapshotChild.forEach((snapshot) => {
                        if( snapshot.exists() ){
                            if( snapshot.data().sender_id != firebase_uid ){
                                activeChatUsers.push(snapshot.data().sender_id)
                            }

                            if( snapshot.data().reciver_id != firebase_uid ){
                                activeChatUsers.push(snapshot.data().reciver_id)
                            }

                        }
                    })
                    var topUsers = [];
                    var lowerUsers = [];
                    for(var i = 0;i < activeChatUsers.length;i++ ){
                        var resultTop =  userDetails.find(item => item.uid == activeChatUsers[i])
                        topUsers.push(resultTop)

                    }
                    for(var i = 0;i < userDetails.length;i++ ){
                        if( !activeChatUsers.includes(userDetails [i].uid) ){
                            lowerUsers.push(userDetails[i])
                        }
                    }
                    var allUsers = [...topUsers,...lowerUsers];


                    for(var a = 0;a < allUsers.length; a++){
                        var [first,last] = allUsers[a].details.displayName.split(' ');
                        allUsers[a].nickname = `${first[0].toUpperCase()}${last[0].toUpperCase()}`;
                    }
                    this.setState({'tenantList':allUsers});

                })
            }else{
                this.setState({'tenantList':[]});
            }
        }else{
            this.setState({'tenantList':[]});
        }
        this.setState({adminUid:querySnapshotAdmin.firebase_uid})
        this.setState({adminNickName:querySnapshotAdmin.displayName})
        this.setState({isLoad:0});
    }

    componentDidMount = async () => {
        this.handleAllUserData();
        this.scrollToBottom()
        if( this.state.adminUid == '' ){
            this.setState({adminUid:localStorage.getItem('firebase_uid')});
        }

        if(typeof this.props.UserUidCode.UserUidCode.ActiveUserDetails !== 'undefined' && this.props.UserUidCode.UserUidCode.ActiveUserDetails !== '' ){
            this.handleUserActiveCallback(this.props.UserUidCode.UserUidCode.ActiveUserDetails.displayName,this.props.UserUidCode.UserUidCode.ActiveUserDetails.uid,this.props.UserUidCode.UserUidCode.ActiveUserDetails.nickName);
        }


    }

    componentWillReceiveProps = (nextProps) => {
        if( this.props.UserUidCode.UserUidCode.ActiveUserDetails.uid !== nextProps.UserUidCode.UserUidCode.ActiveUserDetails.uid ){
            this.handleUserActiveCallback(nextProps.UserUidCode.UserUidCode.ActiveUserDetails.displayName,nextProps.UserUidCode.UserUidCode.ActiveUserDetails.uid,nextProps.UserUidCode.UserUidCode.ActiveUserDetails.nickName);
        }
    }

    handleSearchUser = (event) => {
        this.setState({searchUser:event.target.value})
        this.handleAllUserData(event.target.value);
    }



    handleUserActiveCallback = async (name,userId,nickname) => {

         this.setState({isChatLoad:1});
        /*  var id = this.state.adminUid > userId?
                    this.state.adminUid + userId:
                    userId + this.state.adminUid */;

     var id = localStorage.getItem('firebase_uid') > userId?
                    localStorage.getItem('firebase_uid') + userId:
                    userId + localStorage.getItem('firebase_uid');

        this.setState({selectChat:userId})
        this.setState({activeName:name});
        this.setState({nickname:nickname});
        try{
            var sendBy = localStorage.getItem('firebase_uid') + userId
            this.handleUpdateReadMsg(id,sendBy);
            if (userId) {
                const docSnap = await getDoc(doc(db, "lastMsg", id));
                if (docSnap.data() && docSnap.data().reciver_id == userId) {
                    await updateDoc(doc(db, "lastMsg", id), { unread: false });
                }

                var msgRef =  collection(db,'messages',id,'chat');
                var q  = query(msgRef,orderBy('timestamp','asc'));

                if( userId == this.state.selectChat ){
                    await onSnapshot(q,(querySnapshot) => {
                        var msgs = [];
                        querySnapshot.forEach((doc) => {
                            let images = '';
                            let msgdate = doc.data().timestamp.toDate();
                            let AllData = doc.data();
                            if( doc.data().images.length > 0 && doc.data().images != '' && typeof doc.data().images != 'undefined' ){
                                images  = JSON.parse(doc.data().images);
                                AllData.images = images;
                            }else{
                                AllData.images = [];
                            }
                            msgs.push({id:doc.id,details: AllData,timeage:timeSince(msgdate)})
                        })
                        this.setState({setAllMessages:msgs});
                    })
                }

            }
            this.setState({isChatLoad:0});
        }catch(error){
            console.log( error );
        }

    }

    scrollToBottom = () => {
        this.messagesEndRef.current?.scrollIntoView()
    }

    handleSearchFieldEmpty = () => {
        this.setState({searchUser:''});
        this.handleAllUserData()
    }

    componentDidUpdate () {
        this.scrollToBottom()
    }

    render(){
        return (
            <>
            <div className="content-wrapper pb-0">
                <section className="row-chat mt-4">
                    <div className="card-chat" id="chat3">
                        <div className="card-chat-body">
                            <div className="row row-theme">
                                <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
                                <div className="card card-left-aside-chat">
                                    <div className="card-body pt-0 position-relative">
                                        { this.state.isLoad?<ComponentLoader />:''}
                                        <div className="p-4 left-aside-chat-padding">
                                            <div className="input-group rounded chat-search mb-3">
                                            <span className="input-group-text border-0" id="search-addon">
                                            {
                                                (this.state.searchUser == '')?
                                                    <i className=" mdi mdi-magnify "></i>
                                                :<i className="mdi mdi-close" onClick={(e) => this.handleSearchFieldEmpty()}></i>
                                            }
                                            </span>
                                            <input type="search" onChange={(e) => this.handleSearchUser(e) } value={this.state.searchUser||'' } onKeyUp={(e) => this.handleSearchUser(e) } className="form-control rounded" placeholder="Search message or users" aria-label="Search" aria-describedby="search-addon" />
                                            </div>
                                            <div className="left-aside-chat" data-mdb-perfect-scrollbar="true">
                                            <ul className="list-unstyled mb-0">

                                                {
                                                    this.state.tenantList.length > 0?
                                                        this.state.tenantList.map( (row,i) =>
                                                            <UserList key={i} details={row} usersId={row.uid} adminUid={this.state.adminUid} handleUserActiveCallback={this.handleUserActiveCallback} selectChat={this.state.selectChat}  />
                                                    ):''
                                                }

                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-6 col-lg-7 col-xl-8 position-relative">
                                    { this.state.isChatLoad?<ComponentLoader />:''}
                                    {
                                        this.state.selectChat?
                                            <>
                                                <div className="chat-header pb-3">
                                                    <div className="row row-theme align-items-center text-center text-xl-start">
                                                        <div className="col-12 col-xl-6">
                                                            <div className="row row-theme align-items-center gx-5">
                                                            <div className="col-auto pe-0">
                                                                <div className="avatar d-none d-xl-inline-block">
                                                                    <div className="avatar-img avatar-online">
                                                                        {this.state.nickname}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col overflow-hidden">
                                                                <p className="text-truncate fw-bold mb-0">{this.state.activeName}</p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-3 pe-3 chat-right-aside" data-mdb-perfect-scrollbar="true">
                                                    {
                                                        this.state.setAllMessages.length > 0?
                                                                this.state.setAllMessages.map((data,i) =>
                                                                    (data.details.sender_id == this.state.adminUid  )?
                                                                        data.details.images.length > 0?
                                                                            <div className="d-flex flex-row justify-content-end chat-right-box rc-chat-box" key={i} >
                                                                                <div className="chat-right-box-sms">
                                                                                    <div className="chat-box-content">
                                                                                        <div className="message-gallery me-3 mb-1">
                                                                                            <div className="row gx-3">
                                                                                                {
                                                                                                    data.details.images.map( (img,j) =>
                                                                                                    (this.state.imageType.includes(img.extension))?                                                                                                                      <div className="col" key={j}>
                                                                                                            <a class="download-files-icon message-gallery-icons" href={img.url} download={img.url} target="_blank" > <i class=" mdi mdi-cloud-download"></i> </a>
                                                                                                            <img className="img-fluid rounded" src={img.url} data-action="zoom" alt="" />
                                                                                                        </div>
                                                                                                    :
                                                                                                    <div class="col">
                                                                                                    <div class="row-message-file text-center justify-content-center gx-4">
                                                                                                        <div>
                                                                                                            <div class="col-auto">
                                                                                                                <a href={img.url} download  class="avatar-img bg-white text-primary" target="_blank">
                                                                                                                        <i class="mdi mdi-download "></i>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                            <div class="col-auto overflow-hidden">
                                                                                                                <p class="text-truncate mb-0 text-reset">
                                                                                                                    <a class="text-reset">{img.origialName}</a>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    </div>

                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                (data.details.messages != '' )?
                                                                                                    <p className="small p-2 mb-1 text-white rounded-3 chat-box-primary">{data.details.messages}</p>
                                                                                                :''
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <p class="small mb-3 me-3 rounded-3 text-muted float-end">{data.timeage}</p>
                                                                                </div>
                                                                                <div class="avatar-img">
                                                                                    {this.state.nickname}
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className="d-flex flex-row justify-content-end chat-right-box rc-chat-box" key={i}>
                                                                                <div className="chat-right-box-sms">
                                                                                    <div className="chat-box-content">
                                                                                    <p className="small p-2 me-3 mb-1 text-white rounded-3 chat-box-primary">{data.details.messages}</p>
                                                                                    </div>
                                                                                    <p className="small me-3 mb-3 rounded-3 text-muted float-end">{data.timeage}</p>
                                                                                </div>
                                                                                <div className="avatar-img">
                                                                                    {this.state.nickname}
                                                                                </div>
                                                                            </div>
                                                                        :
                                                                        data.details.images.length > 0?
                                                                            <div className="d-flex flex-row justify-content-start chat-right-box lc-chat-box">
                                                                                <div class="avatar-img">
                                                                                    {this.state.adminNickName}
                                                                                </div>
                                                                                <div className="chat-right-box-sms">
                                                                                    <div className="chat-box-content">
                                                                                        <div className="message-gallery ms-3 mb-1">
                                                                                            <div className="row gx-3">
                                                                                                {
                                                                                                    data.details.images.map( (img,j) =>
                                                                                                    (this.state.imageType.includes(img.extension))?                                                                                                                      <div className="col" key={j}>
                                                                                                            <a class="download-files-icon message-gallery-icons" target="_blank" href={img.url}> <i class=" mdi mdi-cloud-download"></i> </a>
                                                                                                            <img className="img-fluid rounded" src={img.url} data-action="zoom" alt="" />
                                                                                                        </div>
                                                                                                    :
                                                                                                    <div class="col">
                                                                                                    <div class="row-message-file text-center justify-content-center gx-4">
                                                                                                        <div>
                                                                                                            <div class="col-auto">
                                                                                                                <a href={img.url} target="_blank" download  class="avatar-img bg-white text-primary">
                                                                                                                        <i class="mdi mdi-download "></i>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                            <div class="col-auto overflow-hidden">
                                                                                                                <p class="text-truncate mb-0 text-reset">
                                                                                                                    <a class="text-reset">{img.origialName}</a>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    </div>

                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                (data.details.messages != '' )?
                                                                                                    <p className="small mt-2 p-2 mb-1 rounded-3 chat-box-white">{data.details.messages}</p>
                                                                                                :''
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <p class="small mb-3 ms-3 rounded-3 text-muted">{data.timeage}</p>
                                                                                </div>

                                                                            </div>
                                                                        :
                                                                        <div className="d-flex flex-row justify-content-start chat-right-box lc-chat-box" key={i}>
                                                                            <div className="avatar-img">
                                                                                {this.state.adminNickName}
                                                                            </div>
                                                                            <div className="chat-right-box-sms">
                                                                                <div className="chat-box-content">
                                                                                <p className="small p-2 ms-3 mb-1 rounded-3 chat-box-white">
                                                                                    {data.details.messages}
                                                                                </p>
                                                                                </div>
                                                                                <p className="small ms-3 mb-3 rounded-3 text-muted ">{data.timeage}</p>
                                                                            </div>
                                                                        </div>
                                                                )
                                                        :<ChatNotSelected title="No messages yet..." />
                                                    }
                                                    <div ref={this.messagesEndRef} />
                                                </div>
                                                <div className="d-flex justify-content-start align-items-center me-3 chat-form-content">
                                                    <div className={this.state.fileUpload ? "dz-preview bg-dark dz-preview-moved pb-10 pt-3 px-2":"dz-preview bg-dark dz-preview-moved pb-10 pt-3 px-2 d-none"}  id="dz-preview-row" data-horizontal-scroll="">

                                                            {
                                                                this.state.fileLinks.length > 0?
                                                                    this.state.fileLinks.map( (imgs,k) =>

                                                                    <div className="theme-file-preview position-relative mx-2 dz-processing dz-image-preview dz-error dz-complete" key={k} >
                                                                    <div className="avatar-img avatar-lg dropzone-image-preview">
                                                                            {
                                                                                (this.state.imageType.includes(imgs.extension))?
                                                                                    <img src={imgs.url} alt="avatar" class="d-flex align-self-center" />
                                                                                :<span class="avatar-text" title={imgs.origialName}> <i class="mdi mdi-file-outline"></i>  </span>

                                                                            }
                                                                    </div>
                                                                    <a className="badge badge-circle bg-body text-white position-absolute top-0 end-0 m-2"  data-dz-remove=""  >
                                                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" className="css-i6dzq1" imgurlname={imgs.name} onClick={this.handleDeteleFile.bind(this)}>
                                                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                                                    </svg>
                                                                    </a>
                                                                    </div>
                                                                    )
                                                                :'File Uploading...'
                                                            }
                                                    </div>
                                                    <div className="chat-form rounded-pill bg-light w-100" data-emoji-form="">
                                                        <div className="row align-items-center gx-0">
                                                            <div className="col-auto">
                                                                <div className="file-paperclip btn btn-icon btn-link text-body rounded-circle dz-clickable">
                                                                    <input type="file" id="file-input" multiple onChange = { (e)  => this.handleUploadDoc(e)} />
                                                                    <label htmlFor="file-input">
                                                                    <i className="mdi mdi-paperclip "></i>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control form-control-lg" id="inputMessageBox"
                                                                    onKeyDown={this.handleKeyPress} onFocus={() => this.handleReadMessages(this.state.selectChat,this.state.adminUid) }  onChange={(e) => this.setState({inputMessage:e.target.value})}  placeholder="Type your message..." />
                                                                </div>
                                                            </div>
                                                            <div className="col-auto">
                                                            <button className="btn btn-icon btn-primary rounded-circle ms-5" id="sendMsgButton" onClick={this.handleKeyPress}>
                                                                <i className="mdi mdi-send"></i>
                                                            </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        :<ChatNotSelected title="No conversation selected yet..." />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
	UserUidCode:state.UserUidCode
})

export default connect(mapStateToProps)(Messages);
